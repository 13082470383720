<template>
  <button
    :class="`${variant} btn-${size}`"
    class="btn"
    v-bind="$attrs"
    :disabled="disabled"
    :type="submit ? 'submit' : 'button'"
  >
    <slot />
  </button>
</template>

<script lang="ts">
export default defineNuxtComponent({
  props: {
    variant: {
      type: String,
      required: true,
    },
    size: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    submit: {
      type: Boolean,
      default: false,
    },
  },
})
</script>

<style lang="scss" scoped>
.btn-shadow {
  box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.32);
}

.btn-shadow-large {
  box-shadow: 0 0 8px 0 rgba(0, 0, 0, 0.32);
}

.btn-shadow-none {
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.32);
}

.btn {
  min-width: 200px;
  min-height: 40px;
  @apply px-4;
  @apply uppercase;
  @apply rounded-sm;
  @apply font-bold;
  @apply btn-shadow;
  @apply transition-colors duration-300;
  @apply text-base;
  @apply inline-flex;
  @apply items-center;
  @apply justify-center;
  &:disabled {
    @apply cursor-not-allowed;
  }
}

.btn:hover {
  @apply btn-shadow-large;
}

.btn:active {
  @apply btn-shadow-none;
}

.btn-md {
}

.btn-sm {
  @apply w-200p;
  @apply h-40p;
}

.btn-sm {
  min-width: 150px;
  min-height: 40px;
  @apply w-150p;

  @screen md {
    @apply w-200p;
    @apply h-40p;
  }
}

.btn-lg {
  @apply w-400p;
  @apply h-65p;
}

.btn-lg-w-full {
  @apply w-full;
  @apply h-65p;
}

.btn-h-full {
  @apply h-full;
  @apply w-140p;
}

.btn-w-full {
  min-width: 100px;
  @screen lg {
    min-width: 150px;
  }
  @apply w-full;
}

.btn-desk-full-mobile-small {
  @apply w-150p;
  @screen lg {
    @apply w-full;
  }
}

.btn-full {
  @apply w-full;
  @apply max-w-full;
  @apply min-w-full;

  @screen md {
    @apply w-full;
  }

  @screen lg {
    @apply w-full;
  }
}

.primary {
  @apply bg-spl-primary;
  @apply text-white;

  @screen sm {
    &:hover {
      @apply bg-spl-primary-dark;
    }
  }
  &:disabled {
    @apply bg-spl-gray-2;
    @apply border-none;
  }
}

.acent {
  @apply bg-spl-acent-dark;
  @apply text-white;

  @screen sm {
    &:hover {
      @apply bg-spl-acent;
    }
  }
  &:disabled {
    @apply bg-spl-acent;
    @apply border-none;
  }
}

.acent-green {
  @apply bg-spl-primary;
  @apply text-white;

  @screen sm {
    &:hover {
      @apply bg-spl-primary-dark;
    }
  }
  &:disabled {
    @apply bg-spl-secondary;
    @apply border-none;
  }
}

.secondary {
  @apply bg-spl-secondary;
  @apply text-spl-dark;

  @screen sm {
    &:hover {
      @apply bg-spl-secondary-dark;
    }
  }
}

.link {
  @apply text-spl-gray-4;
}


.link {
  text-transform: none;
  min-width: unset;
  min-height: unset;

  @apply p-0 px-2;
  @apply m-0;
  @apply justify-start;
  @apply font-normal;
  @apply text-spl-dark;
  @apply underline;
  box-shadow: none;
}

.link-right {
  @apply link;
  @apply justify-end;
}

.vertical {
  @apply w-auto;
  height: inherit;

  min-width: unset;
  min-height: unset;

  @apply px-0;

  @apply bg-spl-gray-1;
}

.round {
  @apply w-auto;
  height: inherit;

  min-width: unset;
  min-height: unset;

  @apply px-4 py-2;
  @apply bg-spl-gray-1;

  @apply rounded-full;
}

.round:hover {
  @apply text-spl-dark;
}
</style>
