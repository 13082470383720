export type PictureDTO = {
  order: number;
  entity_uuid: string;
  relation_type: string;
  file: {
    uuid: string;
    name: string;
    url: string;
  };
};

export class Picture {
  constructor(
    public uuid: string,
    public rel: string,
    public url: string,
  ) {
  }

  static from(dto: PictureDTO): Picture {
    const { uuid, url } = dto.file

    return new Picture(
      uuid,
      dto.relation_type,
      url,
    )
  }

  static getDefaultImage(): Picture {
    const uuid = '11111111-0000-0000-0000-111111111111'
    const url = 'https://marketplace-assets.s3.eu-west-3.amazonaws.com/2020/10/45656-pre-barrosapalace-spa4206.jpg'

    return new Picture(
      uuid,
      'images',
      url,
    )
  }
}
