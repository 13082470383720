// ----------------------------------------------------------
// Setup script
function addScript(src: string) {
  const s = document.createElement('script')
  s.setAttribute('src', src)
  document.body.appendChild(s)
}

function ensureGA4(key: string, conversionKey: string): boolean {
  const win: any = window
  if (win.gtag) {
    return true
  }

  const gaID = key

  // Antiguo: GTM-NR3WH34
  // Nuevo  : GTM-P4FK9SC2
  // Google Tag Manager
  const config = useRuntimeConfig()
  const gtagManagerID = config.public.gtmKey

  if (!gaID || !gtagManagerID) {
    console.warn('No Google Analitics key supplied')
    return false
  }

  addScript(`https://www.googletagmanager.com/gtag/js?id=${gaID}`)
  // addScript(`https://metrics.spalopia.com/gtag/js?id=${gaID}`)
  win.dataLayer = win.dataLayer || []
  function gtag(...args: any[]) {
    // eslint-disable-next-line prefer-rest-params
    return win.dataLayer.push(arguments)
  }

  win.gtag = gtag

  // Google tag manager
  const installGTM = function (w: any, d: any, s: any, l: any, i: any) {
    w[l] = w[l] || []
    w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' })
    const f = d.getElementsByTagName(s)[0]

    const j = d.createElement(s)
    const dl = l !== 'dataLayer' ? '&l=' + l : ''

    j.async = true
    j.src = 'https://metrics.spalopia.com/gtm.js?id=' + i + dl
    f.parentNode.insertBefore(j, f)
  }

  // GTM
  installGTM(window, document, 'script', 'dataLayer', gtagManagerID)

  const noScript = document.createElement('noscript')
  const iframe = document.createElement('iframe')
  iframe.setAttribute('width', '0')
  iframe.setAttribute('height', '0')
  iframe.setAttribute('style', 'display:none;visibility:hidden')
  iframe.setAttribute('src', `https://metrics.spalopia.com/ns.html?id=${gtagManagerID}`)
  noScript.appendChild(iframe)
  document.body.append(noScript)

  // END GTM

  gtag('consent', 'default', {
    'ad_storage': 'denied',
    'ad_user_data': 'denied',
    'ad_personalization': 'denied',
    'analytics_storage': 'granted'
  })

  gtag('js', new Date())

  gtag('config', gaID, { allow_enhanced_conversions: true })

  // GA4 conversion
  if (conversionKey) {
    gtag('config', conversionKey)
  }

  return true
}
// ----------------------------------------------------------
type GAEvent = Record<string, string | boolean | object> | undefined;

export default class GA4EventManager {
  static events: Array<{ name: string; meta: GAEvent }> = []
  public static key = ''
  public static keyConversion = ''
  public static running = false

  static pushEvent(name: string, meta: GAEvent) {
    // GA4 conversion
    if (this.keyConversion && name === 'conversion' && meta) {
      meta.send_to = this.keyConversion + '/i0lJCLj_5aYZEPib38ED'
    }

    this.events.push({ name, meta })
    this.startEventLoop()
  }

  static startEventLoop() {
    if (this.running) {
      return
    }

    this.running = ensureGA4(this.key, this.keyConversion)
    setInterval(() => this.tryToSend(), 2000)
  }

  static tryToSend() {
    const win = (window as any)
    const gtag = win.gtag

    if (this.running && this.events.length && gtag) {
      const event = this.events.shift()
      if (event) {
        const { name, meta } = event

        if (name.startsWith('consent')) {
          const consentType = name.split('.')[1]
          gtag('consent', consentType, meta)
        } else if (name.startsWith('set.')) {
          const setType = name.split('.')[1]
          gtag('set', setType, meta)
        } else if (event.meta && event.meta.transaction_id) {
          // GTM Event style
          win.dataLayer.push({ ecommerce: null })
          win.dataLayer.push({
            event: event.name,
            ecommerce: event.meta,
          })
        } else {
          gtag('event', name, meta)
        }
      }
    }
  }
}
