import { default as indexPlnFHA4YvWMeta } from "/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/index.vue?macro=true";
import { default as buyerjA8ZzGwiI8Meta } from "/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/checkout/buyer.vue?macro=true";
import { default as payment8lgJHumYPSMeta } from "/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/checkout/payment.vue?macro=true";
import { default as confirmationHm1lMZif4GMeta } from "/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/confirmation.vue?macro=true";
import { default as korRrslwFI2cMeta } from "/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/ko.vue?macro=true";
import { default as startoeFinnkoBeMeta } from "/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/purchase/start.vue?macro=true";
import { default as checkout7vzqD3m3noMeta } from "/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/service-booking/checkout.vue?macro=true";
import { default as buyerAO1ST7EwbUMeta } from "/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/checkout/buyer.vue?macro=true";
import { default as payment3QY61BGKDJMeta } from "/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/checkout/payment.vue?macro=true";
import { default as confirmationO66XQo1snKMeta } from "/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/confirmation.vue?macro=true";
import { default as challengegF7bz1mYklMeta } from "/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/redsys/challenge.vue?macro=true";
import { default as startpofgpHgDidMeta } from "/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/voucher/start.vue?macro=true";
import { default as indexjbgBm9fPveMeta } from "/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/index.vue?macro=true";
import { default as Redirection_46pagerCHv4cd173Meta } from "/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue?macro=true";
export default [
  {
    name: indexPlnFHA4YvWMeta?.name ?? "lang",
    path: indexPlnFHA4YvWMeta?.path ?? "/:lang?",
    props: indexPlnFHA4YvWMeta?.props ?? false,
    meta: indexPlnFHA4YvWMeta || {},
    alias: indexPlnFHA4YvWMeta?.alias || [],
    redirect: indexPlnFHA4YvWMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/index.vue")
  },
  {
    name: buyerjA8ZzGwiI8Meta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-buyer",
    path: buyerjA8ZzGwiI8Meta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/buyer",
    props: buyerjA8ZzGwiI8Meta?.props ?? false,
    meta: buyerjA8ZzGwiI8Meta || {},
    alias: buyerjA8ZzGwiI8Meta?.alias || [],
    redirect: buyerjA8ZzGwiI8Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/checkout/buyer.vue")
  },
  {
    name: payment8lgJHumYPSMeta?.name ?? "lang-purchase-packagepurchaseuuid-checkout-payment",
    path: payment8lgJHumYPSMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/checkout/payment",
    props: payment8lgJHumYPSMeta?.props ?? false,
    meta: payment8lgJHumYPSMeta || {},
    alias: payment8lgJHumYPSMeta?.alias || [],
    redirect: payment8lgJHumYPSMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/checkout/payment.vue")
  },
  {
    name: confirmationHm1lMZif4GMeta?.name ?? "lang-purchase-packagepurchaseuuid-confirmation",
    path: confirmationHm1lMZif4GMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/confirmation",
    props: confirmationHm1lMZif4GMeta?.props ?? false,
    meta: confirmationHm1lMZif4GMeta || {},
    alias: confirmationHm1lMZif4GMeta?.alias || [],
    redirect: confirmationHm1lMZif4GMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/confirmation.vue")
  },
  {
    name: korRrslwFI2cMeta?.name ?? "lang-purchase-packagepurchaseuuid-ko",
    path: korRrslwFI2cMeta?.path ?? "/:lang?/purchase/:packagepurchaseuuid()/ko",
    props: korRrslwFI2cMeta?.props ?? false,
    meta: korRrslwFI2cMeta || {},
    alias: korRrslwFI2cMeta?.alias || [],
    redirect: korRrslwFI2cMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/purchase/[package-purchase-uuid]/ko.vue")
  },
  {
    name: startoeFinnkoBeMeta?.name ?? "lang-purchase-start",
    path: startoeFinnkoBeMeta?.path ?? "/:lang?/purchase/start",
    props: startoeFinnkoBeMeta?.props ?? false,
    meta: startoeFinnkoBeMeta || {},
    alias: startoeFinnkoBeMeta?.alias || [],
    redirect: startoeFinnkoBeMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/purchase/start.vue")
  },
  {
    name: checkout7vzqD3m3noMeta?.name ?? "lang-service-booking-checkout",
    path: checkout7vzqD3m3noMeta?.path ?? "/:lang?/service-booking/checkout",
    props: checkout7vzqD3m3noMeta?.props ?? false,
    meta: checkout7vzqD3m3noMeta || {},
    alias: checkout7vzqD3m3noMeta?.alias || [],
    redirect: checkout7vzqD3m3noMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/service-booking/checkout.vue")
  },
  {
    name: buyerAO1ST7EwbUMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-buyer",
    path: buyerAO1ST7EwbUMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/buyer",
    props: buyerAO1ST7EwbUMeta?.props ?? false,
    meta: buyerAO1ST7EwbUMeta || {},
    alias: buyerAO1ST7EwbUMeta?.alias || [],
    redirect: buyerAO1ST7EwbUMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/checkout/buyer.vue")
  },
  {
    name: payment3QY61BGKDJMeta?.name ?? "lang-voucher-voucherpurchaseuuid-checkout-payment",
    path: payment3QY61BGKDJMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/checkout/payment",
    props: payment3QY61BGKDJMeta?.props ?? false,
    meta: payment3QY61BGKDJMeta || {},
    alias: payment3QY61BGKDJMeta?.alias || [],
    redirect: payment3QY61BGKDJMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/checkout/payment.vue")
  },
  {
    name: confirmationO66XQo1snKMeta?.name ?? "lang-voucher-voucherpurchaseuuid-confirmation",
    path: confirmationO66XQo1snKMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/confirmation",
    props: confirmationO66XQo1snKMeta?.props ?? false,
    meta: confirmationO66XQo1snKMeta || {},
    alias: confirmationO66XQo1snKMeta?.alias || [],
    redirect: confirmationO66XQo1snKMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/confirmation.vue")
  },
  {
    name: challengegF7bz1mYklMeta?.name ?? "lang-voucher-voucherpurchaseuuid-redsys-challenge",
    path: challengegF7bz1mYklMeta?.path ?? "/:lang?/voucher/:voucherpurchaseuuid()/redsys/challenge",
    props: challengegF7bz1mYklMeta?.props ?? false,
    meta: challengegF7bz1mYklMeta || {},
    alias: challengegF7bz1mYklMeta?.alias || [],
    redirect: challengegF7bz1mYklMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/voucher/[voucher-purchase-uuid]/redsys/challenge.vue")
  },
  {
    name: startpofgpHgDidMeta?.name ?? "lang-voucher-start",
    path: startpofgpHgDidMeta?.path ?? "/:lang?/voucher/start",
    props: startpofgpHgDidMeta?.props ?? false,
    meta: startpofgpHgDidMeta || {},
    alias: startpofgpHgDidMeta?.alias || [],
    redirect: startpofgpHgDidMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/[[lang]]/voucher/start.vue")
  },
  {
    name: indexjbgBm9fPveMeta?.name ?? "index",
    path: indexjbgBm9fPveMeta?.path ?? "/",
    props: indexjbgBm9fPveMeta?.props ?? false,
    meta: indexjbgBm9fPveMeta || {},
    alias: indexjbgBm9fPveMeta?.alias || [],
    redirect: indexjbgBm9fPveMeta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/pages/index.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 0",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-deluxe-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-anticelulitico-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/depilacion-brazos-espalda-pecho-o-abdomen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/masaje-dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-experience-by-leo-deluxe/jacuzzi-pago-hotel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-brisa-de-ganesha-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-relajante-50-min-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/microdermoabrasion-con-punta-de-diamante-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 9",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacion-bikini-brasil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 10",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-facial-biolifting-tensor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 11",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/depilacion-de-entrecejo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 12",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/at-desayuno-spa-con-masaje-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 13",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/masaje-anticelulitico-y-maderoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 14",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacion-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 15",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/depilacion-brazos-completos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 16",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-vip-masaje-50-min-recepcion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 17",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/masaje-de-espalda-holistico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 18",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/masaje-local-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 19",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/facial-timexpert-white/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 20",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/exfoliacion-corporal-cerveza-masaje-hidratante-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 21",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/especial-black-friday-circuito-spa-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 22",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/reflexologia-60min-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 23",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/promocion-circuito-spa-reina-servicio-de-te-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 24",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/pedicura-basica-60min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 25",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-nucia-spa-masshotel/circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 26",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/relajante-30-experiencia-bodyna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 27",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/santa-cruz-de-tenerife/spa-callao-sport/dayspa-spa-almuerzo-buffet-piscina-exterior-y-servicio-de-hamacas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 28",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-esencia-naturales-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 29",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-pazo-los-escudos/especial-black-friday-circuito-celta/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 30",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/semipermanente-con-pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 31",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/hb-spa-50-cava-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 32",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/pack-spa-enamorados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 33",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/especifico-localizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 34",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hspa-valparaiso/reunion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 35",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-nucia-spa-masshotel/endless-summer-barcelo-la-nucia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 36",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-real-taoista/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 37",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/tratamiento-antimanchas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 38",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/tratamiento-facial-kobido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 39",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/masaje-de-aromaterapia-la-cala-80min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 40",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/depilacion-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 41",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/especial-papa-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 42",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/socio-trainer/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 43",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/kobido-o-lifting-facial-japones-circuito-spa-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 44",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/lomi-lomi-nui-55-min-promocion-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 45",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/bono-mensual-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 46",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/7-circuito-deloix/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 47",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/propina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 48",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/masaje-son-brull-sanctuary-90min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 49",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/drenaje-linfatico-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 50",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/programa-glamour-2-pax-recepcion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 51",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/depilacion-de-abdomen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 52",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/pack-acido-hialuronico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 53",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/tinte-de-pestanas-o-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 54",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/pedicura-basica-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 55",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/centro-bienestar-sunprime-atlantic/ritual-canario/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 56",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/drenaje-linfatico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 57",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/black-friday-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 58",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/tarjeta-regalo-business/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 59",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-15-min-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 60",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/pedicuralacadodecoracion-fantasia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 61",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/depilacion-brazos-completos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 62",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/ritual-cold-cream-marine-nutricion-y-confort/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 63",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/true-love-circuito-spa-san-valentin-con-cava-y-bombones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 64",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/circuito-spa-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 65",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/pedicura-spaxion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 66",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 67",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/black-friday-2x1-masaje-30-circuito-spa-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 68",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/black-friday-2x1-masaje-30-entrada-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 69",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-flor-de-cerezo-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 70",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/pack-cerveza-dia-del-padre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 71",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/pack-navidad-relax-pareja-circuito-spa-ritual-gold-theraphy-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 72",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-apacible-paraiso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 73",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/programa-detox/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 74",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/ritual-de-la-india-crema-udvartana-firmeza-y-adelgazamiento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 75",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 76",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/envoltura-de-algas-barro-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 77",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/hesperides-thalasso-spa/experiencia-semana-santa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 78",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/cura-adelgazante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 79",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/pack-premium-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 80",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/tratamiento-facial-especial-hombres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 81",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/te-lo-mereces/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 82",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/circuito-arabe-2px-agencia-22e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 83",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-feb-2020/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 84",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/bono-6-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 85",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-bienestar-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 86",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/exfolicion-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 87",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-despalda-descontracturante-con-aceite-naranja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 88",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/ritual-sensorial-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 89",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/pedicura-completa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 90",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/circuito-hidroterapia-spa-y-cena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 91",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hotel-spa-cadiz-plaza/quiromasaje-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 92",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/circuito-spa-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 93",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/radiofrecuencia-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 94",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/permanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 95",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/tratamiento-facial-y-corporal-para-piel-radiante-con-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 96",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-15-min-pago-directo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 97",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/envoltura-de-algas-marinas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 98",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/15min-chocolate-o-facial-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 99",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/masaje-relajante-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 100",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/spa-buffet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 101",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-15-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 102",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/raices-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 103",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/masaje-holistico-relajante-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 104",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-despacio-h10-benidorm/sublime-skin-deluxe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 105",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guadalajara/spa-castilla-termal-brihuega/ducha-vichy-a-cuatro-manos-con-peeling-de-miel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 106",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/blanqueamiento-dental-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 107",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/cura-especial-deportistas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 108",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/experiencia-renovadora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 109",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/manicura-infantil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 110",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/ritual-de-la-felicidad-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 111",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/oferta-9-tto-corporal-y-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 112",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/envoltura-con-barro-del-mar-muerto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 113",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/ritual-equilibrante-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 114",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/relax-andalusi-en-pareja-circuito-spa-ritual-al-andalus/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 115",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/pedicura-son-brull-sanctuary/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 116",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/masaje-sueco-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 117",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-relajante-con-aceite-de-uvas-de-la-suerte-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 118",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/spa-attica-21-villalba/tratamiento-corporal-nutritivo-a-la-miel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 119",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/1-dia-en-spa-diamante-con-masaje-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 120",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/lomi-lomi-nui-70-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 121",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/piernas-enteras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 122",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/tratamiento-facial-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 123",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-cuerpo-completo-terapeutico-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 124",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/pack-rosa-cerveza-hidromiel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 125",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/especial-san-valentin-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 126",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/cura-antioxidante-revitalizante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 127",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-sa-voga/flotarium-duo-15/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 128",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/bienestar-familiar-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 129",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 130",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/pedicura-con-esmalte-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 131",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/bragi-exclusive/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 132",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 133",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/mas-antiestres-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 134",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/energizer-55-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 135",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/pack-spa-enamorados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 136",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/masaje-energy-tea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 137",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/ritual-flor-de-cerezo-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 138",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/envoltura-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 139",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/masaje-equilibrante-shiatsu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 140",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/drain-body-bandages/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 141",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/tratamiento-oxigenante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 142",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/promocion-especial-enamorados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 143",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/solar-supreme/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 144",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/masaje-especifico-localizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 145",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/pro-magic-stones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 146",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-citricos-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 147",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/chocomassage-en-pareja-con-extra-karite/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 148",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/bono-6-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 149",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/circulatorio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 150",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/ritual-de-la-polinesia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 151",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/esmaltado-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 152",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/daypass-neptuno-spa-chill-spa-50-beach-club/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 153",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/burgos/spa-tudanca/ritual-relajante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 154",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hspa-valparaiso/acceso-circuito-spa-2hrs-comida-viernes-a-domingo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 155",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/permanente-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 156",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/oporto/the-spa-by-sheraton-porto/embarazadas-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 157",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/laura-leon-wellness/exfoliaciones-sensasiones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 158",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-shi-zen-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 159",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/peeling-y-envoltura-corporal-mediterranea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 160",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/castellon/balneario-de-montanejos/masaje-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 161",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/circuito-piscinas-2-horas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 162",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 163",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/masaje-con-pindas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 164",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/relax-craneo-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 165",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/anti-age-by-corallium-cosmetics/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 166",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-deep-tissue-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 167",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/kit-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 168",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/suite-luxury-oriental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 169",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/spa-tapas-2-pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 170",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/masaje-relajante-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 171",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-fresh-piernas-pies-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 172",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/honey-sesame-body-glow/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 173",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-aqua-shot/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 174",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/ritual-de-la-polinesia-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 175",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-sueco-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 176",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/ritual-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 177",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/viaje-a-la-provenza-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 178",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/wine-sensations-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 179",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/masaje-relajante-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 180",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/ritual-evasion-club-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 181",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/10-sesiones-circuito-beer-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 182",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/depilacion-de-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 183",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/depilacion-con-diodo-zona-media/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 184",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/programa-detox/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 185",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/detox-treatment/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 186",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/sublime-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 187",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/tratamiento-corporal-body-sculpt-anti-cell-con-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 188",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-para-2-oferplan/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 189",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/depilacion-integral/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 190",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/maquillaje-basico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 191",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/piedras-calientes-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 192",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/tarjeta-regalo-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 193",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/tinte-de-pelo-corto-lavar-y-marcar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 194",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/jarra-regalo-dia-del-padre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 195",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/ritual-ayurvedico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 196",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/masaje-celta-de-piedras-calientes-y-frias-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 197",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 198",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/estancia-desayuno-completo-spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 199",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/masaje-exotico-ceilan/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 200",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/tratamiento-facial-deluxe-mille/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 201",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/circuito-privado-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 202",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/piscinas-exteriores-almuerzo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 203",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/tratamiento-pies-bodyna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 204",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-de-cortesia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 205",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/corte-de-pelo-caballeros/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 206",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/drenaje-linfatico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 207",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/programa-fun-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 208",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-deluxe-30-minutos-masaje-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 209",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/ritual-de-miel-y-almendras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 210",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-spaxion-hg-la-molina/tratamiento-wellness-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 211",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/programa-relax-total/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 212",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/duo-perla-2-circuitos-termales-2-masajes-descontracturantes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 213",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-hotel-hyltor/sauna-finlandesa-30min-servicio-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 214",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/black-friday-2x1-masaje-80-entrada-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 215",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-aromatico-15-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 216",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-especial-embarazadas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 217",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/especial-te-quiero-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 218",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/bono-5-masajes-parciales/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 219",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/pack-spa-enamorados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 220",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/masaje-reductor-o-reafirmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 221",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/masaje-con-aromaterapia-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 222",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/duo-san-valentin-circuito-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 223",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/bioterapia-sistemica-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 224",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/drenaje-linfatico-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 225",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/envoltura-lodos-del-mar-muerto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 226",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/the-spa-by-signature-sir-victor/love-massage-scrub-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 227",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/exfoliacion-sublime-alisante-con-monoi-y-noni-de-tahiti/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 228",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-aromatico-15-minutos-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 229",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/me-me-experience-embarazo-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 230",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/hesperides-thalasso-spa/piedras-calientes-circuito-thalasso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 231",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/masaje-relajante-20min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 232",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-masaje-ayurvedico-30-masajes-de-la-tierra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 233",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/body-care-uli-massage-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 234",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/mallorca-relax-enjoy-voucher/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 235",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/porque-ellas-se-lo-merecen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 236",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/facial-para-hombres-80min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 237",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-apacible-paraiso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 238",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/masaje-balines/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 239",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/hard-rock-spa-marbella/sweet-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 240",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/pack-algas-adelgazante-corporal-y-remineralizante-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 241",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/lavar-y-marcar-cabello-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 242",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/pack-ritual-parejas-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 243",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/masaje-esencia-natural-algas-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 244",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/relax-andalusi-en-pareja-circuito-spa-ritual-al-andalus/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 245",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-relajante-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 246",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-by-alina-vriel/masaje-con-velas-y-circuito-spa-para-dos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 247",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/maquillaje-de-dia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 248",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-seda-club/exfoliacion-corporal-completa-envoltura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 249",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/timexpert-c-iluminador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 250",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/maquillaje-fiesta/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 251",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/centro-bienestar-sunprime-atlantic/masaje-ayurveda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 252",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 253",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/ritual-de-vitamina-c-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 254",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/mwc-special-ritual-digital-wellness-escape-40-circuito-aguas-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 255",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/exfoliacion-natura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 256",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/relax-choco-en-pareja-circuito-spa-chocolaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 257",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/tecnica-reductora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 258",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-facial-anti-estres-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 259",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/ritual-combinado-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 260",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/masaje-exclusivity-de-cerveza-55min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 261",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/extra-relaxing-love-circuito-beer-spa-pareja-masaje-25-minutos-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 262",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-de-cerveza-degustacion-de-1-cerveza-especial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 263",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/depilacion-de-ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 264",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/segovia/spa-spaxion-convento-capuchinos/ritual-experiencia-oriental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 265",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-spa-beach/especial-dia-de-la-madre-con-masaje-50-y-piscina-exterior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 266",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/oriental-manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 267",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/circuito-alegria-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 268",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/pack-navidad-glow-up-circuito-spa-tratamiento-lifting-sicilium-marin-pack-regalo-pack-para-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 269",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/masaje-en-pareja-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 270",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/depilacion-con-diodo-zona-pequena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 271",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-masaje-50-experiencia-desayuno-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 272",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-masaje-50-experiencia-matinal-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 273",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/masaje-futura-mama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 274",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/rejuvenecimiento-facial-con-laser-diodo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 275",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cura-semana-antiestres-6-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 276",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/reina-de-egipto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 277",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/experiencia-relax-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 278",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/circuito-alegria-grupo6px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 279",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/experiencia-hidrotermal-relajante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 280",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/criolipolisis/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 281",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 282",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/bano-de-olivino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 283",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/pack-navidad-glow-up-circuito-spa-tratamiento-lifting-sicilium-marin-pack-regalo-pack-para-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 284",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/exfoliacion-purificante-con-savon-noir-beldi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 285",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-pureza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 286",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/mascarilla-facial-para-la-bella-durmiente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 287",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/bono-10-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 288",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/tono-sobre-tono/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 289",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-masmonzon/masaje-parcial-de-cuerpo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 290",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/bioterapia-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 291",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/radiofrecuencia-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 292",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/exfoliacion-corporal-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 293",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/vila-real/natureness-spa/spa-60-1-adulto-1-crianca/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 294",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/especial-pareja-circuito-spa-y-belleza-masaje-de-25min-servicio-de-te-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 295",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-ayurvedico-de-la-india-tonificante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 296",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/depilacion-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 297",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-de-5-masajes-80-all-days/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 298",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/facial-purificante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 299",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-25min-chocolate-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 300",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-de-cerveza-de-55-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 301",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/harmonie-desprit-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 302",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/relax-choco-en-pareja-circuito-spa-chocolaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 303",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/masaje-abhyanga/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 304",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-spa-beach/serenidad-para-mama-con-masaje-30-y-piscina-exterior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 305",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-aqua-shot/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 306",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/black-friday-2x1-masaje-30-entrada-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 307",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/black-friday-2x1-masaje-30-circuito-spa-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 308",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/pro-active-h-a-serum/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 309",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/lvb-spa-cava-o-coctel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 310",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/manicura-spaxion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 311",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/spa-hotel-blu-almansa/thalassomassage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 312",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-15-min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 313",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 314",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/color-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 315",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-15min-chocolate-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 316",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-ninos-20-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 317",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/pedicura-permanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 318",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/pack-cerveza-verde/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 319",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/spa-manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 320",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-craneocervical-15/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 321",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/masaje-web-25-min-pack-para-dos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 322",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/rebajas-10-1-entrada-doble-acceso-spa-50-total-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 323",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/especial-navidad-serenity-spa-masaje-copa-cava-y-cofre-alqvimia-regalo-para-ti/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 324",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/especial-san-valentin-om-in-love/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 325",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/corte-y-lavado-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 326",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/presoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 327",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/ayurveda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 328",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-esencias-naturales-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 329",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/drenaje-facial-firmas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 330",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/depilacion-ingles-brasilenas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 331",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/tinte-de-cejas-o-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 332",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/royal-spa-especial-for-two/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 333",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-attica21-vigo/sala-privada-terapeutica-90min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 334",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-grupo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 335",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/oasis-relax-gourmet-silver-edition-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 336",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-relax-gourmet-silver-edition-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 337",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/spa-kids/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 338",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/sublime-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 339",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/romantic-spa-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 340",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/aladinia-spaalmuerzo-o-cena-2-personas-7990e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 341",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/aladinia-spaalmuerzo-o-cena-2-personas-80e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 342",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/bono-10-sesiones-ep/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 343",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/promocion-especial-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 344",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/peeling-de-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 345",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/masaje-de-aromaterapia-la-cala-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 346",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/masaje-tailandes-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 347",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/lavar-marcar-y-cortar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 348",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/masaje-energy-tea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 349",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-exclusivity-de-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 350",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/lemax-wellness-club-wellington/especial-masaje-y-spa-con-mama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 351",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/pedicura-bodyna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 352",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-anti-dolor-dia-3-envoltura-de-barros-minerales-bano-de-sales-masaje-relajante-25m/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 353",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-anti-dolor-dia-3-banera-hidromasaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 354",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/acceso-fitness-90-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 355",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/acceso-fitness-90-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 356",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/m-spa-catedral-mercer/spa-tapa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 357",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/peeling-corporal-mascarilla/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 358",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/suplemento-empaquetado-europlayas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 359",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/desmaquillado-gel-color/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 360",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/gel-color-by-opi-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 361",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/piernas-enteras-ingles-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 362",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/tu-madre-se-lo-merece-y-mas-ahora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 363",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-holistico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 364",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/laser-zonas-medianos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 365",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/the-spa-by-signature-sir-victor/sensorial-organic-ritual-80min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 366",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/ritual-bienestar-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 367",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/cura-reconfortante-para-trastornos-lifo-sanguineos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 368",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/pack-aniversario/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 369",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/estas-navidades-regale-bienestar-2-pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 370",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/masaje-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 371",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/circuito-chocorelax-pareja-circuito-spa-masaje-relajante-de-chocolate-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 372",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/manicura-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 373",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/facial-personalizado-espa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 374",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/ritual-oasis-de-serenidad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 375",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hotel-spa-cadiz-plaza/circuito-termal-privado-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 376",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/masaje-corporal-con-caracolas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 377",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/pro-masaje-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 378",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/circuito-hidrotermal-aniversario/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 379",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/spa-privado-masaje-50-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 380",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/masaje-15min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 381",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/programa-beauty-platinum-1-pax-higiene-facial-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 382",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/programa-beauty-platinum-1-pax-higiene-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 383",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/envoltura-de-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 384",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 385",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/las-dunas-spa/exfoliacion-en-vichy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 386",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/masaje-piernas-ligeras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 387",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/viaje-suite-privada-en-pareja-90min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 388",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/pedicura-con-masaje-podal-y-esmalte-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 389",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-acceso-spa-75-semanal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 390",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-acceso-spa-50-semanal-4-dias-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 391",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/ritual-radiance-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 392",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-timexpert-srns/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 393",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 394",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/circuito-deluxe-duo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 395",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-wellness-la-amatista/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 396",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 397",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/circuito-hydrowellness/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 398",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/depilacion-1-2-piernas-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 399",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/especial-dia-de-la-madre-wellness-beauty-day/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 400",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/fitness-2aadd/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 401",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/momento-spa-masaje-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 402",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/aromaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 403",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/bono-4-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 404",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/envoltura-corporal-aloe-vera-by-spa-in-cosmetics/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 405",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-masaje-30-experiencia-gastro-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 406",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-masaje-25-experiencia-gastro-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 407",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/black-friday-2x1-masaje-30-entrada-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 408",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/ritual-cuidado-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 409",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/blanqueamiento-dental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 410",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/ritual-brisa-de-ganesha/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 411",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/viaje-al-eden-tropical/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 412",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/masaje-oro-y-aceites-esenciales-relajantes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 413",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/bono-4-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 414",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/cura-antioxidante-revitalizante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 415",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/spa-day-acompanante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 416",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/facial-efecto-lifting-con-parches-coreanos-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 417",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/neceser-cuidado-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 418",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa-masaje-menu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 419",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/aladinia-masaje-de-75-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 420",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/aladinia-masaje-de-75-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 421",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/tratamiento-de-piedras-calientes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 422",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/tarjeta-regalo-business/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 423",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-con-aromaterapia-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 424",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/experiencia-fruit-power-treatment/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 425",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/pack-romantic-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 426",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-detox-dia-1-udvartana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 427",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/propina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 428",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/masaje-15-min-pago-directo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 429",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/vip-serena-spa-luxury-signature-ritual-y-zona-wellness-privada-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 430",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/circuito-wellness-bono-10-de-lunes-a-viernes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 431",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-vilaromana-salou/especial-pareja-circuito-spa-masaje-de-25-minutos-servicio-de-te-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 432",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-aromatico-15-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 433",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/experiencia-bio-spa-85/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 434",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/tratamiento-silicium-by-thalgo-con-especifico-de-ojos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 435",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-sala-comun-pareja-reserva-directa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 436",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/tratamiento-capilar-de-hidratacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 437",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/viaje-a-la-ruta-de-las-delicias-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 438",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-belleza-dia-3-trat-capilar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 439",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/web-masaje-relajante-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 440",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-excusivity-completo-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 441",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/bono-ilimitado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 442",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/equilibro-de-energias-75/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 443",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-spaxion-hg-la-molina/momento-spa-masaje-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 444",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-privado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 445",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/manicura-barbie/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 446",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-enero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 447",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/envoltura-vitamina-c-firmnes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 448",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/pack-hydr-oxigen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 449",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/5-sesiones-lavar-y-marcar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 450",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/manicura-de-esmalte-de-gel-permanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 451",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-exclusivity-completo-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 452",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-alto-aragon/anti-age-vit-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 453",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/masaje-ayurveda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 454",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/por-ser-canario-thalasso-3h-y-smoothie/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 455",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/ritual-neuro-aroma/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 456",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-15min-chocolate-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 457",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/algoterapia-el-nutriente-del-mar-en-nuestra-piel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 458",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/manana-deluxe-circuito-termal-masaje-desayuno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 459",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/exfoliante-groupon/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 460",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/tratamiento-facial-antimanchas-oxigenante-o-calmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 461",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/facial-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 462",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/terapia-prenatal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 463",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-cerveza-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 464",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/masaje-placido-sueno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 465",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/tratamiento-osteopatia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 466",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/belleza-armonia-3-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 467",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/rock-manicure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 468",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/cura-especial-deportistas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 469",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/retirar-shellac/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 470",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/drenaje-linfatico-todo-el-cuerpo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 471",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/bano-de-citricos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 472",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-roca-negra/promocion-black-friday-circuito-spa-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 473",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/limpieza-facial-profunda-crema-gley-de-brech-de-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 474",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spa-relax-con-masaje-de-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 475",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/lifting-rejuvenecedor-tensor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 476",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/masaje-calma/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 477",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/masaje-placido-sueno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 478",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-kumaras/embrujo-shohai-circuito-jacuzzi-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 479",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/baleares/senator-caleia-talayot-spa/ritual-equilibrante-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 480",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-equilibrante-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 481",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/bono-10-sesiones-ep/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 482",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/pack-hotel-spa-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 483",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/gold-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 484",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/corte-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 485",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/escapada-beauty-dia-a-dia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 486",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/prelude-marin-con-sales-marinas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 487",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/pack-relax-total/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 488",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/masaje-prenatal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 489",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/masaje-descontracturante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 490",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/chorro-jet-ts/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 491",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-craneal-shirogriva/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 492",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-esencias-naturales-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 493",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/circuito-hidrotermal-navidad-2-tratamientos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 494",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-flor-de-cerezo-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 495",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/recogido-de-novia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 496",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/circuito-spa-miercoles-2-adultos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 497",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/qi-gong/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 498",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/masaje-shi-zen-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 499",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/fangoterapia-cromatica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 500",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/tinte-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 501",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacion-medias-piernas-e-ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 502",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/spa-buffet-para-adulto-sabado-o-domingo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 503",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/el-escape-perfecto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 504",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/masaje-deportivo-60min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 505",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/bloqueo-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 506",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/bono-5-sesiones-de-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 507",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guipuzcoa/spa-foresta-wellness/ritual-foresta-wellnes-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 508",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/promocion-circuito-spa-reina-masaje-de-15-min-servicio-de-te-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 509",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/pack-graduacion-para-estudiantes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 510",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/pedicura-spaxion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 511",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/mi-dia-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 512",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/masaje-apacible-paraiso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 513",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/tinte-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 514",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/spa-privado-by-night-para-1-a-6-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 515",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/flor-decoracion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 516",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/ritual-pureza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 517",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/envoltura-chocolate-nina-o-masaje-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 518",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/circuito-ano-nuevo-alojado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 519",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/masaje-de-espalda-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 520",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/masaje-3-en-1-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 521",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/pedicura-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 522",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/otono-masajes-del-mundo-polynesia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 523",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/ritual-chocoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 524",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/tratamiento-expres-luminoso-ritual-de-bali/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 525",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/escapada-one-week-6-noches/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 526",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 527",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/pedicura-deluxe-by-opi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 528",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/tratamiento-facial-elementos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 529",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/diferencia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 530",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/aromaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 531",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cura-ducha-jet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 532",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/masaje-ayurvedico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 533",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/love-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 534",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/relax-en-canarias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 535",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/mar-de-sensaciones-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 536",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/masaje-con-aromaterapia-20min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 537",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/masaje-sueco-30-min-cuatro-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 538",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/facial-royal-jelly/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 539",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/propina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 540",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/pack-cerveza-verde/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 541",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/15min-chocolate-o-facial-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 542",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/circuito-deloix-hotel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 543",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-spaxion-hg-la-molina/momento-spa-masaje-6/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 544",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-tradicional-nuad-thai/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 545",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/circuito-spa-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 546",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/private-spa-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 547",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/masaje-cervicodorsal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 548",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/energia-vital/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 549",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/spa-attica-21-villalba/wellness-beauty-attica21/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 550",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/cava/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 551",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/mindfullness-detox-80-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 552",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/mindfullness-detox-80-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 553",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/envoltura-corporal-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 554",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/capricho-de-otono/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 555",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/promocion-black-firday-senses-night/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 556",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/tinte-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 557",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/segovia/spa-spaxion-convento-capuchinos/ritual-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 558",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-exclusivity-full-body-75/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 559",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/pack-spa-enamorados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 560",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/tratamiento-budha-circuito-3-masajes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 561",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/circuito-hidrotermal-solo-interno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 562",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/semipermanente-con-pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 563",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/exfoliacion-y-mascara-corporal-75/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 564",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/depilacion-de-ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 565",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/tratamientos-de-manos-con-cerveza-manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 566",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/ritual-holistico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 567",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/pack-luxury-love/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 568",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hspa-valparaiso/limpieza-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 569",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/masaje-apacible-paraiso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 570",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/depilacion-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 571",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-armonia-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 572",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/momento-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 573",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-exclusivity-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 574",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/depilacion-de-25-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 575",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/depilacion-de-25-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 576",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/higiene-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 577",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/especial-pareja-circuito-alegria-servicio-de-te-pareja-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 578",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/hydracure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 579",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/tratamiento-facial-antimanchas-oxigenante-o-calmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 580",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/peeling-corporal-de-coco-hidratacion-completa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 581",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/ritual-equilibrante-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 582",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/romantic-spa-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 583",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/especial-parejas-circuito-ohtels-masaje-de-15-min-servicio-de-te-parejas-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 584",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/masaje-holistico-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 585",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/pedicura-con-masaje-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 586",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/circuito-spa-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 587",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/anti-stress-3-dias-dia-1-masaje-25-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 588",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/facial-cleansing/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 589",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/promocion-circuito-spa-masaje-25-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 590",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/personalizado-shama-eternal-love-para-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 591",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/oasis-de-serenidad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 592",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/black-beer-burbujeante-circuito-sala-comun-regalos-para-dos-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 593",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-privado-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 594",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/promocion-circuito-spa-masaje-55-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 595",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-alto-aragon/for-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 596",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/andulacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 597",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/sesion-terapeutica-personalizada/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 598",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/recogido-eventos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 599",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/regalate-san-valentin-en-la-gloria/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 600",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/ritual-aloe-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 601",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/circuito-beer-spa-privado-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 602",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/masaje-deportivo-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 603",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/chocolaterapia-navidad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 604",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/dulce-cumpleanos-para-los-peques-de-la-casa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 605",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/bono-6-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 606",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/viaje-a-la-ruta-de-las-delicias-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 607",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/otono-masajes-del-mundo-atlantique/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 608",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 609",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/agua-luz-spa/circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 610",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/lipo-choco-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 611",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/masaje-sunset-in-egypt/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 612",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/higiene-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 613",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/experiencia-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 614",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/depilacion-piernas-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 615",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/exfoliacion-corporal-hidratacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 616",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/black-friday-2x1-masaje-30-entrada-spa-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 617",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-sueco-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 618",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-momento-especial-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 619",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/circuito-spa-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 620",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/evasion-romantica-for-two-cena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 621",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/notas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 622",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/tratamiento-facial-kobido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 623",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/experiencia-bio-spa-con-masaje-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 624",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/madre-e-hija-tratamientos-facial-y-manos-recepcion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 625",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/circuito-alegria-hotel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 626",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/masaje-shi-zen-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 627",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-masaje-50-experiencia-matinal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 628",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/masaje-con-aromaterapia-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 629",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-detox-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 630",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-25min-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 631",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/facial-pure-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 632",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/anti-stress-2-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 633",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/body-care-sandos-papagayo-spa-wellness/pro-best-moment-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 634",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 635",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/depilacion-pecho-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 636",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/masaje-descontracturante-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 637",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/socio-trainer/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 638",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/tratamiento-antigrasa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 639",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/tenacillas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 640",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/body-mask/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 641",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/15min-chocolate-o-facial-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 642",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/masaje-de-cuello/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 643",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/we-love-spa-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 644",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/tratamiento-capilar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 645",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/recogido-para-novia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 646",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/majestic-spa-2/the-cure-by-natura-bisse-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 647",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/capuccino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 648",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/las-palmas/siam-spa-bohemia-suites/training/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 649",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-relajante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 650",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/spa-buffet-para-adulto-de-miercoles-a-viernes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 651",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/ritual-bienestar-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 652",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/tratamiento-shanara-con-cuencos-tibetanos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 653",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 654",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spacio-telde/tratamiento-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 655",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/san-valentin-entrada-termal-2h-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 656",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/wonderbox-2-personas-3990e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 657",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/aire-de-mar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 658",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/experiencia-sun-repair/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 659",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/derma-glow-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 660",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/cura-reconfortante-para-trastornos-lifo-sanguineos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 661",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/tratamiento-facial-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 662",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/circuito-hydrowellness-pareja-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 663",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/depilacion-de-50-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 664",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/depilacion-de-50-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 665",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/bioterapia-corporal-sculptor-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 666",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/masaje-10/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 667",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/experiencia-sun-repair/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 668",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-exclusivity-parcial-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 669",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cura-contorno-de-ojos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 670",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/circuito-spa-buffet-libre-con-bebidas-incluidas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 671",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/o2-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 672",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/ritual-orquideas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 673",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/peeling-hidratante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 674",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/pack-hydr-oxigen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 675",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/depilacion-laser-zona-reducida/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 676",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/masaje-antiestres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 677",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/ritual-de-oriente-110/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 678",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/extra-spa-privado-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 679",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-de-pies-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 680",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/masaje-tui-na/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 681",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/sweety-kids/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 682",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-15min-individual-feb-2020/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 683",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/spa-masaje-canario/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 684",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/cura-control-de-peso-y-o-volumen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 685",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/facial-groupon/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 686",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/bioterapia-sistemica-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 687",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/masaje-relajante-de-25-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 688",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/peeling-corporal-de-coco-hidratacion-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 689",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 690",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/hb-black-friday-serenity-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 691",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/spamascarilla-con-pincel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 692",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/pedicura-prospa-opi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 693",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/pedicura-prospa-opi-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 694",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacion-pecho/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 695",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/abhyanga/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 696",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/circuito-spa-gratis/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 697",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/luxury-japan-private-room-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 698",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-belleza-dia-2-pedicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 699",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/masaje-corporal-relajante-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 700",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/envoltura-algas-barro-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 701",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/masaje-shi-zen-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 702",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-masaje-50-experiencia-gastro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 703",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-spa-alojados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 704",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/a-c-reserva-de-servicio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 705",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/un-dia-de-relax-con-mama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 706",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 707",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 708",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-de-5-masajes-50-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 709",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/circuito-hidrotermal-ss/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 710",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-hydracure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 711",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/cocoon-spa-lazure/acceso-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 712",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/circuito-spa-junior-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 713",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/pack-antimanchas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 714",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/aromaterapia-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 715",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/masaje-esencia-natural-cerveza-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 716",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/spa-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 717",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-flor-de-cerezo-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 718",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/spa-by-family-para-2-adultos-y-hasta-3-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 719",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/manicura-con-esmalte-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 720",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/pedicura-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 721",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/privilege-masaje-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 722",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/momento-spa-masaje-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 723",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/burgos/spa-tudanca/masaje-cervico-craneal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 724",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/especial-black-friday-especial-pareja-circuito-spa-servicio-de-te-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 725",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/manicura-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 726",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 727",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/ritual-del-corazon-de-la-tierra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 728",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/promocion-circuito-spa-y-belleza-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 729",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/felicidades-mama-te-mereces-todo-empecemos-por-mimarte/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 730",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/masaje-con-aromaterapia-20min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 731",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/gimnasia-acuatica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 732",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/spa-en-familia-2x1-mas-20-descuento-en-tratamientos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 733",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/groupon-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 734",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/masaje-aromasoul-aromaterapia-85min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 735",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/tratamiento-reafirmante-de-ojos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 736",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/hesperides-thalasso-spa/un-dia-de-mimos-spa-y-desayuno-con-mama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 737",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/belleza-en-armonia-5-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 738",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/barba/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 739",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/barba-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 740",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/circuito-beer-spa-masaje-15min-atrapalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 741",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/tratamiento-anticaida/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 742",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/day-pass-aqua-tu-daypass-de-piscina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 743",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/early-morning-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 744",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-abhyanga-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 745",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/plancha-lisa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 746",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-esplendor-radiante-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 747",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/paquete-personalizado-circuito-de-aguas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 748",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/sweet-love-circuito-beer-spa-pareja-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 749",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/depilacion-con-diodo-cuerpo-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 750",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/gimnasio-1-sesion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 751",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/navaja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 752",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/balayage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 753",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/balayage-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 754",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/envolturas-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 755",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/exfoliacion-completa-con-guante-kessa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 756",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/masaje-calmante-muscular-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 757",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-alto-aragon/momento-spa-masaje-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 758",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/yoga-mornings/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 759",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-10-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 760",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/reflexologia-podal-45/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 761",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/ritual-naturae/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 762",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/depilacion-ingles-o-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 763",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/pack-massada-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 764",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/acceso-wellness-myevjf-90-privatizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 765",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/socio-mensual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 766",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/laura-leon-wellness/thai-foot-reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 767",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/depilacion-de-torax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 768",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-prenatal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 769",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-sa-voga/ritual-bienestar-by-alqvimia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 770",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/cura-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 771",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/aceite-corporal-alqvimia-150ml-anti-stress/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 772",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/masaje-original-indio-de-cabeza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 773",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/hotelbreak-masaje-de-25-minutos-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 774",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/hotelbreak-masaje-de-25-minutos-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 775",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-wellness-50-con-masaje-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 776",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/especial-navidad-serenity-spa-masaje-copa-cava-y-cofre-alqvimia-regalo-para-ti/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 777",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/taller-meditacion-3/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 778",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/drenaje-linfatico-manual-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 779",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/menton/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 780",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/termal-el-palacete/momento-spa-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 781",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/kit-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 782",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-15sala-privada-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 783",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/circuito-catalonia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 784",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-so-delicate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 785",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 786",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/espacio-acuatico-masaje-piedras-calientes-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 787",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/circuito-spa-con-ritual-de-bienvenida/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 788",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/viaje-al-eden-tropical-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 789",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/a-c-reserva-de-servicio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 790",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/moldeador-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 791",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/romantic-spa-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 792",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/circuito-spa-adulto-club-senator-vip-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 793",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/masaje-descontracturante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 794",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/vip-love-circuito-vip-pareja-masaje-15-minutos-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 795",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/pack-especial-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 796",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/cuidado-equilibrante-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 797",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/momento-spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 798",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/exfoliante-corporal-de-aloe-vera-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 799",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/cama-piscina-1-hora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 800",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/ritual-canario-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 801",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/limpieza-facial-profunda-crema-gley-de-brech-de-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 802",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/sun-repair/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 803",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/piernas-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 804",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-beauty-platinum-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 805",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/depilacion-ingles-brasilenas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 806",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-25-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 807",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cura-pack-hidratacion-2-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 808",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/precio-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 809",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/drenaje-linfatico-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 810",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/skinzen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 811",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/envoltura-suave-como-la-seda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 812",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/depilacion-labio-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 813",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/we-love-spa-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 814",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-25min-individual-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 815",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-nucia-spa-masshotel/endless-summer-barcelo-la-nucia-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 816",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/san-valentin-ritual-in-love/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 817",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/circuito-dusch-agencia-condor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 818",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/chocolate-relajante-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 819",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/stone-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 820",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 821",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/tratamiento-facial-kobido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 822",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-adulto-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 823",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/facial-hidratacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 824",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/tratamiento-facial-aloe-vera/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 825",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/black-friday-circuito-termal-lunes-a-jueves/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 826",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/promocion-especial-dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 827",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-15-min-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 828",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/ayurveda-abhyanga-special/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 829",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/higiene-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 830",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/dia-del-padre-masaje-cerveza-25-hidromiel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 831",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-aromatico-15-minutos-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 832",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/termes-de-montbrio/promocion-black-friday-spa-termal-masaje-relajante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 833",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/cura-de-belleza-integral/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 834",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/vuelve-pronto-spa-75-antes-de-60-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 835",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/anti-stress-2-dias-dia-1-masaje-25-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 836",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/atrapalo-masaje-de-75-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 837",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/atrapalo-masaje-de-75-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 838",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/tratamiento-reductor-reafirmante-localizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 839",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-relajante-localizado-20eliminado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 840",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/pack-especial-parejas-spa-masaje-45/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 841",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/mechas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 842",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/upselling-mascarilla/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 843",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/anticelulitico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 844",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/tratamiento-antiedad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 845",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/ritual-sensaciones-club-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 846",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-spa-on-top/serenity-vitality-ritual-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 847",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-buffet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 848",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/hidratante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 849",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/depilacion-axila-o-bikini/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 850",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/masaje-deportivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 851",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/oporto/neya-day-spa-porto/invicta-express-duo-para-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 852",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/especial-ayurveda-abhyanga/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 853",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/masaje-oriental-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 854",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/oferta-8-m-50-envoltura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 855",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/entren-funcional-1-ses/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 856",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/pedicure-xpress-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 857",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-exclusivity-parcial-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 858",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/wellnessweekend-2x1-spa-matinal-circuito-aguas-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 859",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/kit-romantico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 860",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/higiene-base-facial-para-dos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 861",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/lavar-y-secar-pelo-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 862",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-de-la-felicidad-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 863",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/spa-hotel-blu-almansa/antiedad-global/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 864",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/cinderellas-feet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 865",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/lhygiene-80-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 866",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/lhygiene-80-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 867",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-wellness-50-experiencia-desayuno-l-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 868",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-wellness-experiencia-matinal-50-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 869",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/circuito-spa-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 870",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-masaje-en-pareja-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 871",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-subacuatico-envoltura-de-algas-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 872",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/pack-goloso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 873",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/black-beer-vip-circuito-sala-privada-masaje-15-mnts-regalos-para-dos-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 874",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/circuito-arabe-1-persona-de-lunes-a-jueves/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 875",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-sa-voga/ritual-sa-voga-by-alqvimia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 876",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/radiofrecuencia-facial-6-sesiones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 877",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/depilacion-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 878",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/unas-de-gel-acrilica-porcelana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 879",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/manicura-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 880",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/circuito-privado-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 881",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-beauty/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 882",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/momento-spa-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 883",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/exfoliante-corporal-de-aloe-vera/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 884",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/cavitacion-presoterapia-1-sesion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 885",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/recogido-novia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 886",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 887",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-leo-deluxe/pack-navidad-esencias-circuito-spa-masaje-de-esencias-naturales-55-minutos-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 888",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/agua-luz-spa/masaje-antiestres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 889",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/tratamiento-circulatorio-de-piernas-y-pies-40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 890",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-ayurvedico-con-pindas-para-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 891",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/manicura-con-esmalte-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 892",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/peeling-corporal-con-sales-del-mar-muerto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 893",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/facial-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 894",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/ritual-atardecer-en-el-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 895",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/love-secret/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 896",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/facial-arude-global-antiaging/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 897",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-supreme-en-sala-privada-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 898",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cura-peeling-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 899",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/exfoliacion-radiance-lifting-de-natura-bisse-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 900",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/exfoliacion-radiance-lifting-de-natura-bisse-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 901",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-wellness-urh-ciutat-de-mataro/spa-almuerzo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 902",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/envolturaeliminado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 903",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/black-friday-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 904",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/zona-de-aguas-cena-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 905",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/experiencia-club-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 906",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 907",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/ritual-radiance-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 908",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/especial-pareja-circuito-alegria-masaje-de-15-min-servicio-de-te-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 909",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/hard-rock-spa-marbella/especial-mama-serenity-elixir/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 910",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sunlight-bahia-principe-san-felipe/facial-para-el/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 911",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/radiance-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 912",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/sublime-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 913",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-brunch-para-2-personas-residente-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 914",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-spaxion-hg-la-molina/amanecer-en-la-montana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 915",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-15-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 916",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/contorno-de-ojos-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 917",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/momento-spa-para-2-de-s-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 918",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-esencia-naturales-parcial-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 919",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/circuito-spa-y-belleza-grupo6px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 920",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/reiki-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 921",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/depilacion-de-brazos-hombres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 922",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/circuito-spa-groupon-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 923",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/healthouse-detox-7-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 924",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/corte-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 925",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-apacible-paraiso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 926",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/bienestar-familiar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 927",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/cuota-mensual-spa-gimnasio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 928",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/depilacion-con-diodo-zona-media/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 929",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/tratamiento-antiarrugas-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 930",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/mimos-para-mama-power-defense-by-matriskin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 931",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/inhibit-face-lift/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 932",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/manicura-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 933",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/tratamiento-facial-antimanchas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 934",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/masaje-sueco-30-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 935",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/facial-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 936",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-pureza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 937",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/masaje-bespoke-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 938",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/envolvimiento-a-elegir-5-sesiones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 939",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/momento-spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 940",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/anti-age-by-corallium-cosmetics-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 941",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/recogido-fiesta/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 942",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/masaje-drenante-de-pies-y-piernas-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 943",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/depilacion-piernas-enteras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 944",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/masaje-con-pindas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 945",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/piernas-cansadas-firmas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 946",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/m-produccion-spa-day-1-acompanante-194e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 947",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/especial-dia-del-padre-especial-papa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 948",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/facial-mesoterapia-virtual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 949",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-nucia-spa-masshotel/ritual-delicias-citricas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 950",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/depilacion-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 951",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/pedicura-juvenil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 952",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/masaje-e-beauty-dia-a-dia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 953",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-anti-dolor-dia-2-masaje-terap-tailandes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 954",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/tratamiento-reductor-reafirmante-localizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 955",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spa-relax-con-masaje-de-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 956",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/masaje-shi-zen-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 957",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/oporto/the-spa-by-sheraton-porto/natures-bounty-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 958",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-detox-dia-2-peeling-corporal-masaje-personalizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 959",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-detox-dia-2-envoltura-algas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 960",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-beauty-silver-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 961",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/facial-hydracure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 962",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/termes-de-montbrio/chakra-stone-hidromasaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 963",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/true-love-circuito-spa-san-valentin-con-cava-y-bombones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 964",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/depilacion-ingles-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 965",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/programa-after-sun/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 966",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/piel-de-seda-bioactivo-mar-y-tierra-facial-y-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 967",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/thalasso-mask-refresh-cajitas-y-cupones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 968",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 969",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/ritual-bodyna-escapada-romantica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 970",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lisboa/neya-day-spa-lisboa/banera-de-hidromasaje-masaje-neya-bamboo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 971",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/black-beer-burbujeante-circuito-sala-comun-regalos-para-dos-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 972",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/tarjeta-regalo-business/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 973",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/all-hb-ht-suplemento-pack-spa-50-l-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 974",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/hb-spa-50-con-masaje-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 975",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/masaje-con-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 976",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/mascarilla/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 977",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-alto-aragon/spa-privado-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 978",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-vip-masaje-30-min-recepcion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 979",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/especial-black-friday-vitamina-c-exfoliacion-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 980",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/relaxing-love-circuito-beer-spa-pareja-masaje-15-minutos-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 981",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/mechas-bi-color-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 982",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/mechas-bi-color/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 983",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/golden-tan-nuevo-lanzamiento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 984",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/depilacion-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 985",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/pack-navidad-bano-vikingo-circuito-spa-sala-comun-mascarilla-facial-de-cerveza-para-dos-personas-pack-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 986",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/depilacion-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 987",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/o2-relax-de-natura-bisse-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 988",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/o2-relax-de-natura-bisse-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 989",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/exfoliacion-corporal-de-pina-circuito-spa-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 990",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/spa-privado-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 991",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/tratamiento-facial-especifico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 992",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-wellness-urh-ciutat-de-mataro/spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 993",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/gold-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 994",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/pedicura-con-esmalte-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 995",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/burgos/spa-tudanca/especial-black-friday-tratamiento-ocean-miracle/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 996",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/masaje-piedras-calientes-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 997",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/envoltura-corporal-a-la-arcilla-blanca/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 998",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/pedicura-con-masaje-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 999",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/al-spa-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1000",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-hydraluronic/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1001",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/cuidado-alisante-75/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1002",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/thalgo-refuerzo-de-spirulina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1003",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/momento-spa-masaje-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1004",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/momento-spa-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1005",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/masaje-apacible-paraiso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1006",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/depilacion-bikini-o-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1007",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/tinte-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1008",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/aqua-gym/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1009",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/masaje-hindu-de-cabeza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1010",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/pedicura-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1011",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/ducha-vichy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1012",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pilates-suelo-1-sesion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1013",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/promocion-especial-dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1014",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/facial-pure-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1015",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/spa-masaje-relajante-30-min-s-d-y-festivos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1016",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/masaje-relajante-corporal-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1017",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/combo-4-sesiones-love-your-body/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1018",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/circuito-iberik-i-entrada/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1019",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/peeling-corporal-de-arroz-rojo-al-jazmin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1020",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/circuito-ohtels-masaje-de-15-min-servicio-de-te-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1021",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guadalajara/spa-castilla-termal-brihuega/bano-jamaica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1022",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/masaje-relax-de-rostro-cuello-y-escote-beer/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1023",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/belleza-y-bienestar-para-mama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1024",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/cura-lifting-y-antievejecimiento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1025",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/circuito-spa-privado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1026",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/retirada-de-esmaltada-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1027",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hspa-valparaiso/circuito-spa-comida/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1028",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/manicura-deluxe-francesa-by-opi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1029",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/cura-control-de-peso-y-o-volumen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1030",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/electroestimulacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1031",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/especial-ella-reina-de-egipto-by-alqvimia-regalo-entrada-doble-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1032",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-25-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1033",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/circuito-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1034",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/facial-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1035",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/retirar-unas-semi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1036",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-augas-santas-balneario-golf/circuito-termal-entrada-infantil-tfa-red/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1037",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-romantic-beer-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1038",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/neceser-cuidado-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1039",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/masaje-armonia-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1040",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-suite-presidencial-luxury/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1041",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cura-crioterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1042",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/business-class-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1043",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/bano-de-la-laguna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1044",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/tu-madre-se-lo-merece-y-mas-ahora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1045",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-siargao/2x1-especial-deep-tissue-massage-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1046",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/envoltura-de-te-verde/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1047",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/tarjeta-regalo-50e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1048",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/masaje-balines/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1049",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/depilacion-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1050",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/pack-vital-prevention/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1051",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/depilacion-con-diodo-cuerpo-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1052",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-spa-on-top/love-massage-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1053",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/masaje-thai/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1054",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/moldeador-rulos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1055",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/depilacion-pecho/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1056",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/facial-oriental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1057",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/pedicura-con-masaje-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1058",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/promocion-masaje-relajante-de-espalda-masaje-craneal-y-cervical/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1059",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1060",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/manicura-pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1061",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-especial-parejas-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1062",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/especial-black-friday-circuito-ohtels/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1063",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/exfoliaciones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1064",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa-privado-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1065",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/especial-parejas-circuito-alegria-masaje-de-55-min-servicio-de-te-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1066",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/bono-10-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1067",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/moldeador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1068",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spacio-telde/masaje-relajante-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1069",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/pack-especial-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1070",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/masaje-local-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1071",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/black-friday-2x1-masaje-50-entrada-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1072",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/black-friday-2x1-masaje-50-circuito-spa-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1073",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/tratamiento-facial-basico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1074",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/masaje-especial-embarazada-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1075",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/gastrospa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1076",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-pureza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1077",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/sauna-sierra-nevada/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1078",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-aromatico-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1079",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/paquete-antiestres-4-hrs-spa-experience-masaje-sueco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1080",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/oporto/the-spa-by-sheraton-porto/hidra-revival-molecular-facial-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1081",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/mi-atardecer-romantico-especial-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1082",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/envoltura-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1083",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/exfoliacion-corporal-de-bamboo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1084",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/programa-especial-piernas-cansadas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1085",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-piedras-calientes-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1086",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/depilacion-piernas-enteras-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1087",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/exfoliacion-corporal-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1088",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/wellness-experiencia-matinal-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1089",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-deportivo-80-mins/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1090",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-despacio-h10-benidorm/despacio-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1091",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/pedicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1092",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-timexpert-srns/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1093",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/esmaltado-de-unas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1094",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1095",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/centro-bienestar-sunprime-atlantic/experiencia-aloe-vera-by-spa-in-comestics/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1096",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/experiencia-adios-estres-especial-profes-acceso-spa-60-para-2-masaje-30-para-el-profersor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1097",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-hotel-hyltor/masaje-20min-circuito-spa-60min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1098",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/piensa-en-ti-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1099",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/facial-so-delicate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1100",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/the-spa-by-signature-sir-victor/deep-maxilofacial-body-massage-75min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1101",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/comida-cena-asador-argentino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1102",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/pack-spa-enamorados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1103",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/exfoliacion-de-pies-y-piernas-masaje-piernas-con-naranja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1104",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/upselling-masaje-10/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1105",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/correccion-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1106",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/ritual-placeres-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1107",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/aragon-hills-hotel/stone-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1108",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/depilacion-de-abdomen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1109",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/circuito-spa-cordial-welcome-back-card/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1110",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/depilacion-de-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1111",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/exfolianteeliminado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1112",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/bono-cuida-tu-piel-este-verano/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1113",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/cuida-tu-piel-este-verano/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1114",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-antiestres-dia-2-envoltura-aloevera/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1115",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/lavar-y-marcar-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1116",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/oferta-2-m-c-f-m-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1117",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/desinfeccion-de-cabinas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1118",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-shiatsu-home-service/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1119",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-exclusivity-full-body-75/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1120",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/facial-expres-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1121",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/agua-luz-spa/higiene-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1122",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/energy-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1123",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/manicura-semi-permanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1124",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/par-biomagnetico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1125",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/pedicura-deluxe-by-opi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1126",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/ritual-hapiness/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1127",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/masaje-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1128",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/apacible-paraiso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1129",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/9issui-spa-fitness/momento-spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1130",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/segovia/spa-spaxion-convento-capuchinos/momento-spa-masaje-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1131",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/combo-2-sesiones-love-your-body/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1132",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/masaje-deportivo-40min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1133",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/laser-zonas-medianas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1134",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pilates-suelo-4-sesiones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1135",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-masaje-60-min-2-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1136",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/masaje-espalda-con-peeling-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1137",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/depilacion-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1138",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cantabria/spa-castilla-termal-solares/relax-total-cuatro-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1139",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/exfoliante-corporal-corallium-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1140",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-en-pareja-con-aceite-de-rosa-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1141",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/mascarilla-capilar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1142",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/masaje-deep-tisue/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1143",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/vip-love-circuito-vip-pareja-masaje-15-minutos-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1144",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/retirar-unas-semi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1145",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/experiencia-bio-spa-110/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1146",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/luxury-private-room/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1147",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/presoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1148",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/himalayan-rock/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1149",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/pack-confort-1er-dia-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1150",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/peeling-de-te-verde/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1151",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/tarjeta-regalo-100e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1152",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/momento-spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1153",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/experiencia-relax-110-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1154",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/depilacion-de-piernas-enteras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1155",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/choose-your-peace/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1156",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/pack-luxury-love/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1157",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/masaje-nino-a-espalda-o-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1158",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-cerler/momento-spa-masaje-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1159",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/especial-navidad-love-para-2-personas-spa-masaje-copa-cava-y-cofre-alqvimia-regalo-para-ti/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1160",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/piscina-adulto-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1161",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/momento-spa-masaje-5/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1162",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/circuito-flash-de-aguas-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1163",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/spa-manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1164",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/pack-vital-prevention/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1165",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1166",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/despacio-spa-center-at-the-one/the-one-barcelona-signature-treatment/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1167",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-la-gavina/masaje-relajante-duo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1168",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sunlight-bahia-principe-san-felipe/masaje-relajante-canario-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1169",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/manicura-de-esmalte-de-gel-permanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1170",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/body-sculptor-alqvimia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1171",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/suite-de-masaje-para-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1172",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/laura-leon-wellness/violetas-del-teide/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1173",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/pack-antimanchas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1174",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/manicura-color/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1175",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/promocion-black-friday-circuito-privado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1176",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/depilacion-pecho-o-abdomen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1177",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-15min-chocolate-pareja-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1178",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-45min-de-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1179",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-esencias-naturales-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1180",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/masaje-aloe-vera-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1181",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/calidad-spa-free/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1182",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/agencias-wellness-area-90-privatizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1183",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/chocolaterapia-despues-del-sol/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1184",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/promocion-especial-dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1185",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/pedicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1186",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/tinte-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1187",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/biocelular/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1188",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/sabila/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1189",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/oporto/the-spa-by-sheraton-porto/masaje-relajante-de-espalda-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1190",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/masaje-top-care/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1191",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/envoltura-naturales/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1192",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/propina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1193",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/cura-adelgazante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1194",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/entrada-termal-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1195",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/tarde-romantica-en-be-aloe-con-masaje-y-cena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1196",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/circuito-arabe-masaje-de-15-minutos-guia-granada-39e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1197",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/facial-radiofrecuencia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1198",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-iberostar-las-gaviotas/circuito-hydrowellness-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1199",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/10-sesiones-circuito-beer-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1200",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/body-care-sandos-papagayo-spa-wellness/especial-mar-muerto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1201",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/tratamiento-facial-luminusidad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1202",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/tarjeta-regalo-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1203",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/socio-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1204",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-energizante-ayurvedico-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1205",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-anticelulitico-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1206",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/full-day-circuito-wellness-masaje-menu-arrocero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1207",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/gimnasio-1-semana-cliente-exterior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1208",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/tratamiento-adelgazante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1209",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/circuito-deloix/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1210",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/ht-masaje-30-experiencia-gastro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1211",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/circuito-deloix-weekendesk/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1212",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/despacio-spa-center-at-the-one/masaje-deep-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1213",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-aromatico-15-minutos-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1214",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/masaje-esencias-naturales-parcial-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1215",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-bienestar-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1216",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/calma-spa/especial-san-valentin-circuito-spa-masaje-calma-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1217",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/feliz-dia-del-padre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1218",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/masaje-thai-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1219",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/at-spa-50-cava-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1220",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/retirar-unas-semi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1221",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/facial-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1222",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/gym-mensual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1223",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-bienestar-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1224",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/family-kids-luxury/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1225",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/masaje-exclusivity-55-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1226",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-piernas-cansadas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1227",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-corporal-de-arcilla-caliente-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1228",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/castellon/balneario-de-villavieja/parafango-zona-pequena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1229",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/masaje-relajante-mundo-de-los-aromas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1230",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-25min-chocolate-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1231",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/depilacion-con-diodo-zona-media/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1232",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-monasterio-de-valbuena/ducha-vichy-a-cuatro-manos-con-peeling-de-miel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1233",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/spa-food/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1234",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/programa-especial-piernas-cansadas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1235",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-armonia-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1236",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/tratamiento-anticelulitico-localizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1237",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/pack-mama-almuerzo-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1238",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/especial-black-friday-masaje-sandara-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1239",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/depilacion-piernas-enteras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1240",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/mascarilla-peeling-upselling/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1241",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/estiramientos-thai-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1242",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-descontracturante-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1243",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/lipo-choco-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1244",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/circuito-de-spa-privado-y-masaje-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1245",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/masaje-local/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1246",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/masaje-15-min-alojados-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1247",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/hidra-marine-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1248",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/masaje-anticelulitico-localizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1249",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/pack-navidad-glow-up-circuito-spa-tratamiento-lifting-sicilium-marin-pack-regalo-pack-para-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1250",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-free-relajante-15/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1251",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-romantic-beer-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1252",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/devolucion-gastronomia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1253",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/facial-photocare/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1254",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/ritual-anticelulitico-reafirmante-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1255",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/thai-room-ultimate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1256",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-ritual-de-atlas-marruecos-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1257",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/bano-de-vino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1258",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/laser-diodo-por-zona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1259",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/masaje-relajante-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1260",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/manicura-con-esmalte-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1261",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/depilacion-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1262",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/maquillaje-de-novia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1263",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/maderoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1264",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/day-pass/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1265",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/firming-eye-treatment-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1266",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/el-teide-masaje-de-piedras-calientes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1267",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/ondas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1268",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/the-spa-by-signature-sir-victor/relaxing-massage-3-lavanders-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1269",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/spa-buffet-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1270",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-nucia-spa-masshotel/tratamiento-efecto-tensor-lifting/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1271",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-seda-club/especial-black-friday-masaje-relajante-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1272",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/depilacion-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1273",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/deportivo-35/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1274",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-fathers-day-40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1275",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-spaxion-hg-la-molina/anti-age-vit-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1276",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/masaje-craneo-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1277",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/thalgo-cold-marine/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1278",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/depilacion-laser-reducida/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1279",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-pazo-los-escudos/wellness-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1280",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/lpg/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1281",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/envoltura-chocolate-20min-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1282",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1283",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/bono-4-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1284",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/circuito-flash-de-aguas-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1285",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/masaje-tui-na/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1286",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/mar-de-juventud-ocean-view/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1287",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/pack-luxury-love/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1288",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/experiencia-adios-estres-especial-profes-entrada-welness-area-60-para-2-pers-masaje-30para-el-profesor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1289",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/centro-bienestar-sunprime-atlantic/relax-futuras-mamas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1290",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-esencias-naturales-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1291",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/relax-andalusi-en-pareja-circuito-spa-ritual-al-andalus/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1292",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/tratamiento-manos-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1293",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/depilacion-medios-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1294",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/circuito-hidrotermal-navidad-1-tratamiento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1295",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/wellnessweekend-2x1-spa-matinal-circuito-aguas-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1296",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/maquillaje-novia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1297",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/pedicura-lacado-f/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1298",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/tarjeta-regalo-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1299",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/ocean-view-pool-1pax-incluido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1300",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/promo-matinal-facial-35/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1301",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/circuito-de-aguas-cliente-externo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1302",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/circuito-especial-san-valentin-expres-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1303",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/ritual-supreme/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1304",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/ritual-de-chocoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1305",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/circuito-spa-domingo-2-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1306",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/dolce-love-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1307",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/dolce-love-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1308",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-love-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1309",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/pedicura-basica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1310",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/masaje-shi-zen-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1311",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/facial-express-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1312",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/aromspa-refresh-mask-momento-especial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1313",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/aroma-body-relax-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1314",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-attica21-vigo/masaje-de-pies-15min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1315",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/facial-express-mascarilla-de-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1316",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/spa-teen-dto-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1317",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/aladinia-masaje-25-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1318",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/aladinia-masaje-25-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1319",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/masaje-lomi-lomi-hawaiano-85min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1320",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/depilacion-bikini/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1321",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/circuito-de-aguas-cliente-alojado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1322",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-experiencia-duo-romantica-separados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1323",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-nucia-spa-masshotel/bienestar-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1324",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/propina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1325",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-ducha-jet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1326",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/depilacion-con-diodo-cuerpo-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1327",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/aragon-hills-hotel/facial-for-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1328",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-programa-suite-50-min-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1329",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/balneario-vichy-catalan/circuito-termal-cliente-hotel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1330",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/ritual-timexpert-srns/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1331",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/depilacion-labio-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1332",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-siesta-relajante-pies-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1333",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/beauty-party/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1334",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-wellness-urh-ciutat-de-mataro/spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1335",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/pack-hydr-oxigen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1336",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/manicura-basica-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1337",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/cura-reconfortante-para-trastornos-lifo-sanguineos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1338",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-privado-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1339",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/depilacion-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1340",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/relax-refuel-en-unico-spa-masajecocktail/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1341",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/sala-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1342",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/lomi-lomi-nui-55-min-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1343",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/exfoliacion-corporal-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1344",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/experiencia-del-mes-40-minutos-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1345",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/experiencia-del-mes-40-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1346",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/desayuno-con-diamantes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1347",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/masaje-signature-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1348",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/oasis-relax-gourmet-gold-edition/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1349",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/acceso-piscina-adulto-75-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1350",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/cuidado-maternal-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1351",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/oasis-relax-gourmet-silver-edition/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1352",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/oasis-relax-gourmet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1353",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/masaje-signature-25min-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1354",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/masaje-de-25-minutos-infantil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1355",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/entrada-infantil-ninos-de-4-a-11-anos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1356",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/love-is-in-the-air/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1357",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/hot-stone-80-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1358",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/abhyanga-80min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1359",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/uso-gym/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1360",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/masaje-40-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1361",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-15min-chocolate-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1362",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/tinte-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1363",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/manicura-y-pedicura-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1364",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/masaje-hawaiano-lomi-lomi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1365",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/organic-vegan-ritual-80-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1366",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/organic-vegan-ritual-80-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1367",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/masaje-reiki/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1368",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/relax-andalusi-en-pareja-circuito-spa-ritual-al-andalus/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1369",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/circuito-spa-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1370",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-oriental-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1371",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/pack-antimanchas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1372",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/aladinia-masaje-de-40-minutos-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1373",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bcn-noche-spa-60-cava/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1374",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bcn-noche-spa-75-cava/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1375",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bcn-noche-spa-60-cava-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1376",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/especial-parejas-circuito-playa-granada-masaje-de-15-min-cena-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1377",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/super-papa-spa-breakfast/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1378",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/2x1-especial-deep-tissue-massage-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1379",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/ritual-de-amor-115-min-con-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1380",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/pedicura-spa-con-esmaltado-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1381",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/piscina-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1382",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/rejuvenecimiento-facial-con-laser-diodo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1383",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/cara-labio-superior-o-barbilla/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1384",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/spa-buffet-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1385",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/pedicura-completa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1386",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/personalized-facial-men-women-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1387",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/depilacion-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1388",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/aladinia-rd-2-personas-130e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1389",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/aladinia-rd-2-personas-120e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1390",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-privado-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1391",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/masaje-relajante-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1392",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-enero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1393",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/pack-jarra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1394",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-de-citricos-152px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1395",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/tratamiento-facial-antimanchas-oxigenante-o-calmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1396",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/masaje-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1397",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/bienestar-para-dos-75min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1398",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-feb-2020/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1399",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/circuito-tahara-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1400",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/manicura-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1401",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/evento-yoga-brunch-spa-domingo-19-de-noviembre-2023/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1402",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-piedras-calientes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1403",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/depilacion-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1404",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/hydracure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1405",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/vit-c-supreme/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1406",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/maquillaje-de-dia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1407",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/moldeador-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1408",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/exotico-ceilan/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1409",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/masaje-luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1410",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-bienestar-60-wellbeing-ritual-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1411",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/baleares/senator-caleia-talayot-spa/ritual-bienestar-60-wellbeing-ritual-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1412",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/calma-spa/promocion-black-friday-circuito-spa-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1413",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-hydraluronic/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1414",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/exfoliante-corporal-corallium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1415",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hspa-valparaiso/acceso-circuito-hspa-2-hrs-desayuno-viernes-a-domingo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1416",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/corte-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1417",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/spa-buffet-adulto-bebida-no-incluida/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1418",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/masaje-circulatorio-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1419",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/tratamiento-hidratante-la-source-marine-by-thalgo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1420",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/capuccino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1421",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-oriental-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1422",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-de-pindas-con-extracto-de-cerveza-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1423",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/the-spa-by-signature-sir-victor/intense-regenerating-ritual-80min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1424",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/depilacion-de-pecho-abdomen-o-espalda-hombres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1425",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/masaje-samay-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1426",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/ritual-for-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1427",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/tinte-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1428",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/01-cavier-essence-maria-galland/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1429",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/tinte-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1430",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/estudio-y-correccion-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1431",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-anti-dolor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1432",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/depilacion-de-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1433",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/circuito-spa-privado-60-min-exp/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1434",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/hawiian-experience-70/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1435",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1436",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/jacuzzisaunamasaje-15min-2pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1437",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/tratamiento-alisante-detox-spiruline-boost/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1438",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/presoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1439",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/limpieza-facial-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1440",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/masaje-lomi-lomi-hawai-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1441",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/depilacion-con-diodo-cuerpo-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1442",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/yoga-60-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1443",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45-para-dos-masaje-consecutivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1444",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/atardecer-oceanico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1445",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/manicura-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1446",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/especial-navidad-exfoliante-de-frambuesa-y-ficus/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1447",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/experiencia-renovadora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1448",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/mukabhyanga/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1449",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/corte-senora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1450",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/la-vida-es-bella-circuitos-2-personas-3990e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1451",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-palma/spa-la-palma-teneguia-princess/princess-tratamiento-oxigenante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1452",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/segovia/spa-spaxion-convento-capuchinos/masaje-suspiro-hindu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1453",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/ritual-privado-de-evasion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1454",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/pro-body-care/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1455",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-equilibrante-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1456",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/chocolate-envolvimiento-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1457",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-despacio-h10-benidorm/soin-hidratacion-intensa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1458",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hotel-spa-cadiz-plaza/circuito-termal-privado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1459",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/criolipolisis/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1460",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/tratamiento-facial-antimanchas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1461",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/envoltura-chocolate-nino-a/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1462",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/drenaje-linfatico-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1463",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/cura-relax-antiestres-desfatigante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1464",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/medios-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1465",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/medio-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1466",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/depilacion-laser-zona-grande/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1467",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/bano-hidrotermal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1468",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/hotel-break-momento-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1469",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/pedicura-con-masaje-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1470",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/jacuzzi-relax-melia-tamarindos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1471",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/segovia/spa-spaxion-convento-capuchinos/especial-black-friday-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1472",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/tratamiento-jet-lag-regenerador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1473",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/atrapalo-masaje-de-50-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1474",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/atrapalo-masaje-de-50-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1475",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/ritual-flor-de-cerezo-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1476",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-ritual-de-polinesia-2h-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1477",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-sueco-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1478",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/bono-6-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1479",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/masaje-personalizado-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1480",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/black-friday-2x1-masaje-50-entrada-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1481",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1482",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-san-valentin-vip-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1483",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/masaje-a-cuatro-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1484",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-pureza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1485",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/mascarilla-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1486",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/maquillaje-novia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1487",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/circuito-reina-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1488",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/pack-navidad-super-vikingo-circuito-vip-para-dos-personas-pack-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1489",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/tratamiento-facial-lifting-silicium-marino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1490",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/tratamiento-for-men-5-sesiones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1491",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-kumaras/jacuzzi-con-aromaterapia-15/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1492",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/paquete-personalizado-spa-privado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1493",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/sublime-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1494",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/facial-aromaterapia-con-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1495",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hotel-spa-cadiz-plaza/quiromasaje-55-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1496",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/envoltura-chocolate-fango-negro-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1497",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/depilacion-de-torax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1498",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/matizador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1499",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/aroma-body-relax-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1500",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/especial-pareja-circuito-alegria-masaje-de-25-min-servicio-de-te-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1501",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/masaje-armonia-zonal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1502",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/experiencia-relax-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1503",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/facial-vitamina-c-iluminador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1504",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/depilacion-piernas-enteras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1505",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/escapada-romantica-2-noches/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1506",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-10-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1507",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/ritual-so-delicate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1508",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/masaje-relajante-oriental-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1509",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/luxor-spa/dia-de-la-madre-experiencia-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1510",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/masaje-aromaterapia-reflexologia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1511",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-parcial-terapeutico-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1512",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/facial-30-masaje-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1513",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/facial-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1514",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/envoltura-chocolate-masaje-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1515",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/programa-belleza-integral/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1516",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/energy-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1517",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/pack-cerveza-dia-del-padre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1518",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/spa-en-familia-2x1-mas-20-descuento-en-tratamientos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1519",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/viaje-sultane-saba-orient/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1520",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-adulto-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1521",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/day-pass-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1522",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/sublime-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1523",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-relajante-personalizado-en-pareja-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1524",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/gastrospa-masaje-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1525",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/palacio-albacete-spa/gastrospa-masaje-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1526",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/masaje-express-para-dos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1527",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/ritual-ambar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1528",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/depilacion-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1529",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/oriental-pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1530",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/perfection-experience-de-natura-bisse-80-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1531",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/perfection-experience-de-natura-bisse-80-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1532",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/dakotabox-spaalmuerzo-cena-2-personas-7990e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1533",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/terapia-de-perla-y-oro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1534",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-aromatico-completo-55-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1535",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/envoltura-vitamina-c-firmness/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1536",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/depilacion-labio-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1537",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/entrada-spa-50-copa-cava/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1538",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-terapeutico-duo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1539",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-chocolate-25-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1540",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/masaje-luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1541",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-masaje-circulatorio-de-pierna-25-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1542",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/azores/musgo-spa/ritual-poder-vulcanico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1543",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-equilibrante-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1544",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/tenacillas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1545",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/reflexologia-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1546",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-20individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1547",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/relax-andalusi-en-pareja-circuito-spa-ritual-al-andalus/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1548",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/manicura-y-pedicura-semiperamente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1549",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/masaje-luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1550",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/jarra-regalo-dia-del-padre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1551",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/a-c-reserva-de-servicio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1552",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/especial-pareja-circuito-arabe-masaje-de-15-min-servicio-de-te-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1553",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/retirada-de-esmalte-de-gel-permanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1554",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/masaje-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1555",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/ritual-sublime-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1556",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/diseno-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1557",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/m-spa-catedral-mercer/spa-mojito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1558",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/permanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1559",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/bioterapia-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1560",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-privado-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1561",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/detox-botox-like-spiruline-boost/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1562",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/serenity-full-body-massage-50-min-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1563",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/bcn-noche-masaje-15/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1564",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/exfoliacion-corporal-de-coco-plataformas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1565",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/radiofrecuencia-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1566",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/las-palmas/siam-spa-bohemia-suites/recepcion-3-horas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1567",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-15min-chocolate-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1568",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/promocion-especial-dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1569",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-ritual-de-adan-1h-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1570",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/tratamiento-especifico-anti-acne-purificante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1571",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-25-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1572",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/drenaje-linfatico-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1573",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/neceser-cuidado-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1574",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-esencias-naturales-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1575",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/maquillaje-de-fiesta/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1576",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-luz-del-mediterraneo-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1577",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-hydracure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1578",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/paraiso-oceanico-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1579",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-deportivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1580",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/manicura-residente-canario/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1581",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/bano-de-vino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1582",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/for-men-express-marine-anti-fatigue/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1583",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/bano-de-parafina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1584",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1585",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/taller-meditacion-5/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1586",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/kid-massage-4-12-anos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1587",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/especial-san-valentin-spa-para-dos-masaje-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1588",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-equilibrante-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1589",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/pedicura-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1590",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/porque-ellas-se-lo-merecen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1591",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/cura-de-la-tension-cervico-encefalica-y-dolor-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1592",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/lipo-choco-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1593",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-de-oro-o-chocolate-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1594",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/pro-masaje-relax-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1595",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/masaje-liporeafirmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1596",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/facial-whitening/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1597",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/silueta-perfecta/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1598",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-aromatico-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1599",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/acceso-termal-privado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1600",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/envolvimiento-de-algas-marinas-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1601",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/masaje-relajante-de-pies-y-piernas-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1602",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/ritual-kobido-emperatriz/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1603",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-wellness-50-con-masaje-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1604",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/experiencia-espalda-en-equilibrio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1605",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-piernas-cansadas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1606",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-piedras-calientes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1607",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-masaje-oriental-45-msajes-energias-de-la-tierra-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1608",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-chocolate-25min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1609",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/dream-white-suites/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1610",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/circuito-benidorm/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1611",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/masaje-udvartana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1612",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/rejuvenecimiento-facial-con-laser-diodo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1613",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/depilacion-con-diodo-zona-grande/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1614",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/circuito-arabe-alojados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1615",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-leo-deluxe/pack-navidad-glow-up-circuito-spa-tratamiento-lifting-sicilium-marin-pack-regalo-pack-para-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1616",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/tratamiento-facial-antimanchas-oxigenante-o-calmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1617",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/higiene-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1618",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/mi-manana-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1619",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-tui-na-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1620",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/manicura-deluxe-by-opi-50min-sin-esmaltado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1621",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/manicura-deluxe-50-min-sin-esmaltado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1622",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/tratamiento-facial-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1623",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/circuito-deloix-alojados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1624",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/pro-top-care/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1625",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/mar-de-juventud-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1626",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/249-serum-profilift-maria-galland/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1627",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/pack-navidad-relax-pareja-circuito-spa-ritual-gold-theraphy-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1628",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/manicura-esmalte-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1629",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/facial-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1630",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/polynesia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1631",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/tratamiento-purificante-y-desintoxicante-con-crema-de-rassoul/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1632",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-so-delicate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1633",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/sant-jordi-con-spa-privado-circuito-agua-cava-rosa-y-o-bombones-para-2-masajes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1634",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/sant-jordi-con-spa-privado-circuito-agua-cava-rosa-y-o-bombones-para-2-masajes-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1635",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/depilacion-de-cejas-y-labio-de-15-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1636",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/depilacion-de-cejas-y-labio-de-15-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1637",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/segovia/spa-spaxion-convento-capuchinos/ritual-belleza-global/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1638",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/sensual-care-inmersion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1639",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/love-secret/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1640",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/hamman-experience-kesa-y-savon-noir/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1641",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/depilacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1642",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/cura-intensiva/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1643",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/especial-black-friday-especial-mexico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1644",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/circuito-spa-promocion-okeymas-fin-de-semana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1645",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/experiencia-masaje-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1646",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/menorca/spa-faustino-gran/fitness-room-60-only-guests/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1647",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/tratamiento-laser/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1648",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-by-alina-vriel/envoltura-de-naranja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1649",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/esmaltado-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1650",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/spa-cena-buffet-tarde-de-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1651",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/burgos/spa-tudanca/masaje-de-bambuterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1652",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/circuito-spa-privado-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1653",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/peeling-de-uva-y-papaya/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1654",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-15min-pago-directo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1655",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/castellon/balneario-de-villavieja/balneario-menu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1656",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/especial-black-friday-especial-pareja-circuito-alegria-masaje-de-15-min-servicio-de-te-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1657",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/facial-express-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1658",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/envoltura-de-barros-minerales-del-mar-muerto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1659",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/at-masaje-50-experiencia-matinal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1660",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/burgos/spa-tudanca/especial-black-friday-tratamiento-skin-sensations/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1661",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/especial-black-friday-circuito-hydrowellness-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1662",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/terapeutico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1663",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/circuito-spa-club-senator-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1664",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/masaje-reductor-reafirmante-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1665",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/bono-trimestral/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1666",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-timexpert-srns/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1667",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/piscina-exterior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1668",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-sa-voga/ritual-mother-to-be-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1669",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/prueba-de-maquillaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1670",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-escapada-romantica-1-persona-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1671",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/programa-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1672",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/thalasso-experience-1-dia-menu-nature-corner-x-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1673",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/tratamiento-facial-i-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1674",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/nyxpert-the-royal-fitness-spa/express-massage-acceso-al-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1675",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/la-vida-es-bella-circuitos-2-personas-4990e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1676",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/mindfulness-meditacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1677",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/masaje-revitalizador-con-piedras-calientes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1678",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/drenaje-linfatico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1679",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/evasion-romantica-for-two-hotel-desayuno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1680",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/after-sun/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1681",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/bono-10-sesiones-ep/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1682",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/agua/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1683",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/christmas-pack-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1684",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/piernas-enteras-ingles-o-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1685",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/tratamiento-antiedad-silicio-marino-by-thalgo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1686",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/groupon-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1687",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/timepert-c-iluminador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1688",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/bono-5-noches-hotel-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1689",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/body-care-sandos-papagayo-spa-wellness/masaje-especial-mexico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1690",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/depilacion-brazos-completos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1691",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/15min-chocolate-o-facial-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1692",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-balines-masaje-sueco-duo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1693",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/beauty-party-ellas-spa-privado-con-barra-libre-de-cava-y-caja-bombones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1694",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/corporal-detox/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1695",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-relax-rostro-cuello-y-escote/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1696",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/masaje-circulatorio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1697",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/depilacion-de-cejas-con-pinza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1698",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/viaje-a-la-ruta-de-las-delicias-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1699",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/black-friday-circuito-spa-60-mindesayuno-buffet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1700",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/pack-jarra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1701",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/volcan-luxury/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1702",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/mechas-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1703",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/espacio-acuatico-templarium-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1704",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/manicura-infinity-opi-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1705",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/infinity-opi-manicure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1706",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/manicura-infinity-opi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1707",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-belleza-dia-3-facial-antiedad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1708",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/envoltura-fondo-marino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1709",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/masaje-descontracturante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1710",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/neceser-exfoliacion-xl/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1711",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-deluxe-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1712",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1713",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/sun-repair/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1714",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/pack-acido-hialuronico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1715",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/pure-salt-ritual-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1716",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/romantic-package/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1717",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/exfoliacion-corporal-expres-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1718",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/peeling-a-la-oliva-y-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1719",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/piensa-en-ti/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1720",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/pro-especial-mar-muerto-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1721",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/blanqueamiento-dental-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1722",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/tratamiento-hifu-cara-completa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1723",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/relax-andalusi-en-pareja-circuito-spa-ritual-al-andalus/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1724",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/manicura-o-pedicura-opi-infinite-shine-de-50-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1725",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/manicura-o-pedicura-opi-infinite-shine-de-50-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1726",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-thailandes-home-service/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1727",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/masaje-circulatorio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1728",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/a-c-reserva-de-servicio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1729",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/smb-spa-facial-antiestress-1-pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1730",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/programa-de-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1731",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/masaje-60min-y-tratamiento-facial-mil-y-una-noches/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1732",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/manicura-con-esmalte-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1733",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/maquillaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1734",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/shirodara/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1735",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/exfoliante-bambu-jeju/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1736",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/tratamiento-volcan-circuito-1-tratamiento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1737",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/personalizado-masaje-antiestres-40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1738",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/corporal-piel-de-seda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1739",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/manicura-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1740",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-masaje-tui-na/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1741",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/presoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1742",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/momento-spa-gastro-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1743",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/tratamientos-de-manos-con-cerveza-manicura-residente-canario/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1744",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/happiness-with-friends-spa-privado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1745",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/happiness-with-friends/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1746",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/masaje-a-su-eleccion-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1747",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/masaje-con-aromaterapia-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1748",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/pack-tensor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1749",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/digital-wellness-escape/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1750",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/foot-reflexology/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1751",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hotel-spa-cadiz-plaza/masaje-relax-30-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1752",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/depilacion-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1753",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/especifico-localizado-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1754",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/pedicura-spaxion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1755",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/laura-leon-wellness/circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1756",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/pack-vital-prevention/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1757",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/socio-mensual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1758",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/el-mejor-papa-moet-sensations/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1759",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/de-pies-a-cabeza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1760",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/oferta-1-aloe-vera/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1761",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/mechas-bicolor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1762",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/tu-madre-se-lo-merece-y-mas-ahora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1763",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/spa-privado-2h/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1764",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spa-relax-con-masaje-de-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1765",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-ayurveda-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1766",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/vuelve-pronto-facial-30-antes-de-30-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1767",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/tratamiento-facial-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1768",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/tratamiento-hifu-piernas-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1769",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/circuito-spa-privado-para-dos-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1770",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-pazo-los-escudos/wellness-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1771",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/vuelve-pronto-masaje-30-antes-de-60-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1772",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/pack-spa-enamorados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1773",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/masajes-luxury-gourmet-30min-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1774",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/ritual-de-la-felicidad-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1775",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-so-delicate-so-delicate-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1776",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/masaje-oriental-80min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1777",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/kit-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1778",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/pack-hydr-oxigen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1779",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-50-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1780",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/manicura-spaxion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1781",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/jacuzzi-masaje-oferta-relampago/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1782",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/viaje-a-la-ruta-de-las-delicias-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1783",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/ritual-armonia-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1784",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-hydraluronic/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1785",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/circuito-spa-externo-bono-4-1-entrada/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1786",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/circuito-alegria-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1787",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/sweet-love-circuito-beer-spa-pareja-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1788",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/personalizado-masaje-descontracturante-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1789",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/masaje-bienestar-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1790",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/tinte/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1791",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/exfoliacion-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1792",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-radiance-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1793",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1794",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/menorca/spa-faustino-gran/anakasumi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1795",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/bano-de-cristales-efervescentes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1796",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/after-sun/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1797",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-privado-pareja-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1798",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/ninguno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1799",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-augas-santas-balneario-golf/circuito-termal-entrada-s-d-f-recp/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1800",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/lavar-y-marcar-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1801",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-supreme-en-sala-privada-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1802",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/programa-piernas-perfectas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1803",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/1-2-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1804",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/envoltura-exotico-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1805",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/ritual-brisa-de-ganesha/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1806",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/pack-day-pass/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1807",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-hotel-monarque-fuengirola-park/ritual-belleza-en-tu-mirada-para-ella/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1808",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/masaje-sunset-en-egipto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1809",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-tratamiento-facial-kobido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1810",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/especial-india-con-aceite-caliente-cuerpo-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1811",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/pedicura-de-esmalte-de-gel-permanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1812",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/masaje-espalda-y-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1813",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/azores/musgo-spa/musgo-bliss/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1814",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/envoltura-con-fango-del-mar-muerto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1815",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/stop-jet-lag-75/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1816",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/bio-chi-face-body/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1817",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1818",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/masaje-oriental-80-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1819",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-relax-del-rostro-cuello-y-escote/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1820",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/lipo-choco-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1821",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/masaje-de-espalda-y-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1822",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-seda-club/especial-black-friday-circuito-spa-privado-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1823",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-relajante-20-min-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1824",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/depilacion-de-labio-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1825",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spacio-telde/circuito-termal-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1826",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/comida-cena-buffet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1827",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/viaje-vinum-man-vinoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1828",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/total-body/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1829",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/circuito-spa-masaje-relajante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1830",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-alto-aragon/amanecer-en-la-montana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1831",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/raices-medio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1832",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/especial-halloween-l-j-circuito-de-aguas-con-copa-de-cava-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1833",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/masaje-futura-mama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1834",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/facial-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1835",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/ritual-son-brull/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1836",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/hidromasaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1837",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/cargo-extra-depilacion-hombre-zona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1838",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-especial-pre-mama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1839",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/laser-zonas-grandes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1840",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/triangulo-dorado-para-una-piel-resplandeciente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1841",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/couple-cocoon-signature-ritual-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1842",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/navidad-de-ensueno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1843",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/pack-acido-hialuronico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1844",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/exfoliacion-con-pure-de-papaya/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1845",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/aprender-a-nadar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1846",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/botanical-facial-express-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1847",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/botanical-facial-express-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1848",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cortar-y-peinar-pelo-muy-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1849",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/pure-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1850",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/body-care-sandos-papagayo-spa-wellness/pro-masaje-sueco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1851",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/ocean-men-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1852",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/exfoliante-bambu-jeju/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1853",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-masaje-25-l-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1854",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-masaje-30-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1855",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/masaje-de-cabeza-y-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1856",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-pureza-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1857",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/terapia-geotermal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1858",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/especial-ayurveda-abhyanga-55-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1859",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/masaje-tandem-de-cabeza-y-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1860",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/ampliacion-servicio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1861",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-de-citricos-152px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1862",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/higiene-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1863",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/pack-romantico-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1864",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/depilacion-medio-brazo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1865",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/momento-spa-masaje-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1866",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/bano-de-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1867",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/depilacion-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1868",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-con-piedras-volcanicas-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1869",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/propina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1870",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-attica21-vigo/experiencia-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1871",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/masaje-prenatal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1872",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/spa-gastronomia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1873",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-vip-pareja-reserva-directa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1874",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/the-cure-de-natura-bisse-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1875",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/the-cure-de-natura-bisse-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1876",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/ht-wellness-experiencia-gastro-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1877",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/reflexologia-pies-manos-y-cabeza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1878",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/depilacion-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1879",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/viernes-matinal-ponte-guap-exclusivo-web/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1880",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-10-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1881",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/masaje-shi-zen-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1882",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/depilacion-de-piernas-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1883",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/so-delicate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1884",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/al-spa-con-masaje-cava-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1885",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/pack-romantic-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1886",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/jaen/thermas-palacio-de-ubeda/ritual-renacimiento-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1887",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/drenaje-linfatico-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1888",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-55sala-privada-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1889",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/masaje-exclusivity-completo-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1890",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/exfoliacion-corporal-completa-envoltura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1891",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/facial-neluhm/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1892",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/masaje-de-espalda-cuello-y-brazos-25-min-relajante-o-profundo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1893",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/especial-ayurveda-abhyanga-80min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1894",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/decoloracion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1895",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/banador-mujer/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1896",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/masaje-holistico-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1897",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/corte-caballero-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1898",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/corte-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1899",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/socio-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1900",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/cura-lifting-y-antievejecimiento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1901",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/drenaje-linfatico-todo-el-cuerpo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1902",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/depilacion-cejas-y-labio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1903",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/promocion-black-friday-masaje-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1904",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/acceso-alojado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1905",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/acceso-alojado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1906",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/spa-socios-hotel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1907",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/signature-diamante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1908",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-holistico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1909",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/vuelve-pronto-facial-30-antes-de-60-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1910",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-anti-dolor-dia-1-ultrasonido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1911",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/lomi-lomi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1912",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/tratamiento-luminoso-ritual-flores-de-bali/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1913",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/oporto/the-spa-by-sheraton-porto/masaje-corporal-relajante-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1914",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/ritual-japones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1915",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/despertar-by-serena-spa-desayuno-30-masaje-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1916",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/circuito-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1917",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/palacio-albacete-spa/masaje-con-aceites-calientes-bio-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1918",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/masaje-con-aceites-calientes-bio-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1919",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/manicura-bodyna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1920",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bcn-noche-desayuno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1921",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/especial-pareja-circuito-dusch-masaje-de-25-minutos-servicio-de-te-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1922",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/cura-intensiva/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1923",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/ritual-de-juventud-ko-bi-do/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1924",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/las-dunas-spa/peeling-y-envoltura-corporal-mediterranea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1925",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/holystic-christmas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1926",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/can-bordoy-spa/dia-de-la-madre-flores-ostentosas-y-bienestar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1927",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/matriskin-lifting-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1928",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/energy-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1929",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/promocion-invierno-integral-detox/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1930",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/setubal/spa-do-monte/spa-hidromassagem-para-casal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1931",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-descontracturante-espalda-duo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1932",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/pack-goloso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1933",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/espalda-en-equilibrio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1934",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/manicura-con-gel-color/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1935",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-de-5-masajes-30-all-days/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1936",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/bono-tui-family/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1937",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/circuito-spa-adulto-club-playa-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1938",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/ritual-bienestar-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1939",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/depilacion-laser-zona-grande/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1940",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/radiofrecuencia-capilar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1941",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/ritual-naturae/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1942",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/masaje-hidratante-aromatico-azahar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1943",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/masajes-luxury-gourmet-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1944",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/pack-antimanchas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1945",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/nota/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1946",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/masaje-25min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1947",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/ritual-de-la-polinesia-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1948",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/pack-glamour/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1949",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/cena-relax-50-couple/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1950",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/deco-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1951",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/relax-choco-en-pareja-circuito-spa-chocolaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1952",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/higiene-facial-60min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1953",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/club-termal-60-min-masaje-local-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1954",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/momento-spa-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1955",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/ritual-adelgazante-by-thalgo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1956",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/calma-spa/promocion-black-friday-circuito-spa-masaje-calma-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1957",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/tratamiento-facial-vitamina-c-vitamin-c-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1958",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/masaje-especial-mexico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1959",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/oriental-55-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1960",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/manicura-permanente-novedad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1961",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/spa-75-grums-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1962",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/facial-tratamiento-clasico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1963",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/tratamiento-facial-celular-rejuvenecedor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1964",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/vitamina-c-corporal-citrus-supreme/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1965",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/cativacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1966",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/depilacion-bikini/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1967",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacion-piernas-enteras-e-ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1968",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/pack-bano-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1969",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/san-valentin-romantico-organico-vegano/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1970",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-vilaromana-salou/especial-pareja-circuito-spa-masaje-de-15-minutos-servicio-de-te-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1971",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/masaje-relax-de-rostro-cuello-y-escote-beer/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1972",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-relax-del-rostro-cuello-y-escote/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1973",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/maquillaje-de-fiesta/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1974",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/tto-facial-naturae/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1975",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/depilacion-de-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1976",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/tratamiento-facial-remineralizante-y-antioxidante-de-50-circuito-spa-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1977",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/rulos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1978",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/propina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1979",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/pedicura-bodyna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1980",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/gimnasio-spa-deloix-1-semana-cliente-camping-raco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1981",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-de-citricos-152px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1982",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/quitar-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1983",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/tinte-pestanas-y-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1984",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/mas-baby-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1985",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/viaje-a-la-ruta-de-las-delicias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1986",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/manicura-francesa-opi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1987",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/circuito-agua-pase-de-dia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1988",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/especial-pareja-circuito-arabe-servicio-de-te-parejas-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1989",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/circuito-hydrowellness-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1990",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/especial-san-valentin-eros-ypsiqe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1991",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/cura-belleza-integral/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1992",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/wellnessweekend-2x1-spa-matinal-circuito-aguas-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1993",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/bioenergetico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1994",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/experiencia-radisson-blu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1995",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/masaje-son-brull-sanctuary-en-pareja-90-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1996",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/gym/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1997",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/masaje-ayurvedico-de-la-india-tonificante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1998",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/manicura-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 1999",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/axilas-o-ingles-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2000",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-20min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2001",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/spa-de-lunes-a-sabado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2002",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-10-min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2003",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/duo-eco-2-circuitos-termales-2-masajes-parciales/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2004",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-armonia-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2005",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/relax-envoltura-arcilla/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2006",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-especial-embarazada-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2007",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-exclusivity-parcial-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2008",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-beauty-gold-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2009",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/relax-choco-en-pareja-circuito-spa-chocolaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2010",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/drenaje-linfatico-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2011",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/romantico-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2012",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-25-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2013",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-15-min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2014",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/capricho-de-chocolate-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2015",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-de-la-felicidad-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2016",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/bano-con-cristales-efervescentes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2017",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guipuzcoa/spa-foresta-wellness/teak-roots-masaje-balines-y-tradicional-thai/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2018",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-de-chocolate-252px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2019",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/envoltura-chocolate-fango-negro-con-30-de-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2020",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/tratamiento-de-limpieza-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2021",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-relajante-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2022",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/masaje-relajante-20-martes-y-sabados-2-adultos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2023",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-20min-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2024",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/tratamiento-facial-antiarrugas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2025",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/pack-especial-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2026",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/ritual-so-delicate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2027",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/aladinia-spamasaje-40e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2028",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/vuelve-pronto-masaje-30-antes-de-30-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2029",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-masaje-en-pareja-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2030",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/masaje-aromaterapia-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2031",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/couple-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2032",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/touch-go-facial-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2033",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/especial-navidad-love-para-2-personas-spa-masaje-copa-cava-y-cofre-alqvimia-regalo-para-ti/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2034",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-mediterraneo-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2035",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/depilacion-de-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2036",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/pack-bano-dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2037",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/a-c-reserva-de-servicio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2038",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/tinte-pelo-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2039",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hspa-valparaiso/pausa-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2040",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-iberostar-las-gaviotas/essences-senses/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2041",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/tratamiento-facial-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2042",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/lomi-lomi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2043",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/ritual-brisa-de-ganesha/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2044",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-aloe-vera-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2045",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/air-bio-spa-privado-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2046",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-citricos-15min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2047",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/masaje-para-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2048",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/ritual-sport-tonic-energy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2049",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/depilacion-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2050",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/depilacion-piernas-completas-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2051",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/spa-en-familia-2x1-20-descuento-en-tratamientos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2052",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/especial-ayurveda-abhyanga-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2053",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2054",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/aerosoles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2055",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/pedicura-limar-y-pintar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2056",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2057",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-de-la-felicidad-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2058",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-love40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2059",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/dolce-love40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2060",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/dolce-love40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2061",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/ritual-canario/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2062",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/despacio-spa-center-at-the-one/masaje-deep/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2063",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/blanqueamiento-dental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2064",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/manicura-basica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2065",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/cura-intensiva/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2066",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-15-min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2067",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/tratamiento-colageno-by-thalgo-con-especifico-de-ojos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2068",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/febrero-romantico-50-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2069",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/masaje-craneo-facial-hindu-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2070",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/circuito-alegria-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2071",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/pack-navidad-relax-pareja-circuito-spa-ritual-gold-theraphy-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2072",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/secretos-marinos-ocean-view/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2073",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/masaje-30-pack-agua-y-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2074",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/especial-pack-silueta-perfecta-circuito-thalasso-experience-infusion-te-verde/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2075",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/masaje-profundo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2076",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/facial-caviar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2077",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/pack-glamour/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2078",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/circuito-spa-huespedes-cordial-bono-8-2-entradas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2079",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/ducha-escocesa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2080",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/depilacion-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2081",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-de-cerveza-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2082",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/masaje-de-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2083",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/momento-spa-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2084",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/propina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2085",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/regenerador-de-naranjo-y-olivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2086",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/masaje-deep-tissue/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2087",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-reflexologico-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2088",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/circuito-ohtels-masaje-de-25-min-servicio-de-te-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2089",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/cena-2-px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2090",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/masaje-relax-pareja-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2091",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/tratamiento-renovador-para-piernas-cansadas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2092",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/cuello-y-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2093",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-oriental-dia-2-lomi-lomi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2094",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/pack-romantic-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2095",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/mechas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2096",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/mechas-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2097",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-ama-islantilla-resort/spa-gastronomia-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2098",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/hogueras-de-san-juan-circuito-beer-spa-masaje-de-25-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2099",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-wellness-experiencia-gastro-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2100",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/momento-spa-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2101",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/especial-black-friday-2x1-masaje-relajante-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2102",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-timexpert-srns/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2103",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-privado-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2104",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/vacaciones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2105",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/noche-de-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2106",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/anti-estres-sensations/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2107",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/higiene-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2108",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/desayuno-buffet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2109",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/spa-water-circuit-10/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2110",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/all-at-al-hb-ht-desayuno-buffet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2111",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/espalda-cuello-y-cabeza-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2112",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/masaje-55-min-alojados-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2113",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/gold-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2114",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/higiene-facial-basica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2115",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/spa-celestial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2116",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/masaje-apacible-paraiso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2117",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/circuito-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2118",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/bano-de-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2119",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-sa-voga/bloqueig/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2120",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-de-cabeza-hindu-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2121",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/infralabial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2122",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/ritual-naturae/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2123",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2124",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/acido-hialuronico-efecto-lifting/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2125",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/masaje-luz-serena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2126",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-nino-2x1-hotel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2127",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/moldeador-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2128",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/4-hrs-spa-experience-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2129",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/circuito-ano-nuevo-pqt-alojado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2130",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/capuccino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2131",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/ritual-pureza-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2132",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-exclusivity-completo-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2133",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/bono-6-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2134",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/chocolate-relajante-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2135",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/inner-flow/bliss-bundles-days-spa-flotarium-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2136",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-deportivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2137",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-manicura-bodyna-tratamientos-de-manos-y-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2138",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/bono-10-sesiones-ep/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2139",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/facial-timexpert-white/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2140",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/feliz-cumpleanos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2141",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/envoltura-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2142",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-aromaterapia-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2143",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/smb-spa-masaje-40-min-1-pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2144",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/ritual-dionisio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2145",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/melia-in-the-clouds/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2146",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/bono-6-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2147",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/pack-cerveza-dia-del-padre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2148",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/envoltura-de-fango/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2149",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/acceso-spa-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2150",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/ritual-sensacion-oriental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2151",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/mejillas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2152",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/oasis-de-serenidad-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2153",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/pedicura-con-masaje-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2154",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/relaxing-love-circuito-beer-spa-pareja-masaje-15-minutos-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2155",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/especial-circuito-wellness-masaje-estimulador-del-sistema-linfatico-en-camilla-de-andulacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2156",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/eir-exclusive/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2157",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/promo-matinal-spa-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2158",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/gold-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2159",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2160",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/depilacion-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2161",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/pack-vital-prevention/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2162",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/bono-4-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2163",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/viaje-al-eden-tropical-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2164",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-leo-deluxe/pack-navidad-relax-pareja-circuito-spa-ritual-gold-theraphy-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2165",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/clase-privada-de-yoga/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2166",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/castellon/balneario-de-villavieja/masaje-75min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2167",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/depilacion-con-diodo-zona-pequena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2168",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/pedicura-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2169",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/oferta-junio-cool-face-feet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2170",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/ritual-muri-shinai-japones-reafirmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2171",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/facial-japones-kobido-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2172",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/tinte-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2173",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-relajante-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2174",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2175",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/circuito-beer-spa-grupo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2176",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/ritual-reina-de-egipto-80min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2177",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/promocion-circuito-spa-reina-masaje-de-25-min-servicio-de-te-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2178",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/reflexologia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2179",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/pack-romantic-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2180",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madeira/quinta-do-furao-sen-spa/massagem-de-laranja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2181",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/wine-sensations/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2182",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/detox/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2183",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/sprunch-spa-brunch/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2184",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/descanso-30m-1pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2185",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/descanso-30m-1pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2186",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/msje-descon-duo-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2187",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/exfoliante-vitamina-c-scrub/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2188",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cura-ducha-vichy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2189",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/natural-massage-algas-aceite-de-oliva-miel-y-chocolate-5-sesiones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2190",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/depilacion-de-pubis/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2191",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/global-on-anti-age/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2192",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/pedicura-para-el/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2193",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/depilacion-labio-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2194",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/bono-10-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2195",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/tratamiento-shanara-con-cuencos-tibetanos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2196",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/drenaje-linfatico-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2197",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/especial-pareja-circuito-ohtels-servicio-de-te-pareja-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2198",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/pack-luxury-love/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2199",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/relax-melia-tamarindos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2200",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/experiencia-renovadora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2201",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/body-care-sandos-papagayo-spa-wellness/pro-love-secret/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2202",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/retirar-unas-semi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2203",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/estas-navidades-regale-bienestarbelleza-2-pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2204",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/jardin-de-los-deseos-man-massage-yang-by-alqvimia-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2205",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/masaje-bespoke-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2206",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/circuito-spa-masaje-relajante-para-dos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2207",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2208",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/circuito-sensaciones-alojados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2209",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/ritual-bienestar-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2210",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/4-hrs-piscinas-exteriores-spa-pack-gourmet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2211",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/rebajas-40-4-entrada-dobles-acceso-spa-50-total-8-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2212",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/especial-halloween-v-d-y-festivos-circuito-de-aguas-con-copa-de-cava-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2213",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-equilibrante-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2214",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/masaje-descontracturante-de-espalda-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2215",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/promocion-especial-dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2216",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/higueron-spa/canjeo-circuito-spa-black-friday-3x70e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2217",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/neceser-basic/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2218",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/masaje-bio-relajante-50-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2219",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/the-spa-by-signature-sir-victor/city-relax-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2220",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/momento-spa-masaje-4/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2221",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/depilacion-torax-o-abdomen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2222",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/9issui-spa-fitness/sala-vip-2pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2223",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-anticelulitico-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2224",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/drenaje-linfatico-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2225",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/manicura-o-pedicura-princesa-nina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2226",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/masaje-circulatorio-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2227",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spacio-telde/pack-circuit-termal-masaje-50min-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2228",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/azores/musgo-spa/spa-for-kids-6-a-12-anos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2229",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-10-min-individual-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2230",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/circuito-spa-buffet-menores-4-anos-cumplidos-a-menores-de-18/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2231",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/masaje-sueco-85min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2232",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/tratamiento-corporal-geotermal-con-piedras-volcanicas-calientes-y-aceites-esenciales/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2233",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/viaje-al-eden-tropical/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2234",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-parcial-terapeutico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2235",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/kit-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2236",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-15-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2237",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/ondas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2238",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-palma/spa-la-palma-teneguia-princess/princess-experiencia-valentine/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2239",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/exfoliante-vitamina-c-scrub/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2240",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-cerler/momento-spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2241",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/shi-zen-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2242",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/masaje-especial-ayurveda-abhyanga-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2243",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/mesoterapia-virtual-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2244",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valladolid/azz-penafiel-las-claras/circuito-envoltura-mascarilla/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2245",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hspa-valparaiso/despayuno-lunes-a-domingo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2246",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/higueron-spa/friends-beauty-experience-spa-limpieza-facial-y-mascarilla-de-belleza-flash-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2247",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/prenatal-y-posnatal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2248",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/terapia-de-colageno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2249",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/pack-navidad-glow-up-circuito-spa-tratamiento-lifting-sicilium-marin-pack-regalo-pack-para-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2250",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/masaje-intensivo-espalda-combinacion-piedras-calientes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2251",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/purify-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2252",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/depilacion-de-torax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2253",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/limpieza-facial-profunda-crema-gley-de-brech-de-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2254",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/wellness-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2255",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/masaje-neurosedante-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2256",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/tratamiento-ritual-sublime-cinco-flores-de-bali-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2257",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/exfoliacon-e-hidratacion-corporal-parcial-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2258",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/aroma-relax-60min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2259",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/depilacion-piernas-enteras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2260",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/masaje-sueco-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2261",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/tratamiento-platino-circuito-1-tratamiento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2262",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/detalle-sushi-jamon-queso-dulces/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2263",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/gold-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2264",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/eres-granadin-esta-oferta-es-para-ti/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2265",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/hot-stone-80-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2266",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/mi-dia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2267",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/masaje-con-velas-de-aceite/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2268",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/flyer-25-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2269",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/beauty-party-cena-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2270",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/depilacion-entrecejo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2271",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/vitamina-c-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2272",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/circuito-beer-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2273",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-bienestar-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2274",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-masaje-30-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2275",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-slimming/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2276",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/exclusivo-caballeros/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2277",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/circuito-spa-nudista/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2278",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/facial-skin-zen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2279",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/bono-4-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2280",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/depilacion-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2281",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/mama-te-lo-mereces-circuito-agua-masaje-gratis-circuito-agua-acompanante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2282",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/papa-te-lo-mereces-masaje-antiestres-y-circuito-de-aguas-gratis-acompanante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2283",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/entrenamiento-personal-4-ses/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2284",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/pack-navidad-bano-bikingo-circuito-spa-sala-comun-mascarilla-facial-de-cerveza-para-dos-personas-pack-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2285",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/ritual-radiance-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2286",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/programa-beauty-platinum-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2287",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/groupon-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2288",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-de-cerveza-55-2px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2289",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/oxigenacion-e-hidratacion-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2290",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/exfoliante-lito-scrub/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2291",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/relaxing-love-circuito-beer-spa-pareja-masaje-15-minutos-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2292",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-relajante-de-polinesia-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2293",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guipuzcoa/spa-foresta-wellness/tjikko-roots-masaje-sueco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2294",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/toalla-alquiler/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2295",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/spa-hotel-blu-almansa/circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2296",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/masaje-deportivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2297",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-despacio-h10-benidorm/viajes-luxury/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2298",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/bioterapia-piernas-perfectas-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2299",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/ritual-bienestar-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2300",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/exfoliacion-iluminadora-con-pure-de-papaya/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2301",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-relajante-con-aceite-de-citricos-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2302",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/tratamiento-facial-45/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2303",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/ritual-de-la-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2304",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/dia-del-padre-spa-masaje-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2305",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/spa-masaje-ayurveda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2306",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/depilacion-pack-cuerpo-mujer/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2307",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/limpieza-de-d-tox-tratamiento-de-ojos-y-labios/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2308",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-bienestar-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2309",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/laura-leon-wellness/spa-masaje-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2310",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/pack-antimanchas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2311",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/volcan-timanfaya/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2312",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/manicura-shellac/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2313",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/matizador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2314",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/pro-sweet-dreams/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2315",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/cura-intensiva/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2316",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/facial-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2317",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/envoltura-nutritiva-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2318",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/depilacion-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2319",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/pedicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2320",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/a-c-reserva-de-servicio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2321",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/smb-spa-cava-o-coctel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2322",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/chorro-jet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2323",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/experiencia-sweet-time/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2324",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-we-love-beer-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2325",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/pedicura-permanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2326",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/maquillaje-natural/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2327",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/vodder-masaje-drenante-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2328",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/wellness-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2329",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/calma-spa/especial-san-valentin-masaje-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2330",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/tratamiento-parafina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2331",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/especial-fiesta-30-08-cena-con-musica-en-directo-pool-party/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2332",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/after-sun-fresh-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2333",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/especial-black-friday-circuito-beer-spa-masaje-completo-55-min-sala-comun-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2334",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/vip-love-circuito-vip-pareja-masaje-15-minutos-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2335",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/especial-san-valetin-deluxe-day/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2336",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/rejuvenecimiento-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2337",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-15-min-pareja-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2338",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/depilacion-labio-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2339",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/ritual-ayurvedico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2340",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/tratamiento-facial-express-essencial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2341",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/espacio-acuatico-templarium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2342",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/spa-privado-cena-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2343",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/envoltura-con-aloe-vera-de-spa-in-cosmetics/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2344",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/mi-dia-romantico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2345",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/depilacion-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2346",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/santa-cruz-de-tenerife/spa-callao-sport/momento-spa-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2347",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/masaje-podal-oriental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2348",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/especial-semana-santa-en-be-aloe-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2349",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/masaje-corporal-relajante-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2350",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/sensations-anti-estres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2351",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/ahuecador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2352",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/circuito-beer-spa-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2353",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/hotel-neptuno-spa/spa-y-gastronomia-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2354",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guadalajara/spa-castilla-termal-brihuega/masaje-de-piedras-volcanicas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2355",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/castellon/balneario-de-villavieja/relax-juntos-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2356",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-completo-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2357",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/facial-basico-facial-a-tu-medida/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2358",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/ligero-como-el-aire-40min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2359",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/exfoliante-bambu-jeju/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2360",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-chocolate-15/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2361",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/las-palmas/siam-spa-bohemia-suites/recepcion-90min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2362",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/black-friday-bioterapia-sistemica-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2363",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/radiofrecuencia-facial-6-sesiones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2364",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/al-masaje-25-experiencia-gastro-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2365",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-naturae/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2366",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-iberostar-las-gaviotas/masaje-relajante-chic-child/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2367",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/depilacion-de-torax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2368",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/tratamiento-luminoso-ritual-flores-de-bali-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2369",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-nocturno-spa-termal-17-febrero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2370",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/hesperides-thalasso-spa/mes-de-las-madres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2371",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/circuito-spa-gourmet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2372",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/corte-secado-nina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2373",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-equilibrante-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2374",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/alquiler-banador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2375",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/bano-de-burbuja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2376",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/exfoliaciones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2377",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/dia-del-padre-spa-masaje-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2378",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/depilacion-brazos-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2379",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-roca-negra/circuito-spa-obsidiana-express-masaje-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2380",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/masaje-neuro-sedante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2381",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/envoltura-corporal-de-algas-masaje-craneal-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2382",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/envoltura-de-aceites-esenciales/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2383",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/mas-espalda-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2384",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/manicura-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2385",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/aragon-hills-hotel/wellness-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2386",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/programa-energetico-revitalizante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2387",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/depilacion-ingles-brasilenas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2388",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-wellness-con-masaje-experiencia-matinal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2389",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sunlight-bahia-principe-san-felipe/facial-eterna-juventud/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2390",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/at-masaje-30-experiencia-matinal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2391",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/tto-facial-naturae/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2392",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/neceser-relax-xl/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2393",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/bano-niagara-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2394",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-exclusivity-de-cerveza-25min-residente-canario/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2395",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/ritual-de-oriente-110/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2396",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/promocion-black-friday-body-care-uli-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2397",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bano-de-pies-con-sal-y-citricos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2398",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/masaje-30-experiencia-matinal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2399",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-holistico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2400",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/masaje-deportivo-parcial-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2401",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-exclusivity-full-body-75/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2402",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/eliminacion-shellac-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2403",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/bono-parafina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2404",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/masaje-25min-individual-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2405",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-bienestar-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2406",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/calma-spa/promocion-black-friday-masaje-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2407",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/masaje-20-infantil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2408",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-de-chocolate-25paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2409",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/masaje-acupuntura-40-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2410",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-balines-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2411",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/masaje-anticelulitico-firma/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2412",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/masaje-anticelulitico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2413",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/up-selling-iroha-1persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2414",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-relajante-con-aromas-del-bosque-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2415",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/personalizado-masaje-ayurvedico-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2416",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2417",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-spaxion-hg-la-molina/momento-spa-masaje-5/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2418",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/ritual-bienestar-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2419",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/momento-spa-estancia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2420",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/tratamiento-facial-express-25min-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2421",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/circuito-termal-basic/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2422",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/spa-hotel-blu-almansa/masaje-biodescanso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2423",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/spa-buffet-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2424",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/weekend-brunch-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2425",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/depilacion-medias-piernas-residente-canario/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2426",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/maquillaje-de-fiesta/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2427",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/viaje-al-eden-tropical/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2428",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/sesion-de-osteopatia-70-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2429",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/terapia-de-golfistas-70-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2430",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/pack-romantic-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2431",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/jacuzzi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2432",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/cativacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2433",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/retirar-unas-semi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2434",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-15-pack-fallas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2435",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/ritual-flor-de-cerezo-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2436",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/exfoliacion-corporal-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2437",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/beer-spa-para-2-oferplan/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2438",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/black-beer-burbujeante-circuito-sala-comun-regalos-para-dos-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2439",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-relajante-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2440",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-relajante-de-media-luna-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2441",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/masaje-aromatico-completo-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2442",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/pedicura-esmalte-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2443",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/masaje-nino-40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2444",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-alto-aragon/masaje-deportivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2445",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/envoltura-vitamina-c-firmness/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2446",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guipuzcoa/spa-foresta-wellness/spa-y-brunch-fines-de-semana-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2447",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/masaje-sensorial-de-cabeza-y-cuero-cabelludo-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2448",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/lift-c-v-s-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2449",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/cargo-extra-manicura-pedicura-francesa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2450",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/masaje-celta-de-piedras-calientes-y-frias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2451",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/circuito-motril-alojados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2452",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/tratamiento-antiarrugas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2453",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/mechas-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2454",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/at-masaje-25v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2455",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/at-msj-30-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2456",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/promocion-circuito-spa-aqua-center-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2457",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/masaje-relajante-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2458",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/corte-de-pelo-para-ninos-hasta-12-anos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2459",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/peeling-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2460",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/masaje-relajante-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2461",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/menorca/spa-faustino-gran/aromaterapia-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2462",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2463",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/promocion-masaje-15/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2464",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/cura-especial-deportistas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2465",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/facial-terapia-de-colageno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2466",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/oferta-aloe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2467",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/masaje-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2468",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/ritual-brisa-de-ganesha/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2469",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2470",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/relax-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2471",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/habitacion-con-vistas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2472",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/wellness-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2473",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/tratamiento-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2474",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/facial-eternal-youth/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2475",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-piedras-masaje-sueco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2476",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/cura-especial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2477",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/pack-acido-hialuronico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2478",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/nos-merecemos-un-dia-especial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2479",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/cura-relax-antiestres-desfatigante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2480",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-completo-de-cerveza-degustacion-de-1-cerveza-especial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2481",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/tto-facial-naturae-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2482",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/facial-personalizado-y-con-extraccion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2483",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/depilacion-con-diodo-zona-pequena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2484",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/depilacion-integral/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2485",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/envolvimiento-con-aloe-vera/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2486",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/black-friday-thalasso-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2487",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/spa-buffet-grupos-verano/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2488",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/peeling-cleaner-para-mama-estrella/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2489",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-15-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2490",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/burgos/spa-tudanca/especial-mama-tratamiento-ocean-miracle/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2491",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/experiencia-relax-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2492",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/sudacion-manta-de-calor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2493",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/tinte-de-pestanas-tinte-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2494",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/laser-zonas-pequenas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2495",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/spa-hotel-blu-almansa/calma-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2496",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/ritual-solar-supreme/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2497",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/essential-massage-75-min-con-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2498",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/facial-anti-rides/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2499",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/masaje-exclusivity-parcial-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2500",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/rejuvenecimiento-facial-con-laser-diodo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2501",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/relax-post-sport-descongestion-y-reparacion-especial-caballeros/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2502",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-augas-santas-balneario-golf/envoltura-de-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2503",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/champi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2504",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/especial-mama-cava-deluxe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2505",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/diseno-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2506",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-hotel-hyltor/spa-privado-masaje-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2507",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-aromaterapia-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2508",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/maderoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2509",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/manicura-pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2510",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/palacio-albacete-spa/invierno-ritual-ilumina-tu-piel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2511",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/invierno-ritual-ilumina-tu-piel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2512",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/banera-hidromasaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2513",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/peeling-corporal-de-coco-hidratacion-completa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2514",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/balinese-track-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2515",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/masaje-apacible-paraiso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2516",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/reafirmante-lift-corps/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2517",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/menorca/spa-faustino-gran/circuito-termal-60-clientes-alojados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2518",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/lomi-lomi-nui-55-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2519",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/porque-ellas-se-lo-merecen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2520",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hm-ayron-park/cama-balinesa-almuerzo-a-la-carta-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2521",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-tandem-de-cabeza-y-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2522",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/especial-pareja-circuito-oliveres-masaje-de-55-min-servicio-de-te-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2523",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/spa-attica-21-villalba/pack-descontracturante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2524",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/sprunch-bodyna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2525",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/bonos-circuito-hidrotermal-10-sesiones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2526",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-feb-2020/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2527",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-nucia-spa-masshotel/spa-masaje-desayuno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2528",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/burgos/spa-tudanca/ritual-anticelulitico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2529",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/masaje-udvartana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2530",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2531",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/banador-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2532",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-citricos-15min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2533",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/facial-calmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2534",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/recogido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2535",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/combinado-de-masajes-asiatico-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2536",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/oporto/the-spa-by-sheraton-porto/paz-para-dos-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2537",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-ritual-de-atlas-marruecos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2538",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/programa-mision-banador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2539",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/tailandes-o-shiatsu-90-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2540",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/tratamiento-sublime-ritual-de-cinco-flores/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2541",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/depilacion-con-diodo-zona-media/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2542",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/cura-adelgazante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2543",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/nyxpert-the-royal-fitness-spa/lulur-ritual-acceso-al-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2544",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/facial-expreso-para-hombre-mujer-40-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2545",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/facial-expreso-para-hombre-mujer-40-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2546",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-15-min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2547",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/spa-lovers-paquetes-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2548",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/depilacion-bikini/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2549",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/masaje-aloe-vera-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2550",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/maderoterapia-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2551",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/peeling-exotico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2552",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/hard-rock-spa-marbella/be-my-valentine/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2553",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/esmaltado-de-unas-infantil-de-25-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2554",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/esmaltado-de-unas-infantil-de-25-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2555",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/piedras-volcanicas-i-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2556",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/experiencia-romantica-san-valentin-2-pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2557",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/oferta-hotel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2558",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/brunch-recepcion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2559",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/gimnasio-2-semanas-cliente-camping-raco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2560",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/pack-hydr-oxigen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2561",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/masaje-vital/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2562",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/esmaltado-de-unas-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2563",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/san-valentin-24/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2564",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/masaje-deep-tisue/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2565",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/especial-navidad-serenity-spa-masaje-copa-cava-y-cofre-alqvimia-regalo-para-ti/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2566",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/lipolitico-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2567",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/masaje-relajante-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2568",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/facial-acido-hialuronico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2569",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/depilacion-medios-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2570",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2571",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/tratamiento-con-lodo-de-algas-marinas-cuerpo-completo-ducha-tricoloma/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2572",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/circuito-termal-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2573",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/lipo-choco-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2574",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/exfoliaciones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2575",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/azores/musgo-spa/medida-do-desporto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2576",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/spa-privado-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2577",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/pack-bano-dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2578",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/mascara-corporal-45/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2579",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/the-spa-by-signature-sir-victor/cama-piscina-members/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2580",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-hawai-lomi-lomi-recepcion-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2581",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/pedicura-de-esmalte-de-gel-permanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2582",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/thalgo-ritual-purete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2583",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/weekendesk-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2584",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/weekendesk-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2585",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/dolce-relax-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2586",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/sweet-love-circuito-beer-spa-pareja-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2587",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-bienestar-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2588",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/pro-revitalazing/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2589",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/pack-graduacion-para-estudiantes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2590",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/manicura-spa-con-esmaltado-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2591",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/pro-love-secret/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2592",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/burgos/spa-tudanca/masaje-circulatorio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2593",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/bono-solarium-5-1-sesion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2594",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/tratamiento-anticelulitico-y-moldeador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2595",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-de-citricos-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2596",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/balneario-vichy-catalan/circuito-termal-infantil-cliente-hotel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2597",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/palacio-albacete-spa/masaje-con-aceites-calientes-bio-3/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2598",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/ducha-presion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2599",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/masaje-aromaterapia-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2600",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-a-medida-30-min-en-cabina-de-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2601",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-25min-chocolate-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2602",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/depilacion-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2603",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/depilacion-labio-superior-o-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2604",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/reiki/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2605",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/especial-dia-del-padre-kit-del-rey/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2606",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/pro-tratamiento-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2607",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/ducha-jet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2608",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/masaje-piernas-sport/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2609",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/spa-privado-by-night-para-1-a-6-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2610",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-spa-beach/serenity-vitality-ritual-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2611",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/entrada-termal-2h-nocturna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2612",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/depilacion-de-ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2613",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/circuito-spa-brunch-domingos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2614",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/pedicura-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2615",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-experiencia-bodyna-2-personas-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2616",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/circuito-spa-naturista/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2617",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-equilibrante-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2618",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/neceser-cuidado-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2619",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/masaje-energizer-55-min-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2620",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/depilacion-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2621",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/cura-especial-deportistas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2622",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-aromatico-15-minutos-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2623",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/viaje-a-las-vinas-de-la-toscana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2624",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/masaje-30-ht-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2625",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/ritual-de-sensaciones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2626",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/vitamina-c-facial-citrus-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2627",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/oporto/the-spa-by-sheraton-porto/express-glow-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2628",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/exfoliacion-citrus-scrub-de-natura-bisse-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2629",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/exfoliacion-citrus-scrub-de-natura-bisse-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2630",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-intenso-30-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2631",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/masaje-con-piedras-calientes-75-circuito-hidrotermal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2632",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/depilacion-brazos-o-brasilena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2633",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/depilacion-laser-dos-zonas-pequenas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2634",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/especial-pareja-navidad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2635",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/sesion-desbloqueo-emocional/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2636",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/sunrice/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2637",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/urban-sports-club-acceso-spa-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2638",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/custom/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2639",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/signature-radiance-by-emerginc/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2640",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/pack-navidad-glow-up-circuito-spa-tratamiento-lifting-sicilium-marin-pack-regalo-pack-para-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2641",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/circuito-hidrotermal-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2642",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/pack-acido-hialuronico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2643",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2644",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/centro-bienestar-sunprime-atlantic/masaje-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2645",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-ayurveda-50-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2646",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/spa-privado-masaje-25-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2647",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/pedicura-deluxe-by-opi-50min-sin-esmaltado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2648",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/pedicura-deluxe-50-min-sin-esmaltado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2649",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-citricos-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2650",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/piernas-ligeras-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2651",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/ritual-tentacion-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2652",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/peeling-corporal-citrico-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2653",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/masaje-con-piedras-calientes-75min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2654",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/bono-6-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2655",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/despertar-by-serena-spa-desayuno-50-masaje-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2656",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-despacio-h10-benidorm/ayurveda-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2657",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/promocion-especial-dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2658",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/hotel-break-momento-spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2659",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-kumaras/jacuzzi-con-aromaterapia-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2660",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/masaje-relajante-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2661",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/exfoliacion-corporal-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2662",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/aragon-hills-hotel/facial-hydracure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2663",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/bono-5-entradas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2664",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/3-sesiones-de-laser-diodo-en-cuerpo-completo-1-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2665",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hotel-spa-cadiz-plaza/masaje-relax-55-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2666",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/black-friday-2x1-circuito-spa-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2667",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/a-c-reserva-de-servicio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2668",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/masaje-corporal-relajante-45/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2669",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-y-envoltura-de-oro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2670",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/masaje-piedras-calientes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2671",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/terapeutico-deportivo-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2672",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/detox/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2673",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sunlight-bahia-principe-san-felipe/masaje-anti-dolor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2674",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/ritual-iles-pacifique-promocion-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2675",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-leo-deluxe/relax-andalusi-en-pareja-circuito-spa-ritual-al-andalus/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2676",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/promocion-web-facial-kobido-con-piedras-de-jade-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2677",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/sun-repair/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2678",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/sweet-love-circuito-beer-spa-pareja-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2679",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/masaje-californiano/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2680",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/9issui-spa-fitness/momento-spa-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2681",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/desinfeccion-de-cabina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2682",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/semirecogido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2683",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-craneocervical-10-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2684",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/deep-tissue/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2685",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-alto-aragon/ritual-de-hidratacion-total/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2686",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/centro-bienestar-sunprime-atlantic/tratamiento-facial-beauty-time/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2687",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/burgos/spa-tudanca/ritual-belleza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2688",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/tratamiento-facial-rosa-de-bulgaria/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2689",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/pelo-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2690",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/jaen/thermas-palacio-de-ubeda/masaje-lomi-lomi-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2691",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/depilacion-ingles-o-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2692",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guadalajara/spa-castilla-termal-brihuega/masaje-kobido-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2693",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/chocolat-passion-spa-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2694",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/especial-pareja-piscina-de-tratamientos-masaje-de-25-min-servicio-de-te-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2695",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/tratamiento-facial-kobido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2696",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/solo-tarta-cumpleanos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2697",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-y-envoltura-de-oro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2698",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/woman-haircut-4/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2699",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/peinado-extra-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2700",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/peinado-extra-largo-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2701",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/andes-colombianos-massage-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2702",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/brunch-1-enero-circuito-termal-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2703",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/anti-stress-3-dias-dia-1-masaje-25-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2704",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/masaje-55-min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2705",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/tratamiento-facial-riche-de-jeunesse/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2706",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/higueron-spa/ducha-jet-revitalizante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2707",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/meditation/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2708",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/envoltura-de-algas-barro-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2709",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/depilacion-ingles-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2710",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/balneario-san-nicolas/circuito-termal-dulces-sensaciones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2711",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/curaenvoltura-de-algas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2712",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-15min-pago-directo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2713",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/envoltura-picota-o-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2714",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/palacio-albacete-spa/promocion-especial-circuito-hidrotermal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2715",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/promocion-especial-circuito-hidrotermal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2716",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-tailandes-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2717",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/gran-hotel-spa-lakua/taller-de-masaje-familiar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2718",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/terapia-watsu-en-parejas-o-duo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2719",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/masaje-exclusivity-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2720",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/ducha-vichy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2721",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/experiencia-relax-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2722",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/precio-senora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2723",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/pack-bano/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2724",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/castellon/balneario-de-montanejos/aqua-semana-santa-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2725",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-cuerpo-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2726",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/eventos-a-medida/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2727",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/especial-pareja-navidad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2728",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/masaje-relajante-de-espalda-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2729",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/las-palmas/siam-spa-bohemia-suites/reunion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2730",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-cerler/momento-spa-masaje-4/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2731",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/circuito-chocorelax-pareja-circuito-spa-masaje-relajante-de-chocolate-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2732",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/oferta-flash-programa-leon-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2733",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/tratamiento-facial-thalgo-beauty-flash/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2734",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-alto-aragon/momento-spa-masaje-3/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2735",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-intenso-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2736",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-spa-on-top/acces-spa-free-60min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2737",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/tratamiento-alisante-y-reafirmante-ko-bi-do/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2738",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/black-friday-2x1-masaje-80-entrada-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2739",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-15-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2740",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/limpieza-facial-basica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2741",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/circuito-victoria/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2742",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/especial-san-valentin-spa-para-dos-de-lunes-a-jueves/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2743",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2744",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2745",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/recogido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2746",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/especial-black-friday-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2747",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/9issui-spa-fitness/masaje-terapeutico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2748",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/spa-buffet-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2749",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/despertar-by-serena-spa-desayuno-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2750",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/depilacion-bikini/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2751",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/burgos/spa-tudanca/especial-mama-tratamiento-purifying/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2752",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-de-la-felicidad-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2753",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-10min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2754",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/promocion-black-friday-body-care-uli-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2755",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/masaje-energizer-40-min-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2756",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/vegan-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2757",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/spa-oferta-romantica-incluido-habitacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2758",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/masaje-cuerpo-entero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2759",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/noche-termal-i-entrada/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2760",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/masaje-suite-privada-en-pareja-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2761",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2762",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/bioptron/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2763",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/pack-navidad-relax-pareja-circuito-spa-ritual-gold-theraphy-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2764",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/hidratacion-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2765",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/timexpert-liftin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2766",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/circuito-navidad-pqt-alojado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2767",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/rejuvenecimiento-facial-con-laser-diodo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2768",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-oriental-55-min-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2769",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-srns/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2770",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/oasis-de-serenidad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2771",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/exfoliacion-corporal-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2772",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/masaje-aromatico-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2773",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-25-min-alojados-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2774",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-cielo-y-tierra-para-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2775",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/bono-sesiones-circuito-15-sesiones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2776",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-promociones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2777",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/mascarilla-y-exfoliante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2778",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/circuito-sentidos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2779",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/masaje-ninos-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2780",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/exfoliante-corporal-corallium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2781",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2782",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-leo-deluxe/pack-spa-enamorados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2783",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-detox-dia-3-bano-de-algas-drenaje-linfatico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2784",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-detox-dia-3-banera-subacuatica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2785",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/firm-and-tone-sculpting-tratamiento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2786",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/extra-maquillado-unas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2787",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/extra-maquillado-unas-by-opi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2788",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/gel-color-by-opi-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2789",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/talleres-mindfulness/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2790",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/desayuno-en-hotel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2791",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/biologico-bio-lifting-bamboo-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2792",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/prueba-recogido-de-novia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2793",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/vitality/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2794",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guipuzcoa/spa-foresta-wellness/spa-y-brunch-fines-de-semana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2795",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/masaje-esencias-naturales-completo-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2796",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/masaje-relajante-firmas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2797",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/ritual-hydraluronic/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2798",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/tratamiento-facial-the-cure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2799",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/higiene-base-revitalizante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2800",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/unas-acrilicas-gel-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2801",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/unas-acrilicas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2802",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/circuito-spa-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2803",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/masaje-shi-zen-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2804",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/zona-de-aguas-masaje-relajante-50-min-cena-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2805",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/castellon/balneario-de-villavieja/balneario-masaje-parafango/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2806",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/cura-de-la-tension-cervico-encefalica-y-dolor-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2807",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/lunch-dinner-pareja-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2808",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2809",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/black-friday-cofre-calm-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2810",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/vip-love-circuito-vip-pareja-masaje-15-minutos-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2811",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/mesoterapia-virtual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2812",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/programa-mimate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2813",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-masaje-balines-en-pareja-con-frutas-y-cava-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2814",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cura-mascarilla-de-algas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2815",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-15min-pago-directo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2816",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/bono-10-sesiones-masaje-parcial-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2817",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/masaje-circulatorio-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2818",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/relajese-en-pareja-2pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2819",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/personalizado-kids-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2820",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/cavitacion-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2821",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/corte-nina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2822",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/corte-nina-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2823",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/cut-kids/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2824",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/bano-hidromasaje-ts/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2825",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2826",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-siesta-cuello-hombros/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2827",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/cargo-extra-para-manicura-pedicura-francesa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2828",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/ritual-hydraluronic/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2829",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/circuito-spa-privado-8-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2830",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/oferta-deportistas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2831",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-la-gavina/masaje-champi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2832",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/luxor-spa/experiencia-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2833",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/masaje-bienestar-45/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2834",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-sueco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2835",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/day-pass-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2836",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-descontracturante-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2837",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/bono-10-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2838",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/sauna-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2839",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/ritual-so-delicate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2840",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/ritual-kobido-emperatriz/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2841",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/cata-de-cerveza-con-maridaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2842",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/anti-stress-3-dias-dia-3ducha-presion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2843",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/sweet-love-circuito-beer-spa-pareja-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2844",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/intima-completa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2845",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/depilacion-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2846",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/masaje-con-aromaterapia-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2847",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/depilacion-laser-varias-zonas-entrecejo-labio-o-labio-barbilla/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2848",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-sueco-completo-home-service/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2849",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/luxor-spa/experiencia-en-pareja-bano-aromatico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2850",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/reflexologia-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2851",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/peeling-corporal-y-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2852",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/chocolaterapia-e-beauty/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2853",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/depilacion-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2854",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/manicura-para-el/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2855",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/weekend-brunch-individual-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2856",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-leo-deluxe/promocion-circuito-spa-masaje-de-55-min-servicio-de-te-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2857",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/masaje-anti-estres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2858",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/gr-spa-con-masaje-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2859",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/sport-tonic-energy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2860",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-15-min-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2861",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/masaje-profundo-parte-posterior-de-piernas-y-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2862",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2863",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/pack-romantico-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2864",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-sueco-descontracturante-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2865",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/relax-andalusi-en-pareja-circuito-spa-ritual-al-andalus/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2866",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/silueta-perfecta-5-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2867",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/experiencia-relax-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2868",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/ritual-happiness-i-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2869",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-acceso-spa-75-anual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2870",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-spa-50-anual-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2871",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/sprunch-con-spa-privado-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2872",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2873",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/maquillaje-de-dia-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2874",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/maquillaje-de-dia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2875",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/santa-cruz-de-tenerife/spa-callao-sport/momento-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2876",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/circuito-hidrotermal-buffet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2877",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/cura-especial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2878",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/spa-attica-21-villalba/recuperacion-postvacacional-con-exfoliacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2879",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/luxury-japan-private-room-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2880",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/masaje-craneo-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2881",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-excusivity-completo-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2882",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/envoltura-al-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2883",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-completo-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2884",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/circuito-alegria-grupos-6px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2885",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2886",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/pedicura-basica-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2887",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/manicura-basica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2888",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/depilacion-labio-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2889",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/masaje-oriental-55-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2890",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/circuito-alegria-hotel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2891",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/relax-choco-en-pareja-circuito-spa-chocolaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2892",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/majestic-spa/zona-de-hidroterapia-alojado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2893",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/depilacion-ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2894",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/masaje-deep-tisue/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2895",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/masaje-bespoke-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2896",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/peeling-y-facial-vit-c-circuito-spa-gratis/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2897",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/lavar-secar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2898",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/momento-spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2899",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/ritual-iles-pacifique-con-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2900",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/media-pierna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2901",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-holistico-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2902",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/burgos/spa-tudanca/especial-black-friday-eye-perfection-treatment/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2903",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-de-la-felicidad-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2904",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/pedicura-con-masaje-podal-residente-canario/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2905",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/promocion-black-friday-spa-break-2x1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2906",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/corporal-marino-remineralizante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2907",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/piensa-en-ti-masaje-40-min-1-pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2908",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/spa-desayuno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2909",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/masaje-circulatorio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2910",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/tratamiento-para-la-espalda-especial-caballeros/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2911",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/mama-te-lo-mereces-circuito-agua-masaje-gratis-circuito-agua-acompanante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2912",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/papa-te-lo-mereces-masaje-antiestres-y-circuito-de-aguas-gratis-acompanante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2913",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/rd-a-105e-2personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2914",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/rd-a-95e-2personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2915",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/exfoliante-bambu-jeju-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2916",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/ayurveda-abhyanga/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2917",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/promocion-reapertura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2918",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/socio-mensual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2919",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/masaje-luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2920",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spacio-telde/masaje-relajante-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2921",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/especial-san-valentin-spabrunch-by-bahia-del-duque/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2922",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/gastrospa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2923",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/momento-spa-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2924",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/pro-anti-celulitico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2925",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/winter-relax-foodie-acceso-spa-de-90-minutos-menu-almuerzo-de-3-platos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2926",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/exfoliacion-con-peeling-cerveza-masaje-hidratante-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2927",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-flor-de-cerezo-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2928",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/1-bono-taller-grupal-1-sesion-desbloqueo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2929",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/experiencia-club-termal-y-flotarium-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2930",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/viaje-a-la-provenza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2931",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/masaje-cuerpo-completo-terapeutico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2932",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/ducha-jet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2933",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/sensuality-spaxion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2934",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/hb-spa-50-con-masaje-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2935",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/envoltura-picota-o-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2936",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/pedicura-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2937",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/dedos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2938",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/detox-40-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2939",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/facial-royal-jelly/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2940",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/electroestimulacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2941",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/circuito-dusch-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2942",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-exclusivity-full-body-75/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2943",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/tinte-de-pestanas-y-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2944",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/masaje-luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2945",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/we-love-spa-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2946",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/tratamiento-bio-celular/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2947",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/timexpert-c-iluminador-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2948",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/socio-trainer/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2949",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/tratamiento-reparador-cold-marine-by-thalgo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2950",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/reserva-restaurante-dors-gourmet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2951",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-descontracturante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2952",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/reflexologia-podal-45-circuito-hidrotermal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2953",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/propina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2954",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/exfoliacion-completa-guante-kessa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2955",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/bambuterapia-masaje-con-canas-de-bambu-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2956",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/bono-10-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2957",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/acceso-spa-paquetes-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2958",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/manicure-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2959",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/serenidad-para-mama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2960",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-anti-stress-residentes-lanzarote/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2961",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hotel-spa-cadiz-plaza/oxyspa-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2962",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/especial-san-valentin-deseo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2963",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/body-care-sandos-papagayo-spa-wellness/pro-sweet-dreams/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2964",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/tratamiento-descontracturante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2965",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-relajante-anti-stres-70-min-duo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2966",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/lavar-marcar-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2967",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2968",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-relax-del-rostro-cuello-y-escote/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2969",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/miercoles-matinal-masajes-al-50-exclusivo-web/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2970",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/masaje-neurosedante-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2971",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/depilacion-con-diodo-zona-pequena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2972",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/bano-oceanico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2973",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/facial-for-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2974",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/manicura-deluxe-con-parafina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2975",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/masaje-deep-tissue/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2976",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/masaje-tradicional-tailandes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2977",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/burgos/spa-tudanca/masaje-cervico-craneal-y-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2978",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-sa-voga/ritual-pindas-energeticas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2979",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/vip-spa-privado-30-botella-cava/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2980",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-relajante-oriental-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2981",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/depilacion-de-entrecejo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2982",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sunlight-bahia-principe-san-felipe/facial-deluxe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2983",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/depilacion-con-diodo-zona-media/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2984",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-ayurvedico-de-cabeza-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2985",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/pack-navidad-esencias-circuito-spa-masaje-de-esencias-naturales-55-minutos-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2986",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/tarjeta-regalo-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2987",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/ritual-bienestar-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2988",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/depilacion-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2989",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/pack-antimanchas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2990",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/after-sun/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2991",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/tratamiento-hifu-abdomen-flancos-o-gluteos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2992",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/menorca/spa-faustino-gran/ritual-botanico-de-menorca/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2993",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/ritual-brisa-de-ganesha-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2994",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/criolipolisis/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2995",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/especial-navidad-serenity-zona-wellness-masaje-copa-cava-y-cofre-alqvimia-regalo-para-ti/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2996",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/bono-10-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2997",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/pro-masaje-esencial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2998",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/depilacion-medias-piernes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 2999",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-aniversario-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3000",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/bono-bano/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3001",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-wellness-50-experiencia-gastro-l-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3002",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-wellness-50-experiencia-gastro-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3003",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/spa-attica-21-villalba/especial-club-mananas-entre-semana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3004",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/depilacion-1-2-piernas-3/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3005",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cortar-y-peinar-senora-pelo-medio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3006",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/antiestres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3007",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/azores/musgo-spa/spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3008",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/energizer-massage-40min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3009",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-de-naranja-1-pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3010",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/hidromasaje-de-esencias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3011",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/manicura-basica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3012",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/promo-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3013",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/basico-facial-a-tu-medida/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3014",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-relax-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3015",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/masaje-descontracturante-60-circuito-hidrotermal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3016",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/matriskin-lifting-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3017",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/masaje-integral-plus/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3018",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/masaje-deportivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3019",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-terapeutico-masaje-antiestres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3020",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/masaje-dulce-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3021",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/circuito-ritual-oriental-equilibrio-en-tu-cuerpo-y-mente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3022",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cortar-sin-lavar-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3023",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/campanas-de-boda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3024",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-turco-con-jabon/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3025",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-de-15min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3026",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/experiencia-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3027",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-relax-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3028",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/experiencia-sun-repair/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3029",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/ritual-pureza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3030",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/spa-kids/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3031",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/exfoliacion-20-relajante-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3032",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-olmedo/shiatsu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3033",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/promocion-black-friday-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3034",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/pack-especial-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3035",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-10-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3036",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/masaje-sunset-en-egipto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3037",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacion-axilas-y-bikini-brasil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3038",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/masaje-desestresante-70min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3039",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/sublime-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3040",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/tratamiento-calmante-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3041",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/15min-chocolate-o-facial-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3042",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/exfoliante-vitamina-c-scrub/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3043",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/hard-rock-spa-marbella/rockmantic-retreat/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3044",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/ritual-timexpert-srns/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3045",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/black-beer-vip-circuito-sala-privada-masaje-15-mnts-regalos-para-dos-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3046",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/san-valentin-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3047",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/cachimba/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3048",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/masaje-50-min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3049",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/depilacion-laser-zona-muy-pequena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3050",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-de-pies-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3051",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/rayos-uva/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3052",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/circuito-hidroterapia-spa-y-comida/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3053",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/masaje-50-experiencia-matinal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3054",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-55-min-paquete-arom/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3055",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-relajante-oriental-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3056",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/tratamiento-reafirmante-y-tonificante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3057",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/kobido-masaje-facial-japones-efecto-lifting/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3058",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-palma/spa-la-palma-teneguia-princess/princess-momentos-compartidos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3059",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/fitness-spa-2-aadd/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3060",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/ducha-jet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3061",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/corte-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3062",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/lifting-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3063",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/limpieza-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3064",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/masaje-relajante-20-min-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3065",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-de-chocolate-252px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3066",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/drenaje-corporal-de-cerveza-55-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3067",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/relaxing-love-circuito-beer-spa-pareja-masaje-15-minutos-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3068",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/spa-cava-cocktel-para-2-smb/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3069",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/3-sesiones-de-laser-diodo-en-zona-mediana-1-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3070",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/bono-6-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3071",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/exfoliacion-corporal-hidratacion-cuerpo-completo-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3072",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/15min-chocolate-o-facial-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3073",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/free-masaje-15min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3074",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/lavar-y-marcar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3075",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/bono-experiencia-bodyna-masaje-relajante-citrico-de-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3076",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/pedicura-francesa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3077",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/sesion-solarium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3078",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/pack-navidad-esencias-circuito-spa-masaje-de-esencias-naturales-55-minutos-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3079",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/masaje-25-min-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3080",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/tratamiento-facial-dermo-peel-con-punta-de-diamante-e-indiba/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3081",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/circuito-spa-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3082",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/cura-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3083",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/pack-glamour/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3084",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-excusivity-completo-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3085",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/al-masaje-50-experiencia-gastro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3086",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/al-masaje-50-experiencia-gastro-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3087",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/desayuno-sauna-piscina-climatizada-masaje-de-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3088",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/neceser-basic/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3089",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/bono-4-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3090",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-esencias-naturales-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3091",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/at-spa-con-masaje-cena-para-dos-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3092",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/aromasoul-ritual-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3093",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/tratamiento-luz-en-la-mirada/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3094",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/plan-para-papa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3095",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/corte-nina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3096",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-exclusivity-parcial-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3097",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/happy-valentines-day/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3098",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/circuito-deloix-masaje-55-min-2pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3099",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/duo-oasis-2-circuitos-termales-2-masajes-corporales/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3100",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/lomi-lomi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3101",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/masaje-relajante-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3102",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-masmonzon/sirodhara/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3103",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/ritual-armonia-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3104",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/masaje-deportivo-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3105",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-wellness-urh-ciutat-de-mataro/spa-desayuno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3106",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/acceso-a-piscina-exteriorpeeling-o-masaje-a-elegir-de-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3107",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/bono-5-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3108",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/2x1-piscina-puesta-en-forma-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3109",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/smartbox-spaalmuerzo-cena-2-personas-7990e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3110",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/ritual-fresh/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3111",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/personalizado-shama-care-para-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3112",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/viaje-a-las-vinas-de-la-toscana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3113",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/manicura-permanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3114",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/dermajet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3115",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/leyendas-del-oceano/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3116",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/can-bordoy-spa/dia-de-la-madre-flores-y-bienestar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3117",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/antiestres-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3118",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/castellon/balneario-de-montanejos/masaje-pindas-manual-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3119",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/signature-diamante-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3120",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/circuito-real-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3121",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/deep-tissue-60-min-indiba-30-min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3122",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/tratamiento-facial-oxigeno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3123",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/luxury-love-experience-110min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3124",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-privado-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3125",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/vitamina-c-corporal-by-natura-bisse/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3126",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-detox/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3127",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bcn-n-msj-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3128",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/exfoliacion-corporal-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3129",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-holistico-con-aceite-de-cerveza-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3130",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/termal-el-palacete/tratamiento-exfoliante-reafirmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3131",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-privado-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3132",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/laura-leon-wellness/circuito-spa-degustacion-de-quesos-y-vino-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3133",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-jc1-sercotel-murcia/circuito-capricho/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3134",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-jc1-sercotel-murcia/circuito-termal-bienestar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3135",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-fresh-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3136",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/envoltura-de-aloe-vera/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3137",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3138",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/cueva-de-sal-del-himalaya-adultos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3139",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/circuito-spa-2x1-solo-huespedes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3140",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-masaje-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3141",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3142",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/citrus-vita-essence/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3143",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/wellness-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3144",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-exclusivity-full-body-75/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3145",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-romantic-beer-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3146",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-10-min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3147",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/depilacion-de-ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3148",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/labio-superior-o-barbilla/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3149",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/bano-cleopatra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3150",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/masajes-armonia-zonal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3151",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/depilacion-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3152",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-privado-pareja-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3153",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3154",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-excusivity-completo-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3155",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/cena-menu-a-la-carte-para-2-3-o-4-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3156",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/balneario-vichy-catalan/2x-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3157",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-sa-voga/experiencia-bon-despertar-duo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3158",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/sensory-sound/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3159",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/envoltura-vitamina-c-firmness/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3160",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/promocion-black-friday-senses-night/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3161",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/pausa-40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3162",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/circuito-wellness-menu-arrocero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3163",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/envoltura-con-crema-de-rassoul/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3164",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/blanqueamiento-dental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3165",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/limpieza-de-cutis-cura-de-belleza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3166",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/facial-for-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3167",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/tratamiento-facial-fleurs-de-bali/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3168",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/experiencia-privada-banos-romanos-en-pareja-2pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3169",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/day-dream-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3170",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/lipo-choco-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3171",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/circuito-alegria-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3172",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/the-spa-by-signature-sir-victor/sublime-vegan-ritual-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3173",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/masaje-relax-aroma-x2-hammam/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3174",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/spa-thalasso-smoothie-pina-colada/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3175",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/candy-beauty-party/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3176",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/entrada-matinal-3-horas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3177",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/circuito-hidrotermal-especial-canarias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3178",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-thai/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3179",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/exfoliante-vitamina-c-scrub/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3180",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3181",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/limpieza-facial-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3182",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/circuito-especial-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3183",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/bano-de-burbujas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3184",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-anticelulitico-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3185",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/pedicura-bodyna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3186",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/envoltura-de-algas-marinas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3187",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/depilacion-media-pierna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3188",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/ritual-diamond-rose/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3189",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/masaje-linfatico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3190",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/tratamiento-hifu-piernas-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3191",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/silueta-perfecta-3-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3192",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-relax-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3193",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/pro-sweet-dreams/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3194",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-residentes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3195",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/especial-dia-del-padre-papa-el-mejor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3196",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/circuito-spa-exfoliacion-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3197",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/viaje-bio-aromasoul/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3198",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/ritual-flor-de-cerezo-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3199",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/drenaje-linfatico-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3200",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-10-min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3201",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/masaje-mundo-de-los-aromas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3202",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cura-banera-hidromasaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3203",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-40min-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3204",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/masaje-con-aceite-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3205",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/tratamiento-reafirmante-5-sesiones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3206",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/entrada-bono-sport/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3207",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-so-delicate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3208",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/ritual-remodelante-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3209",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/castellon/balneario-de-montanejos/circuito-familiar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3210",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/exotico-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3211",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-de-cerveza-55pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3212",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/te-regalo-un-dia-de-desconexion-y-relax-total/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3213",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-ayurveda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3214",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/3-sesiones-de-laser-diodo-en-zona-pequena-1-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3215",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/programa-de-belleza-integral-y-relajacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3216",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/tratamiento-anticelulitico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3217",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/cavitacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3218",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/ritual-de-la-felicidad-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3219",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/retirar-unas-gel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3220",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/ritual-oasis-de-serenidad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3221",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/dream-white-suites-duo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3222",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/especial-san-valentin-spa-para-dos-de-viernes-a-domingos-y-festivos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3223",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-sa-voga/ritual-mother-to-be/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3224",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/pedicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3225",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/manicure-xpress/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3226",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/cura-especial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3227",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/oasis-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3228",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/laura-leon-wellness/circuito-spa-para-2-brunch/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3229",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/masaje-ayurveda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3230",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/pack-hidratacion-face-body-regalo-cosmetico-ejove/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3231",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-timexpert-srns/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3232",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/gommage-p50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3233",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-15-min-pareja-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3234",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/ritual-piel-en-calma/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3235",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/experiencia-spa-groupon/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3236",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/circuito-deluxe-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3237",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/agencias-spa-entrada-50-privatizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3238",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/antiedad-lift-in-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3239",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/sun-repair/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3240",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-capricho-bodyna-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3241",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/vodder-masaje-drenante-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3242",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/corte-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3243",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/ritual-y-menu-especial-dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3244",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/mencey-sweet-day-lunes-a-viernes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3245",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-brunch/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3246",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/masaje-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3247",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/hb-daypass-piscina-mas-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3248",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/el-lifting-facial-kobido-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3249",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/healthouse-21-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3250",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/masaje-exclusivity-full-body-75/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3251",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/circuito-de-aguas-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3252",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/piscina-vitality-2h/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3253",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/sun-repair/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3254",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/ritual-canario/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3255",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-25min-chocolate-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3256",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/wellness-experiencia-gastro-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3257",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/wellness-party/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3258",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-two-by-two-outdoor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3259",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/presoterapia-1-sesion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3260",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/masaje-descontracturante-relajante-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3261",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/hamman-experience-aladinia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3262",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-de-10-masajes-30-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3263",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/personalizado-masaje-descontracturante-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3264",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spacio-telde/pack-circuito-termal-masaje-piedras-calientes-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3265",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-alto-aragon/aromatico-energy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3266",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3267",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/hard-rock-spa-marbella/especial-mama-pure-beauty/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3268",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/oferta-5-ritual-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3269",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/circuito-spa-miercoles-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3270",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3271",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-20min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3272",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cantabria/spa-castilla-termal-solares/lomi-juma/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3273",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cantabria/spa-castilla-termal-solares/reflexologia-tailandesa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3274",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/circuito-hidrotermal-especial-canarias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3275",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/segovia/spa-spaxion-convento-capuchinos/facial-pura-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3276",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/3-sesiones-de-laser-diodo-en-zona-grande-1-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3277",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/drain-body-bandages/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3278",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/reflexologia-podal-45min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3279",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/biologico-masaje-corporal-con-canas-de-bambu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3280",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-attica21-vigo/sala-privada-terapeutica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3281",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/bono-personalizado-10x8/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3282",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cura-mascarilla-anticelulitica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3283",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-bienestar-80-wellbeing-ritual-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3284",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/especial-pareja-circuito-spa-oliveres-masaje-de-15-min-servicio-de-te-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3285",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/peeling-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3286",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-deportivo-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3287",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/mascarilla-y-exfoliante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3288",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/manicura-con-esmalte-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3289",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/ritual-brisa-de-ganesha-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3290",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/drenaje-linfatico-cuerpo-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3291",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/las-palmas/siam-spa-bohemia-suites/recepcion-2-horas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3292",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-balines-masaje-antiestres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3293",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/depilacion-laser-zona-muy-pequena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3294",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/masaje-anticelulitico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3295",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/manicura-vaho/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3296",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-terapeutico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3297",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/color-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3298",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/color-completo-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3299",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/envoltura-de-fango/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3300",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-slimming-dia-3-envoltura-algas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3301",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-hydracure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3302",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-beauty-platinum-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3303",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-25-min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3304",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/peeling-corporal-de-coco-hidratacion-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3305",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-citricos-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3306",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/thalgo-colageno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3307",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-la-manga-club/experiencia-lavanda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3308",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/depilacion-con-diodo-zona-pequena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3309",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guipuzcoa/spa-foresta-wellness/secuoya-roots-masaje-relajante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3310",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/ritual-de-la-felicidad-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3311",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/masaje-reafirmante-o-anticelulitico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3312",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/body-care-sandos-papagayo-spa-wellness/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3313",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/masaje-luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3314",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/ritual-de-envoltura-con-vino-70min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3315",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/circuito-socio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3316",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/vila-real/boticas-hotel-art-spa/masaje-infantil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3317",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/bangkok-healthy-spa/combinados-especial-navidad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3318",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/masaje-sunset-en-egipto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3319",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/momento-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3320",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/especial-pareja-piscina-de-tratamientos-pareja-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3321",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-esplendor-radiante-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3322",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/color-raiz-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3323",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/color-raiz/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3324",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/spa-desayuno-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3325",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-antiestres-dia-1-yogameditacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3326",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/drenaje-linfatico-de-50-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3327",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/drenaje-linfatico-de-50-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3328",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/hidratacion-wine-oil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3329",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/masaje-drenaje-linfatico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3330",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/aragon-hills-hotel/piernas-cansadas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3331",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-de-la-felicidad-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3332",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/masaje-pinda-sweda-90min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3333",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/piedras-volcanicas-25m/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3334",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/masaje-con-piedras-volcanicas-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3335",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-descontracturante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3336",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/facial-skin-zen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3337",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/al-masaje-50-experiencia-matinal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3338",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/al-masaje-50-desayuno-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3339",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/reflexologia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3340",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/sala-vip-2-masajes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3341",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/manicura-juvenil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3342",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/pack-confort-2ndo-dia-masaje-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3343",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/hb-masaje-50-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3344",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/masaje-de-chocolate-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3345",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/masaje-piedras-calientes-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3346",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/experiencia-relax-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3347",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-monasterio-de-valbuena/bano-hidromasaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3348",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/exfoliaciones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3349",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/depilacion-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3350",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/ritual-timexpert-srns/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3351",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/facial-for-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3352",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/anti-stress-3-dias-dia-2-algoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3353",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/segovia/spa-spaxion-convento-capuchinos/especial-black-friday-circuito-termal-para-dos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3354",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/oasis-relax-gourmet-gold-edition-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3355",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-relax-gourmet-gold-edition-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3356",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/tinte-de-pestanas-o-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3357",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/manicura-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3358",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/paquete-bienestar-2-noches-mp-spa-masaje-45/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3359",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sunlight-bahia-principe-san-felipe/circuito-hidroterapia-2x1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3360",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/entrada-spa-ampliacion-servicio-cliente-mcm/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3361",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/entrada-spa-general-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3362",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/ayurveda-royal-thai-poultice-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3363",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/masaje-de-aromaterapia-con-piedras-calientes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3364",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/bano-de-hidromasaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3365",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/duo-thai/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3366",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/reestructurante-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3367",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/especifico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3368",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/peinado-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3369",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-ayurveda-dia-1-masaje-udvartana-detox/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3370",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/depilacion-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3371",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/masaje-groupon-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3372",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/bano-de-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3373",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3374",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/programa-oxyjeune-14-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3375",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/masaje-corporal-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3376",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-de-10-masajes-50-all-days/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3377",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/depilacion-bikini/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3378",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/depilacion-bikini-hair-removal-bikini/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3379",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/experiencia-adios-estres-especial-profes-entrada-spa-60-para-2-pers-masaje-30para-el-profesor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3380",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/depilacion-de-ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3381",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/depilacion-de-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3382",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/oporto/the-spa-by-sheraton-porto/daypass-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3383",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/pack-relajacion-masaje-40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3384",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/hydraluronic/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3385",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/spa-facial-exclusivo-web/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3386",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/exfoliacion-envoltura-mineral-de-barro-de-picota-roja-circuito-spa-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3387",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-masaje-de-piernas-cansadas-30-masajes-energias-de-la-tierra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3388",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/exfoliante-exotico-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3389",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-en-horario-de-tarde/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3390",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-iberostar-las-gaviotas/stone-therapy-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3391",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-ritual-de-siam-1h-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3392",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/ritual-source-marine-luminosidad-e-hidratacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3393",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/tratamiento-reductor-reafirmante-localizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3394",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-espectaculo-flamenco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3395",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/masaje-piedras-volcanicas-canarias-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3396",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-de-cerveza-completo-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3397",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/laura-leon-wellness/circuito-spa-nocturno-masaje-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3398",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/corte-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3399",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/masaje-drenante-linfatico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3400",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-relax-de-rostro-cuello-y-escote-beer/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3401",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/manicura-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3402",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/algoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3403",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/higiene-facial-profunda-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3404",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/sauna-y-jacuzzi-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3405",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/sauna-y-jacuzzi-1-persona-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3406",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/black-friday-masaje-fulll-body-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3407",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/vip-love-circuito-vip-pareja-masaje-15-minutos-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3408",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/facial-pure-hydration-hyaluronic-acid-q10/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3409",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-oriental-shiatsu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3410",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/sauna-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3411",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/gran-hotel-spa-lakua/quiromasaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3412",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/promocion-black-friday-circuito-de-aguas-masaje-relax-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3413",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-15-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3414",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/masaje-circulatorio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3415",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/circuito-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3416",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/manicura-para-ninas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3417",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/power-defense-higiene-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3418",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/masaje-general/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3419",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/depilacion-de-ingles-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3420",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/ritual-ayurvedico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3421",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/masaje-piernas-cansadas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3422",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/bano-de-vino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3423",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-descontracturante-25-min-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3424",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/tinte/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3425",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/spa-peeling/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3426",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/reiki/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3427",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/elixir-oro-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3428",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/ritual-chocoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3429",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/exfoliacion-completa-con-guante-kessa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3430",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-de-piernas-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3431",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/masaje-pinda-sweda-90min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3432",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/facial-pearl-perfection/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3433",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/tratamiento-facial-pureza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3434",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/bono-10-sesiones-ep/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3435",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/lavar-cortar-y-peinar-cabello-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3436",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/ht-masaje-50-experiencia-matinal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3437",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/cena-2-px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3438",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/clase-de-yoga-grupos-de-6-a-10-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3439",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja-oferta-flash/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3440",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/masaje-deportivo-o-tejido-profundo-55-o-85-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3441",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/pro-tratamiento-facial-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3442",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/envoltura-de-algas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3443",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/viaje-a-la-ruta-de-las-delicias-circuito-spa-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3444",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/pedicura-deluxe-con-parafina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3445",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-15min-chocolate-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3446",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-we-love-beer-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3447",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/especial-papa-cava-deluxe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3448",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/beauty-time/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3449",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/circuito-spa-domingo-2-adultos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3450",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/circuito-spa-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3451",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-californiano/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3452",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-flor-de-cerezo-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3453",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/pro-masaje-sueco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3454",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/msj-40-aroma-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3455",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/ritual-de-sales-del-mar-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3456",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-de-5-masajes-30-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3457",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/circuito-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3458",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/envoltura-de-algas-micro-estalladas-renovacion-marina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3459",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-exclusivity-de-cerveza-25min-pareja-residente-canario/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3460",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/brunch-1-enero-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3461",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/drenaje-corporal-de-cerveza-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3462",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/agencias-entrada-spa-90-privatizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3463",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/time-ritual-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3464",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/ingles-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3465",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/depilacion-laser-zona-pequena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3466",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3467",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/experiencia-relax-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3468",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/tratamiento-facial-booster-vitamin-c-85-min-con-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3469",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/masaje-apacible-paraiso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3470",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/tinte-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3471",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/envoltura-anticelulitica-especial-algas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3472",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-spaxion-hg-la-molina/momento-spa-masaje-3/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3473",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/chocolaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3474",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-spa-on-top/acces-spa-hotel-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3475",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/entrada-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3476",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-completo-de-cerveza-degustacion-de-1-cerveza-especial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3477",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-by-alina-vriel/circuito-spa-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3478",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/terapia-antidolor-antiestres-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3479",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-alto-aragon/momento-spa-masaje-5/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3480",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/especial-pareja-circuito-dusch-masaje-de-15-minutos-servicio-de-te-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3481",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/masaje-a-cuatro-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3482",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/tratamiento-circulatorio-de-piernas-y-pies-70/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3483",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circulatorio-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3484",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/azores/musgo-spa/ritual-senhora-da-rosa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3485",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-de-10-masajes-30-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3486",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-hydracure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3487",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/cama-piscina-1h/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3488",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/peeling-corporal-de-coco-hidratacion-completa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3489",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/piernas-enteras-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3490",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/masaje-cuerpo-completo-relajante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3491",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-armonia-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3492",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-flor-de-cerezo-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3493",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/pack-vital-prevention/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3494",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/pinda-sveda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3495",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/masaje-descontracturante-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3496",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/drenaje-linfatico-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3497",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/bono-lodo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3498",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-de-cerveza-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3499",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/reflexologia-podal-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3500",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/circuito-spa-groupon/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3501",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-spaxion-hg-la-molina/for-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3502",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-masaje-oriental-45-msajes-energias-de-la-tierra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3503",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/circuito-hidrotermal-ss/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3504",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/burgos/spa-tudanca/masaje-espalda-y-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3505",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-exfoliacion-con-pure-de-papaya-exfoliaciones-corporales/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3506",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-pazo-los-escudos/ritual-eternal-youth-alqvimia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3507",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/masaje-50-cajitas-y-cupones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3508",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/envoltura-corporal-mineral-arcilla-o-de-algas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3509",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/golden-harmony/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3510",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/sensorial-organic-ritual-80min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3511",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/zumos-detox-y-energizantes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3512",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/suplemento-por-cera-caliente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3513",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-spaxion-hg-la-molina/cascada-de-luz/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3514",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/circuito-ano-nuevo-infantil-alojado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3515",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/retirada-esmalte-gel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3516",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/pack-navidad-super-vikingo-circuito-vip-para-dos-personas-pack-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3517",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/ht-masaje-50-experiencia-gastro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3518",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/the-spa-by-signature-sir-victor/luxury-signature-ritual-110min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3519",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/tratamiento-especifico-para-ojos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3520",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/osteopatia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3521",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/cuidado-anti-aging-75/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3522",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-miercoles-2-adultos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3523",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/radiant-global-energy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3524",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/depilacion-piernas-enteras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3525",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/masaje-armonia-zonal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3526",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/masaje-40min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3527",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-bienestar-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3528",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/termes-de-montbrio/spa-termal-masaje-local/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3529",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/especial-rebajas-piscina-puesta-en-forma-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3530",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/06-oro-essence-maria-galland/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3531",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/after-work-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3532",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/detox-con-te-verde/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3533",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/drenaje-linfatico-manual-parejas-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3534",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/terapia-de-perla-y-oro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3535",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/pecho-o-gluteos-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3536",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/entrecejo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3537",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/pack-navidad-bano-bikingo-circuito-spa-sala-comun-mascarilla-facial-de-cerveza-para-dos-personas-pack-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3538",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/masaje-relajante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3539",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/tratamiento-facial-antiage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3540",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-hotel-hyltor/circuito-hidroterapia-50min-servicio-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3541",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/masaje-dia-del-padre-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3542",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/body-care-sandos-papagayo-spa-wellness/especial-india-con-aceite-caliente-cuerpo-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3543",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/nyxpert-the-royal-fitness-spa/spa-massage-piscina-exterior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3544",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-alto-aragon/momento-spa-masaje-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3545",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-facial-tensor-e-iluminador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3546",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/mar-de-sensaciones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3547",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/masaje-relax-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3548",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/masaje-de-piernas-cansadas-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3549",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/especial-fiesta-31-08-cena-con-musica-en-directo-concierto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3550",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/aladinia-masaje-de-50-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3551",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/aladinia-masaje-de-50-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3552",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/palacio-albacete-spa/momento-spa-masaje-en-pareja-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3553",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/momento-spa-masaje-en-pareja-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3554",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/manicura-prospa-opi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3555",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/manicura-prospa-opi-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3556",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/circuito-arabe-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3557",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/tinte-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3558",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/neo-skin-gel-glicolic-10/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3559",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/spa-hotel-blu-almansa/lavanda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3560",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/organic-body-scrub-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3561",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/organic-body-scrub-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3562",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/lomi-lomi-nui-55-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3563",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-reflexologico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3564",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-15parejas-privado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3565",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-equilibrante-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3566",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/piccolo-de-cava/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3567",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-anti-dolor-dia-3-reflexologia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3568",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/comida/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3569",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/balneari-broquetas/experiencia-thermalium-deluxe-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3570",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-sa-voga/ritual-coffee-session/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3571",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/pack-belleza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3572",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-anticelulitico-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3573",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3574",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/bioterapia-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3575",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/al-wellness-50-experiencia-gastro-50-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3576",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/al-wellness-experiencia-gastro-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3577",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/masaje-pinda-sweda-india-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3578",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-deluxe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3579",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/menu-gastrospa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3580",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/masaje-de-cerveza-completo-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3581",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/ninguno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3582",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/mukabhyanga/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3583",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/comida-despedida-de-soltera/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3584",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/albornoz-alquiler/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3585",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-15-min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3586",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/bano-de-leche/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3587",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/skin-zen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3588",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/a-c-reserva-de-servicio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3589",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/depilacion-ingles-brasilenas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3590",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/relax-spa-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3591",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/laura-leon-wellness/spa-privado-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3592",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/circuito-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3593",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/cura-de-la-tension-cervico-encefalica-y-dolor-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3594",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-quirogolf-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3595",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-facial-ko-bi-do-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3596",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/masaje-anti-estres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3597",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-adulto-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3598",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/masaje-energy-tea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3599",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/aragon-hills-hotel/facial-global-on-anti-age/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3600",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/masaje-prenatal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3601",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/aquatonic/promocion-black-friday-aquatonic-masaje-relax-duo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3602",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/lavar-y-peinar-pelo-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3603",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/masaje-suite-privada-en-pareja-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3604",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/gastrospa-con-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3605",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/hesperides-thalasso-spa/circuito-thalasso-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3606",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-timexpert-srns/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3607",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-bienestar-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3608",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-shiatsu-craneal-rostro-y-craneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3609",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/5-sesiones-lavar-y-marcar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3610",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/presoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3611",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/pack-massada-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3612",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/promocion-circuito-spa-y-belleza-masaje-de-25min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3613",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/luna-de-miel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3614",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/grupo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3615",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/relax-choco-en-pareja-circuito-spa-chocolaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3616",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/programa-belleza-integral/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3617",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/pack-navidad-super-vikingo-circuito-vip-para-dos-personas-pack-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3618",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-attica21-vigo/tu-momento-attica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3619",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/facial-timexpert-white/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3620",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/anti-stress-3-dias-dia-2-bano-de-burbujas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3621",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/pro-especial-mexico-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3622",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/aguas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3623",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/cata-de-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3624",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/especial-mama-bienestar-y-relajacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3625",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/rehidratacion-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3626",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/aromatherapy-back-massage-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3627",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/depilacion-ingles-o-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3628",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valladolid/azz-penafiel-las-claras/circuito-chocolaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3629",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/spa-water-circuit-6/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3630",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/menu-cena-para-2-all/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3631",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/all-at-al-hb-ht-menu-cena-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3632",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/momento-spa-gastro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3633",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/descarga-de-piernas-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3634",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/masaje-con-piedras-calientes-85min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3635",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-real-de-siam/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3636",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/masaje-libertad-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3637",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/ciudad-real/spa-casa-del-rector/tratamiento-facial-con-oro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3638",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-25-min-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3639",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/cura-intensiva-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3640",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/pack-navidad-esencias-circuito-spa-masaje-de-esencias-naturales-55-minutos-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3641",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/masaje-50-experiencia-gastro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3642",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/especial-dia-de-la-madre-masaje-relajante-duo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3643",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/neceser-basic-xl/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3644",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/bioterapia-facial-60min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3645",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-paradisus/dia-de-la-madre-experiencia-de-lujo-y-relajacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3646",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/especial-dia-de-relax-en-spa-be-aloe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3647",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/oxigenacion-con-vitaminas-aef/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3648",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/masaje-de-pindas-con-extracto-de-cerveza-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3649",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/ritual-bombon-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3650",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-ayurveda-masaje-sueco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3651",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/tratamiento-for-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3652",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/terapia-vibracional-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3653",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/papa-te-lo-mereces-masaje-antiestres-y-zona-wellness-gratis-acompanante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3654",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/mama-te-lo-mereces-circuito-agua-masaje-gratis-circuito-agua-acompanante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3655",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/agua-luz-spa/masaje-de-velas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3656",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/programa-beatuty-platunum1-pax-masaje-antiestres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3657",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/programa-beauty-platinum1-pax-masaje-antiestres-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3658",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/otono-masajes-del-mundo-indoceane/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3659",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/facial-for-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3660",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/tratamiento-facial-rejuvenecedor-e-hidratante-con-velo-de-colageno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3661",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/masaje-energy-tea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3662",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-antiedad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3663",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-exclusivity-full-body-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3664",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/depilacion-pack-cuerpo-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3665",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/especial-dia-de-la-madre-con-masaje-50-y-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3666",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/manicura-bodyna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3667",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/higiene-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3668",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/lavar-y-marcar-medio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3669",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/propina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3670",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3671",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/udvartana-detox/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3672",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/pack-acido-hialuronico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3673",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/masaje-craneo-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3674",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-capricho-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3675",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/ritual-chocolate-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3676",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-y-envoltura-de-oro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3677",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/limpieza-facial-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3678",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/bono-descanso-bienestar-2-pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3679",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/ritual-de-aloe-vera/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3680",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/relax-banera/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3681",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/tratamiento-aloe-vera-y-menta/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3682",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/paquete-romantico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3683",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/black-friday-2x1-masaje-80-entrada-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3684",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-golf-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3685",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/ritual-caprichos-de-naturaleza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3686",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/pack-confort-2ndo-dia-masaje-de-piernas-cansadas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3687",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-exclusivity-de-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3688",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/momento-spa-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3689",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-armonia-80-harmony-ritual-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3690",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/pedicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3691",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/indian-ritual-tahara/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3692",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/envoltura-fango-nina-o-masaje-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3693",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-holistico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3694",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-craneocervical-15/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3695",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/groupon-tto-luxury/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3696",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/circuito-spa-2x1-2-adultos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3697",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/depilacion-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3698",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-craneocervical-15/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3699",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/ritual-radiance-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3700",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/dia-de-la-madre-ritual-silicio-con-roller-fitness-facial-by-thalgo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3701",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/corporal-express-25min-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3702",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/reunion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3703",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/criolipolisis/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3704",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/sur-de-canarias-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3705",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/desayuno-circuito-wellness/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3706",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/burgos/spa-tudanca/especial-black-friday-tratamiento-purifying/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3707",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/ritual-geisha/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3708",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/semirrecogido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3709",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/depilacion-brazos-enteros/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3710",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/tratamiento-antimanchas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3711",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-pazo-los-escudos/total-hidratacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3712",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/momento-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3713",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guipuzcoa/spa-foresta-wellness/circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3714",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/coloracion-cabello-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3715",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-la-gavina/masaje-relajante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3716",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/drenaje-linfatico-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3717",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/vitamina-c-iluminador-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3718",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/depilacion-cejas-y-labio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3719",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-15-min-pago-directo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3720",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/pedicura-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3721",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/contorno-de-ojos-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3722",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hotel-spa-cadiz-plaza/aloexperience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3723",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/a-c-reserva-de-servicio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3724",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-cerveza-55-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3725",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/precio-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3726",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/radiant-global-energy-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3727",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/depilacion-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3728",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-antiestres-dia-3-reiki/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3729",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/tratamiento-especifico-anti-acne-purificante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3730",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/experiencia-club-termal-y-flotarium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3731",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/piscina-hidrotermal-coctel-atrapalo-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3732",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/diamond-package/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3733",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/pedicura-express-gel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3734",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/momento-spa-ritual-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3735",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/gastrospa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3736",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/hesperides-thalasso-spa/day-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3737",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/pack-navidad-vikingo-circuito-spa-sala-comun-para-dos-personas-pack-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3738",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-tui-na/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3739",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/ritual-chocolate-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3740",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/08-diamond-essence-maria-galland/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3741",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/facial-antiedad-lift-in/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3742",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/facial-hidratacion-y-nutricion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3743",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/aceites-calientes-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3744",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/sala-vip-2-masajes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3745",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-privado-pareja-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3746",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/ritual-oxygen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3747",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/eternal-youth-75min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3748",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/hb-masaje-50v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3749",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/hb-masaje-50l-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3750",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/manicura-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3751",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-spaxion-hg-la-molina/ritual-de-hidratacion-total/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3752",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/rock-pedicure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3753",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/nyxpert-the-royal-fitness-spa/back-soul-acceso-al-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3754",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-relajante-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3755",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/pack-jarra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3756",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3757",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/masaje-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3758",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/electroestimulacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3759",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-equilibrante-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3760",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/un-momento-para-ti/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3761",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/depilacion-brazos-completos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3762",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bcn-n-msj-15/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3763",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-roca-negra/promocion-black-friday-circuito-spa-masaje-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3764",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/bono-personal-10-entradas-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3765",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-excusivity-completo-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3766",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/momento-spa-masaje-3/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3767",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/reflexologia-podal-relax-melia-tamarindos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3768",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-euforia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3769",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/eliminar-esmalte-gel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3770",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/entrenamiento-pareja-4-sesiones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3771",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/entr-persn-pareja-4-sesiones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3772",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/bono-10-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3773",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/couple-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3774",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/ritual-polinesia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3775",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/minceur-integrative-remodelante-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3776",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-10-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3777",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/viaje-a-la-provenza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3778",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/programa-fin-de-seman-2-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3779",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/hidrocapricho-de-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3780",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/2x1-especial-deep-tissue-massage-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3781",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/gimnasio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3782",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/al-spa-50cava-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3783",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/al-spa-50cc-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3784",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/exfoliacion-y-envoltura-corporal-de-coco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3785",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/xmas-glow-experience-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3786",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/xmas-glow-experience-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3787",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/peeling-programa-thalasso-especial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3788",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/pack-confort-1er-dia-peeling-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3789",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-bienestar-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3790",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/balneario-vila-de-caldes/masaje-aromaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3791",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/facial-calmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3792",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-15-min-pareja-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3793",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/tratamiento-regenerante-ritual-flores-y-frutas-de-bali/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3794",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cantabria/balneario-la-hermida/ritual-balneoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3795",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/masaje-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3796",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/hb-masaje-25l-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3797",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/hb-msj-25v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3798",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/peeling-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3799",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/masaje-apacible-paraiso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3800",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-pies-15-nuad-thao/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3801",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3802",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-completo-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3803",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-balines-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3804",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/masaje-sueco-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3805",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/pedicura-francesa-nina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3806",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/envoltura-exotica-de-inspiracion-china/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3807",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/peeling-suave/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3808",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3809",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/depilacion-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3810",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/pedicura-permanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3811",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/masaje-15min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3812",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/momento-spa-masaje-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3813",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/masaje-relajante-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3814",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-palma/spa-la-palma-teneguia-princess/princess-tierra-volcanica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3815",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/masaje-ritual-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3816",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/tinte-vegetal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3817",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lisboa/azure-wellness-spa/hot-unique-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3818",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/spa-cena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3819",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/acceso-a-la-sauna-y-bano-turco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3820",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/gastrospa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3821",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/facial-dermoabrasion-spaxion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3822",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-spa-on-top/beauty-party/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3823",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/corte-hombre-tijera/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3824",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/aromaterapia-masaje-25-min-con-aceite-esencial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3825",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/masaje-deportivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3826",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/diseno-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3827",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/envoltura-exotica-de-inspiracion-china/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3828",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/recuperacion-post-shopping/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3829",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/especial-pareja-circuito-playa-granada-masaje-de-15-min-servicio-de-te-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3830",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/masaje-de-pindas-herbales/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3831",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-alto-aragon/tratamiento-wellness-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3832",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/indoceane-bano-lacteo-celeste/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3833",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3834",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/essential-hot-oils-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3835",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/pack-mama-ritual-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3836",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/neptuno-relax-masaje-50-spa-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3837",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-20-min-recepcion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3838",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/family-moments/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3839",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/masaje-muscular-profundo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3840",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/masaje-con-aromaterapia-50min-aromatherapy-massage-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3841",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/las-palmas/siam-spa-bohemia-suites/recepcion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3842",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/facial-lifting-con-collageno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3843",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/ritual-de-la-alegria/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3844",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-privado-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3845",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/presoterapia-cajitas-y-cupones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3846",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/spa-buffet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3847",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/masaje-con-aromaterapia-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3848",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/taller-meditacion-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3849",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/acceso-wellness-area-60-especial-profes-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3850",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-holistico-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3851",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/envoltura-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3852",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/termes-de-montbrio/spa-termal-masaje-relajante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3853",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/radiofrecuencia-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3854",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-relajante-anti-stres-75-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3855",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/diseno-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3856",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/pack-tensor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3857",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/viaje-a-la-ruta-de-las-delicias-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3858",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/a-c-reserva-de-servicio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3859",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/deco-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3860",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-deportivo-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3861",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/kit-romantico-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3862",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/depilacion-media-pierna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3863",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/prueba-de-maquillaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3864",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/lavar-y-marcar-cabello-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3865",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/ritual-joyeaux-atlantique/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3866",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/tratamiento-excepcion-con-especifico-de-ojos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3867",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/detox-55-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3868",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/wos-tantra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3869",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/spa-masaje-estimulante-en-adulacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3870",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/laura-leon-wellness/aloe-vera-antiestres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3871",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/mas-happy-mum/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3872",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/spa-pedicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3873",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/blanqueamiento-dental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3874",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-exclusivity-25min-5-sesiones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3875",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/suplemento-tinte/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3876",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/soria/spa-castilla-termal-burgo-de-osma/bloqueo-cabina-4-h/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3877",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/pack-confort-2ndo-dia-sauna-o-jacuzzi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3878",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-muscular-profundo-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3879",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/dolce-spa-break/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3880",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-detox-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3881",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/dolce-detox-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3882",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/dolce-detox-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3883",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/ritual-de-la-felicidad-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3884",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-masaje-de-aromaterapia-45-masajes-de-la-tierra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3885",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/ritual-combinado-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3886",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/pro-masaje-sueco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3887",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/pro-sueco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3888",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3889",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/tratamiento-facial-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3890",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/indoceane-bano-lacteo-celeste/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3891",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/manicura-esmalte-de-gel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3892",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/presoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3893",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/san-valentin-en-be-aloe-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3894",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-aromatico-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3895",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/o2-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3896",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/masaje-anticelulitico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3897",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-cerler/momento-spa-masaje-5/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3898",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/masaje-lomi-lomi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3899",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/experiencia-bio-spa-con-masaje-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3900",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/facial-oxigenante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3901",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/matizador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3902",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/beauty-hands-full-manicure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3903",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/para-mama-manicura-pedicura-spa-2-horas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3904",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/masaje-balines-para-dos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3905",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/peeling-corporal-de-coco-hidratacion-completa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3906",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/calma-spa/promocion-black-friday-masaje-relajante-calma/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3907",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/circuito-spa-wellness-weekend/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3908",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/circuito-benidorm-alojados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3909",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/combinacion-5-sesiones-1-gratis/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3910",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/agua-luz-spa/masaje-total-relajacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3911",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/depilacion-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3912",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/chocoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3913",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/tinte-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3914",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/masaje-e-one-week/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3915",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-citricos-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3916",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/tinte-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3917",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cortar-flequillo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3918",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-sa-voga/masaje-relajante-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3919",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-vip-en-sala-privada-pareja-tabla-de-embutido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3920",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-cerler/momento-spa-masaje-3/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3921",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hspa-valparaiso/santuario-hspa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3922",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/pack-especial-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3923",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/nyxpert-the-royal-fitness-spa/choccolat-massage-scrub-acceso-al-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3924",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3925",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/manicura-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3926",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/tijera-secado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3927",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/castellon/balneario-de-villavieja/parafango-zona-pequena-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3928",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/gimnasio-2-semanas-cliente-exterior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3929",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/pack-hydr-oxigen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3930",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/segovia/spa-spaxion-convento-capuchinos/momento-spa-masaje-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3931",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/reflexologia-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3932",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/promo-matinal-facial-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3933",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/piscina-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3934",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/pack-familiar-primeras-experiencias-spa-para-los-mas-peques/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3935",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/cura-relax-antiestres-desfatigante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3936",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/unico-iong-bao/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3937",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3938",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/tratamiento-especifico-antiedad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3939",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lisboa/azure-wellness-spa/heal-restore-50-spa-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3940",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-25-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3941",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/ritual-brisa-de-ganesha-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3942",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-equilibrante-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3943",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-la-gavina/momento-spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3944",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/plan-para-mama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3945",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/hidratacion-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3946",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-esplendor-radiante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3947",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/pack-vital-prevention/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3948",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-15-min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3949",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/aladinia-rd-60e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3950",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/aladinia-rd-65e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3951",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/smb-spa-facial-antiestress-1-pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3952",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/beauty-party-deluxe-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3953",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/manicura-spa-incluye-tratamiento-de-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3954",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/peeling-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3955",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/circuito-aqua-center-grupo6px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3956",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/tratamiento-facial-regenerante-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3957",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/depilacion-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3958",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/radiofrecuencia-facial-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3959",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/masaje-facial-kobido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3960",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/pack-antimanchas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3961",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/lunes-matinal-2-x-1-entrada-spa-exclusivo-web/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3962",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/ritual-de-oriente-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3963",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-20-min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3964",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/mechas-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3965",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-masaje-25-experiencia-desayuno-l-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3966",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-masaje-30-experiencia-matinal-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3967",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/cena-relax-30-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3968",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/mar-de-juventud/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3969",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/oxigenante-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3970",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/masaje-15min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3971",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/promocion-masaje-15/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3972",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/spa-pedicura-opi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3973",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/peeling-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3974",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/despedida-de-soltera-con-picoteo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3975",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/black-friday-2x1-masaje-30-entrada-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3976",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-of-youth-facial-regeneration-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3977",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/bioterapia-sistemica-facial-75min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3978",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/pedicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3979",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/circuito-c-deportivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3980",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/masaje-corporal-30-min-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3981",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-citricos-15min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3982",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/sensuality-seductive/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3983",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/3d-collagen-shock/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3984",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-relajante-corporal-30eliminado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3985",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/body-organic-scrub-relax-massage-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3986",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/ducha-jet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3987",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-atelier-del-amor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3988",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/depilacion-con-diodo-cuerpo-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3989",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-hotel-hyltor/acceso-banera-de-hidromasaje-30min-servicio-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3990",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/depilacion-ingles-integral/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3991",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-de-chocolate-252px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3992",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/esmaltado-permanente-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3993",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/pack-confort-2ndo-dia-tratamiento-hidratante-corporal-con-leche-de-karite/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3994",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-tropical-de-bahia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3995",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/pack-romantic-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3996",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-de-cerveza-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3997",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/depilacion-1-2-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3998",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 3999",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/radiofrecuencia-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4000",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-exclusivity-de-cerveza-55min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4001",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/circuito-playa-granada-agencia-5e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4002",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/circuito-victoria-hotel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4003",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/ritual-purete-marine-purificar-y-equilibrar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4004",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/4-hrs-piscinas-exteriores-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4005",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/circuito-spa-masaje-relajante-momento-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4006",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-cafe-brasileno-un-tratamiento-adelgazante-y-reafirmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4007",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/neceser-exfoliacion-xl/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4008",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-equilibrante-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4009",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/black-friday-circuito-spa-wellness/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4010",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4011",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/pack-navidad-relax-pareja-circuito-spa-ritual-gold-theraphy-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4012",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/cortesia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4013",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/depilacion-labio-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4014",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/depilacion-pubis-completa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4015",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/bono-circuito-termal-10-accesos-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4016",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-de-10-masajes-80-all-days/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4017",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/envoltura-picota-o-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4018",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/segovia/spa-spaxion-convento-capuchinos/momento-spa-y-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4019",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-relax-del-rostro-cuello-y-escote/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4020",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/depilacion-espalda-o-pecho/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4021",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-lomi-lomi-hawai/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4022",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/masaje-personalizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4023",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/pack-hydr-oxigen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4024",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/black-friday-ritual-con-envoltural-corporal-gratis-circuito-spa-90min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4025",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/masaje-relajante-20-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4026",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/tratamiento-ko-bi-do-anti-edad-global-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4027",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/higueron-spa/circuito-spa-130h-2h-de-solarium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4028",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/tratamiento-facial-reafirmante-con-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4029",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/depilacion-labio-superior-menton-o-patillas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4030",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/promocion-circuito-spa-y-belleza-masaje-15min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4031",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/masaje-oriental-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4032",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/masaje-de-pindas-con-extracto-de-cerveza-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4033",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/escapada-romantica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4034",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/masaje-con-piedras-volcanicas-85-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4035",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/pack-vital-prevention/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4036",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/spa-buffet-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4037",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/limpieza-facial-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4038",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/at-spa-50-con-masaje-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4039",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/biologico-piel-de-seda-activo-mar-y-tierra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4040",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/manicura-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4041",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/programa-piernas-perfectas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4042",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/martes-matinal-mayores-60-anos-exclusivo-web/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4043",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/pack-navidad-vikingo-circuito-spa-sala-comun-para-dos-personas-pack-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4044",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/exfoliacion-corporal-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4045",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/bano-aromaceane/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4046",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/ritual-evasion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4047",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/bio-lifting-bamboo-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4048",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-balines-masaje-piedras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4049",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-beauty-plus/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4050",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/hidratante-te-blanco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4051",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/masaje-con-piedras-calientes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4052",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/masaje-de-pindas-con-extracto-de-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4053",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/dolce-detox-40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4054",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-detox-40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4055",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/dolce-detox-40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4056",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/depilacion-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4057",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/depilacion-labio-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4058",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/wonderbox-circuitosalmuerzo-cena-2-personas-7790e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4059",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hspa-valparaiso/pack-champangne-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4060",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/depilacion-piernas-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4061",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/exfoliaciones-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4062",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/moet-chandon/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4063",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/felicidades-mama-te-mereces-todo-empecemos-por-cuidarte/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4064",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/depilacion-de-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4065",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-15-min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4066",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/depilacion-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4067",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/masaje-e-beauty/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4068",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/15min-chocolate-o-facial-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4069",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/manicura-y-pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4070",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/masaje-25min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4071",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/momento-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4072",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/facial-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4073",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/secador-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4074",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/pro-ayurveda-parejas-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4075",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/thalasso-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4076",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guadalajara/spa-castilla-termal-brihuega/reiki/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4077",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/depilacion-pieras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4078",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/dia-del-padre-super-papa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4079",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/energy-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4080",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/masaje-hawaiano/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4081",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/ninguno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4082",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-ayurvedico-de-la-india-tonificante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4083",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/circuito-hidroterapia-spa-y-desayuno-bufe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4084",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/especial-san-valentin-alqvimia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4085",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/kit-romantico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4086",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/depilacion-de-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4087",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/masaje-relajante-20-min-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4088",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-de-chocolate-55-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4089",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/masaje-deportivo-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4090",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-so-delicate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4091",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/spa-express-momentos-de-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4092",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/exfoliacion-corporal-hidratacion-cuerpo-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4093",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hotel-spa-cadiz-plaza/manicura-y-pedicura-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4094",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-en-sala-privada/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4095",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/laura-leon-wellness/circuito-spa-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4096",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-relax-de-rostro-cuello-y-escote-beer-residente-canario/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4097",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/circuito-spa-junior-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4098",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/depilacion-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4099",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/love-massage-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4100",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/womens-beauty-party/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4101",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-aromatico-15-minutos-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4102",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/reflexologia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4103",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/viaje-a-la-ruta-de-las-delicias-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4104",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/circuito-wellness-menu-arrocero-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4105",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-despacio-h10-benidorm/holistico-h10-luxury/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4106",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/salutem-per-aquam/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4107",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/neceser-relax-xl/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4108",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/retirar-unas-semi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4109",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-15-freeeliminado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4110",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-wellness-experiencia-matinal-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4111",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-oriental-dia-3-masaje-craneal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4112",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/atardecer-en-el-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4113",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-masaje-30-experiencia-matinal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4114",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-jc1-sercotel-murcia/circuito-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4115",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-roca-negra/circuito-spa-obsidiana-express-masaje-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4116",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/drenaje-linfatico-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4117",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-pureza-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4118",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cura-drenaje-linfatico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4119",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/masaje-aromaterapia-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4120",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/spa-privado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4121",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/tratamiento-facial-peeling-marino-by-thalgo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4122",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/masaje-descontracturante-40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4123",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/manicura-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4124",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/rd-a-65e-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4125",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/rd-a-60e-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4126",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/silueta-perfecta-7-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4127",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/depilacion-ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4128",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/oporto/the-spa-by-sheraton-porto/tejido-profundo-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4129",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-hotel-hyltor/jacuzzi-privado-50min-para-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4130",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/facial-global-on-anti-age/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4131",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-alto-aragon/masaje-zonal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4132",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/stone-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4133",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/depilacion-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4134",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-exclusivity-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4135",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-sa-voga/exfoliante-karite/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4136",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/masaje-luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4137",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/recepcion-1-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4138",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/profe-relajate-un-poco-masaje-y-circuito-spa-con-copa-de-cava-o-te/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4139",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-hidratacion-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4140",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/pack-mama-facial-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4141",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-iberostar-las-gaviotas/oriental-55-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4142",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/gimnasio-1-mes-cliente-camping-raco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4143",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-relajante-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4144",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/masaje-15min-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4145",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/aromatherapy-back-massage-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4146",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-equilibrante-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4147",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/trenzas-boxer/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4148",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/circuito-spa-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4149",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/cejas-con-pinzas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4150",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/facial-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4151",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-10-min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4152",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/ritual-bienestar-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4153",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/programa-antiestres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4154",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/masaje-con-aromaterapia-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4155",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/ritual-flor-de-cerezo-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4156",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/my-day-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4157",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/tratamiento-antimanchas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4158",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/azores/musgo-spa/orientais-ayurvedica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4159",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/ritual-hydraluronic/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4160",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-exfoliaciones-corporal-aromatica-exfoliciones-corporales/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4161",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/masaje-facial-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4162",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/90-soin-de-cou/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4163",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-peeling-e-hidratacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4164",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/higienizacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4165",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/higienizacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4166",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/tratamiento-facial-kobido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4167",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/ritual-de-siam/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4168",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hspa-valparaiso/circuito-hspa-valparaiso-120-minutos-lunes-a-jueves/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4169",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/oferta-dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4170",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-de-citricos-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4171",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/sublime-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4172",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/masaje-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4173",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/masaje-aromatico-parcial-25-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4174",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/masaje-localizado-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4175",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/depilacion-piernas-enteras-residente-canario/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4176",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/cena-2-px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4177",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/futura-mama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4178",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-25min-chocolate-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4179",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/relax-choco-en-pareja-circuito-spa-chocolaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4180",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/envoltura-picota-o-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4181",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/cachimba/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4182",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/cumple-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4183",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/pack-navidad-vikingo-circuito-spa-sala-comun-para-dos-personas-pack-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4184",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/chocolaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4185",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/exfoliacion-corporal-completa-envoltura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4186",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/pack-energy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4187",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/masaje-geotermal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4188",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/circuito-spa-alojado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4189",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/ritual-geothermal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4190",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/pro-anti-celulitico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4191",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacion-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4192",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/depilacion-laser-dos-zonas-muy-pequenas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4193",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/corporal-anticelulitico-tonificante-y-reafirmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4194",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/spa-lunch-saludable/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4195",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/kit-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4196",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/diamond-well-living-by-natura-bisse-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4197",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-de-chocolate-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4198",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4199",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/depilacion-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4200",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lisboa/azure-wellness-spa/bio-face-50-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4201",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-25min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4202",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/tratamiento-hifu-cara-completa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4203",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/tratamiento-facial-antiage-hyallu-procollagen-con-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4204",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/experiencia-oriental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4205",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spacio-telde/pack-circuito-termal-tratamiento-corporal-chocolate-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4206",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/chocolaterapia-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4207",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/reunion-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4208",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-nucia-spa-masshotel/tratamiento-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4209",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/spa-comida/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4210",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/tratamiento-facial-anti-edad-opatra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4211",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/crypolipolisis/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4212",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guadalajara/spa-castilla-termal-brihuega/terapia-con-ventosas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4213",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/ritual-alegria-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4214",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-10-min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4215",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/facial-tratamiento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4216",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-citricos-15min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4217",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/depilacion-ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4218",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/circuito-spa-week/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4219",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/masaje-descontracturante-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4220",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/hesperides-thalasso-spa/un-dia-especial-para-mama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4221",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/depilacion-de-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4222",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/masaje-relajante-45/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4223",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/especial-mama-un-dia-para-ella/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4224",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/tinte-pelo-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4225",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/facial-nueva-mirada/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4226",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/depilacion-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4227",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-chocolate-15/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4228",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/otono-masajes-del-mundo-artique/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4229",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guipuzcoa/spa-foresta-wellness/daypass/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4230",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-exclusivo-de-citricos-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4231",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/masaje-lomi-lomi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4232",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/pack-spa-after-work/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4233",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-cerveza-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4234",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/desmaquillado-gel-color-by-opi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4235",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/piernas-sport/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4236",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/promocion-black-friday-espacio-acuatico-templarium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4237",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/dia-de-spa-infantil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4238",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/circuito-spa-infantil-club-senator-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4239",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/masaje-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4240",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/ritual-holistico-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4241",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/smb-spa-cava-o-coctel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4242",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/exfoliacion-completa-guante-kessa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4243",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/vila-real/natureness-spa/spa-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4244",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/cura-relax-antiestres-desfatigante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4245",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/neceser-cuidado-facial-xl/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4246",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-vip-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4247",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/experiencia-natura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4248",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/cambio-de-esmaltes-de-unas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4249",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/estiramientos-thai-25-min-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4250",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-de-la-felicidad-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4251",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/spa-buffet-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4252",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4253",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/mas-antiestres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4254",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/tratamiento-corporal-resplandor-de-piel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4255",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/masaje-25-min-alojados-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4256",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/depilacion-con-diodo-zona-grande/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4257",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-y-envoltura-de-oro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4258",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/depilacion-laser-grande/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4259",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/promocion-especial-dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4260",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cantabria/spa-castilla-termal-solares/programa-osteoarticular-3-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4261",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/bano-de-color-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4262",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/al-masaje-25-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4263",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/al-mas-30-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4264",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/spa-hotel-blu-almansa/calma-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4265",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/tratamiento-corporal-reafirmante-y-anticelulitico-con-radiofrecuencia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4266",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/especial-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4267",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/kit-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4268",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/masaje-deportivo-40min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4269",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-sueco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4270",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/depilacion-labio-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4271",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-aromatico-15-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4272",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/termal-el-palacete/momento-spa-gastro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4273",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/experiencia-relax-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4274",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/el-mejor-regalo-masaje-antiestres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4275",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/centro-bienestar-sunprime-atlantic/masaje-hindu-de-cabeza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4276",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/mi-atardecer-romantico-especial-parejas-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4277",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-piedras-calientes-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4278",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/depilacion-facial-por-unidad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4279",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/manicura-deluxe-by-opi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4280",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-palma/spa-la-palma-teneguia-princess/princess-higiene-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4281",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/viaje-al-eden-tropical-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4282",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/belleza-rosa-de-bulgaria/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4283",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/chorro-jet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4284",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4285",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-relajante-de-30minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4286",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-de-la-felicidad-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4287",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/bono-6-masajes-tailandes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4288",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/bono-4-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4289",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/facial-for-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4290",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/pack-navidad-super-vikingo-circuito-vip-para-dos-personas-pack-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4291",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-oriental-tailandes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4292",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/bono-personalizado-6x5/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4293",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/tejidos-profundos-90-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4294",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/bono-10-sesiones-circuito-spa-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4295",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/presoterapia-45min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4296",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/masaje-bio-relajante-25-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4297",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/detalle-romantico-novios/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4298",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/flotarium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4299",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/sprunch-masajes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4300",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/ritual-anticelulitico-reafirmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4301",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/ritual-anti-estres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4302",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-con-catering-de-tapas-frias-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4303",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/relax-andalusi-en-pareja-circuito-spa-ritual-al-andalus/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4304",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/circuito-dusch-agencia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4305",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/masaje-sueco-60-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4306",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/bono-4-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4307",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/masaje-25min-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4308",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/masaje-nino-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4309",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cortar-y-lavar-caballero-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4310",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/spa-hotel-blu-almansa/circuito-spa-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4311",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/spa-peeling/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4312",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-spa-beach/relax-massage-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4313",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/sabila-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4314",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/novedad-spabrunch-by-bahia-del-duque/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4315",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/pack-energy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4316",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/segovia/spa-spaxion-convento-capuchinos/momento-spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4317",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-10min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4318",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/espalda-cuello-y-cabeza-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4319",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/kit-romantico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4320",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-energy-tea-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4321",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/bono-mensual-5-entradas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4322",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/pack-hotel-masaje-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4323",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/pedicura-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4324",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/neptuno-love-spa-50-masaje-50-bano-aromatico-20-y-cena-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4325",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-nucia-spa-masshotel/wellness-party/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4326",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/circuito-spa-miercoles-2-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4327",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/exfoliacion-iluminadora-con-pure-de-papaya/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4328",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/depilacion-ingles-brasilenas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4329",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/circuito-spa-promocion-okeymas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4330",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/at-spa-50con-masaje-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4331",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/all-at-al-gr-suplemento-pack-spa-50-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4332",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/tratamiento-facial-para-hombre-by-thalgo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4333",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/aquatonic/promocion-black-friday-aquatonic-masaje-bienestar-duo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4334",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spacio-telde/tratamiento-corporal-de-frutos-rojos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4335",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/termes-de-montbrio/promocion-black-friday-spa-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4336",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/ritual-privado-de-evasion-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4337",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/pack-luxury-love/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4338",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/oxigenacion-e-hidratacion-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4339",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/tarjeta-regalo-business/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4340",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/tratamiento-facial-aire/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4341",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/masaje-deportivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4342",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/acceso-wellness-myevjf-50-no-privatizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4343",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/circuito-arabe-masaje-15-promocion-martes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4344",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-masaje-deportivo-masajes-energias-de-la-tierra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4345",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/ritual-wine-sensations/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4346",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/pedicura-deluxe-pies-mimados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4347",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/a-c-reserva-de-servicio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4348",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/depilacion-pecho-o-gluteos-caballeros/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4349",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/bono-10-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4350",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/palacio-albacete-spa/masaje-muscular-profundo-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4351",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/masaje-muscular-profundo-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4352",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/tratamiento-facial-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4353",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/radiofrecuencia-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4354",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/flash-fundamental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4355",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-10-min-individual-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4356",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/ritual-del-amanecer/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4357",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/circuito-ohtels-masaje-15-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4358",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/cocoon-spa-lazure/cocoon-intensive-remodeling-by-wekker/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4359",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-antiestres-dia-1-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4360",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/tarjeta-regalo-business/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4361",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/drenaje-linfatico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4362",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-tratamiento-kobido-precieuse-tratamientos-faciales/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4363",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-armonia-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4364",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/facial-beauty-in-fusion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4365",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/asturias/spa-nature/junio-retiro-de-salud-y-bienestar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4366",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/acceso-spa-naturista-90-1-copa-cava-precio-especial-fnnc/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4367",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/acceso-spa-90-2-copas-cava-socio-club-naturista/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4368",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/acceso-spa-entrada-naturista-circuito-de-aguas-90-y-copa-de-cava/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4369",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-pureza-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4370",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/circuito-playa-granada-grupos-6px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4371",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/masaje-exclusivity-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4372",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-exclusivity-parcial-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4373",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/feliz-dia-papa-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4374",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/velada-romantica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4375",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/depilacion-piernas-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4376",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/peeling-exotic/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4377",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-attica21-vigo/experiencia-beira-con-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4378",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/extra-room-service/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4379",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/especial-black-friday-ayurveda-abhyanga-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4380",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/classic-spa-circuito-de-aguas-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4381",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/suplemento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4382",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/masaje-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4383",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/masaje-termal-basic/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4384",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/programa-belleza-integral/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4385",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/depilacion-piernas-enteras-hair-removal-full-leg/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4386",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/la-mejor-mama-moet-sensations/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4387",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/yes-we-lunch/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4388",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/viaje-a-la-provenza-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4389",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/tinte/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4390",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guipuzcoa/spa-foresta-wellness/ritual-foresta-wellnes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4391",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-slimming-dia-2-banera-subacuatica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4392",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/circuito-spa-premium-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4393",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/dia-del-padre-masaje-cerveza-25-hidromiel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4394",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/pro-best-moment-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4395",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-craneal-o-podal-10/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4396",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/masaje-descontracturante-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4397",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/tratamiento-especial-manos-suaves/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4398",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/facial-rejuvenecimiento-spaxion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4399",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-experiencia-duo-25-separados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4400",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/masaje-relax-de-rostro-cuello-y-escote-beer-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4401",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/ritual-citrico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4402",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/pack-navidad-bano-vikingo-circuito-spa-sala-comun-mascarilla-facial-de-cerveza-para-dos-personas-pack-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4403",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/depilacion-piernas-enteras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4404",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/peeling-de-limon/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4405",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/masaje-15min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4406",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4407",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-anticelulitico-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4408",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-de-cerveza-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4409",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/pro-bright-day/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4410",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/facial-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4411",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/facial-hidratante-express-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4412",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4413",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-palma/spa-la-palma-teneguia-princess/princess-masaje-deportivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4414",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-anticelulitico-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4415",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/tratamiento-hyaluronique-by-thalgo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4416",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/diseno-de-cejas-con-pinza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4417",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/masaje-sensitivo-con-vela-perfumada/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4418",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-romance-de-sol-y-luna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4419",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/masaje-oriental-tui-na/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4420",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lisboa/azure-wellness-spa/ritual-for-two-100-spa-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4421",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/envoltura-corporal-kamin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4422",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/bono-tratamiento-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4423",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/black-friday-2x1-masaje-50-entrada-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4424",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/exfoliacion-corporal-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4425",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-despacio-h10-benidorm/drenaje-linfatico-60min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4426",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/timexpert-white/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4427",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/abhyanga-80min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4428",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/masaje-relajante-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4429",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cortar-sin-secar-senora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4430",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/masaje-dia-de-la-madre-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4431",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/anti-rides/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4432",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/programa-glamour-1-pax-recepcion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4433",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/espacio-acuatico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4434",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/mechas-plata/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4435",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/chocolat-passion-spa-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4436",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/momento-spa-masaje-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4437",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/depilacion-de-torax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4438",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/terapeutico-deportivo-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4439",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/tratamiento-contorno-de-ojos-y-ojeras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4440",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/masaje-deportivo-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4441",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/siente-tus-pies-suaves-y-sedosos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4442",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/entrada-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4443",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/masaje-deportivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4444",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/aromaterapia-con-vela/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4445",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/chorro-jet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4446",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/body-mask/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4447",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-spa-beach/cama-piscina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4448",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/castellon/balneario-de-montanejos/aqua-semana-santa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4449",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/masaje-local-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4450",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/minifacial-hidrobuster/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4451",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/switt-lift-firming-by-emerginc/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4452",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/ritual-elixir-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4453",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/depilacion-medios-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4454",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-reflexologia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4455",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bcn-noche-masaje-15/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4456",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/depilacion-bikini-brasileno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4457",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/depilacion-labio-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4458",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-de-la-felicidad-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4459",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/masaje-con-piedras-calientes-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4460",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/depilacion-facial-por-unidad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4461",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/tratamiento-facial-kobido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4462",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/youth-waves/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4463",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-siesta-cuello-hombros-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4464",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/circuito-termal-business/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4465",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/express-marine-anti-fatigue/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4466",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/cura-de-la-tension-cervico-encefalica-y-dolor-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4467",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/drain-body-bandages/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4468",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-excusivity-completo-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4469",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/masaje-15min-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4470",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-25min-chocolate-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4471",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/desayuno-buffet-incluido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4472",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/desayuno-buffet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4473",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/reflexologia-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4474",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/hotel-neptuno-spa/spa-y-masaje-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4475",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4476",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/paquete-especial-pareja-masaje-aromatico-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4477",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/esmaltado-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4478",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/axilas-o-ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4479",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/tratamiento-especifico-anti-acne-purificante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4480",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/entren-funcional-4-ses/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4481",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/entrenamiento-func-4-ses/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4482",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/ritual-equilibrante-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4483",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/menu-light/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4484",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/navidad-ritual-aqua-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4485",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/bano-de-color/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4486",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/bano-de-color-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4487",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/labio-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4488",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/teide-pack-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4489",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/masaje-misteriosa-turquia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4490",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/menorca/spa-faustino-gran/balance-corporal-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4491",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/las-palmas/siam-spa-bohemia-suites/spa-privado-50-con-cava/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4492",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/masaje-craneo-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4493",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/spa-promocion-matinal-2-x-1-l-v/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4494",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/black-friday-masaje-holistico-oriental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4495",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/lomi-lomi-nui-70-min-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4496",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/masaje-anticelulitico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4497",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/pack-especial-mama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4498",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/majestic-spa/momento-quierete-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4499",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-by-alina-vriel/masaje-de-espalda-y-circuito-spa-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4500",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/extra-deep/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4501",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-apacible-paraiso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4502",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/tratamiento-capilar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4503",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/brazos-completos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4504",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/pedicura-spaxion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4505",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/depilacion-piernas-enteras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4506",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/masaje-bio-prenatal-50-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4507",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/ritual-hydracure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4508",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/cargo-extra-por-depilacion-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4509",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-refrescante-con-emulsion-de-pina-mango-i-menta-circuito-spa-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4510",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/depilacion-con-diodo-cuerpo-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4511",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/coloracion-cabello-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4512",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-de-cabeza-hindu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4513",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-hotel-hyltor/masaje-relajante-30min-jacuzzi-30min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4514",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/hb-beauty-party-spa-privado-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4515",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/real-taoista-masaje-piedras-volcanicas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4516",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/mechas-plata-bi-color/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4517",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-sala-comun-masaje-15-pareja-reserva-directa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4518",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/experiencia-spa-cena-buffet-para-dos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4519",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/segovia/spa-spaxion-convento-capuchinos/momento-spa-y-masaje-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4520",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/depilacion-axilas-ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4521",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/5-sesiones-lavar-y-peinar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4522",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/propina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4523",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-massage-for-children-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4524",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/pack-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4525",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/drenaje-linfatico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4526",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/masaje-relajante-50-min-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4527",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/programa-adelgazante-puesta-a-punto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4528",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/lomi-lomi-nui-55-min-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4529",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/ritual-piel-renovadora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4530",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/oasis-de-serenidad-5-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4531",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-aromatico-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4532",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/masaje-localizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4533",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/depilacion-piernas-enteras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4534",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/pack-romantic-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4535",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/taller-meditacion-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4536",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/socio-mensual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4537",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/bono-20-sesiones-ep/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4538",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/solar-supreme/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4539",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/detox-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4540",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/depilacion-de-labio-o-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4541",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/experiencia-mediterranea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4542",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/depilacion-1-2-piernas-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4543",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/zona-de-aguas-masaje-relajante-30-min-cena-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4544",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/depilacion-con-diodo-zona-grande/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4545",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-descontracturante-de-espalda-con-castanas-y-masaje-craneofacial-circuito-termal-promocion-noviembre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4546",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-hotel-hyltor/circuito-spa-completo-60min-servicio-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4547",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/pack-massada-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4548",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/facial-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4549",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/depilacion-pierna-entera/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4550",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/exfoliacion-corporal-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4551",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/depilacion-labio-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4552",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4553",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/banos-romanos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4554",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/momento-spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4555",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-masmonzon/consultaayurvedica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4556",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/aladinia-spamasaje-2-personas-80e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4557",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/body-care-uli-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4558",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/acceso-spa-complementado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4559",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/promocion-black-friday-circuito-spa-ninos-4-15-anos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4560",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/tratamiento-acido-hialuronico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4561",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/suite-experiencia-hidrotermal-relajante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4562",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-holistico-con-aceite-de-cerveza-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4563",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-15-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4564",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-black-friday-serenity-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4565",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/masaje-con-aromaterapia-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4566",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/livinglasnegras-bliss-pack/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4567",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/sweet-love-circuito-beer-spa-pareja-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4568",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/for-men-face-and-body/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4569",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/depilacion-ingles-integral/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4570",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cena-terracita/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4571",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/masaje-corporal-con-mousse-de-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4572",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/lomi-lomi-nui-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4573",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/masaje-oriental-80min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4574",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/depilacion-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4575",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/masaje-relax-con-aceites-calientes-60min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4576",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/facial-blue-ocean-forman/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4577",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/manicura-con-tratamiento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4578",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/pack-luxury-love/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4579",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/bono-6-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4580",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/lavar-y-secar-pelo-medio-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4581",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-10-min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4582",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/spa-attica-21-villalba/especial-club-circuito-termal-mananas-entre-semana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4583",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/lipo-choco-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4584",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/masaje-30-cajitas-y-cupones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4585",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/after-work-con-circuito-termal-de-lunes-a-viernes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4586",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/c-privado-sala-comun/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4587",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/wellness-spam25cena-tematica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4588",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/tratamiento-de-seda-para-sus-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4589",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/sapphire-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4590",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/masaje-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4591",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/pack-relax-total-spa-masaje-sueco-55-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4592",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/pack-hydr-oxigen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4593",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/fitness/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4594",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/ritual-hydracure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4595",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/plan-mama-y-yo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4596",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/masaje-aromaterapia-40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4597",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/experiencia-relax-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4598",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/masaje-a-4-manos-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4599",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-bienvenida/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4600",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/wellnessweekend-2x1-acceso-matinal-zona-wellness-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4601",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-a-medida-50min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4602",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/promocion-especial-dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4603",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/palacio-albacete-spa/ritual-polinesia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4604",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/ritual-polinesia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4605",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-ritual-de-brasil-1h-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4606",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/citrus-vita-essence-de-natura-bisse-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4607",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/citrus-vita-essence-de-natura-bisse-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4608",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4609",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-de-10-masajes-80-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4610",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/bono-10-sesiones-ep/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4611",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/especial-mexico-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4612",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4613",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/retirada-de-esmalte-de-gel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4614",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/bono-5-sesiones-masaje-completo-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4615",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/envoltura-de-karite-rhassoul-con-masaje-craneal-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4616",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/especial-san-valentin-spa-para-dos-masaje-en-pareja-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4617",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/circuito-spa-club-senator-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4618",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/masaje-relajante-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4619",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/pack-luxury-love/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4620",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4621",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/spa-attica-21-villalba/recuperacion-postvacacional-con-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4622",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/mesoterapia-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4623",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/chocolaterapia-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4624",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/paquete-babymon-30-min-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4625",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/bono-10-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4626",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/mer-des-indes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4627",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/rejuvenecimiento-facial-con-laser-diodo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4628",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cura-escapada-antiestres-4-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4629",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/lomi-lomi-nui/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4630",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4631",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/hidromasaje-de-esencias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4632",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/facial-hidratante-al-colageno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4633",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-aromatico-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4634",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-circulatorio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4635",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/ligero-como-el-aire-20min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4636",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/propina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4637",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/rejuvenecimiento-spaxion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4638",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-kobido-emperatriz/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4639",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/maquillaje-de-novia-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4640",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/maquillaje-de-novia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4641",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/higiene-facial-by-thalgo-con-especifico-de-ojos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4642",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/momento-spa-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4643",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/aromaterapia-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4644",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/desayuno-buffet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4645",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-attica21-vigo/circuito-spa-fin-de-ano/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4646",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/retirada-de-esmalte-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4647",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/lemax-wellness-club-wellington/especial-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4648",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/xmas-glow-gift-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4649",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/xmas-glow-gift-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4650",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-relajante-aroma-citricos-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4651",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/pro-magic-stones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4652",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/depilacion-ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4653",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-thailandes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4654",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/bono-descanso-bienestar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4655",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/pack-navidad-esencias-circuito-spa-masaje-de-esencias-naturales-55-minutos-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4656",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/maquillaje-de-noche-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4657",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/maquillaje-de-noche/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4658",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/cuidado-flash-eclat-45/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4659",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/cheese-and-wine-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4660",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/facial-express-by-esdor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4661",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/mechas-pelo-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4662",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/drenante-depurante-reafirmante-3-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4663",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/maquillaje-de-noche/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4664",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-hydracure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4665",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/tinte-color-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4666",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-wellness-con-masaje-experiencia-gastro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4667",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/smb-spa-2-pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4668",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/detox-40-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4669",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/socio-mensual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4670",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4671",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/lomi-lomi-nui/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4672",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/masaje-energy-tea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4673",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/corte-nina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4674",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/masaje-relax-60-circuito-hidrotermal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4675",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/masaje-shi-zen-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4676",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/tratamiento-after-sun/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4677",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/reiki/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4678",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/aroma-relax-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4679",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/ritual-de-la-felicidad-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4680",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/sensations-anti-estres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4681",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hspa-valparaiso/circuito-hspa-valparaiso-120-minutos-viernes-a-domingo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4682",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/masaje-thai-lomi-lomi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4683",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/masaje-60min-escapadas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4684",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/oxigenacion-e-hidratacion-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4685",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-relax-gourmet-gold-edition/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4686",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/masaje-piedras-calientes-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4687",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/be-aloe-hot-cold-stone-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4688",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/futura-mama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4689",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/spa-merienda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4690",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/aqua-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4691",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/tinte-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4692",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/retirar-unas-de-gel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4693",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/especial-navidad-beauty-spa-facial-flash-copa-cava-y-cofre-alqvimia-regalo-para-ti/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4694",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/masaje-deportivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4695",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/deep-maxilofacial-body-massage-75min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4696",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/viaje-al-eden-tropical/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4697",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-cerler/tratamiento-wellness-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4698",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/neceser-cuidado-facial-xl/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4699",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/promo-cabello-sano/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4700",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/hidratacion-capilar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4701",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/bono-5-masajes-oriental-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4702",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/envoltura-de-aglas-con-exfoliante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4703",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/aromaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4704",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/envoltura-limos-marinos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4705",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-muscular-profundo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4706",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/ritual-brisa-de-ganesha/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4707",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/masaje-local-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4708",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-fangoterapia-80min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4709",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/programa-glamour-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4710",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/relax-masaje-corportalcraneal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4711",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/himalayan-rock-i-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4712",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/ciudad-real/spa-casa-del-rector/especial-black-friday-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4713",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/glow-up-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4714",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/laguna-relax-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4715",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/gel-puro-ecologico-aloe-vera-100ml/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4716",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/masaje-de-piernas-cansadas-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4717",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/piel-de-seda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4718",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/gorrito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4719",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/pack-jarra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4720",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/mechas-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4721",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/manos-perfectas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4722",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/masaje-luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4723",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/cura-relax-antiestres-desfatigante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4724",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-masaje-relajantes-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4725",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/ritual-bienestar-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4726",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/consulta-medica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4727",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/bambuterapia-masaje-con-canas-de-bambu-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4728",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-thailandes-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4729",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-thailandes-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4730",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/ritual-pureza-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4731",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-shirodhara/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4732",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4733",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/programa-especial-piernas-cansadas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4734",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/true-love-circuito-spa-san-valentin-con-cava-y-bombones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4735",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/masaje-parcial-relajante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4736",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/pro-especial-mar-muerto-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4737",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/serenidad-para-mama-con-masaje-30-y-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4738",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/masaje-podal-oriental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4739",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/facial-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4740",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-craneal-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4741",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/masaje-descontracturante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4742",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/porque-ellas-se-lo-merecen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4743",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/pack-navidad-vikingo-circuito-spa-sala-comun-para-dos-personas-pack-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4744",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/devolucion-gastronomia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4745",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/masaje-sueco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4746",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4747",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/ritual-esplendor-radiante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4748",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-roca-negra/promocion-black-friday-circuito-spa-gastronomia-cocktail/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4749",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/manicura-sanctuary-son-brull/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4750",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-55-sala-privada-1px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4751",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/bono-circuito-termal-10-accesos-dobles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4752",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/facial-pure-expert/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4753",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/especial-parejas-circuito-playa-granada-masaje-de-55-min-servicio-de-te-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4754",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/masaje-especial-ayurveda-abhyanga-80min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4755",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/entrada-termal-bebes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4756",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/al-wellness-experiencia-matinal-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4757",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/emocion-circuito-alegria-masaje-15cava-2px-l-aj/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4758",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/pedicura-deluxe-con-parafina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4759",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/atrapalo-masaje-25-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4760",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/atrapalo-masaje-25-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4761",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/experiencia-bienestar-para-dos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4762",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-spa-25-min-de-masaje-aromatico-relajante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4763",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/exfoliacion-aromatica-con-especias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4764",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/naiad-wellness-center/especial-mama-belleza-y-bienestar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4765",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/bono-10-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4766",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/masaje-15min-pago-directo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4767",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/radiofrecuencia-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4768",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-relajante-de-polinesia-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4769",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/pure-salt-ritual-global-experience-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4770",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/drenante-depurante-reafirmante-3-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4771",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/lunch-dinner-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4772",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-corporal-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4773",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/secretos-marinos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4774",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/masaje-luz-serena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4775",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/gimnasio-15-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4776",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/the-essential/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4777",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-capricho-bodyna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4778",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/higiene-facial-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4779",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/laura-leon-wellness/circuito-spa-para-2-masaje-brunch/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4780",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/jacuzzi-privado-y-sauna-para-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4781",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/jacuzzi-privado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4782",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-10-min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4783",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/masaje-con-piedras-volcanicas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4784",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/oriental-bano-indonesio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4785",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-55-min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4786",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-circulatorio-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4787",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/gold-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4788",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/tratamiento-facial-vitamina-c-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4789",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-augas-santas-balneario-golf/circuito-termal-entrada-infantil-recp/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4790",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/masaje-pinda-sweda-60min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4791",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/especial-mama-nunca-estaras-sola/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4792",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/depilacion-espalda-o-pecho-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4793",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/circuito-spa-infantil-2x1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4794",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/acceso-spa-60-especial-profes-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4795",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/circuito-dusch-10-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4796",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/masaje-bio-muscular-profundo-50-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4797",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/termes-de-montbrio/ayurveda-embarazadas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4798",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/masaje-drenante-linfatico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4799",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/blanqueamiento-dental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4800",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/circuito-spa-adulto-2x1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4801",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/day-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4802",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/exfoliacion-corporal-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4803",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-belleza-dia-1-peeling-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4804",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-de-pindas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4805",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-plaza-mercado/circuito-termal-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4806",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/pack-navidad-esencias-circuito-spa-masaje-de-esencias-naturales-55-minutos-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4807",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/aromaterapia-85min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4808",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/relax-choco-en-pareja-circuito-spa-chocolaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4809",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/masaje-luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4810",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-facial-expres-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4811",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/limpieza-facial-profunda-residente-canario/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4812",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-craneocervical-15/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4813",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-con-piedras-calientes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4814",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/smb-spa-masaje-40-min-1-pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4815",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/depilacion-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4816",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/ritual-piel-renovadora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4817",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/ayurveda-abhyanga-75-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4818",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/primavera-oasis-hindu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4819",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/bienestar-55-anos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4820",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/depilacion-de-pecho/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4821",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/menu-nature-corner/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4822",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/promocion-circuito-spa-reina-masaje-de-55-min-servicio-de-te-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4823",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/gran-hotel-spa-lakua/circuito-spa-60-exfoliacion-30-masaje-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4824",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-relajante-corporal-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4825",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/spa-en-familia-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4826",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/oferta-deportista-40min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4827",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/pack-ritual-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4828",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cortar-y-peinar-senora-pelo-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4829",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/oferta-flash-ritual-antiestres-60-min-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4830",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/special-black-friday-circuito-spa-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4831",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/personalizado-masaje-antiestres-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4832",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/esencias-y-sentidos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4833",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/depilacion-con-diodo-zona-media/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4834",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/depilacion-con-diodo-zona-pequena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4835",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacion-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4836",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/experiencia-sublime/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4837",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/esencial-belleza-tratamiento-anti-envejecimiento-60min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4838",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/lomi-lomi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4839",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/circuito-spa-con-ritual-de-bienvenida-residentes-canarios/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4840",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/lpg-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4841",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/hidro-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4842",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/relajante-30-o-facial-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4843",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-flor-de-cerezo-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4844",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-sinfonia-deliciosa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4845",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/pack-tensor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4846",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/ritual-beldi-argan/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4847",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-jueves-2-adultos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4848",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-15min-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4849",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/bono-6-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4850",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/bono-4-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4851",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/jacuzzi-sauna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4852",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/gimnasio-spa-deloix-3-semanas-cliente-camping-raco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4853",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/circuito-spa-tratamiento-con-sales-de-mallorca/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4854",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/spa-privado-exclusive-love/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4855",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/pro-magic-stones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4856",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/masaje-cuerpo-entero-y-craneofacial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4857",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/al-masaje-50-experiencia-gastro-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4858",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/nyxpert-the-royal-fitness-spa/ritual-candle-acceso-al-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4859",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/drain-body-bandages/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4860",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacion-de-cejas-tinte-de-cejas-tinte-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4861",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4862",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/castellon/balneario-de-montanejos/sesion-basica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4863",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-sunset-en-egipto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4864",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/depilacion-ingles-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4865",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/exotico-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4866",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/oriental-peeling/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4867",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/circuito-spa-6-circuitos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4868",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/spa-en-familia-2x1-20-descuento-en-tratamientos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4869",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/masaje-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4870",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-de-la-felicidad-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4871",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/especial-san-valentin-spa-para-dos-de-viernes-a-domingos-y-festivos-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4872",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/tratamiento-vitamina-c-supreme/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4873",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/viaje-al-eden-tropical-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4874",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-25-min-recepcion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4875",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/power-to-myself/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4876",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-deportivo-duo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4877",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/pack-antimanchas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4878",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/circuito-wellness-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4879",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/facial-especifico-anti-acne-purificante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4880",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-exclusivity-completo-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4881",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-antiestres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4882",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/swedish-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4883",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/mechas-pelo-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4884",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/masaje-cuero-cabelludo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4885",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/masaje-esencia-natural-vino-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4886",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-despacio-h10-benidorm/masaje-a-4-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4887",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/pack-navidad-glow-up-circuito-spa-tratamiento-lifting-sicilium-marin-pack-regalo-pack-para-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4888",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/pack-spa-enamorados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4889",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/circuito-spa-semiprivado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4890",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/chanclas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4891",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/facial-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4892",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/exfoliante-bambu-jeju/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4893",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/circuito-termal-navidad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4894",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-ritual-bangalore-india-rituales-del-mundo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4895",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/semipermanente-con-manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4896",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/at-spa-75/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4897",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/esencias-y-sentidos-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4898",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/facial-antiedad-lift-in/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4899",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/menorca/spa-faustino-gran/fitness-room-30-only-guests/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4900",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/manicura-o-pedicura-especificar-comentario/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4901",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lleida/iberik-rocallaura-balneari/bano-hidromasaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4902",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/cena-puerta-de-la-alhambra-2-px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4903",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-cellulite-system/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4904",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/especial-te-quiero-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4905",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/black-friday-masaje-bereber/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4906",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/black-friday-circuito-spa-60-min-masaje-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4907",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/masaje-oriental-25-min-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4908",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/aceites-calientes-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4909",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-15min-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4910",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-relax-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4911",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4912",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/at-wellness-experiencia-gastro-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4913",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/ritual-flor-de-cerezo-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4914",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/bonos-vip-masaje-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4915",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/baleares/senator-caleia-talayot-spa/ritual-equilibrante-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4916",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-equilibrante-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4917",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/cama-piscina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4918",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/masaje-sueco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4919",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/masaje-relajante-corporal-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4920",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/jarra-regalo-dia-del-padre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4921",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/masaje-25-promo-flash/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4922",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/ciudad-real/spa-casa-del-rector/ritual-deluxe-de-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4923",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/ritual-de-la-felicidad-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4924",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/masaje-craneo-facial-nino-a/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4925",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/ritual-armonia-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4926",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-despacio-h10-benidorm/drenaje-linfatico-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4927",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/las-palmas/siam-spa-bohemia-suites/recepcion-1-hora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4928",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/moldeador-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4929",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4930",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/hidratacion-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4931",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/retirado-de-esmalte-semipermanente-en-20-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4932",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/retirado-de-esmalte-semipermanente-en-20-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4933",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/sensual-care-inmersion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4934",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/spa_peeling/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4935",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/hard-rock-spa-marbella/especial-papa-zona-de-aguas-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4936",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/ritual-pindas-energeticas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4937",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-shiatsu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4938",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4939",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/tratamiento-piernas-cansadas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4940",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/masaje-energetico-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4941",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cortar-y-lavar-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4942",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/momento-spa-estancia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4943",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-reductor-para-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4944",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-empaquetado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4945",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/al-masaje-25-desayuno-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4946",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/al-masaje-30-experiencia-matinal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4947",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/circuito-alegria-2px-cofre-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4948",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/ritual-esplendor-radiante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4949",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/pack-navidad-vikingo-circuito-spa-sala-comun-para-dos-personas-pack-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4950",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/tratamiento-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4951",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/depilacion-piernas-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4952",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/tto-facial-oroterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4953",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/royal-jelly/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4954",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/pack-navidad-relax-pareja-circuito-spa-ritual-gold-theraphy-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4955",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-con-aromaterapia-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4956",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/majestic-spa/masaje-personalizado-90-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4957",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/secador-medio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4958",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/acceso-spa-50gratuitos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4959",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-spa-50-10-entradas-alldays/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4960",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-acceso-spa-50-10-entradas-alldays/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4961",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/circuito-arabes-grupo-6px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4962",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/pedicure-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4963",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/tratamiento-antiaging/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4964",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/pack-navidad-esencias-circuito-spa-masaje-de-esencias-naturales-55-minutos-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4965",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/tratamiento-shanara-con-cuencos-tibetanos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4966",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/masaje-sunset-en-egipto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4967",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-armonia-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4968",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/swedish-massage-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4969",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-spa-beach/2x1-especial-deep-tissue-massage-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4970",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-excusivity-completo-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4971",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/peeling-hidratacion-rosa-mosqueta-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4972",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hotel-spa-cadiz-plaza/circuito-termal-publico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4973",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/pack-massada-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4974",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4975",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/masaje-30-experiencia-gastro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4976",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/depilacion-piernas-completas-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4977",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/jacuzzi-x2-hammam/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4978",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/rejuvenecimiento-facial-con-laser-diodo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4979",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/exotico-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4980",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/presoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4981",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/chorro-jet-alta-presion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4982",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-exclusivity-parcial-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4983",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-completo-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4984",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/envoltura-fango-negro-nino-a/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4985",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/viaje-paquete-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4986",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/masaje-45min-de-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4987",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/especial-parejas-circuito-arabe-masaje-de-55-min-servicio-de-te-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4988",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/termes-de-montbrio/duo-herbal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4989",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/masaje-15-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4990",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/masaje-15-min-pago-directo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4991",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/masaje-esencia-natural-aceite-de-oliva-y-miel-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4992",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/masaje-bienestar-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4993",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-spa-beach/2x1-especial-deep-tissue-massage-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4994",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/sueno-profundo-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4995",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/especial-piernas-cansadas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4996",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-masaje-ayurvedico-30-masajes-de-la-tierra-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4997",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/spa-privado-masaje-cena-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4998",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 4999",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/ritual-bienestar-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5000",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/abhyanga/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5001",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/piernas-enteras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5002",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/masaje-reductor-o-reafirmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5003",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-de-oro-o-chocolate-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5004",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/ritual-kobido-emperatriz/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5005",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-hotel-hyltor/masaje-relajante-30min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5006",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/masaje-a-la-brasa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5007",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-15min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5008",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-masmonzon/masaje-uzichil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5009",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/retirar-unas-semi-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5010",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/hidratacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5011",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/manicura-deluxe-by-opi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5012",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/signature-cookbook/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5013",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/spa-privado-deluxe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5014",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/circuito-spa-infantil-4-14-anos-club-playa-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5015",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lisboa/azure-wellness-spa/winter-ritual-110-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5016",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/programa-glamour-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5017",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-exclusivity-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5018",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/purificante-detoxificante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5019",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/masaje-en-pareja-60-circuito-hidrotermal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5020",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/lavar-y-marcar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5021",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/viaje-al-eden-tropical/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5022",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/spa-masaje-lomi-lomi-hawaiano/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5023",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/depilacion-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5024",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/bono-experiencia-bodyna-masaje-aroma-citrico-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5025",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/plancha-rizada/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5026",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-aromatico-15-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5027",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/tratamiento-facial-efecto-flash/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5028",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/especial-busto-alqvimia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5029",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/9issui-spa-fitness/momento-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5030",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5031",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/glow-up-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5032",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/momento-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5033",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/luxor-spa/drenaje-linfatico-manual-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5034",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/peinado-medio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5035",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/especial-pareja-piscina-de-tratamientos-masaje-de-25-min-servicio-de-te-pareja-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5036",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/pedicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5037",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/pack-luxury-love/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5038",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-deportivo-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5039",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/piensa-en-ti-cueva-de-sal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5040",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/reflexologia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5041",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/masaje-oriental-2paxeliminado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5042",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/ritual-oriental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5043",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cantabria/spa-castilla-termal-solares/shiatsu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5044",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/manicura-spaxion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5045",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/experiencia-relax-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5046",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/momento-spa-masaje-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5047",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-cuerpo-completo-relajante-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5048",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/aromaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5049",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/gimnasio-1-semana-cliente-camping-raco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5050",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/valencia-promocion-relajante-reafirmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5051",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/circuito-spa-infantil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5052",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/esmaltado-de-unas-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5053",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/sensory-slow/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5054",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/sport-flash/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5055",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/sauna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5056",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bcn-noche-masaje-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5057",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pass-gym-sabado-y-domingo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5058",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/lavar-y-cortar-mujer/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5059",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/experiencia-de-luna-creciente-en-sierra-nevada-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5060",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/facial-hidratante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5061",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/bano-oceanico-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5062",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/silueta-perfecta-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5063",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/tratamiento-facial-firmas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5064",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/tratamiento-facial-45/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5065",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-con-aromaterapia-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5066",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/foaming-enzyme-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5067",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/spa-50-almuerzo-o-cena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5068",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/sesion-individual-ep/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5069",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/arreglo-de-barba-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5070",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-flor-de-cerezo-80-cherry-blossom-ritual-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5071",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/pool-vip-pass-con-almuerzo-de-lunes-a-viernes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5072",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/viaje-al-eden-tropical/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5073",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/masaje-liporeafirmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5074",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/tratamiento-laser/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5075",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/ritual-pureza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5076",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/facial-basico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5077",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hspa-valparaiso/aromaterapia-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5078",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-coctel-2-pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5079",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/ritual-romance-de-sol-y-luna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5080",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/san-valentin-sesion-noche-spa-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5081",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-spaxion-hg-la-molina/tratamiento-wellness-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5082",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/piscina-puesta-en-forma-especial-dia-del-carmen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5083",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/jabon-loccitane-en-pastilla/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5084",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-25-sala-privada-1px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5085",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/solar-supreme/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5086",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/al-masaje-25-experiencia-gastro-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5087",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/al-masaje-30-experiencia-gastro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5088",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/pack-navidad-esencias-circuito-spa-masaje-de-esencias-naturales-55-minutos-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5089",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/masaje-samay-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5090",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/depilacion-de-entrecejo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5091",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/peeling-y-facial-vit-c-circuito-spa-gratis/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5092",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/bono-10-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5093",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/bano-de-parafina-de-15-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5094",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/20-minute-paraffin-bath/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5095",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/bano-de-parafina-de-15-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5096",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/diamond-experience-facial-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5097",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/depilacion-piernas-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5098",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/ritual-aromaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5099",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/circuito-navidad-infantil-alojado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5100",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/programa-reino-de-egipto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5101",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/masaje-samay-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5102",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/pack-massada-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5103",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/add-semipermanent-nail-polish/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5104",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/anadir-esmalte-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5105",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/masaje-relajante-para-dos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5106",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/energy-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5107",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/tinte-pelo-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5108",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/vichy-detox/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5109",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/beauty-feets-full-pedicure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5110",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/estancia-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5111",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/depilacion-piernas-enteras-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5112",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/grapeseed-renewal-body-scrub/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5113",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-piedras-calientes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5114",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/masaje-stone-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5115",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/vuelve-pronto-spa-75-antes-de-30-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5116",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/masaje-lomi-lomi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5117",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-ayurveda-abhyanga/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5118",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guadalajara/spa-castilla-termal-brihuega/acupuntura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5119",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/manicura-basica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5120",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5121",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/especial-ayurveda-abhyanga/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5122",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/body-care-sandos-papagayo-spa-wellness/pro-top-care/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5123",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cantabria/balneario-la-hermida/evasion-marina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5124",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/feliz-dia-papa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5125",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/masaje-thai-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5126",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/podal-reflexology/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5127",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/15min-chocolate-o-facial-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5128",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/masaje-con-aromaterapia-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5129",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/facial-oxigenante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5130",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/bono-8-masajes-tailandes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5131",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/envoltura-con-fango-del-mar-muerto-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5132",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/black-friday-2x1-masaje-50-entrada-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5133",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/pro-ayurveda-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5134",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/recogido-de-noche/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5135",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/recogido-classico-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5136",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/tratamiento-reductor-reafirmante-localizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5137",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/radiofrecuencia-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5138",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-facial-iluminador-japones-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5139",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/masaje-relajante-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5140",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/bono-ilumina-y-regenera-tu-piel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5141",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-de-cerveza-degustacion-de-1-cerveza-especial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5142",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/belleza-flash-20-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5143",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/cata-de-cerveza-con-maridaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5144",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/body-care-uli-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5145",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/pack-tensor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5146",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/circuito-termal-infantil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5147",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/especial-canarias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5148",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-de-oro-o-chocolate-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5149",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/bono-10-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5150",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/tratamiento-facial-vitamina-c-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5151",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/banera-hidromasaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5152",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/facial-express-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5153",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/pedicura-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5154",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-descontracturante-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5155",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/lipocell-sculptor-por-natura-bisse-80-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5156",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/lipocell-sculptor-por-natura-bisse-80-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5157",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-iberostar-las-gaviotas/bioterapia-sistemica-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5158",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/ritual-cuidado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5159",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/tratamiento-facial-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5160",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/permanente-y-tinte-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5161",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/pure-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5162",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/peinado-para-novia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5163",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/spamasaje-10/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5164",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/drenaje-linfatico-manual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5165",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/masaje-aromaterapia-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5166",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/depilacion-piernas-enteras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5167",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/especial-san-valentin-amor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5168",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/oasis-de-energia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5169",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/cumpleanos-en-salobre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5170",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/ritual-oasis-de-serenidad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5171",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/tratamiento-facial-aqua/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5172",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/gran-hotel-spa-lakua/circuito-spa-60-exfoliacion-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5173",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/ritual-indoceane/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5174",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/palacio-albacete-spa/ritual-indoceane/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5175",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/facial-so-delicate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5176",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/tratamiento-facial-arrugas-fuera-con-radiofrecuencia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5177",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/4-aadd/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5178",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/pedicura-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5179",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/pack-antimanchas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5180",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-hydraluronic/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5181",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/promocion-especial-dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5182",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/citrus-vita-essence/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5183",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/masaje-para-contractura-localizada/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5184",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/chocolaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5185",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/radiofrecuencia-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5186",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/dia-del-padre-te-lomereces-papa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5187",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/piedras-calientes-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5188",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/higiene-base-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5189",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-craneal-recepcion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5190",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/ritual-armonia-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5191",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/personalizado-masaje-antiestres-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5192",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/vip-spa-privado-30-botella-cava/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5193",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/spa-hotel-galatea/masaje-relajante-30-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5194",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/peeling-corporal-de-coco-hidratacion-completa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5195",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-spa-50-trimestral-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5196",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-acceso-spa-75-trimestral/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5197",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/sala-vip-2-masajes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5198",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/ritual-cuidado-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5199",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-relajante-anti-stres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5200",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/circuito-spa-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5201",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/ritual-experience-a-elegir/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5202",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/el-lifting-facial-kobido-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5203",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/bano-en-pareja-con-espuma-de-rosa-y-copa-de-cava-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5204",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/manicura-gellac-opi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5205",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-placeres-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5206",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/ritual-capricho-de-la-naturaleza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5207",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5208",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/masaje-turco-con-jabon/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5209",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/exfoliacion-completa-con-guante-kessa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5210",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/black-friday-2x1-masaje-50-entrada-wellness/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5211",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/black-friday-2x1-masaje-50-circuito-wellness-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5212",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/depilacion-labio-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5213",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/especial-pareja-circuito-ohtels-masaje-de-25-min-servicio-de-te-parejas-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5214",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/04-trufa-blanca-essence-maria-galland/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5215",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-craneal-shirogriva-duo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5216",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/circuito-spa-domingo-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5217",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/spa-bio-tu-detox/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5218",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-cuerpo-completo-terapeutico-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5219",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/relax-y-gastro-mediodia-de-lunes-a-viernes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5220",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/circuito-motril-grupos-6px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5221",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/depilacion-medios-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5222",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-so-delicate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5223",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/depilacion-piernas-enteras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5224",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-exclusivity-de-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5225",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-de-pindas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5226",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/romance-spa-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5227",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/burgos/spa-tudanca/especial-mama-eye-perfection-treatment/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5228",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/personalitzado-gourmet-love-para-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5229",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/ritual-placeres-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5230",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-hydracure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5231",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/bioterapia-corporal-sculptor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5232",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-de-pindas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5233",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/manicura-spaxion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5234",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-bienestar-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5235",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-augas-santas-balneario-golf/circuito-termal-entrada-l-v-recp/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5236",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-tratamiento-facial-riche-de-jeunesse-tratamientos-facialese/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5237",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/evasion-romantica-for-two-cena-hotel-desayuno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5238",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/late-check-out-hasta-19h/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5239",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/masaje-piernas-especial-piernas-ligeras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5240",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/especial-halloween-v-d-y-festivos-circuito-de-aguas-con-copa-de-cava-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5241",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/stone-therapy-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5242",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-adulto-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5243",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/ingles-axilas-o-cara/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5244",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-iberostar-las-gaviotas/stone-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5245",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/spa-privado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5246",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/especial-black-friday-circuito-de-aguas-cliente-alojado-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5247",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/body/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5248",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/centro-bienestar-sunprime-atlantic/circuito-spa-no-huesped/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5249",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/depilacion-labio-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5250",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/a-c-reserva-de-servicio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5251",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/dermoabrasion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5252",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-capricho-de-los-dioses/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5253",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/capricho-de-dioses/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5254",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/masaje-deportivo-todo-el-cuerpo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5255",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/faro/soul-spa-by-jupiter-albufeira/facial-hidratacao-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5256",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/kit-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5257",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/experiencia-relax-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5258",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-golf/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5259",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-ayurveda-abhyanga-duo-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5260",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/facial-especifico-anti-acne-purificante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5261",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/extra-relaxing-love-circuito-beer-spa-pareja-masaje-25-minutos-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5262",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/nyxpert-the-royal-fitness-spa/magic-honey-acceso-al-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5263",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-spa-masaje-x2-paquetes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5264",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/centro-bienestar-sunprime-atlantic/romance-spa-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5265",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/tejidos-profundos-90-min-deep-tisue-recepcion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5266",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5267",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/custom/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5268",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/drenaje-linfatico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5269",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-fusion-con-gin-tonic/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5270",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/piernas-enteras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5271",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/circuito-ano-nuevo-infantil-pqt-alojado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5272",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/ritual-romance-spa-privado-2-pers/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5273",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-energizante-ayurvedico-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5274",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/especial-black-friday-masaje-exclusivity-completo-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5275",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/pecho-o-gluteos-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5276",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/relajacion-total/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5277",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/facial-hidratante-basico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5278",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/rock-spa-luxury-manicure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5279",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/kit-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5280",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/masaje-clasico-55-o-85-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5281",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/upselling-servicio-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5282",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/despertar-los-5-sentidos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5283",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/eres-alicantin-este-descuento-es-para-ti/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5284",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/masaje-signature-80-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5285",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-signature-80-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5286",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/after-sun-cold-cream-marine/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5287",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/especial-halloween-l-j-circuito-de-aguas-con-copa-de-cava-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5288",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/especial-black-friday-lomi-lomi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5289",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/corte-medio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5290",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/envoltura-corporal-rejuvenecedora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5291",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/peeling-cristal-con-aceites-flores/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5292",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/wellness-ritual-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5293",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-la-gavina/ritual-facial-valmont-vitalite-des-glaciers/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5294",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/pack-massada-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5295",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/ritual-equilibrante-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5296",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/diseno-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5297",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-hotel-hyltor/bano-turco-30min-servicio-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5298",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-relajante-eclipse-lunar-50-circuito-spa-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5299",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/peeling-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5300",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/higiene-facial-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5301",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/ritual-placeres-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5302",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/laser-zonas-reducidos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5303",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-equilibrante-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5304",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/circuito-wellness-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5305",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/labio-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5306",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/ht-wellness-experiencia-matinal-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5307",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/presoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5308",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-masaje-de-craneo-facial-masajes-energias-de-la-tierra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5309",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/spa-moet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5310",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/escapada-luxury/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5311",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/depilacion-medias-piernas-hair-removal-half-leg/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5312",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/especial-ella-reina-de-egipto-by-alqvimia-regalo-entrada-doble-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5313",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/bono-4-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5314",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/aladinia-relax-para-2-accesocava/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5315",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/higueron-spa/experiencia-wellness-express-45-min-spa-15-min-masaje-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5316",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/peeling-corporal-y-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5317",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/arreglo-barba/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5318",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/tratamiento-calmante-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5319",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/promocion-especial-hosteleros/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5320",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-15-privado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5321",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/depilacion-con-diodo-zona-media/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5322",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/depilacion-bikini/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5323",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-detox/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5324",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/ritual-kobido-emperatriz/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5325",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-pazo-los-escudos/pure-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5326",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/masaje-sueco-85/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5327",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/tratamiento-facial-efecto-flash-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5328",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-cerler/tratamiento-wellness-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5329",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/facial-express-personalizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5330",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/the-spa-by-signature-sir-victor/city-relax-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5331",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pressotherapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5332",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/gentleman-facial-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5333",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/circuito-termal-beauty-bath/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5334",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/spa-entrada-circuito-aguas-60-especial-profes-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5335",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/timexpert-c-iluminator/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5336",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/ritual-de-la-felicidad-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5337",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/choco-lipo-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5338",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-attica21-vigo/sala-privada/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5339",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5340",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5341",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/soria/spa-castilla-termal-burgo-de-osma/experiencia-san-baudelio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5342",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/bright-day/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5343",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/reflexologia-podal-de-50-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5344",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/reflexologia-podal-de-50-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5345",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/ingles-o-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5346",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/masaje-reductor-reafirmante-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5347",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/promo-matinal-masaje-relajante-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5348",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/depilacion-laser-zona-pequena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5349",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/masaje-con-aromaterapia-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5350",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-detox-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5351",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/pack-hydr-oxigen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5352",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/fantasia-de-chocolate-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5353",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/masaje-de-piernas-y-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5354",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/rebajas-30-3-entrada-dobles-acceso-spa-50-total-6-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5355",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-con-piedras-calientes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5356",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/spa-in-love-para-dos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5357",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-deep-tissue-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5358",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/masaje-descontracturante-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5359",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/ducha-vichy-a-cuatro-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5360",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/exfoliacion-aromatica-y-energizante-con-especias-preciosas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5361",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/treats-relax-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5362",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/madre-e-hija-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5363",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/luxor-spa/dia-de-la-madre-experiencia-en-pareja-masaje-relajante-local/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5364",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/circuito-spa-y-belleza-pareja-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5365",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/circuito-hidrotermal-masaje-relajante-de-20-minutos-buffet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5366",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/circuito-navidad-entrada-infantil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5367",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/exfoliante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5368",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/experiencia-detox/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5369",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/gr-spa-50-cava-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5370",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/g-2x-spa-75-cc/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5371",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/masaje-energy-tea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5372",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/pack-cerveza-dia-del-padre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5373",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/anadir-parafina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5374",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/facial-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5375",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/especial-black-friday-love-secret/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5376",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/calmante-para-pieles-sensibles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5377",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-relajante-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5378",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/presoterapia-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5379",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/pack-vital-prevention/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5380",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/bono-10-sesiones-ep/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5381",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/depilacion-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5382",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5383",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guipuzcoa/thalasso-villa-antilla/recorrido-marino-infantil-12-16-anos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5384",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/tratamiento-facial-de-hidratacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5385",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/circuito-spa-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5386",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-spa-75-mensual-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5387",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-spa-50-mensual-30-dias-alldays/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5388",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/especial-black-friday-masaje-presion-profunda-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5389",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-relajante-aromas-de-alejandria/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5390",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/paquete-un-dia-para-ti/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5391",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-1-adulto-1-nino-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5392",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/foot-reflexology/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5393",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/jarra-regalo-dia-del-padre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5394",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/masaje-apacible-paraiso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5395",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-relajante-corporal-50eliminado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5396",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/desayuno-buffet-para-coger-cita/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5397",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/circuito-wellness-masaje-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5398",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/promocion-especial-dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5399",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/entrenamiento-personal-1-ses/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5400",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/peeling-corporal-dulde-flor-de-thiare/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5401",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/ritual-armonia-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5402",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/masaje-10-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5403",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/spa-1h-almuerzo-cena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5404",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/fittnes-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5405",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/pedicura-basica-15-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5406",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/masaje-cuerpo-completo-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5407",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/tratamiento-de-la-alegria/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5408",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/dermoabrasion-spaxion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5409",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/eternal-youth-120min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5410",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/spa-promocion-matinal-2x1-l-v/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5411",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/higiene-facial-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5412",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/pedicura-con-tratamiento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5413",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/jaen/thermas-palacio-de-ubeda/ritual-renacimiento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5414",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/clase-privada-de-paddle-surf/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5415",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/ritual-bienestar-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5416",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/pack-relax-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5417",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/circuito-spa-adultos-club-senator-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5418",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/ritual-de-la-felicidad-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5419",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/1-bono-taller-individual-1-sesion-desbloqueo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5420",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/body-care-sandos-papagayo-spa-wellness/pro-bright-day/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5421",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/faro/soul-spa-by-jupiter-algarve/hidratacion-facial-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5422",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/faro/soul-spa-by-jupiter-algarve/facial-hidratacao-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5423",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/spa-desayuno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5424",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal-vip-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5425",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sunlight-bahia-principe-san-felipe/manicura-pedicura-tradicional/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5426",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-armonia-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5427",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/depilacion-brasilena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5428",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/tratamiento-facial-con-uvas-de-la-suerte-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5429",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/taller-meditacion-4/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5430",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-anticelulitico-50-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5431",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/navidad-spa-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5432",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/full-manicura-o-pedicura-con-gel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5433",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/tratamiento-facial-antimanchas-oxigenante-o-calmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5434",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/tratamiento-antiarrugas-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5435",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/calma-spa/promocion-black-friday-masaje-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5436",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/gimnasio-acceso-1-dia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5437",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/summer-24-relax-time/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5438",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/ingles-integral/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5439",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5440",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/masaje-de-sabila-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5441",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/cura-lifting-y-antievejecimiento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5442",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/aragon-hills-hotel/facial-radiance-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5443",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/tratamiento-osteopatia-residentes-lanzarote/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5444",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/ritual-pureza-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5445",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/masaje-esencias-naturales-completo-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5446",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/pack-tensor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5447",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/pack-confort-1er-dia-sauna-o-jacuzzi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5448",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/depilacion-de-gluteos-hombres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5449",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/ducha-vichy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5450",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/manicura-fantasia-nina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5451",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/palacio-albacete-spa/programa-evasion-1-dia-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5452",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/tratamiento-alisado-keratina-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5453",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/pack-navidad-bano-vikingo-circuito-spa-sala-comun-mascarilla-facial-de-cerveza-para-dos-personas-pack-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5454",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/especial-navidad-beauty-zona-wellness-facial-flash-copa-cava-y-cofre-alqvimia-regalo-para-ti/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5455",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-subacuatico-masaje-descontracturante-de-espalda-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5456",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/masaje-baby-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5457",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/masaje-para-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5458",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/masaje-piernas-cansadas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5459",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/experiencia-relax-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5460",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/g-spa-75-cc/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5461",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/a-c-reserva/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5462",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/lavar-cortar-y-peinar-cabello-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5463",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/masaje-enero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5464",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/calma-spa/especial-san-valentin-circuito-spa-ritual-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5465",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/santa-cruz-de-tenerife/beer-spa-tenerife/masaje-15min-pareja-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5466",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-15min-pareja-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5467",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/peeling-corporal-de-coco-hidratacion-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5468",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/tratamiento-facial-especial-para-hombres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5469",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-oasis-son-caliu/spa-oasis-invitacion-juana-cifuentes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5470",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/pack-glamour/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5471",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-antiedad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5472",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/arreglo-barba-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5473",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/tratamiento-facial-purificante-y-desintoxicante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5474",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/envoltura-con-barro-del-mar-muerto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5475",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/exfoliacion-corporal-completa-envoltura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5476",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/circuito-spa-masaje-25-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5477",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/tratamiento-alisante-y-reafirmante-ko-bi-do/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5478",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/tratamiento-facial-45/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5479",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/ritual-para-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5480",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/experiencia-bodyna-descontracturante-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5481",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/experiencia-bodyna-descontracturante-masaje-de-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5482",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/tratamiento-antiedad-especifico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5483",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/flash-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5484",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/drenaje-linfatico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5485",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/spa-buffet-menores/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5486",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/masaje-deep-tisue/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5487",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/tramuntana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5488",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/ritual-bienestar-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5489",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/masaje-50-promo-flash/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5490",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-crepusculo-luxury/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5491",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-citricos-15min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5492",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/detox-spiruline-boost/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5493",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/masaje-fisioterapeutico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5494",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/especial-dia-del-padre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5495",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/msje-neuro-duo-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5496",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/facial-anti-rides/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5497",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/masaje-deep-tisue/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5498",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/circuito-spa-infantil-2x1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5499",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/depilacion-pack-hombre-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5500",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/reflexologia-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5501",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/masaje-relajante-mundo-de-los-aromas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5502",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/envoltura-exotico-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5503",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/maquillaje-de-dia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5504",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/at-masaje-50-experiencia-gastro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5505",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/cama-spa-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5506",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/msje-neuro-duo-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5507",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/oxigenante-purificante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5508",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/canas-de-bambu-i-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5509",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-con-piedras-calientes-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5510",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/entrenamiento-personal-8-ses/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5511",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-spaxion-hg-la-molina/momento-spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5512",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-sublime-de-la-polinesia-lomi-lomi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5513",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/pedicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5514",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/san-valentin-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5515",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/palacio-albacete-spa/sesion-maderoterapia-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5516",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/sesion-maderoterapia-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5517",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/neceser-basic-xl/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5518",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/depilacion-ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5519",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-exclusivity-completo-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5520",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/bono-6-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5521",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/ritual-flor-de-cerezo-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5522",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/manicura-spa-incluye-tratamiento-de-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5523",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/nutre-y-cuida-tu-piel-en-epoca-estival/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5524",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-neurosedante-con-aceite-de-citricos-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5525",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/pedicura-express-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5526",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/tinte-de-pestanas-y-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5527",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/hidro-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5528",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/tratamiento-manos-bodyna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5529",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-energy-tea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5530",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guipuzcoa/spa-foresta-wellness/promocion-especial-circuito-spa-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5531",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5532",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/corte-de-pelo-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5533",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/bono-4-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5534",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/masaje-energy-tea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5535",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/masaje-25-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5536",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/peeling-corporal-de-coco-hidratacion-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5537",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-aromatico-15-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5538",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/circuito-spa-cena-degustacion-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5539",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/circuito-hidroterapia-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5540",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/circuito-sensaciones-grupo6px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5541",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/circuito-alegria-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5542",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/hidratacion-corporal-firmas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5543",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/masaje-piernas-cansadas-vendas-frias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5544",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/smb-spa-cava-o-coctel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5545",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/deco-medio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5546",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/aladinia-masaje-de-25-minutos-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5547",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/aladinia-masaje-de-25-minutos-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5548",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/depilacion-con-diodo-zona-pequena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5549",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cura-envoltura-de-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5550",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/depilacion-con-diodo-zona-grande/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5551",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/bono-4-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5552",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/kit-romantico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5553",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/programa-ekilum-antiestres-7-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5554",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/body-care-sandos-papagayo-spa-wellness/promocion-black-friday-body-care-uli-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5555",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/ritual-oasis-de-serenidad-i-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5556",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/ayurveda-en-equilibrio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5557",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/romantico-relax-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5558",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/pedicura-con-masaje-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5559",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/masaje-oriental-55-min-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5560",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/facial-personalizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5561",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/esmaltado-permanente-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5562",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/masaje-deportivo-90min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5563",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-excusivity-completo-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5564",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/pure-bliss-ritual-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5565",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/feliz-dia-mama-ritual-magico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5566",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-ritual-de-juventud-kobido-ritual-del-mundo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5567",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/depilacion-de-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5568",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/ocean-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5569",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/tratamiento-hifu-piernas-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5570",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/masaje-drenante-de-pies-y-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5571",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/lavar-y-secar-pelo-muy-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5572",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/manicura-permanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5573",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/pinda-sveda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5574",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-shiatsu-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5575",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/moldeador-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5576",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/tinte-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5577",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/experiencia-mediterranea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5578",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-drenante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5579",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/lifting-facial-japones-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5580",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/circuito-navidad-alojado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5581",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/musg-belleza-y-agua/especial-mama-mimalaun-poco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5582",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-enero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5583",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/udvartana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5584",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/castellon/balneario-de-villavieja/escapada-2-noches-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5585",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/lomi-lomi-nui/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5586",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-empaquetado-europlayas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5587",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-sueco-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5588",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/bioterapia-piernas-perfectas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5589",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/san-valentin-masaje-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5590",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/osteopatia-fascioterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5591",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/programa-belleza-integral/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5592",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/oferta-3-peeling-fac-m-c-f/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5593",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/promocion-especial-dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5594",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-nucia-spa-masshotel/aromaterapia-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5595",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/dia-del-padre-masaje-cerveza-25-hidromiel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5596",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-exclusivity-de-cerveza-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5597",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/viaje-alqvimia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5598",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/spa-buffet-para-nino-de-miercoles-a-viernes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5599",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/tratamiento-facial-anti-acne/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5600",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/tratamiento-higiene-facial-by-thalgo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5601",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/ahuecador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5602",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/spa-peeling/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5603",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/spa-mizu-residentes-resort/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5604",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/exfoliacion-con-peeling-de-cerveza-masaje-hidratante-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5605",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/odin-exclusive/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5606",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-san-valentin-vip-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5607",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/facial-santuario-son-brull/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5608",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/promocion-invierno-en-bora-bora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5609",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/pedicura-spaxion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5610",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/palacio-albacete-spa/programa-belleza-y-bienestar-el-o-ella-1-dia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5611",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/programa-belleza-y-bienestar-el-o-ella-1-dia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5612",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/circuito-spa-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5613",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/promocion-especial-dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5614",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/corte-mujer/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5615",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/momento-spa-masaje-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5616",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/peeling-corporal-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5617",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/black-friday-circuito-termal-2-x-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5618",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/manicura-con-esmalte-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5619",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/masaje-espalda-y-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5620",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/gimnasio-spa-deloix-2-semanas-cliente-exterior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5621",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/ritual-de-la-felicidad-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5622",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/circuito-relax-y-masaje-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5623",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/bono-6-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5624",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/g-msj-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5625",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/depilacion-1-2-piernas-4/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5626",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/socio-trainer/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5627",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/mascarilla-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5628",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/tierra-viento-y-fuego/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5629",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/the-spa-by-signature-sir-victor/deep-tissue-massage-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5630",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/depilacion-labio-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5631",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/depilacion-1-2-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5632",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/pedicura-express-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5633",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/oriental-55-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5634",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/circuito-kid-6-15-anos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5635",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/masaje-relajante-de-espalda-aceite-de-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5636",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5637",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/pedicura-deluxe-francesa-by-opi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5638",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/febrero-romantico-25-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5639",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/masaje-bienestar-digital/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5640",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/bono-10-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5641",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/pack-antimanchas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5642",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/cumpleanos-feliz/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5643",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/sublime-experience-de-natura-bisse-100-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5644",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/sublime-experience-de-natura-bisse-100-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5645",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/energy-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5646",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/ingles-brasilenas-gluteos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5647",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/pedicura-con-esmalte-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5648",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-beer-spa-masaje-localizado-25min-sala-comun-pareja-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5649",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/depilacion-de-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5650",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/masaje-ayurvedico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5651",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/manicura-bodyna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5652",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/pack-luxury-love/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5653",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/chocolaterapia-imserso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5654",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/masaje-cervicodorsal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5655",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-spa-2-personas-oferta/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5656",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/masaje-relajante-con-bambu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5657",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/peeling-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5658",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/at-wellness-experiencia-matinal-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5659",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/masaje-tailandes-85min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5660",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/tratamiento-facial-especial-para-hombres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5661",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/tarjeta-regalo-business/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5662",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/especial-mar-muerto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5663",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/pack-bano-dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5664",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/circuito-spa-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5665",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/pack-delicia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5666",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/ayurveda-para-conocer/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5667",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/patillas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5668",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/momento-spa-masaje-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5669",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/suplemento-empaquetado-europlayas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5670",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/hard-rock-spa-marbella/especial-mama-radiant-glow/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5671",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/circuito-real-de-la-alhambra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5672",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-sueco-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5673",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/masaje-con-piedras-calientes-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5674",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-de-felicidad-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5675",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/tratamiento-reductor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5676",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/body-peeling-silk-skin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5677",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/dulce-relax-2-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5678",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/esmaltado-de-unas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5679",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/ritual-gold-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5680",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/especial-dia-del-padre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5681",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-cortijo/momento-spa-estancia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5682",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/masaje-especial-ayurveda-abhyanga-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5683",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/mascarilla-peeling-upselling/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5684",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-attica21-vigo/experiencia-spa-con-terapia-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5685",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/tratamiento-facial-profundo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5686",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/pro-love-secret/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5687",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-spacena-paquetes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5688",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-facial-hidratante-balines-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5689",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/1-dia-en-spa-diamante-con-masaje-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5690",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/pack-especial-mama-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5691",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cantabria/spa-castilla-termal-solares/terapia-geotermal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5692",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/peeling-de-jengibre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5693",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/masaje-con-aromaterapia-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5694",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/masaje-apacible-paraiso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5695",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/drain-body-bandages/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5696",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-oriental-relajante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5697",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/oferta-4-para-el-o-para-ella/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5698",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/especial-embarazadas-masaje-pre-natal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5699",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/palacio-albacete-spa/especial-embarazadas-masaje-pre-natal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5700",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/circuito-spa-nino-4-14-anos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5701",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/circuito-spa-menores-4-17-anos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5702",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/citrus-vita-essence/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5703",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/masaje-luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5704",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/burgos/spa-tudanca/especial-mama-tratmiento-skin-sensations/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5705",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/tratamiento-facial-kobido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5706",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/detox-40-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5707",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/depilacion-con-diodo-zona-grande/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5708",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-citricos-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5709",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/aromaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5710",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cortar-barba/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5711",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5712",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-flor-de-cerezo-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5713",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/suplemento-empaquetado-europlayas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5714",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/lomi-lomi-nui-70min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5715",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-20min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5716",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/inner-flow/bliss-bundles-days-spa-flotarium-fisioterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5717",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/ritual-vinoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5718",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/tratamiento-mare-nostrum-circuito-1-peeling-2-masajes-3-tratamientos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5719",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/envoltura-de-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5720",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/laura-leon-wellness/spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5721",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/aragon-hills-hotel/wellness-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5722",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/centro-bienestar-sunprime-atlantic/spa-in-sunprime-atlantic-view/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5723",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/masaje-deportivo-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5724",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/la-vida-es-bella-spaalmuerzo-cena-2-personas-7990e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5725",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-esencia-naturales-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5726",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/thalgo-source-marine/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5727",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/momento-spa-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5728",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/serenity-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5729",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-deportivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5730",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/nyxpert-the-royal-fitness-spa/date-un-capricho-acceso-al-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5731",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/spa-pedicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5732",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-attica21-vigo/circuito-spa-fin-de-ano-menores-de-12-anos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5733",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/tratamiento-reparador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5734",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-balines/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5735",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-relajante-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5736",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/bono-4-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5737",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/exfoliacion-corporal-completa-envoltura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5738",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-premium-en-sala-privada-pareja-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5739",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/extra-masaje-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5740",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/corte-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5741",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/masaje-muscular-profundo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5742",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/2x1-circuito-hidrotermal-masaje-relajante-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5743",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/socio-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5744",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/manicuralacado-fantasia-nina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5745",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/detox/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5746",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa-masaje-menu-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5747",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-aromatico-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5748",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-we-love-beer-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5749",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/depilacion-con-diodo-cuerpo-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5750",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/ritual-hydracure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5751",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-25min-chocolate-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5752",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/bono-10-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5753",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/navidad-spa-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5754",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/foot-reflexology-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5755",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5756",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/spa-masaje-sensaciones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5757",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/circuito-spa-miercoles-2-adultos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5758",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/circuito-hidrotermal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5759",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/depilacion-bikini-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5760",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-pies-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5761",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/masaje-futura-mama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5762",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/ritual-armonia-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5763",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/tratamiento-hidratacion-profunda-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5764",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/masaje-completo-fisio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5765",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-kumaras/arhat-jacuzzi-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5766",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/semipermanente-con-manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5767",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/masaje-facial-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5768",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/circuito-spa-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5769",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/depilacion-1-2-piernas-5/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5770",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-aqua-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5771",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/depilacion-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5772",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/especial-te-quiero-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5773",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/deluxe-day-sensations-day-pass-tu-dia-completo-con-masaje-moet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5774",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/tratamiento-ritual-sublime-cinco-flores-de-bali-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5775",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/masaje-descontracturante-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5776",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-relajante-con-aceite-de-naranja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5777",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/cura-adelgazante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5778",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-pureza-facial-purity-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5779",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/pack-luxury-love/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5780",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/depilacion-brazos-espalda-o-pecho/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5781",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/ritual-power-to-myself/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5782",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/aquatonic/promocion-black-friday-aquatonic-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5783",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/masaje-bajo-ducha-vichy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5784",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/ayurveda-abhyanga-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5785",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/reactiva-y-tonifica-tus-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5786",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/sivana-holistic-spa-augusta-sanxenxo/circuito-termal-1h30min-buffet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5787",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/circuito-termal-1h30min-buffet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5788",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/entrada-termal-2h/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5789",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/cerezaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5790",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/facial-lifting-3d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5791",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/experiencia-relax-masaje-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5792",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/dulce-cumpleanos-fiesta-peeling-facial-recepcion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5793",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/piedras-calientes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5794",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/tratamiento-calmante-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5795",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/masaje-luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5796",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-iberostar-las-gaviotas/deep-tissue-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5797",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/tratamiento-antiedad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5798",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/gran-hotel-spa-lakua/circuito-spa-60-quiromasaje-40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5799",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/aladinia-masaje-de-50-minutos-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5800",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/aladinia-masaje-de-50-minutos-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5801",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/circuito-spa-adulto-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5802",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/circuito-spa-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5803",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/exfoliacion-corporal-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5804",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/tratamiento-facial-antimanchas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5805",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/pedicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5806",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/relax-choco-en-pareja-circuito-spa-chocolaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5807",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/circuito-spa-oferta-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5808",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/depilacion-de-piernas-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5809",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/drain-body-bandages/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5810",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/terapia-de-sueno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5811",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/acceso-spa-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5812",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/acceso-spa-30-gratuitos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5813",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hspa-valparaiso/acceso-circuito-spa-2hrs-comida-viernes-a-domingo-y-festivos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5814",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/especial-black-friday-piscina-puesta-en-forma/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5815",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-exclusivity-parcial-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5816",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cura-masaje-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5817",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/facial-so-delicate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5818",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/spa-deluxe-edicion-navidad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5819",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/acceso-wellness-myevjf-50-privatizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5820",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/hamman-clasico-andaluz-70min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5821",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/ritual-garoe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5822",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/ritual-brisa-de-ganesha-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5823",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/matizar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5824",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5825",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/fitness/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5826",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/beauty-party-fun-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5827",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/promocion-especial-enamorados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5828",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/pro-masaje-esencial-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5829",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/facial-hidratante-basico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5830",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/asturias/spa-nature/junio-retiro-de-salud-y-bienestar-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5831",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/masaje-shi-zen-relax-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5832",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-masaje-de-aromaterapia-30-masajes-energias-de-la-tierra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5833",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/bono-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5834",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/relax-choco-en-pareja-circuito-spa-chocolaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5835",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/segovia/spa-spaxion-convento-capuchinos/wellness-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5836",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/depilacion-laser-pequena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5837",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/escapada-stop-stress/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5838",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/energy-c-masaje-circuito-de-agua-120/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5839",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-palma/spa-la-palma-teneguia-princess/princess-masaje-anti-estres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5840",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-buffet-restaurante-entre-semana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5841",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-empaquetado-europlayas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5842",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/msje-descon-duo-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5843",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/viaje-ruta-a-la-de-las-delicias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5844",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/al-spa-con-masaje-cava-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5845",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/bambuterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5846",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/pure-fresh/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5847",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/web-entrada-matinal-spa-60-exclusivo-web/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5848",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/piernas-enteras-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5849",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/02-relax-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5850",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/palacio-albacete-spa/masaje-con-aceites-calientes-bio-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5851",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/otono-ritual-facial-peeling-marine/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5852",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/deep-tissue-massage-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5853",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/black-friday-2x1-masaje-30-entrada-wellness/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5854",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/ritual-corporal-tahara/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5855",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/pelo-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5856",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/atrapalo-masaje-de-25-minutos-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5857",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/atrapalo-masaje-de-25-minutos-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5858",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/cavitacion-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5859",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-cerler/momento-spa-masaje-6/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5860",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/vital-eyes-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5861",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/maquillaje-fiesta-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5862",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/entrada-abonado-sport/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5863",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/depilacion-de-cejas-con-pinzas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5864",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/depilacion-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5865",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/facial-skin-light/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5866",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/masaje-hindu-de-cabeza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5867",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/especial-black-friday-circuito-spa-adultos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5868",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/hidra-marine/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5869",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/gran-ritual-magreb/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5870",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/deep-tissue-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5871",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/experiencia-relax-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5872",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/botella-de-cava-grande/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5873",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/masaje-profundo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5874",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/masaje-corporal-bienestar-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5875",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/ritual-detox-exfoliacion-hidratacion-60min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5876",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/masaje-samay-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5877",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/spa-hotel-galatea/masaje-relajante-60-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5878",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/color-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5879",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/circuito-arabe-2-pax-4790e-smartbox/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5880",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/masaje-15-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5881",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/cena-1px-europlayas-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5882",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/experiencia-vitality/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5883",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/promocion-circuito-alegria-servicio-de-te-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5884",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/momento-spa-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5885",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/manicura-esmalte/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5886",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/facial-higiene-masaje-de-craneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5887",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5888",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/lomi-lomi-nui/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5889",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/tratamiento-antimanchas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5890",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/bio-hidratacion-profunda-50-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5891",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5892",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/silentia-spa-wellness/acceso-residentes-silentia-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5893",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/gym/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5894",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/propina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5895",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/lodo-termal-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5896",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-blancafort-antiestres-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5897",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/masaje-total-relajacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5898",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/tratamiento-corporal-anticelulitico-reductor-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5899",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/gimnasio-spa-deloix-3-semanas-cliente-exterior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5900",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/masaje-anti-estres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5901",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/bono-mensual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5902",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/envoltura-corporal-de-cereza-con-masaje-de-pies-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5903",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-anticelulitico-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5904",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hspa-valparaiso/acceso-circuito-spa-2hrs-brunch-lunes-a-jueves-festivos-no-incluidos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5905",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-after-sun/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5906",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-spa-on-top/city-relax-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5907",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/facial-dermoabrasion-spaxion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5908",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5909",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/acceso-spa-naturista-90-1-copa-cava/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5910",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/acceso-spa-90-2-copas-cava-no-socio-naturista-2-copas-de-cava/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5911",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/acceso-spa-entrada-naturista-no-socio-circuito-90-y-2-copas-de-cava/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5912",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/pack-navidad-glow-up-circuito-spa-tratamiento-lifting-sicilium-marin-pack-regalo-pack-para-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5913",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/envoltura-de-picota-roja-o-arcilla-marron/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5914",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/san-valentin-en-be-aloe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5915",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/momento-spa-masaje-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5916",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5917",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/depilacion-piernas-enteras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5918",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/correccion-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5919",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/tratamiento-facial-colageno-marino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5920",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/ritual-piel-perfecta/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5921",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-y-envoltura-de-oro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5922",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/personalizado-masaje-ayurvedico-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5923",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/suite-viaje-oriental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5924",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/aquatonic/aquatonic-argan/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5925",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/masaje-25min-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5926",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/envoltura-anticelulitica-especial-algas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5927",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/masaje-apacible-paraiso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5928",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/man-glam-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5929",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-25min-chocolate-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5930",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/masaje-de-espalda-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5931",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/ingles-brasilenas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5932",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/bano-de-vino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5933",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-experiencia-duo-50-separados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5934",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/depilacion-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5935",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/navidad-en-familia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5936",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-de-cerveza-degustacion-de-1-cerveza-especial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5937",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/neceser-basic-xl/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5938",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-paradisus/mimos-para-mama-spa-privado-y-coctel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5939",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/reflexologia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5940",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/depilacion-ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5941",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/oasis-natura-bisse/oasis-de-serenidad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5942",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/aromasoul-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5943",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-10-min-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5944",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/masaje-integral-o-personalizado-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5945",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/circuito-alegria-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5946",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/aladinia-circuitos-2-personas-4990e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5947",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-flor-de-cerezo-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5948",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5949",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/tratamiento-calmante-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5950",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/cavitation/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5951",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/pack-acido-hialuronico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5952",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/refugio-romantico-115-min-en-pareja-con-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5953",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/suplemento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5954",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/spa-water-circuit-50-minutes-7/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5955",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/desayuno-buffet-incluido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5956",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/semipermanente-con-manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5957",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/relax-choco-en-pareja-circuito-spa-chocolaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5958",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/bono-10-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5959",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-armonia-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5960",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/promocion-primavera/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5961",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/piscina-puesta-en-forma/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5962",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/tinte-cejas-y-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5963",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/especial-mar-muerto-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5964",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/pro-especial-mar-muerto-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5965",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/esmaltado-permanente-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5966",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/depilacion-pecho-o-gluteos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5967",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/circuito-arabe-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5968",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/lavar-y-marcar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5969",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/piernas-enteras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5970",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/peeleng-imperial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5971",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/depilacion-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5972",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/prueba-de-maquillaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5973",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/circuito-spa-privado-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5974",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/fantasia-de-chocolate-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5975",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/peeling-corporal-de-coco-hidratacion-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5976",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5977",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/manicura-deluxe-manos-celestiales/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5978",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/upselling-mascarilla-iroha/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5979",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/tratamiento-facial-sensibilidad-y-calmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5980",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/masaje-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5981",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-sueco-80min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5982",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/masaje-circulatorio-de-piernas-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5983",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/pack-goloso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5984",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/tratamiento-facial-antiedad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5985",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-de-la-felicidad-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5986",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/circuito-aqua-center-25min-cena-2pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5987",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/facial-rejuvenecimiento-spaxion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5988",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/circuito-spa-2x1-2-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5989",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spacio-telde/circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5990",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/black-friday-circuito-spa-60-minalmuerzo-cena-a-elegir1-bebida-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5991",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/circuito-vitality-alojados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5992",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/retirar-unas-semi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5993",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/limado-y-esmalte-de-unas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5994",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/princess-hands/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5995",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/ritual-sensationsacceso-termal-60min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5996",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/secretos-del-mar-para-2-personas-con-masaje-con-aromaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5997",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/facial-especial-hombres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5998",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/suplemento-empaquetado-europlayas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 5999",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/alquiler-banador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6000",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/masaje-bio-reflexologia-40-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6001",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/cocoon-spa-lazure/promocion-black-friday-spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6002",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/depilacion-de-axilas-o-ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6003",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/recogido-de-novia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6004",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-pureza-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6005",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/exfoliacion-de-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6006",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-brisa-de-ganesha/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6007",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/body-care-uli-massage-peeling/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6008",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/bono-5-sesiones-ep/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6009",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/circuito-hidrotermal-invitacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6010",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/depilacion-con-diodo-zona-pequena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6011",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/facial-reafirmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6012",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lisboa/azure-wellness-spa/get-ready-for-summer-100-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6013",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/las-palmas/siam-spa-bohemia-suites/comida/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6014",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/true-love-circuito-spa-san-valentin-con-cava-y-bombones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6015",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/experiencia-mediterranea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6016",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/facial-anti-edad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6017",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6018",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/circuito-termal-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6019",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/renueva-tu-piel-este-verano/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6020",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/masaje-de-aromaterapia-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6021",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-day-deluxe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6022",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/tarjeta-regalo-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6023",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/masaje-a-4-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6024",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/promocion-especial-dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6025",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/natura-bisse-exfoliacion-diamond-rose-80-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6026",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/natura-bisse-exfoliacion-diamond-rose-80-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6027",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/masaje-local-25min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6028",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/tinte-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6029",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/piernas-enteras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6030",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-aromaterapia-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6031",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-balines/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6032",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/pedicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6033",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/especial-san-valentin-ternura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6034",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-descontracturante-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6035",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/mini-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6036",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6037",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/pack-spa-enamorados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6038",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/bano-de-cuencos-tibetanos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6039",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/2x1-circuito-spa-adultos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6040",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-la-gavina/ritual-corporal-valmont-purete-des-bisses/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6041",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/vila-real/natureness-spa/spa-180-1-adulto-1-crianca/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6042",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/especial-mar-muerto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6043",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/masaje-balines-envolvente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6044",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/beauty-face-retinol/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6045",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-de-cerveza-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6046",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacion-piernas-enteras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6047",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/masaje-sunset-en-egipto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6048",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-shi-zen-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6049",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/paquete-thalasso-especial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6050",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/circuito-wellness/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6051",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/depilacion-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6052",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-muscular-profundo-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6053",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/depilacion-de-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6054",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-15-min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6055",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/gimnasiospa-deloix-2-semanas-cliente-camping-raco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6056",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/romantic-massage-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6057",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/spa-gastronomia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6058",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/calma-spa/bienestar-total-para-mama-ritual-de-aceite-y-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6059",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/depilacion-pecho-o-abdomen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6060",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-armonia-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6061",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-naturae/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6062",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/radiofrecuencia-manos-magicas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6063",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-masmonzon/masaje-facial-marmas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6064",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/masaje-cacao-sensitive/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6065",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/pro-sueco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6066",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/futura-mama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6067",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spacio-telde/higiene-facial-tratamiento-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6068",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/termes-de-montbrio/spa-termal-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6069",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6070",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/solar-supreme/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6071",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/ritual-timexpert-srns/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6072",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/lodo-integral-plus/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6073",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/cata-de-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6074",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/tratamiento-capilar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6075",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/parafango/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6076",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-beer-spa-residentes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6077",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/masaje-especial-pre-mama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6078",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/promocion-agosto-envoltura-de-fango-negro-hidratacion-y-mascarilla/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6079",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-attica21-vigo/masaje-craneofacial-15min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6080",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/higueron-spa/masaje-prana-relax-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6081",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6082",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/piscina-hidrotermal-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6083",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/experiencia-relax-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6084",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/diferencia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6085",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/masaje-tui-na/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6086",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/semipermanente-con-pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6087",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/retirar-unas-semi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6088",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/lomi-lomi-nui-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6089",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/masaje-relajante-45-min-circuito-hidrotermal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6090",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/programa-leon-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6091",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/facial-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6092",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/relax-circuito-wellness-masaje-oriental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6093",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-spaxion-hg-la-molina/momento-spa-masaje-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6094",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/circuito-deloix-agencia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6095",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/masaje-10min-cajitas-y-cupones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6096",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/energizer-40-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6097",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/tratamiento-hidratacion-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6098",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/oporto/the-spa-by-sheraton-porto/tratamiento-spa-toscana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6099",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/lifting-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6100",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/canas-de-bambu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6101",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/cavitacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6102",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/lipo-choco-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6103",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/ritual-sensorial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6104",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-privado-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6105",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/masaje-25-promo-flash/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6106",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/at-msj-30-l-j-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6107",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/family-kids-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6108",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/momento-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6109",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/bono-10-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6110",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/circuito-hidrotermal-masaje-zonal-con-aceites-aromaticos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6111",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/cena-1px-europlayas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6112",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/sauna-o-jacuzzi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6113",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/masaje-bioenergetic/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6114",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/experiencia-spa-huespedes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6115",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/desinfeccion-de-cabina-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6116",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/dia-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6117",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/masaje-ayurveda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6118",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/tinte/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6119",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-beer-spa-grupo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6120",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/tratamiento-luminoso-ritual-flores-de-bali/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6121",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/exfoliante-exotico-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6122",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-completo-con-aromaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6123",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/masaje-40-infantil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6124",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/pack-graduacion-para-estudiantes-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6125",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/3-aadd/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6126",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-piscina-spa-4h-acceso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6127",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/tratamiento-facial-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6128",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/pack-cerveza-verde/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6129",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/programa-beauty-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6130",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/bono-10-sesiones-masaje-completo-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6131",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/ayurveda-en-equilibrio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6132",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/tinte-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6133",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/foot-reflex/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6134",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-blancafort-antiestres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6135",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/especifico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6136",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-exclusivity-completo-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6137",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/masaje-reductor-o-reafirmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6138",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/pro-top-care/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6139",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/kit-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6140",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/pack-navidad-relax-pareja-circuito-spa-ritual-gold-theraphy-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6141",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/black-beer-burbujeante-circuito-sala-comun-regalos-para-dos-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6142",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/cura-lifting-y-antievejecimiento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6143",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/gimnasio-3-semanas-cliente-camping-raco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6144",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-energy-tea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6145",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/masaje-relajante-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6146",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/masaje-de-chocolate-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6147",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/neceser-relax-xl/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6148",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/santuari-urban-spa/especial-black-friday-2x1-masaje-relajante-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6149",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/envolvimiento-criogenico-especial-piernas-ligeras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6150",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-10-min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6151",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/pro-especial-mexico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6152",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/masaje-de-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6153",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-de-pindas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6154",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/depilacion-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6155",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/correccion-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6156",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/spa-en-familia-2x1-mas-20-descuento-en-tratamientos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6157",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/pack-navidad-super-vikingo-circuito-vip-para-dos-personas-pack-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6158",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/sunset-lover/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6159",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/hesperides-thalasso-spa/ritual-diamond-rose-circuito-thalasso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6160",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/relaxing-massage-3-lavanders-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6161",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/circuito-spa-adulto-2x1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6162",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/dakotabox-circuitos-2-personas-3990e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6163",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/3d-collagen-shock-de-natura-bisse-80-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6164",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/3d-collagen-shock-de-natura-bisse-80-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6165",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/radiofrecuencia-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6166",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/promocion-especial-dia-de-la-madre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6167",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/gastro-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6168",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/ritual-de-juventud-ko-bi-do/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6169",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/higiene-facial-20min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6170",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6171",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6172",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/masaje-dulce-relax-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6173",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-de-cerveza-degustacion-de-1-cerveza-especial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6174",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/terapia-de-algas-peeling-envoltura-algas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6175",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/suplemento-rituals/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6176",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/viaje-a-la-provenza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6177",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/recogido-fiesta/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6178",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/laura-leon-wellness/luxury-aromaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6179",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacion-espalda-y-pecho/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6180",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/secador-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6181",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/ritual-futura-mama-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6182",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-corporal-50-min-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6183",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/hotel-neptuno-spa/oferta-de-lanzamiento-spa-y-masaje-tailandes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6184",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/momento-spa-masaje-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6185",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa-menu-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6186",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/pack-acido-hialuronico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6187",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-la-manga-club/shiro-abhyanga/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6188",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-flor-de-cerezo-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6189",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/deep-tissue/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6190",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-exclusivity-parcial-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6191",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/oferta-junio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6192",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/deluxe-day-relax-day-pass-tu-dia-completo-con-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6193",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/clase-de-yoga-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6194",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/hydracure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6195",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/black-friday-2x1-masaje-80-entrada-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6196",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-energy-tea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6197",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/pack-romantic-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6198",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/masaje-lomi-lomi-hawai-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6199",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-coctel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6200",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/bono-relax-gin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6201",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spacio-telde/pack-circuito-termal-masaje-30min-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6202",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/masaje-relajante-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6203",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/cueva-de-sal-del-himalaya-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6204",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/presoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6205",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/pedicura-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6206",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/al-masaje-50-pack-desayuno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6207",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/al-masaje-50-desayuno-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6208",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/momento-spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6209",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/spa-en-familia-2x1-mas-20-descuento-en-tratamientos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6210",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/espacio-acuatico-ritual-indian-natural/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6211",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/tratamiento-precioso-ko-bi-do-anti-edad-global/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6212",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/peeling-envoltura-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6213",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/sesiones-de-fisioterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6214",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/circuito-ocean-grupos-6px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6215",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/decoracion-fantasia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6216",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-spa-oferta/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6217",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/masaje-termal-business/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6218",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/treats-relax-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6219",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/gran-ritual-del-viajero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6220",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-exclusivity-full-body-75/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6221",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/circuito-spa-huespedes-cordial-bono-4-1-entrada/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6222",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/masaje-antiestres-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6223",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/laura-leon-wellness/domingos-de-spa-y-cocktail/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6224",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/tratamiento-reductor-reafirmante-localizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6225",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-roca-negra/promocion-black-friday-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6226",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/tenacillas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6227",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/earth-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6228",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/especial-pareja-piscina-de-tratamientos-masaje-de-15-min-servicio-de-te-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6229",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/pack-navidad-glow-up-circuito-spa-tratamiento-lifting-sicilium-marin-pack-regalo-pack-para-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6230",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/atardecer-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6231",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-masaje-50-l-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6232",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-masaje-50-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6233",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-palma/spa-la-palma-teneguia-princess/princess-masaje-cuerpo-entero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6234",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/especial-pareja-piscina-de-tratamientos-masaje-de-55-min-servicio-de-te-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6235",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/evasion-romantica-for-two/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6236",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/air-bio-spa-privado-masaje-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6237",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/tratamiento-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6238",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/oriental-55-min-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6239",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/yoga/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6240",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/relax-andalusi-en-pareja-circuito-spa-ritual-al-andalus/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6241",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hspa-valparaiso/seawave/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6242",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/masaje-deportivo-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6243",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-chesse-wine/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6244",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/vendas-frias-con-masaje-circulatorio-de-piernas-y-pies-masaje-craneofacial-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6245",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/spa-buffet-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6246",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/circuito-spa-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6247",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/masaje-anticelulitico-circulatorio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6248",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/masaje-de-espalda-2paxeliminado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6249",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/jacuzzi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6250",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6251",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/spa-oferta-wellness-incluido-habitacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6252",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-armonia-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6253",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/circuito-termal-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6254",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/facial-aloe-vera-for-sensitive-skin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6255",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/pack-romantic-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6256",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/reflexologia-podal-60-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6257",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/black-beer-vip-circuito-sala-privada-masaje-15-mnts-regalos-para-dos-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6258",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/especial-pareja-circuito-playa-granada-masaje-de-25-min-servicio-de-te-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6259",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/wellness-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6260",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/chocolat-passion-spa-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6261",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/twin-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6262",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/xmas-glow-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6263",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/xmas-glow-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6264",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/piensa-en-ti-masaje-40-min-2-pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6265",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/oxigenacion-e-hidratacion-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6266",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/radiance-skin-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6267",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-spa-50-mensual-16-dias-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6268",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-spa-75-mensual-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6269",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/gastrospa-masaje-antiestres-30-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6270",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-deluxe-30-minutos-masaje-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6271",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/ritual-armonia-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6272",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/calma-spa/spa-para-mama-dia-especial-juntos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6273",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/bono-10-sesiones-ep/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6274",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-relajante-con-aceite-de-cereza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6275",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/bronceado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6276",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-armonia-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6277",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-holistico-con-aceite-de-cerveza-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6278",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/pack-acido-hialuronico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6279",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/bioterapia-corporal-luz-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6280",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/comida-personal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6281",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6282",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/lomi-lomi-nui-70min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6283",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/tarjeta-regalo-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6284",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/santa-cruz-de-tenerife/spa-callao-sport/spa-de-noche/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6285",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/ritual-plan-b/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6286",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-20-minutos-viernes-2-adultos-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6287",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/navidad-ritual-renueva-tu-energia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6288",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-pureza-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6289",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-de-chocolate-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6290",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-brisa-de-ganesha/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6291",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/bora-bora-breath/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6292",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/calma-spa/promocion-black-friday-circuito-spa-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6293",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-spa-cliente-alojado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6294",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/m-spa-catedral-mercer/promocion-spa-mojito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6295",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/bioterapia-sistemica-facial-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6296",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/programa-relax-deluxe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6297",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cantabria/spa-castilla-termal-solares/solares/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6298",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/holistic-experience-60-min-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6299",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/tratamientos-faciales-oxigenante-o-calmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6300",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/viaje-al-eden-tropical-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6301",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/peeling-alumbre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6302",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/masaje-especial-mexico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6303",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hspa-valparaiso/noche-romantica-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6304",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/radiofrecuencia-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6305",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6306",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/labio-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6307",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/masaje-abhyanga/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6308",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/parafina-para-manos-o-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6309",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/oferta-7-objetivo-silueta/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6310",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/cura-especial-deportistas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6311",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/exfoliacion-corporal-completa-envoltura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6312",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-beauty-silver-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6313",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/depilacion-cejas-y-labio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6314",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/terapeutico-deportivo-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6315",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/exfoliaciones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6316",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/pack-relax-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6317",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-citricos-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6318",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/ritual-especial-busto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6319",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/centro-bienestar-sunprime-atlantic/masaje-sueco-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6320",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/dia-de-la-madre-ritual-piel-en-calma/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6321",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cantabria/balneario-la-hermida/momento-termal-circuito-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6322",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/ritual-reina-de-egipto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6323",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/mimos-de-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6324",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-spa-privado-con-cava-y-bombones-60min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6325",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/depilacion-con-diodo-zona-grande/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6326",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/mision-banador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6327",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/nutre-y-cuida-tu-piel-para-la-nueva-estacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6328",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/exfoliacion-completa-con-guante-kessa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6329",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/exfoliacion-aromatica-energizante-con-especias-raras-y-preciosas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6330",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/masaje-indonesio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6331",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/circuito-real-grupo-6px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6332",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/masaje-terapeutico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6333",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/rejuvenate-alqvimia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6334",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/manicura-bodyna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6335",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/esmaltado-permanente-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6336",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/masaje-drenaje-linfatico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6337",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/experiencia-relax-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6338",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/circuito-termal-pase-especial-2-horas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6339",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/tinte-de-pelo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6340",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/masaje-dia-del-padre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6341",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/circuito-spa-buffet-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6342",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/cheese-and-wine-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6343",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/acceso-piscina-climatizada/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6344",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/pedicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6345",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/spa-entrance-hotel-guest/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6346",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/bano-burbujas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6347",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/cura-reconfortante-para-trastornos-lifo-sanguineos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6348",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/bano-niagara/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6349",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/masaje-relajante-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6350",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/manicura-permanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6351",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/silk-skin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6352",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-spaxion-hg-la-molina/caprichos-de-la-naturaleza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6353",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-descontracturante-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6354",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/tratamiento-alisado-keratina-medio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6355",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/azores/musgo-spa/orientais-champi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6356",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madeira/aqua-natura-bay-sen-spa/massagem-de-laranja-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6357",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/cena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6358",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-craneofacial-y-cervical/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6359",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/corte-secado-senora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6360",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/pro-bright-day/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6361",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/exfoliaciones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6362",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/programa-antiestres-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6363",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-de-chocolate-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6364",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/masaje-descontracturante-localizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6365",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/extra-relaxing-love-circuito-beer-spa-pareja-masaje-25-minutos-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6366",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/masaje-corporal-bienestar-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6367",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/facial-desafio-a-la-edad-de-la-sultane-de-sabia-con-23k-oro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6368",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/vestuario-check-out/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6369",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/circuito-san-valentin-vip-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6370",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/bano-de-hidromasaje-con-aromaterapia-masaje-de-espalda-circuito-spa-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6371",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/depilacion-ingles-o-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6372",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6373",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/desayuno-con-diamantes-gold-edition/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6374",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-anti-dolor-dia-1-masaje-antidolor-ultarasonidos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6375",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-anti-dolor-dia-1-envoltura-lodo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6376",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/ritual-de-siam/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6377",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/ritual-bangalore-india/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6378",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/masaje-a-la-brasa-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6379",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-parcial-terapeutico-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6380",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/piscina-hidrotermal-coctel-atrapalo-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6381",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/pack-hydr-oxigen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6382",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spalopia-spa-brunch-no3/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6383",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/experiencia-sun-repair-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6384",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/depilacion-piernas-medias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6385",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/palacio-albacete-spa/masaje-antiestres-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6386",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/presoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6387",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/love-experience-facial-80min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6388",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/limpieza-facial-especial-caballeros/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6389",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/estancia-desayuno-cena-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6390",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/facial-radiante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6391",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/menu-arrocero-minimo-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6392",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/menu-arrocero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6393",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-holistico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6394",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/ritual-de-exfoliacion-de-la-sultana-de-saba/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6395",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/sensations-day-relax-day-pass-x-2-tu-dia-especial-con-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6396",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/tratamiento-facial-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6397",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/masaje-deep-tisue/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6398",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/wellness-day-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6399",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-masaje-50-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6400",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/depilacion-ingles-brasilenas-gluteos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6401",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-de-cerveza-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6402",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-20-minutos-viernes-2-adultos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6403",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/especial-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6404",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/tinte-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6405",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-de-lunes-a-jueves-festivos-no-incluidos-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6406",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/madre-e-hija-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6407",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-tropical-de-verano-de-espalda-piernas-y-pies-circuito-spa-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6408",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/tratamiento-prenatal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6409",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/pack-navidad-relax-pareja-circuito-spa-ritual-gold-theraphy-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6410",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-antiestres-dia-2-lomi-lomi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6411",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-lomi-lomi-hawai-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6412",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/couple-cocoon-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6413",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/pedicura-vaho/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6414",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-10-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6415",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/depilacion-pecho-o-abdomen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6416",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/facial-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6417",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/masaje-para-ninos-menores-de-12-anos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6418",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/circuito-spa-promocion-okeymas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6419",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/especial-black-friday-drenaje-linfatico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6420",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/happy-mum/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6421",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/masaje-oriental-80min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6422",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/bono-4-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6423",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/age-stop/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6424",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/circuito-hidrotermal-masaje-relajante-con-aceites-aromaticos-de-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6425",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-sandos-monaco/pack-amor-de-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6426",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/masaje-tui-na/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6427",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/mood-spa/promo-noviembre-pre-holiday-power-up/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6428",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/oxigenacion-e-hidratacion-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6429",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/ritual-brisa-de-ganesha-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6430",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/especial-san-valentin-el-mejor-regalo-es-disfrutarlo-juntos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6431",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/propina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6432",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/aloe-aftersun-espalda-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6433",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/masaje-oriental-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6434",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-bono-rd-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6435",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/plancha-lisa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6436",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/santa-cruz-de-tenerife/spa-callao-sport/experiencia-spa-de-noche/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6437",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/azores/musgo-spa/musgo-bliss-90-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6438",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/bono-10-sesiones-ep/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6439",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/cura-especial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6440",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/peinado-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6441",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/experiencia-gold-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6442",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/pack-luna-de-miel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6443",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-spa-masaje-paquetes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6444",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-aguas-regalo-en-los-centros-sb/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6445",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/bono-10-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6446",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/tratamiento-detox/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6447",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/aqua-harmony-recepcion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6448",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/depilacion-de-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6449",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/pausa-15min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6450",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/dolce-sitges-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6451",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/dolce-sitges-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6452",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-vip-en-sala-privada-pareja-oferta-flash/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6453",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/special-black-friday-circuito-spa-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6454",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/ritual-sensaciones-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6455",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/estancia-desayuno-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6456",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-reductor-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6457",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/ritual-mediterranean/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6458",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/centro-bienestar-sunprime-atlantic/manicura-y-pedicura-clasica-beauty-time/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6459",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/masaje-25-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6460",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-spa-50-semanal-7-dias-alldays/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6461",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-spa-50-semanal-alldays/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6462",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/black-friday-2x1-masaje-30-entrada-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6463",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/peeling-envoltura-masaje-aloe-vera-circuito-spa-gratis/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6464",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/masaje-sunset-in-egypt/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6465",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/exfoliante-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6466",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/termes-de-montbrio/pindas-herbales-hidromasaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6467",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/peeling-corporal-de-canela-y-jengibre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6468",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/peeling/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6469",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-hotel-hyltor/masaje-relajante-20min-circuito-hidroterapia-50-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6470",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/masaje-de-cerveza-completo-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6471",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/ritual-radiance-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6472",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-spa-beach/relax-massage-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6473",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/bono-4-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6474",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/tratamiento-ocean-especial-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6475",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-10-min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6476",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-aromatico-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6477",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/facial-anti-rides/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6478",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-10-min-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6479",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/especial-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6480",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/tratamiento-facial-especial-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6481",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/the-cure-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6482",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/spa-masaje-45-buffet-masaje-consecutivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6483",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/ritual-pureza-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6484",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/pedicura-con-masaje-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6485",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/hb-msj-25-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6486",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/curas-masaje-anticelulitico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6487",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/masaje-piedras-calientes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6488",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/tarjeta-regalo-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6489",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-con-catering-de-tapas-frias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6490",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-muscular-profundo-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6491",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/san-valentin-masaje-30-y-spa-true-love/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6492",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/depilacion-ingles-brasilena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6493",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/circuito-spa-huespedes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6494",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/setubal/spa-do-monte/spa-para-1-adulto-1-crianca-5-a-11-anos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6495",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/christmas-pack-85/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6496",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-25-min-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6497",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/tratamiento-facial-especial-para-hombres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6498",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/circuito-masaje-parcial-relajante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6499",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/manicura-spaxion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6500",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/promocion-circuito-spa-masaje-de-25-min-servicio-de-te-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6501",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/programa-para-los-amantes-del-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6502",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/facial-oxigenante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6503",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/day-dream-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6504",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-aromaterapia-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6505",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/facial-bio-celular/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6506",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/masaje-luxury-collection-en-pareja-45min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6507",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/masaje-circulatorio-de-piernas-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6508",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/facial-purificante-marino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6509",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/tratamiento-facial-spa-in-cosmetics-touch-go/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6510",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/mas-deep-tissue/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6511",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/experiencia-relax-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6512",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/lemax-wellness-club-wellington/especial-mama-abrazos-de-sol/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6513",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-de-cerveza-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6514",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/chocolate-fusion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6515",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/masaje-craneo-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6516",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/tratamiento-corporal-peeling-envoltura-de-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6517",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/wine-oil-scrub/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6518",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-ritual-de-eva-1h-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6519",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/viaje-al-eden-tropical/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6520",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/circuito-termal-para-mayores-de-65-anos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6521",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-leo-deluxe/relax-choco-en-pareja-circuito-spa-chocolaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6522",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-10-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6523",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/energizer-40-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6524",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6525",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/arreglo-de-barba/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6526",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-55-min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6527",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/relax-andalusi-en-pareja-circuito-spa-ritual-al-andalus/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6528",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/facial-mesoterapia-virtual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6529",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/masaje-balines-35/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6530",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/facial-higiene/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6531",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/azores/musgo-spa/ritual-refugio-na-quinta/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6532",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/hb-desayuno-spa-con-masaje-l-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6533",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/pack-navidad-relax-pareja-circuito-spa-ritual-gold-theraphy-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6534",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/circuito-aqua-center-15mincena-2pax-domingo-a-viernes-115e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6535",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/alquiler-de-paddle-surf/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6536",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6537",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/febrero-romantico-spa-masaje-cava-cena-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6538",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/circuito-spa-experiencia-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6539",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/pack-navidad-glow-up-circuito-spa-tratamiento-lifting-sicilium-marin-pack-regalo-pack-para-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6540",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/masaje-aromaterapeutico-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6541",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/cuidado-parfaite-experience-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6542",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/cerezaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6543",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/circuito-spa-2-adultos-spa-circuit-2-adults/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6544",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/presoterapia-6-sesiones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6545",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-relajante-oriental-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6546",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/limpieza-facial-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6547",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/exfoliacion-con-peeling-cerveza-masaje-hidratante-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6548",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6549",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/armonia-de-masaje-a-cuatro-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6550",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/promocion-circuito-alegria-masaje-de-15-min-servicio-de-te-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6551",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/exfoliante-coco-y-azucar-moreno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6552",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/envolturas-detox-equilibrantes-e-hidratantes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6553",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/exfoliantes-exotico-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6554",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/kids-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6555",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/pack-navidad-esencias-circuito-spa-masaje-de-esencias-naturales-55-minutos-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6556",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/tejido-profundo-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6557",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/masaje-apacible-paraiso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6558",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/lipo-choco-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6559",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/at-masaje-30-experiencia-gastro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6560",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/viaje-magico-de-la-ruta-de-la-seda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6561",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/masaje-con-aromaterapia-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6562",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-10-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6563",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/renewed-energy-con-circuito-termal-lunes-a-jueves/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6564",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/programa-rejuvenecimiento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6565",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-slimming-dia-2-masaje-reductor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6566",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/tratamiento-pies-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6567",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/azores/musgo-spa/momento-a-dos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6568",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/entrenador-personal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6569",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/mechas-1-tono/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6570",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/masaje-de-cerveza-parcial-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6571",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-despacio-h10-benidorm/extra-higiene-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6572",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-attica21-vigo/sala-privada-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6573",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/sublime-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6574",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-la-gavina/masaje-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6575",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/medias-piernas-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6576",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/depilacion-ingles-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6577",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/vitamina-c-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6578",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/especial-papa-agua/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6579",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/depilacion-12-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6580",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/aloe-vera/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6581",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/jacuzzi-masaje-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6582",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/socio-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6583",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/bioterapia-sistemica-corporal-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6584",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/sesion-osteopatia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6585",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/radiofrecuencia-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6586",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/restante-brunch-maria-cobadonga/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6587",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/rebajas-20-2-entradas-dobles-acceso-spa-50-total-4-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6588",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/pack-acido-hialuronico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6589",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/tai-chi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6590",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/ritual-de-kioto-japon/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6591",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/presoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6592",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-y-envoltura-de-oro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6593",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/gold-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6594",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-aromatico-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6595",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hspa-valparaiso/notas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6596",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/spa-buffet-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6597",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/pro-magic-stones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6598",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/peeling-corporal-de-coco-hidratacion-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6599",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/circuito-integral-plus/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6600",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/prenatal-y-posnatal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6601",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/depilacion-piernas-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6602",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-palma/spa-la-palma-teneguia-princess/princess-tradicion-canaria/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6603",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/circuito-spa-adulto-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6604",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/masaje-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6605",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/bono-personal-5-entradas-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6606",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/circuito-wellness-ninos-interno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6607",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/pack-vital-prevention/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6608",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/promocion-especial-hosteleros/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6609",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/cortar-y-peinar-pelo-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6610",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/las-palmas/siam-spa-bohemia-suites/daypass-spa-privado-hamburguesa-angus-uso-de-piscinas-exteriores-para-dos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6611",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-antiestres-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6612",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/experiencia-aloe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6613",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/depilacion-bikini/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6614",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/masaje-ayurvedico-45/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6615",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/reflexologia-podal-45/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6616",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/terapia-de-golfistas-40-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6617",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/sesion-de-osteopatia-40-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6618",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/tejidos-profundos-deep-tissue/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6619",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/las-dunas-spa/masaje-deportivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6620",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/tratamiento-facial-anti-edad-40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6621",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/tratamiento-shanara-con-cuencos-tibetanos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6622",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/the-essential-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6623",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/hyalu-procollagene/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6624",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/termal-el-palacete/momento-spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6625",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/peeling-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6626",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/maquillaje-de-novia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6627",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/depilacion-hombre-pierna-completa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6628",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/bono-6-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6629",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-exclusivity-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6630",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6631",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/madre-hija/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6632",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-facial-tensor-e-iluminador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6633",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/socio-mensual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6634",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-spaxion-hg-la-molina/momento-spa-masaje-4/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6635",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/ritual-bienestar-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6636",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/masaje-subacuatico-manual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6637",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-25min-chocolate-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6638",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/champi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6639",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/circuito-playa-granada-alojados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6640",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/experciencias-para-compartir/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6641",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/tto-facial-expres-groupon/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6642",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/sueno-profundo-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6643",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/masaje-con-aromaterapia-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6644",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/tratamiento-kobido-precieuse/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6645",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/oriental-envoltura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6646",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/gran-hotel-spa-lakua/circuito-spa-60-hidratacion-facial-holistica-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6647",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/suite-oriental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6648",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/peeling-corporal-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6649",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/black-friday-2x1-masaje-80-entrada-wellness/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6650",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/acceso-spa-75-10-entradas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6651",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-acceso-spa-50-10-entradas-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6652",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/programa-oasis-de-serenidad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6653",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-jc1-sercotel-murcia/circuito-termal-bienestar-terapeutico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6654",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/circuito-spa-privado-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6655",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/spa-privado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6656",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/especial-navidad-beauty-spa-facial-flash-copa-cava-y-cofre-alqvimia-regalo-para-ti/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6657",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/criolipolisis/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6658",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/momento-spa-masaje-6/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6659",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/bono-4-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6660",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/ritual-de-zeus/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6661",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/corte-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6662",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/woman-haircut-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6663",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/corte-nino-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6664",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/lifting-rejuvenecedor-tensor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6665",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cantabria/spa-castilla-termal-solares/masaje-tailandes-tradicional/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6666",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/ritual-de-la-felicidad-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6667",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/circuito-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6668",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-romantic-luxury/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6669",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/maquillaje-de-fiesta/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6670",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/keratina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6671",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/keratina-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6672",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/momento-spa-ritual-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6673",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/bono-4-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6674",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-2-adultos-1-nino-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6675",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/bano-de-citricos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6676",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/entrada-despedida-de-soltera/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6677",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/wonderbox-circuitos-2-personas-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6678",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/m-spa-catedral-mercer/spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6679",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-15min-pareja-feb-2020/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6680",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/ritual-romantico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6681",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/exfoliacion-purificante-con-jabon-negro-beldi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6682",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/yogui-masaje-yoguico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6683",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-lomi-lomi-hawai-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6684",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/cordial-antiestres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6685",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/cura-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6686",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/masaje-futura-mama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6687",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/momento-spa-masaje-aromaterapia-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6688",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/ritual-kobido-emperatriz/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6689",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/soria/spa-castilla-termal-burgo-de-osma/ausente-4-h/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6690",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spalopia-spa-brunch-no2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6691",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/noches-de-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6692",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-exclusivity-parcial-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6693",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-de-5-masajes-80-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6694",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/envoltura-de-fango/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6695",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/escapada-romantica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6696",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-la-hacienda/relax-andalusi-en-pareja-circuito-spa-ritual-al-andalus/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6697",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/manicura-y-pedicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6698",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6699",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-timexpert-srns/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6700",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/envoltura-vitamina-c-firmness/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6701",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/momento-spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6702",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/radiofrecuencia-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6703",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/ritual-equilibrante-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6704",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/moldeador-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6705",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/depilacion-axilas-o-ingles-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6706",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/black-beer-vip-circuito-sala-privada-masaje-15-mnts-regalos-para-dos-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6707",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/masaje-de-craneo-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6708",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-luxury-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6709",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/experiencia-relax-85-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6710",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/bono-10-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6711",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/lavar-y-peinar-cabello-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6712",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-craneocervical-15/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6713",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-plaza-mercado/circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6714",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-anti-dolor-dia-1-masaje-terapeutico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6715",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-anti-dolor-dia-2-masaje-tailandes-reflexologia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6716",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-flor-de-cerezo-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6717",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/bono-6-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6718",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/exfoliantemasaje-luz-med-programa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6719",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/envoltura-de-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6720",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-la-manga-club/reflexologia-tailandesa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6721",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-pazo-los-escudos/for-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6722",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/peeling-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6723",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/oporto/the-spa-by-sheraton-porto/adolescentes-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6724",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-aromatico-15-minutos-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6725",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/raices-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6726",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/banos-romanos-1h-recepcioneliminadoeliminado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6727",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/depilacion-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6728",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/masaje-circulatorio-en-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6729",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/masaje-e-romantica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6730",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/chocoterapia-i-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6731",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/depilacion-ingles-hollywood/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6732",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/vip-wellness-privado-30-botella-cava/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6733",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6734",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/promocion-circuito-beer-spa-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6735",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/exfoliacion-aromatica-energizante-con-especias-raras-y-preciosas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6736",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/masaje-de-aromaterapia-45/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6737",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/ocean-view-pool-2pax-incluido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6738",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/at-diferencia-de-pack/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6739",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/spa-buffet-infantil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6740",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-suspiro-romantico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6741",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/facial-for-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6742",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bcn-noche-spa-90-cava-pack-desayuno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6743",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/exfoliacion-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6744",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-10min-en-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6745",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/neceser-cuidado-facial-xl/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6746",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/experiencia-after-sun/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6747",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/dia-del-padre-vip-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6748",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/depilacion-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6749",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/experiencia-relax-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6750",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/lomi-lomi-nui-55-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6751",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/depilacion-laser-dos-zonas-muy-pequenas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6752",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/gym-sesion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6753",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6754",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/noche-romantica-by-om/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6755",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/chi-nei-tsang-tecnica-taoista/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6756",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/velo-de-colageno-by-om/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6757",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/pack-tensor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6758",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/depilacion-con-diodo-zona-grande/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6759",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/masaje-apacible-paraiso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6760",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/ritual-oriente-80min-black-friday-10-descuento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6761",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-25-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6762",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-15min-chocolate-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6763",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/hard-rock-spa-marbella/tango-for-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6764",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/tto-promo-sentidos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6765",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/cura-belleza-integral/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6766",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/cura-control-de-peso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6767",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/sauna-y-bano-turco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6768",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/socio-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6769",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/socio-trainer/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6770",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/spagym/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6771",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/tarjeta-regalo-business/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6772",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/cuota-gold/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6773",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/experiencia-de-media-luna-en-sierra-nevada-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6774",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/facial-total-hidratacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6775",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/servicio-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6776",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/depilacion-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6777",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/facial-vitamina-c-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6778",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/depilacion-media-espalda-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6779",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/envoltura-de-algas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6780",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/depilacion-de-entrecejo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6781",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/depilacion-laser-mediana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6782",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/contorno-de-ojos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6783",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/aromaterapia-bano-de-burbujas-con-aceite-esencial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6784",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/facial-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6785",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-10-min-pareja-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6786",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hotel-spa-cadiz-plaza/oxyspa-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6787",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/suplemento-servicio-peinado-en-habitacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6788",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/suplemento-servic-peinado-o-maquillaje-en-habitacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6789",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/menorca/spa-faustino-gran/aromaterapia-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6790",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/bano-de-citricos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6791",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/hydration-for-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6792",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/wrinkles-out/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6793",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-de-chocolate-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6794",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/depilacion-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6795",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/drenaje-linfatico-manual-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6796",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cura-objetivo-silueta/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6797",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/oferta-marzo-2022/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6798",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/feliz-dia-papa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6799",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/combo-3-sesiones-love-your-body/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6800",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/masaje-ducha-afusion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6801",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-flor-de-cerezo-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6802",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/upselling-hamman/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6803",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/black-beer-vip-circuito-sala-privada-masaje-15-mnts-regalos-para-dos-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6804",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-fangoterapia-60min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6805",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/exfoliacion-corporal-aromatica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6806",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/sublime-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6807",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/sublime/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6808",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/sup-masaje-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6809",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/ritual-para-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6810",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6811",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/sauna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6812",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/momento-spa-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6813",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/envoltura-purificante-y-detoxificante-con-crema-de-rassoul/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6814",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/lifting-rejuvenecedor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6815",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-jc1-sercotel-murcia/circuito-azahar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6816",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/tratamientos-de-manos-con-cerveza-manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6817",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/bambu-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6818",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/bono-4-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6819",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-nucia-spa-masshotel/circuito-spa-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6820",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/depilacion-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6821",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/depilacion-piernas-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6822",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-vegano-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6823",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/bono-10-entradas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6824",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-chocolate-15/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6825",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/black-friday-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6826",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/piedras-calientes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6827",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/natural-spa-front-air-congress/circuito-termal-1h30min-prepago/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6828",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/sivana-holistic-spa-augusta-sanxenxo/circuito-termal-1h30min-prepago/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6829",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/manicura-con-esmalte-semipermanente-residente-canario/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6830",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/tratamiento-a-elegir-entre-masaje-relajante-20-exfoliacion-20-corporal-o-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6831",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-sueco-descontracturante-completo-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6832",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/laura-leon-wellness/spa-masaje-picoteo-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6833",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/at-msj-30-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6834",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/at-masaje-25-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6835",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-75/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6836",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/ritual-brasileno-crema-de-cafe-adelgazante-y-reafirmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6837",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/tratamiento-2-horas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6838",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-palma/spa-la-palma-teneguia-princess/princess-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6839",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/mechas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6840",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/desayuno-buffet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6841",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/all-at-al-hb-ht-desayuno-buffet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6842",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/spa-water-circuit-50-minutes-8/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6843",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cura-mascarilla-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6844",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/depilacion-ingles-brasilenas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6845",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/invitacion-sanitarios-entrada-spa-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6846",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/spa-mananas-de-lunes-a-viernes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6847",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/exfoliacion-en-vichy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6848",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-febrero-2020/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6849",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/santa-cruz-de-tenerife/beer-spa-tenerife/circuito-beer-spa-febrero-2020/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6850",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/tinte-de-cejas-y-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6851",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/sensations-anti-estres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6852",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/micropigmentacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6853",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/aragon-hills-hotel/sensuality-and-care-inmersion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6854",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-anticelulitico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6855",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/pack-tensor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6856",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-zahir-essence/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6857",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/depilacion-piernas-enteras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6858",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/privilege-masaje-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6859",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/tratamiento-de-ojeras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6860",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/envoltura-de-barro-del-bosque-encantado-con-masaje-craneo-facial-50-circuito-spa-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6861",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/depilacion-de-ingles-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6862",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/yogui-yogameditacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6863",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/pack-navidad-bano-vikingo-circuito-spa-sala-comun-mascarilla-facial-de-cerveza-para-dos-personas-pack-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6864",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/ayurveda-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6865",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/tinte-medio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6866",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/tratamiento-sublime-ritual-de-cinco-flores/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6867",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/tratamiento-facial-45/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6868",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-con-aromaterapia-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6869",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/masaje-relajante-con-aceites-aromaticos-20-minutos-buffet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6870",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/depilacion-laser-zona-reducida/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6871",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/luxury-visage-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6872",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/masaje-holistico-relajante-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6873",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/experiencia-relax-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6874",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-15-min-pago-directo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6875",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/especial-pareja-navidad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6876",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-exclusivity-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6877",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/lomi-lomi-nui-70min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6878",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/pack-acido-hialuronico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6879",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-de-pindas-con-extractos-de-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6880",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/masaje-local/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6881",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/diseno-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6882",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/recogido-con-trenzas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6883",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/the-spa-by-signature-sir-victor/body-organic-scrub-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6884",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/promocion-especial-hosteleros/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6885",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/pack-confort-2-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6886",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/vegan-sacred-nature/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6887",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/recogido-novia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6888",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/depilacion-ingles-brasilenas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6889",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-spaxion-hg-la-molina/aromatico-energy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6890",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/viaje-al-eden-tropical/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6891",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/circuito-spa-y-belleza-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6892",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/pedicura-basica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6893",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-anticelulitico-40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6894",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/limpieza-facial-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6895",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/extra-facial-higiene/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6896",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/correccion-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6897",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/depilacion-espalda-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6898",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/manicura-pedicura-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6899",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-armonia-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6900",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-slimming-dia-1-fitness-con-entrenador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6901",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/bae-barry/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6902",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/bae-barry-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6903",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/circuito-wellness-comida-cena-buffet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6904",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-completo-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6905",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/jardin-de-los-deseos-man-ritual-yang-by-alqvimia-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6906",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/gimnasio-1-mes-cliente-exterior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6907",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/san-valentin-masaje-50-y-spa-true-love/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6908",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/facial-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6909",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/piernas-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6910",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/senses-spa-experience-health-beauty/senses-luxury-supreme/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6911",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-iberostar-las-gaviotas/lomi-lomi-nui/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6912",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/promocion-dia-del-padre-acceso-al-circuito-hidrotermal-60-minmasaje-20-mincocktail-en-cafeteria/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6913",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-sa-voga/exfoliacion-de-manos-simultaneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6914",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-15-min-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6915",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/exfoliante-bambu-jeju-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6916",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/corporal-hidroterapia-e-hidratacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6917",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/tarjeta-regalo-100e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6918",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/ritual-flor-de-cerezo-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6919",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/experiencia-mediterranea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6920",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/tarjeta-regalo-business/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6921",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/exfoliacion-corporal-completa-envoltura-a-elegir/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6922",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/ritual-de-los-chakras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6923",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/piscina-interior-con-chorros-lumbares-y-cervicales-weekendesk/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6924",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/ritual-equilibrante-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6925",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/9issui-spa-fitness/circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6926",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/pedicura-con-esmalte-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6927",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/ritual-hydracure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6928",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/termes-de-montbrio/promocion-black-friday-spa-termal-masaje-local/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6929",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/circuito-termal-promocion-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6930",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/especial-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6931",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-relajante-personalizado-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6932",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/formacion-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6933",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/pack-massada-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6934",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-spa-50-trimestral-alldays/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6935",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/masaje-aromaterapia-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6936",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-spaxion-hg-la-molina/masaje-zonal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6937",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/codorniu-75cl/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6938",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/peeling-cristal-aceites-flores/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6939",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/corte-flequillo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6940",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/deluxe-day-day-pass-tu-dia-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6941",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hm-jaime-iii/san-valentin-magico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6942",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-masaje-50-experiencia-gastro-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6943",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-masaje-50-experiencia-gastro-l-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6944",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/ampolla-efecto-flash/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6945",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/masaje-relajante-90-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6946",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-spa-on-top/city-relax-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6947",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/exfoliaciones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6948",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/the-spa-by-signature-sir-victor/love-massage-facial-80min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6949",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bellesa-claret/manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6950",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/regalate-san-valentin-en-la-gloria/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6951",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/apacible-paraiso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6952",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/pack-navidad-bano-vikingo-circuito-spa-sala-comun-mascarilla-facial-de-cerveza-para-dos-personas-pack-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6953",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/circuito-spa-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6954",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-deportivo-home-service/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6955",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/ritual-equilibrante-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6956",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-15-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6957",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/masaje-craneal-y-facial-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6958",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-de-oro-o-chocolate-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6959",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/mini-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6960",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/mi-dia-romantico-especial-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6961",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-deluxe-2-peeling-pies-masaje-15-min-recepcion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6962",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-deluxe-2-masajes-de-15-min-recepcion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6963",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/mascarilla-cacao-beauty-party/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6964",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6965",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/pack-navidad-esencias-circuito-spa-masaje-de-esencias-naturales-55-minutos-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6966",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/circuito-alegria/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6967",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/ritual-tentacion-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6968",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/spa-romantico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6969",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/depilacion-torax-o-abdomen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6970",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/special-spa-brunch/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6971",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/envoltura-de-aloe-vera/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6972",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/masaje-relajante-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6973",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-spa-50-anual-alldays/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6974",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/peeling-marino-y-aceite-de-coco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6975",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-spaxion-hg-la-molina/masaje-deportivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6976",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/socio-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6977",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/body-sculpt-anticell/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6978",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/thalgo-men-ocean/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6979",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/masaje-tejido-profundo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6980",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/masaje-relajante-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6981",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-en-sala-comun-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6982",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-descontracturante-de-pocion-magica-renovadora-circuito-spa-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6983",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-manicura-expres-tratamientos-de-manos-y-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6984",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/pedicura-estetica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6985",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/caviar-e-romantica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6986",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-15-min-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6987",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/depilacion-facial-completa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6988",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/bonos-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6989",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/pack-relax-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6990",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/zumo-bio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6991",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-holistico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6992",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/peeling-renovador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6993",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hotel-spa-cadiz-plaza/relax-and-clean/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6994",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/entr-persn-pareja-8-ses/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6995",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/bano-de-citricos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6996",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/circuito-spa-infantil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6997",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/masaje-con-piedras-volcanicas-55-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6998",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/hb-spa-50-cava-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 6999",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/hb-spa-50-cava-l-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7000",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/at-spa-50-cava-v-d-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7001",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/cejas-con-pinzas-solo-con-tratamiento-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7002",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/depilacion-cejas-con-pinzas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7003",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/dia-de-la-madre-ritual-recupera-tu-energia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7004",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-la-gavina/circuito-de-hidroterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7005",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/ritual-hammam/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7006",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/tratamiento-antimanchas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7007",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/pack-navidad-glow-up-circuito-spa-tratamiento-lifting-sicilium-marin-pack-regalo-pack-para-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7008",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-therapy-o2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7009",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/depilacion-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7010",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/primavera-higiene-facial-thalgo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7011",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/paquete-experience-aromaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7012",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-relajante-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7013",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/tinte-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7014",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/doce-love-40m-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7015",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/doce-love-40m-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7016",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/azores/musgo-spa/nove-meses-em-beleza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7017",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/peeling-masaje-luz-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7018",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-cuerpo-completo-terapeutico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7019",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-de-cerveza-degustacion-de-1-cerveza-especial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7020",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/masaje-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7021",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/masaje-son-brull-sanctuary-60-min-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7022",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/circuito-de-aguas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7023",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/volcanoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7024",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/masaje-ayurveda-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7025",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/luxury-aromspa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7026",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/extras-promo-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7027",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/diseno-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7028",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/mesoterapia-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7029",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/sala-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7030",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/sun-repair/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7031",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lisboa/azure-wellness-spa/summer-breeze-50-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7032",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-desayuno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7033",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-la-manga-club/ku-nye/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7034",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/tratamiento-corporal-detox/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7035",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/depilacion-de-cejas-con-pinza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7036",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/ritual-de-chocoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7037",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/desierto-de-oriente-massage-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7038",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-facial-caballero-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7039",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/bono-4-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7040",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-bienestar-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7041",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/masaje-de-piedras-calientes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7042",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/viaje-rosa-de-bulgaria/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7043",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/agencias-spa-entrada-90-privatizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7044",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/piernas-perfectas-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7045",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/masaje-de-aromaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7046",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/brunch-y-circuito-spa-31/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7047",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/masaje-del-amor-45min-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7048",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/momento-be-aloe-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7049",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/pack-acido-hialuronico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7050",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/stone-therapy-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7051",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/manicura-francesa-nina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7052",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/burgos/spa-tudanca/masaje-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7053",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/masaje-de-aromaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7054",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-iberostar-las-gaviotas/family-day-chic-child/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7055",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-exclusivity-completo-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7056",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/tratamiento-calmante-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7057",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/extra-relaxing-love-circuito-beer-spa-pareja-masaje-25-minutos-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7058",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/masaje-con-aromaterapia-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7059",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-naturae/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7060",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/limpieza-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7061",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/ritual-armonia-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7062",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/masaje-local-para-dos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7063",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/pies-y-piernas-en-equilibrio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7064",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/centro-bienestar-sunprime-atlantic/aromaterapia-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7065",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/notas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7066",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/la-vida-es-bella-spaalmuerzo-cena-2-personas-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7067",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/when-africa-found-europe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7068",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/drenaje-linfatico-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7069",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/express-facial-antiage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7070",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-hydracure-hydracure-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7071",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/sesion-reiki/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7072",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/facial-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7073",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/ondas-de-choque/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7074",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/depilacion-de-torax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7075",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/ritual-sublime-de-la-polinesia-80-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7076",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/jueves-matinal-tiempo-para-ti-exclusivo-web/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7077",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/groupon-circuito-luxury/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7078",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-15min-pareja-feb-2020/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7079",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/pack-mama-facial-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7080",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/depilacion-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7081",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/especial-ayurveda-abhyanga-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7082",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/udvartana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7083",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/depilacion-ingles-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7084",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/serenity-full-body-massage-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7085",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/res-estancia-desayuno-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7086",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/all-at-al-hb-ht-desayuno-buffet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7087",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/at-al-hb-ht-desayuno-buffet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7088",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/bahia-in-love/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7089",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/exfoliacion-completa-guante-kessa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7090",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/depilacion-hombre-miedia-pierna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7091",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/tratamiento-alisado-keratina-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7092",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/spa-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7093",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/dia-del-padre-masaje-cerveza-25-hidromiel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7094",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7095",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-completo-cerveza-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7096",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/mananas-de-lux/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7097",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-excusivity-completo-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7098",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/masaje-con-aromaterapia-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7099",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7100",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-especial-parejas-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7101",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/hard-rock-spa-marbella/zona-de-aguas-especial-papa-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7102",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/masaje-exclusivity-de-cerveza-25min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7103",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/spa-almuerzo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7104",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/circuito-spa-menores-4-anos-cumplidos-a-menores-de-18/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7105",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/electroestimulacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7106",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/maderoterapia-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7107",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/body-care-sandos-papagayo-spa-wellness/pro-magic-stones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7108",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/masaje-stone-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7109",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/bono-6-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7110",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/circuito-spa-domingos-tarde-2-adultos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7111",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-radiance-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7112",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/facial-oxigenante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7113",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-masaje-thai/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7114",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-spaxion-estival-islantilla/especial-espalda-sana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7115",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-nucia-spa-masshotel/ritual-arena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7116",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/centro-bienestar-sunprime-atlantic/circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7117",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/acceso-spa-75-mensual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7118",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/pack-rosa-cerveza-hidromiel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7119",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/pack-graduacion-para-estudiantes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7120",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/menorca/spa-faustino-gran/clase-de-yoga/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7121",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/for-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7122",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/centro-bienestar-sunprime-atlantic/pack-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7123",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/indocean/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7124",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/beauty-party-beauty-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7125",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/especial-dia-de-la-madre-con-masaje-50-y-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7126",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/ritual-holistico-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7127",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/depilacion-y-tinte-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7128",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-naturae/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7129",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/experiencia-anti-estres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7130",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/masaje-drenaje-linfatico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7131",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/castellon/balneario-de-villavieja/balneario-parafango/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7132",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-adulto-2x1-hotel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7133",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/despacio-spa-center-at-the-one/hydrating-beauty-treatment/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7134",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/acceso-wellness-myevjf-90-no-privatizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7135",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-spa-termal-sabados-domingos-y-festivos-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7136",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/circuito-playa-granada-weekendesk/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7137",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-spaxion-don-juan/power-to-myself/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7138",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/masaje-descontracturante-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7139",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/masaje-de-aromaterapia-1paxeliminado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7140",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/momento-spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7141",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/detox-40-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7142",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/cambio-de-cabina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7143",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/viaje-al-eden-tropical-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7144",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/peeling-masaje-vichy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7145",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/manicura-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7146",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/soria/spa-castilla-termal-burgo-de-osma/relax-total-cuatro-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7147",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-flor-de-cerezo-60-cherry-blossom-ritual-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7148",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/baleares/senator-caleia-talayot-spa/ritual-flor-de-cerezo-60-cherry-blossom-ritual-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7149",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/agencias-acceso-spa-90-privatizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7150",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/black-beer-burbujeante-circuito-sala-comun-regalos-para-dos-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7151",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/exfoliantemasaje-20-groupon/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7152",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/hidraforce-source-marine/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7153",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/masaje-aromaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7154",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/termes-de-montbrio/spa-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7155",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/spa-cena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7156",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/reina-de-egipto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7157",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/circuito-spa-adulto-club-senator-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7158",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/prueba-de-maquillaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7159",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/tratamiento-hifu-abdomen-flancos-o-gluteos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7160",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/anti-stress-2-dias-dia-2-ducha-presion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7161",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/belleza-natural-mama-la-mejor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7162",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/estancia-desayuno-completo-spa-masaje-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7163",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/masaje-cuatro-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7164",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/san-juan-entrada-termal-2h-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7165",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-leo-deluxe/promocion-circuito-spa-masaje-de-25-min-servicio-de-te-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7166",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-blancafort-descontracturante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7167",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/laura-leon-wellness/circuito-spa-para-2-con-cava-masaje-luxury-brunch/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7168",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/depilacion-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7169",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/envoltura-de-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7170",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/mechas-plata/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7171",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/ritual-esencia-de-biznaga/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7172",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/spa-balinesa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7173",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/pack-vital-prevention/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7174",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/vip-familia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7175",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/fresh-scrub-de-aloe-vera-flor-de-tiare-y-bambu-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7176",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/depilacion-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7177",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/pedicura-bodyna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7178",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/wellness-spacena-tematica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7179",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/cueva-de-sal-ninos-recepcion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7180",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/cueva-de-sal-ninos-recepcion-40min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7181",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/tratamiento-facial-aqua/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7182",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/lomi-lomi-nui-55-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7183",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/manicura-basica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7184",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/masaje-50-promo-dia-del-padre-2024/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7185",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/recepcion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7186",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/stretching/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7187",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/ducha-vichy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7188",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/oferta-masaje-15-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7189",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/experiencia-del-mes-40-minutos-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7190",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/black-friday-2x1-masaje-80-entrada-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7191",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/ritual-jet-lag-refrescante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7192",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/kit-romantico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7193",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/pro-bright-day/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7194",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-radiance-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7195",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/tratamiento-anticelulitico-reafirmante-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7196",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/depilacion-pecho-o-gluteos-caballeros/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7197",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-armonia-60-harmony-ritual-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7198",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/baleares/senator-caleia-talayot-spa/ritual-armonia-60-harmony-ritual-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7199",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/bono-6-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7200",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-exclusivity-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7201",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/masaje-infantil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7202",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-15-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7203",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hm-jaime-iii/encanto-de-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7204",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/no-cellulite-massage-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7205",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/no-cellulite-massage-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7206",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/peinar-y-secar-pelo-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7207",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/spa-peeling/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7208",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-hotel-hyltor/masaje-relajante-60min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7209",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/circuito-spa-para-dos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7210",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/cena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7211",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/experiencia-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7212",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/circuito-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7213",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/black-friday-2x1-masaje-50-entrada-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7214",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/black-friday-2x1-masaje-50-circuito-spa-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7215",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/ritual-equilibrante-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7216",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/pack-mama-ritual-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7217",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hspa-valparaiso/acceso-circuito-hspa-2hrs-cena-viernes-a-domingo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7218",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-15min-pago-directo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7219",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-flor-de-cerezo-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7220",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/masaje-con-aceites-calientes-bio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7221",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/rayos-uva/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7222",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/unico-spa-finca-serena-mallorca/masaje-bienestar-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7223",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7224",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/emocion-circuito-alegria-masaje-15cava-2px-v-a-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7225",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/energizer-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7226",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/legs-massage-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7227",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/legs-massage-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7228",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/dakotabox-circuitos-2-personas-4990e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7229",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/relaxing-love-circuito-beer-spa-pareja-masaje-15-minutos-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7230",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/esencias-y-sentidos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7231",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/masaje-relajante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7232",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/masaje-turco-con-jabon-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7233",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/pack-massada-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7234",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/tinte-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7235",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-la-manga-club/piedras-calientes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7236",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/masaje-descontracturante-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7237",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-15-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7238",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/depilacion-labio-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7239",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-de-chocolate-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7240",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/masaje-exclusivity-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7241",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/envoltura-de-aloe-vera-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7242",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/black-friday-circuito-thalasso-3h-con-jacuzzi-vista-mar-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7243",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-sa-voga/ritual-de-navidad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7244",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/masaje-y-envoltura-de-oro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7245",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/envoltura-de-aloe-vera/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7246",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/masaje-piernas-ligeras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7247",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/especial-navidad-love-para-2-personas-zona-wellness-masaje-copa-cava-y-cofre-alqvimia-regalo-para-ti/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7248",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-aloe-vera-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7249",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-kobido-emperatriz/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7250",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/tratamiento-hifu-abdomen-flancos-o-gluteos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7251",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/love-pareja-citricos-exclusivo-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7252",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/masaje-aromaterapia-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7253",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/limpieza-facial-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7254",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/neptuno-premium-spa-50-2-noches-de-estancia-paquete-romantico-con-cena-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7255",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-hotel-hyltor/masaje-relajante-90min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7256",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/ritual-joya-del-atlantico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7257",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-alto-aragon/momento-spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7258",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/masaje-tailandes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7259",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/spa-masaje-relajante-exclusivo-web/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7260",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-spa-on-top/acces-spa-free-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7261",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/masaje-bioenergetico-masaje-de-espalda-cabeza-y-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7262",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/facial-radiofrecuencia-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7263",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-con-aromaterapia-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7264",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/lpg/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7265",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/gentleman-collagen-boost/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7266",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7267",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/pack-navidad-esencias-circuito-spa-masaje-de-esencias-naturales-55-minutos-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7268",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/especial-black-friday-masaje-anti-estres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7269",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/pack-massada-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7270",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/masaje-relajante-50-min-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7271",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/piedras-de-yucamani/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7272",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/peeling-corporal-duo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7273",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-relajante-50-con-aceite-de-picota-roja-circuito-spa-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7274",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/healthouse-14-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7275",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/especial-parejas-circuito-playa-granada-masaje-de-25-min-cena-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7276",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-cueva-de-sal-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7277",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/masaje-en-el-embarazo-55-o-85-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7278",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/ritual-piel-renovadora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7279",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/single-day-masaje-y-zona-wellness-con-copa-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7280",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/spa-day/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7281",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/dolce-relax-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7282",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/weekendesk-25-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7283",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/weekendesk-25-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7284",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-privado-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7285",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/promocion-san-valentin-circuito-termal-60-minmasaje-piedras-volcanicas-30-minen-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7286",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cura-pack-relax-2-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7287",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/promocion-black-friday-pack-relax-gastronomia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7288",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/circuito-aquacentermasaje-15mincena-2pax-viernes-tardes-a-domingo-manana-125e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7289",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/exfoliacion-completa-guante-kessa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7290",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/exfoliacion-corporal-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7291",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/envoltura-de-te-verde/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7292",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-de-oro-o-chocolate-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7293",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/navidad-ritual-ruta-de-oriente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7294",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/despacio-spa-center-at-the-one/masaje-calm-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7295",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-attica21-vigo/tiempo-para-dos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7296",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/bono-6-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7297",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/ritual-hydracure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7298",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/hesperides-thalasso-spa/banera-niagara/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7299",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/soin-hidratacion-intensa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7300",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-belleza-dia-2-manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7301",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-55-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7302",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/manicura-con-esmalte-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7303",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/spa-privado-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7304",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/masaje-relajante-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7305",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-completo-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7306",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-15-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7307",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/pack-navidad-vikingo-circuito-spa-sala-comun-para-dos-personas-pack-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7308",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/sur-de-canarias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7309",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/circuito-spa-4-circuitos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7310",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-aromatico-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7311",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/ritual-de-la-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7312",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/spa-mizu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7313",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/momento-spa-masaje-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7314",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/oporto/the-spa-by-sheraton-porto/saldo-para-dos-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7315",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/piernas-cansadas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7316",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/kintsugi-spa/circuito-spa-privado-cena-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7317",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/manicura-o-pedicura-expres-de-25-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7318",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/manicura-o-pedicura-expres-de-25-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7319",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/facial-la-cala/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7320",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/invierno-ritual-maderoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7321",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/palacio-albacete-spa/invierno-ritual-maderoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7322",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/super-mama-spa-breakfast/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7323",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-masajes-relajantes-20-masajes-energias-de-la-tierra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7324",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/sauna-bano-turco-jacuzzi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7325",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/piernas-ligeras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7326",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/masaje-30-infantil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7327",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/peeling-del-mediterraneo-by-thalgo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7328",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-iberostar-las-gaviotas/essences-senses-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7329",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/masaje-oriental-80-min-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7330",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/momento-spa-gastro-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7331",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/masaje-aromatico-completo-55/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7332",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/promocion-especial-hosteleros/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7333",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/pack-confort-relax-1-dia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7334",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/pedicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7335",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/ritual-naturae/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7336",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/bono-15-entradas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7337",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/san-valentin-el-amor-esta-en-el-agua/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7338",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/depilacion-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7339",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/spa-cena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7340",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/beauty-party/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7341",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7342",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/masaje-shiatsu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7343",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-bienestar-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7344",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/limpieza-facial-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7345",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-sa-voga/experiencia-relaxant-duo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7346",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/contorno-ojos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7347",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/pilates/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7348",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/stone-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7349",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/exfoliaciones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7350",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/especial-black-friday-love-secret/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7351",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/cortar-y-peinar-pelo-medio-y-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7352",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/ritual-merveille-artique/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7353",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/propina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7354",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pilates-suelo-8-sesiones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7355",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-de-cerveza-25-degustacion-1-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7356",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-lift-in/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7357",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/gimnasio-mensual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7358",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-excusivity-completo-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7359",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-25min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7360",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/tratamiento-facial-antimanchas-oxigenante-o-calmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7361",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-10-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7362",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-iberostar-las-gaviotas/circuito-hydrowellness-120-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7363",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/viaje-sensorial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7364",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/lifting-rejuvenecedor-tensor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7365",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/ritual-citrico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7366",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/manicura-juvenil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7367",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/masaje-profundo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7368",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/hidratacion-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7369",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/semi-recogido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7370",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/masaje-anticelulitico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7371",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/circuito-spa-y-belleza-alojados-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7372",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/envoltura-de-aglas-con-exfoliante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7373",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/sesion-de-fisioterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7374",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-ritual-a-elegir-90min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7375",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/spa-50-5-tapas-gourmet-coctel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7376",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/depilacion-labio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7377",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/ritual-esplendor-radiante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7378",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/limpieza-cutis-termal-beauty/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7379",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/all-at-al-gr-suplemento-pack-spa-50-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7380",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/at-spa-con-masaje-cena-para-dos-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7381",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-descontacturante-para-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7382",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/pedicura-permanente-novedad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7383",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sunlight-bahia-principe-san-felipe/facial-hidratacion-intensiva/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7384",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/masaje-corporal-nino-a-40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7385",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja-regalo-de-una-camiseta-y-una-gorra-beer-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7386",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/spa-masaje-piedras-calientes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7387",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/quitar-esmalte-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7388",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/peeling-lava-volcanica-reafirmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7389",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/vip-pool-pass-menu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7390",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-alto-aragon/tratamiento-wellness-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7391",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-25-min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7392",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/smartbox-2-personas-3990/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7393",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/radiofrecuencia-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7394",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/agencias-entrada-spa-50-privatizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7395",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/mascarilla-lifting/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7396",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/indocean-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7397",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/pedicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7398",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/depilacion-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7399",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/tailandes-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7400",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/tratamiento-de-algas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7401",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/masaje-celta-local/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7402",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-25-sala-privada-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7403",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/masaje-con-piedras-calientes-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7404",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/sandara-wellness-spa/especial-black-friday-circuito-de-aguas-cliente-externo-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7405",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/oferta-especial-de-verano-plazas-limitadas-piscina-puesta-en-forma-solo-de-lunes-a-jueves/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7406",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-55-min-alojados-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7407",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-sensorial-de-cabeza-y-cuero-cabelludo-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7408",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/masaje-10-min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7409",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/ritual-sensual-care-inmersion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7410",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/thalgo-sillicium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7411",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/sun-repair/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7412",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-25min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7413",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/estancia-gastro-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7414",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/san-valentin-masaje-50-y-spa-love-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7415",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/ritual-for-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7416",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa-y-masaje-sushi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7417",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/jacuzzi-privado-antioxidante-con-uva-duo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7418",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/chocomassage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7419",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/masaje-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7420",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/bono-10-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7421",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/masaje-energy-tea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7422",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/castellon/balneario-de-villavieja/balneario-basico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7423",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/gel-color-by-opi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7424",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/masaje-especial-embarazadas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7425",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/circuito-spa-almuerzo-menu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7426",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/neceser-basic/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7427",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-masaje-de-piernas-cansadas-20-masaje-energias-de-la-tierra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7428",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/ninguno/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7429",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/hamman-experience-luxury-hidratacion-argan/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7430",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/spa-masaje-terapeutico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7431",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7432",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-15min-chocolate-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7433",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/promocion-black-friday-mas-body-care-uli-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7434",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/energia-para-golfistas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7435",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/mechas-medio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7436",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-la-gavina/momento-spa-masaje-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7437",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/black-beer-burbujeante-circuito-sala-comun-regalos-para-dos-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7438",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/ritual-oriental-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7439",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/descanso-30m-2-pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7440",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/descanso-30m-2-pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7441",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/healthouse-7-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7442",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/pinda-sweda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7443",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-piedras-calientes-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7444",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/san-valentin-gastrospa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7445",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/ritual-supreme-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7446",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/masaje-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7447",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/timexpert-c-iluminador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7448",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/vip-love-circuito-vip-pareja-masaje-15-minutos-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7449",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/drenaje-linfatico-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7450",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/acceso-spa-bodyna-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7451",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-euforia-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7452",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/sesion-crossfit-en-box-thalassa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7453",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/drenaje-linfatico-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7454",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/bono-10-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7455",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/masaje-hindu-de-cabeza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7456",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/recogidos-pelo-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7457",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/mascarilla-15/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7458",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/pack-glamour/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7459",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/black-friday-2x1-masaje-80-entrada-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7460",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/maquillaje-de-fiesta-noche/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7461",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/estas-navidades-regale-bienestar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7462",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/maquillaje-de-dia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7463",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-deportivo-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7464",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/bono-10-sesiones-ep/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7465",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/depilacion-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7466",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/spa-en-familia-2x1-mas-20-descuento-en-tratamientos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7467",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/gimnasio-acceso-1-dia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7468",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/ritual-sport-tonic-energy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7469",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/especial-pareja-circuito-arabe-masaje-de-25-min-servicio-de-te-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7470",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/masaje-local-terapeutico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7471",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7472",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/sprunch-day-pass-con-spa-brunch/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7473",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/tarjeta-regalo-business/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7474",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-naturae/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7475",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/bono-4-sesiones-masaje-completo-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7476",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/despacio-spa-center-at-the-one/masaje-calm/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7477",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/san-valentin-inolvidable/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7478",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-25min-chocolate-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7479",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/tratamiento-antiarrugas-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7480",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/peinar-y-secar-pelo-medio-y-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7481",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/banco-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7482",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/envoltura-picota-o-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7483",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/depilacion-corporal-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7484",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-craneocervical-15/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7485",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guipuzcoa/spa-foresta-wellness/circuito-spa-y-masaje-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7486",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/corporal-vit-c-supreme/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7487",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/tratamiento-shanara-con-cuencos-tibetanos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7488",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/banera-de-hidromasaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7489",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-seventy-spa/banco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7490",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/vip-spa-privado-30-botella-cava/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7491",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-masaje-balines-en-pareja-con-frutas-y-cava-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7492",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/peeling-corporal-de-coco-hidratacion-completa-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7493",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/love-massage-scrub-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7494",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/ritual-de-la-india-crema-udvartana-firmeza-y-adelgazamiento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7495",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7496",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/masaje-luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7497",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/especial-black-friday-masaje-anti-estres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7498",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/ritual-timexpert-srns/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7499",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/pack-romantico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7500",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/matriskin-lifting-facial-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7501",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/especial-san-valentin-private/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7502",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-15-minutos-sala-comun-pareja-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7503",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/exfoliantes-exotico-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7504",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/diferencia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7505",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/masaje-personalizado-80-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7506",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural/oxigenacion-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7507",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/masaje-samay-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7508",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/cura-control-de-peso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7509",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/the-spa-by-signature-sir-victor/beauty-party/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7510",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/promocion-circuito-spa-y-belleza-masaje-55min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7511",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-relajante-oriental-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7512",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/mechas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7513",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/circuito-spa-domingo-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7514",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/san-valentin-masaje-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7515",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/facial-o2-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7516",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/programa-evasion-1-dia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7517",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/palacio-albacete-spa/programa-evasion-1-dia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7518",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/higiene-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7519",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/masaje-energy-tea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7520",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/ritual-del-oriente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7521",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/circuito-spa-menu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7522",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-despacio-h10-benidorm/masaje-sensorial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7523",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/exfoliacion-corporal-iluminadora-con-papaya-pura-de-bali/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7524",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/tarjeta-regalo-business/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7525",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/masaje-deportivo-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7526",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/blanqueamiento-dental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7527",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/pedicura-color/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7528",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/viaje-al-eden-tropical-individuales/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7529",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/exfoliante-masaje-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7530",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/the-spa-by-signature-sir-victor/body-organic-scrub-relax-massage-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7531",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/depilacion-espalda-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7532",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/retirada-de-esmaltado-permanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7533",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/circuito-vitality-agencia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7534",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/programa-especial-piernas-cansadas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7535",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-exfoliacion-aromatica-con-especias-exfoliaciones-corporaless/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7536",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/pack-navidad-relax-pareja-circuito-spa-ritual-gold-theraphy-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7537",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/especial-primavera-by-om/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7538",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-deluxe-masaje-de-15-min-recepcion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7539",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-deluxe-peeling-pies-masaje-15-min-recepcion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7540",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/rock-spa-luxury-pedicure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7541",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/masaje-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7542",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/tratamiento-antiarrugas-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7543",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/terapia-de-perla-y-oro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7544",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/feliz-dia-mama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7545",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/especial-san-valentin-spa-para-dos-de-lunes-a-jueves-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7546",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/circuito-antiestres-y-belleza-completo-relax-y-belleza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7547",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/manicura-pedicura-clasica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7548",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/gel-color-francesa-by-opi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7549",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/banera-hidromasaje-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7550",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/paquete-belleza-4-hrs-spa-experience-exfoliante-corporal-facial-antiage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7551",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/pack-confort-active-1-dia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7552",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-roca-negra/promocion-black-friday-circuito-spa-gastronomia-masaje-cocktail/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7553",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bcn-noche-spa-75/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7554",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bcn-noche-spa-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7555",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/entrada-circuito-iiberik-25-12-2024/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7556",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/day-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7557",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7558",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/spa-freixenet-ice-disfruta-la-vida/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7559",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/tratamiento-facial-tensor-radiofrecuencia-5-sesiones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7560",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/masaje-con-aromaterapia-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7561",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/corporal-exotico-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7562",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/bono-atrapalo-acceso-spa-menu-3-platos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7563",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/acceso-atrapalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7564",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/acceso-atrapalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7565",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/especial-san-valentin-spa-deluxe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7566",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacion-labio-superior-y-barbilla/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7567",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/entrenador-personal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7568",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/mas-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7569",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-residentes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7570",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/depilacion-de-torax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7571",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/spa-hotel-blu-almansa/masaje-dstress/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7572",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/pack-massada-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7573",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/promocion-black-friday-masaje-aromaterapia-reflexologia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7574",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-naturae/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7575",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7576",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/envoltura-corporal-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7577",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/pack-san-valetin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7578",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-anticelulitico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7579",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-craneo-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7580",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/wellness-day-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7581",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/facial-oxigenacion-y-purificacion-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7582",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/circuito-nocturno-spa-termal-17-febrero-cena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7583",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/aplicacion-parche-facial-corporal-itworks/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7584",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/circuito-ohtels/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7585",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/tratamiento-calmante-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7586",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/pedicura-con-esmalte-semipermanente-residente-canario/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7587",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/menorca/spa-faustino-gran/aromaterapia-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7588",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/masaje-oriental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7589",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/lavar-y-secar-pelo-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7590",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/bioterapia-corporal-luz/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7591",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/torax-abdomen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7592",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/paquete-date-un-capricho-exfolaicionmasaje-manosmasaje-balines-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7593",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7594",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/facial-iluminador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7595",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/black-friday-facial-a-elegir-30-min-kobido-piedra-jade-detox-o-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7596",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/mascarilla-de-ojos-15-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7597",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/mascarilla-de-ojos-15-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7598",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/experiencia-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7599",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-exclusivity-completo-55-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7600",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/woman-haircut-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7601",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/peinado-corto-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7602",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/peinado-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7603",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/masaje-relajante-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7604",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/relajese-disfrute-paquetes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7605",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7606",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/circuito-especial-san-valentin-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7607",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/radiofrecuencia-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7608",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-10-min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7609",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/exfoliacion-purificante-con-jabon-negro-beldi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7610",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/nammu-spa-area-vincci-la-plantacion-del-sur/masaje-para-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7611",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/pack-tensor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7612",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/ritual-flor-de-cerezo-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7613",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/calm-free-massage-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7614",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-slimming-dia-1-body-peeling/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7615",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/spa-privado-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7616",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/ritual-flor-de-cerezo-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7617",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/gym-spa-mensual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7618",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/circuito-spa-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7619",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-detox-dia-3-masaje-drenante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7620",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-detox-dia-4-masaje-pinda-sweda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7621",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/promocion-san-valentin-circuito-termal-60-minmasaje-oriental-30-min-piernas-y-espaldaen-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7622",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/facial-basico-45/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7623",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/tratamiento-facial-personalizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7624",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/2aadd/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7625",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/silhoutte-perfection-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7626",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/viaje-al-eden-tropical/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7627",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/gimnasiospa-deloix-1-mes-cliente-camping-raco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7628",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/mojito-terapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7629",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/masaje-relajacion-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7630",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/personalizado-shama-gourmet-para-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7631",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/circuito-hidrotermal-promo-verano/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7632",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/lifting-facial-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7633",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/tu-madre-se-lo-merece-y-mas-ahora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7634",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/pack-spa-enamorados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7635",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/gimnasio-3-semanas-cliente-exterior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7636",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/depilacion-de-piernas-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7637",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/circuito-spa-domingo-2-adultos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7638",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/depilacion-cejas-y-labio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7639",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/masaje-bio-piedras-calientes-50-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7640",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/blanqueamiento-dental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7641",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/hydraluronic/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7642",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/ritual-de-la-india-crema-udvartana-firmeza-y-adelgazamiento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7643",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/manicura-o-pedicura-para-ninos-y-ninas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7644",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/circuito-reina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7645",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/masaje-facial-japones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7646",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/web-promo-matinal-masaje-35/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7647",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/centro-bienestar-sunprime-atlantic/masaje-lomi-lomi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7648",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/masaje-corporal-bespoke-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7649",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/pedicura-con-esmalte-semipermanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7650",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/criolipolisis-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7651",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/promocion-black-friday-circuito-spa-gastronomia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7652",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/masaje-acupuntura-70-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7653",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/tratamiento-lumiere-by-thalgo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7654",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-balines-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7655",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/spa-buffet-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7656",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/masaje-a-su-eleccion-85min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7657",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/masaje-antiestres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7658",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/te-regalo-un-masaje-en-casa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7659",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-relax-del-rostro-cuello-y-escote/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7660",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/pies-perfectos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7661",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/depilacion-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7662",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/pack-bano/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7663",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/jacuzzi-privado-antioxidante-con-uva/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7664",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/espacio-acuatico-templarium-3/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7665",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/tratamiento-antimanchas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7666",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/masaje-tailandes-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7667",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/aromaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7668",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/oferta-6-especial-dia-de-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7669",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/tarjeta-regalo-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7670",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/termal-el-palacete/momento-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7671",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/babylights/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7672",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/babylights-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7673",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/laura-leon-wellness/romantic-tajinaste/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7674",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/masaje-balines-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7675",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guadalajara/spa-rural-los-anades/equilibrio-energetico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7676",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/bono-4-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7677",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/cierre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7678",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-empaquetado-europlayas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7679",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/9issui-spa-fitness/circuito-spa-masaje-relax-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7680",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/tratamiento-iluminador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7681",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/maquillaje-evento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7682",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/ritual-vincci-sun/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7683",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/facial-solo-para-hombres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7684",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/prueba-de-recogido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7685",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/facial-antiedad-lift-in/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7686",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-acne/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7687",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/pro-sweet-dreams/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7688",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7689",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/depilacion-bikini/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7690",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valladolid/spa-castilla-termal-olmedo/relax-total-cuatro-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7691",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/masaje-drenante-de-pies-y-piernas-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7692",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/brunch-infantil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7693",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/higueron-spa/masaje-prana-relax-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7694",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/spa-buffet-para-nino-sabado-o-domingo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7695",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/stone-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7696",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/exquisite-choco-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7697",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/facial-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7698",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/masaje-relajante-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7699",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-de-la-felicidad-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7700",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/formacion-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7701",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/oxigenacion-e-hidratacion-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7702",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/noche-termal-i-entrada-21-10-23/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7703",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/piel-perfecta/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7704",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hm-palma-blanc/circuito-spa-masaje-deportivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7705",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/pies-de-cenicienta/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7706",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/hidra-marine-higiene-profunda-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7707",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/tu-madre-se-lo-merece-y-mas-ahora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7708",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/experiencia-spa-web-pack-para-dos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7709",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/tratamiento-ko-bi-do-anti-edad-global-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7710",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/faro/soul-spa-by-jupiter-albufeira/futuras-mamas-despues-de-12-semanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7711",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/peinado-medio-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7712",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/woman-haircut-3/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7713",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/peinado-medio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7714",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/espalda-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7715",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/especial-navidad-love-para-2-personas-spa-masaje-copa-cava-y-cofre-alqvimia-regalo-para-ti/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7716",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/manicura-francesa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7717",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/masaje-descontracturante-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7718",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/hb-masaje-30-experiencia-gastro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7719",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-oriental-dia-3-refl-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7720",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/masaje-cuatro-manos-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7721",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/ciudad-real/spa-casa-del-rector/ritual-antioxidante-de-vino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7722",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/black-beer-vip-circuito-sala-privada-masaje-15-mnts-regalos-para-dos-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7723",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/extra-limpieza-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7724",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/gel-color-by-opi-3/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7725",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/maquillaje-de-novia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7726",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/firming-eye-treatment-men-women/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7727",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/lipo-choco-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7728",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/circuito-reina-hotel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7729",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/luxor-spa/oferta-abril-tratamiento-facial-kit-crema-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7730",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/facial-de-la-suerte-skin-bar-efecto-lifting/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7731",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/ritual-armonia-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7732",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/segovia/spa-spaxion-convento-capuchinos/envoltura-fresca/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7733",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/bano-de-burbujas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7734",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-playa-spa-lanzarote/circuito-hidrotermal-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7735",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/maquillaje-de-noche/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7736",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/pack-cerveza-verde/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7737",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/facial-solo-para-hombres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7738",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-hotel-hyltor/masaje-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7739",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/bono-10-sesiones-ep/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7740",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/masaje-deportivo-completo-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7741",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/maquillaje-de-noche/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7742",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/teide-pack-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7743",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/las-palmas/siam-spa-bohemia-suites/recepcion-4-horas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7744",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/tratamiento-antiedad-colageno-marino-by-thalgo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7745",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-alto-aragon/momento-spa-masaje-4/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7746",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/depilacion-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7747",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/pack-tensor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7748",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/masaje-aromatico-parcial-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7749",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/palacio-albacete-spa/momento-spa-masaje-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7750",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/momento-spa-masaje-en-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7751",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pass-gym-lunes-a-viernes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7752",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-timexpert-srns/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7753",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-leo-deluxe/promocion-circuito-leo-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7754",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/maquillaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7755",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/pack-especial-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7756",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-costa-adeje/bio-relajante-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7757",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/ampolla-efecto-flash/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7758",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/masaje-15min-r/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7759",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/junior-experiencia-hidrotermal-relajante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7760",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/spa-yoga/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7761",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/ht-masaje-30-experiencia-matinal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7762",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/tratamiento-de-chocolate-mini-facial-circuito-aguas-para-mama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7763",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/envoltura-exotico-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7764",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/black-beer-vip-circuito-sala-privada-masaje-15-mnts-regalos-para-dos-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7765",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/segovia/spa-spaxion-convento-capuchinos/facial-for-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7766",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/centro-wellness-nirama-by-baobab-suites/ritual-de-aromaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7767",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-ayurvedico-de-cabeza-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7768",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-aniversario/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7769",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/circuito-navidad-infantil-pqt-alojado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7770",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/gastro-bodyna-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7771",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/segovia/spa-spaxion-convento-capuchinos/wellness-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7772",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/circuito-ocean/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7773",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/masaje-tailandes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7774",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/cuidado-optimale-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7775",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-fuente-de-equilibrio-de-la-india/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7776",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/shiatsu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7777",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/masaje-descontracturante-60min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7778",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-despacio-h10-benidorm/soin-sublime-light/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7779",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/masaje-sirochampi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7780",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/pro-top-care/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7781",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/circuito-spa-organizacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7782",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/the-rock-suites-spa/res-estancia-desayuno-cena-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7783",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/stone-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7784",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/detox-con-te-verde-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7785",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/tratamiento-anticelulitico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7786",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/circuito-spa-oferta-para-2-plataformas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7787",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/maquillaje-de-novia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7788",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/experiencia-relax-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7789",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-beauty-plus/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7790",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/circuito-spa-domingo-2-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7791",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/masaje-especial-ayurveda-abhyanga/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7792",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/colageno-by-corallium-cosmetics-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7793",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/masaje-relax-con-aceites-calientes-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7794",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/tarjeta-regalo-business/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7795",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/aroma-relax-90min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7796",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/luna-de-oro-y-miel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7797",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-a-medida-60min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7798",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/gold-package/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7799",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/depilacion-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7800",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/gold-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7801",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/circuito-spa-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7802",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/depilacion-con-diodo-cuerpo-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7803",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/dulce-encuentro-55-min-en-pareja-con-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7804",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/tratamiento-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7805",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/wonderbox-circuitos-2-personas-4990e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7806",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/bano-de-esencias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7807",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/circuito-hydrowellness/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7808",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-aromatico-15-minutos-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7809",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/hyalu-procollagen-marine-filler/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7810",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-de-citricos-152px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7811",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-brisa-de-ganesha/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7812",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/especial-dia-de-la-madre-wellness-relax-day/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7813",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/ritual-cabello/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7814",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/masaje-aloe-vera/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7815",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7816",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/depilacion-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7817",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/acceso-spa-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7818",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/spa-ahemon-promocion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7819",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-masaje-completo-55-min-sala-comun-pareja-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7820",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-flor-de-cerezo-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7821",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/kobido-ritual-de-la-juventud-imperial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7822",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/castanoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7823",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/pure-fresh-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7824",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/especial-dia-de-la-relajacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7825",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/pack-romantic-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7826",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spai-son-armadans/almuerzo-circuito-de-spa-23/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7827",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-iberostar-las-gaviotas/bioterapia-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7828",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/tratamiento-facial-antiarrugas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7829",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/depilacion-piernas-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7830",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/luxury-japan-private-room/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7831",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/oasis-de-serenidad-3-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7832",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-aquabody/servicio-de-spa-privado-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7833",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/entr-pers-pareja-1-sesion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7834",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/masaje-antiestres-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7835",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/masaje-energy-tea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7836",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-roca-negra/promocion-black-friday-circuito-spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7837",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/bano-lacteo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7838",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/ritual-hydraluronic/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7839",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spaxion-isla-canela/pelo-medio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7840",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/masaje-craneal-o-podal-10/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7841",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-equilibrante-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7842",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/masaje-relajante-corporal-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7843",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/pack-luxury-love/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7844",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/momento-spa-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7845",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-sir-victor/digital-wellness-escape-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7846",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/castellon/balneario-de-villavieja/parafango-zona-grande/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7847",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-relajante-aromas-de-alejandria-home-service/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7848",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-15min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7849",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-15-min-pago-directo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7850",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/depilacion-axilas-hair-removal-underarm/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7851",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/depilacion-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7852",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/vip-pool-pass/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7853",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/especial-mar-muerto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7854",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/pack-spa-enamorados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7855",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/cena-1px-europlayas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7856",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/manicura-deluxe-con-parafina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7857",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/masaje-relajante-40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7858",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/peeling-corporal-de-coco-hidratacion-completa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7859",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-brunch-para-2-personas-no-residente-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7860",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/black-friday-2x1-masaje-30-circuito-wellness-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7861",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/ritual-esplendor-radiante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7862",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/10-min-masaje-extra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7863",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/cavitacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7864",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/oriental-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7865",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-cerler/momento-spa-masaje-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7866",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/promocion-black-friday-circuito-spa-adultos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7867",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/pack-navidad-glow-up-circuito-spa-tratamiento-lifting-sicilium-marin-pack-regalo-pack-para-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7868",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/mesoterapia-virtual-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7869",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/momento-spa-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7870",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/tarjeta-regalo-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7871",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/2-jacuzzis-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7872",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/masaje-ayurvedico-india-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7873",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/star-glow/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7874",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/the-spa-by-signature-sir-victor/luxury-love-experience-110min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7875",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/corte-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7876",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/lifting-rejuvenecedor-tensor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7877",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/bano-de-color-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7878",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/circuito-spa-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7879",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/dia-de-la-madre-ritual-iles-pacific/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7880",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/ritual-perfect-body/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7881",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/cool-face-feet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7882",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-de-10-masajes-50-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7883",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-citricos-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7884",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/calma-spa/mimos-para-mama-con-spa-y-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7885",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/feeling-good/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7886",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/dkt-spacava-o-coctel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7887",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/dia-del-padre-vip-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7888",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spacio-telde/circuito-termal-1-adulto-1-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7889",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/masaje-reductor-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7890",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/masaje-luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7891",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/circuito-duch-grupos-6px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7892",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/pack-massada-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7893",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/manicura-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7894",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/facial-express-40-cajitas-y-cupones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7895",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/ritual-eter/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7896",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/bano-niagara/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7897",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/escapada-beauty-2-noches/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7898",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/antiedad-reafirmante-intensivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7899",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/cabina-vip-masaje-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7900",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/black-friday-peeling-corporalmasaje-vitamina-c-40-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7901",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/atrapalo-masaje-de-50-minutos-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7902",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/atrapalo-masaje-de-50-minutos-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7903",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/depilacion-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7904",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/depilacion-de-piernas-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7905",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/sauna-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7906",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/circuito-3h-huesped/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7907",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/corte-de-pelo-nina-hasta-12-anos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7908",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/cura-belleza-integral/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7909",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/cortar-marcar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7910",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/depilacion-ingles-brasilenas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7911",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/depilacion-de-axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7912",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/beauty-face-de-lux/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7913",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/atp-plus-de-30msj-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7914",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/facial-anti-edad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7915",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/bono-10-sesiones-masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7916",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/agencias-acceso-spa-50-privatizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7917",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7918",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/bono-6-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7919",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/masaje-con-aromaterapia-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7920",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/palacio-albacete-spa/programa-belleza-y-bienestar-el-o-ella-1-dia-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7921",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/ritual-bienestar-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7922",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-oriental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7923",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-intenso-60-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7924",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/spa-hotel-blu-almansa/facial-hidratacion-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7925",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/gran-hotel-spa-lakua/circuito-spa-60-limpieza-de-cutis-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7926",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-augas-santas-balneario-golf/masaje-con-aceite-de-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7927",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/spagastro-infantil-4-a-12-anos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7928",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/oporto/the-spa-by-sheraton-porto/cocoon-facial-y-corporal-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7929",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/exfoliacion-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7930",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/sauna-y-jacuzzi-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7931",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/sauna-y-jacuzzi-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7932",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/diferencia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7933",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/masaje-15min-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7934",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/ritual-de-la-felicidad-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7935",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/programa-vichy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7936",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/depilacion-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7937",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/masaje-tailandes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7938",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/masaje-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7939",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/neceser-cuidado-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7940",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-mediterraneo-1h-45/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7941",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-25-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7942",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/masaje-aloe-vera-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7943",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/socio-trainer/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7944",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/especial-black-friday-desayuno-buffet-circuito-spa-masaje-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7945",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-aloe-vera-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7946",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/limpieza-facial-basica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7947",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/masaje-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7948",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/despertar-by-serena-spa-desayuno-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7949",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/masaje-corporal-bespoke-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7950",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/kobido-zen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7951",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/black-friday-spabrunch-by-bahia-del-duque/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7952",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-15min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7953",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/depilacion-brazos-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7954",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/facial-glow/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7955",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/pack-belleza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7956",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/pro-best-moment/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7957",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-a-medida-90min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7958",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/masaje-25-min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7959",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/facial-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7960",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/cofre-masaje-mask-60min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7961",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-escapada-romantica-2-personas30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7962",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-masaje-ayurvedico-45-masajes-energias-de-la-tierra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7963",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/stone-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7964",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/at-spa-50-cava-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7965",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/at-spa-50-cava-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7966",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-organic-spa/serenidad-para-mama-con-masaje-30-y-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7967",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-relajante-oriental-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7968",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/esmaltado-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7969",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/media-espalda-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7970",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/al-mas-30-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7971",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/al-masaje-25-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7972",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/masaje-con-aromaterapia-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7973",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/ritual-toning-tea-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7974",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-attica21-vigo/tiempo-para-dos-90-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7975",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/beer-spa-islantilla/circuito-beer-spa-en-sala-comun-pareja-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7976",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/san-valentin-en-salobre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7977",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/masaje-con-aromaterapia-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7978",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/kintsugi-spa/terapia-con-barro-del-mar-muerto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7979",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/masaje-shi-zen-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7980",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/masaje-con-aromaterapia-25min-aromatherapy-massage-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7981",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/circuito-wellness/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7982",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/maquillaje-de-dia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7983",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/tinte-de-pestanas-o-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7984",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/depilacion-cejas-o-labio-superior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7985",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/ritual-andalusi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7986",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/smartbox-2-personas-4990e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7987",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/black-friday-2x1-circuito-spa-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7988",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/spa-promocion-matinal-2x1-l-v/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7989",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/spa-suite/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7990",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/lavar-y-marcar-con-rulos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7991",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/depilacion-piernas-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7992",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/ritual-so-delicate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7993",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/peeling-corporal-de-coco-hidratacion-completa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7994",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/ritual-radiance-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7995",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/eternal-scens-lifting-experience-kobido-80-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7996",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/eternal-scens-lifting-experience-kobido-80-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7997",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/corte-de-pelo-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7998",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/leyendas-del-oceano-ocean-view/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 7999",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/dia-del-padre-masaje-cerveza-25-hidromiel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8000",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/tratamiento-ayurveda-detox/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8001",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/bio-peeling-e-hidratacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8002",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-15sala-privada-1px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8003",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/pack-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8004",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/masaje-relajante-de-media-luna-home-service/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8005",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/piernas-ligeras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8006",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/nos-regalamos-un-capricho/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8007",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/at-desayuno-spa-con-masaje-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8008",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/masaje-oriental-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8009",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-siesta-relajante-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8010",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-facial-ko-bi-do-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8011",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/masaje-relajante-localizado-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8012",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-bahia-del-duque/black-friday-circuito-spa-residente-canario/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8013",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-adulto-oferta-tto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8014",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/masaje-ayurvedico-para-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8015",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/pack-confort-1er-dia-envoltura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8016",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/masaje-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8017",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/brazos-espalda-pecho-o-abdomen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8018",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/masaje-15-jacuzzi-15-1p-oferta/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8019",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/diferencia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8020",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/circuito-spa-familiar-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8021",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/tinte-pelo-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8022",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/especial-black-friday-circuito-wellness/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8023",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/sacro-craneal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8024",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/masaje-bio-muscular-profundo-25-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8025",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/masaje-deportivo-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8026",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-iberostar-las-gaviotas/lomi-lomi-nui-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8027",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/oxigenante-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8028",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/lavar-marcar-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8029",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/depilacion-laser-zona-mediana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8030",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/especial-embarazadas-hidratacion-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8031",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/masaje-lomi-lomi-hawaiano-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8032",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/circuito-spa-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8033",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/oferta-3-peeling-corporal-masc-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8034",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/mi-tiempo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8035",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/body-care-labranda-spa-wellness/peeling-corporal-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8036",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/alquiler-banador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8037",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/depilacion-corporal-completo-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8038",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/pack-circuito-wellness-con-tratamiento-facial-iluminador-efecto-flash/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8039",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/especial-black-friday-facial-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8040",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/pedicura-con-gel-color/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8041",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/masaje-descontracturante-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8042",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-15min-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8043",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/ritual-coffe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8044",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/neceser-cuidado-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8045",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/depilacion-ingles-linea-bikini/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8046",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/limpieza-facial-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8047",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/especial-pareja-circuito-ohtels-masaje-de-25-min-servicio-de-te-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8048",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/masaje-aromaterapia-con-vela/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8049",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/tinte-pelo-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8050",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/masaje-energy-tea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8051",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-15-min-alojados-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8052",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-luxury-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8053",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/experiencia-relax-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8054",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/ritual-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8055",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/paquete-spa-experience-4-hrs-spa-experience-masaje-turco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8056",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8057",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/rejuvenecimiento-facial-con-laser-diodo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8058",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/segovia/spa-spaxion-convento-capuchinos/facial-anti-age-enterna-juventud/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8059",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-bienestar-moana/feliz-dia-mama-ritual-deluxe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8060",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/extra-relaxing-love-circuito-beer-spa-pareja-masaje-25-minutos-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8061",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/m-produccion-escapada-100e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8062",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/pack-graduacion-para-estudiantes-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8063",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/ritual-flor-de-cerezo-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8064",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/cura-belleza-integral/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8065",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8066",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/pintar-unas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8067",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-antiestres-dia-3-piedras-volcanicas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8068",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/flyer-50-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8069",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/mesoterapia-virtual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8070",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-lomi-lomi-hawai-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8071",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/masaje-45-x-5/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8072",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/vitamina-c-supreme/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8073",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/bono-4-sesiones-masaje-parcial-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8074",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/circuito-spa-miercoles-2x1-adulto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8075",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/sun-repair/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8076",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-con-canas-de-bambu/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8077",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-citricos-15min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8078",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/circuito-spa-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8079",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-oriental-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8080",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/masaje-con-piedras-calientes-85min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8081",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-pureza-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8082",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/pack-navidad-esencias-circuito-spa-masaje-de-esencias-naturales-55-minutos-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8083",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/hifu-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8084",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-bahia-calpe/masaje-drenaje-linfatico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8085",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/alquiler-banador/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8086",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/pack-mujer/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8087",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-energy-tea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8088",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/peeling-corporal-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8089",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-con-maderoterapia-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8090",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/masaje-con-maderoterapia-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8091",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/drenaje-linfatico-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8092",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/reflexologia-podal-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8093",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/masaje-sueco-relax-melia-tamarindos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8094",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-hydracure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8095",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/masaje-son-brull-sanctuary-60min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8096",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/pack-romantic-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8097",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/al-wellness-experiencia-gastro-50-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8098",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/al-wellness-50-experiencia-gastro-50-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8099",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacion-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8100",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/bono-10-pases-piscina-exterior/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8101",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/naital-spa-wellness/masaje-descontracturante-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8102",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/masaje-facial-japones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8103",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/retirar-unas-gel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8104",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/peeling-corporal-de-uva/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8105",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/azores/musgo-spa/basalto-com-amor-express-facial-by-flor-azorica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8106",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/circuito-benidorm-grupo6px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8107",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/programa-energetico-revitalizante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8108",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/tratamiento-shanara-con-cuencos-tibetanos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8109",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/ritual-bienestar-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8110",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/tinte-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8111",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/romantico-premium-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8112",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/masaje-con-aromaterapia-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8113",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/romance-spa-privado-para-dos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8114",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/tinte-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8115",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/relax-choco-en-pareja-circuito-spa-chocolaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8116",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/spa-cena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8117",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-exclusivity-55-min-5-sesiones/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8118",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/prueba-peinado-novia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8119",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/exfoliacion-iluminadora/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8120",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-signature-50min-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8121",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/masaje-signature-50min-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8122",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-bodyna-madrid/relajese-y-disfrute-2-pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8123",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/escapada-romantica-privada-con-almuerzo-o-cena-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8124",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/masaje-deportivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8125",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/programa-energetico-revitalizante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8126",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/sensory-sound-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8127",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/porque-ellas-se-lo-merecen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8128",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/sapphire-experience-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8129",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-balneario-de-guitiriz-golf/masaje-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8130",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/pure-fresh-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8131",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/tratamiento-rejuvenecedor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8132",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/pedicura-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8133",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/volcanoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8134",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/experiencia-relax-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8135",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/masaje-corporal-con-aroma-de-30-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8136",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/tratamiento-facial-hydra-mineral-source-marine-con-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8137",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/masaje-relajante-de-espalda-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8138",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/oporto/the-spa-by-sheraton-porto/piedras-calientes-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8139",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/fitness-reducido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8140",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/circuito-zahara/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8141",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/aladinia-spamasaje-100e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8142",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/laura-leon-wellness/circuito-spa-nocturno-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8143",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/circuito-spa-peeling/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8144",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/viaje-a-la-ruta-de-las-delicias-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8145",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/termes-de-montbrio/argan/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8146",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/circuito-spa-adulto-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8147",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/m-produccion-spa-day-132e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8148",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/day-pass-con-spa-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8149",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/lpg/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8150",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/masaje-facial-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8151",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/corporal-after-sun/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8152",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/jardin-de-los-deseos-man-ritual-yang-by-alqvimia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8153",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/segovia/spa-spaxion-convento-capuchinos/limpieza-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8154",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/bioterapia-facial-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8155",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/royal-spa-circuito-privado-para-2-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8156",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/sensations-day-deluxe-day-pass-x-2-tu-dia-especial-con-masaje-y-moet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8157",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/hsr-lifting-y-antiaging-al-mas-alto-nivel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8158",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/descansa-de-las-vacaciones-disfruta-de-50min-de-spa-con-masaje-de-25min-y-copa-de-cava-o-zumo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8159",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-ayurveda-dia-3-shiro-cabeza-pad-abhyanga-piernas-i-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8160",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-ayurveda-dia-3-facial-rasayana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8161",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-relajante-anti-stres-duo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8162",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/vip-serenity-full-body-ritual-y-spa-privado-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8163",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/circuito-spa-adulto-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8164",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/prueba-maquillaje-novia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8165",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/relajacion-con-chocolate-70/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8166",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/iberik-augas-santas-balneario-golf/masaje-iberik-termal-pqt/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8167",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/cozy-experience-opcion-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8168",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/espacio-acuatico-2x1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8169",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/depilacion-con-diodo-zona-grande/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8170",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/exfoliacion-corporal-completa-envoltura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8171",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/beer-spa-salou/relaxing-love-circuito-beer-spa-pareja-masaje-15-minutos-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8172",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/exfoliacion-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8173",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/facial-hidratacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8174",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/tratamiento-facial-post-verano-con-piedra-jade-circuito-termal-promocion-noviembre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8175",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/masaje-de-pindas-con-extracto-de-cerveza-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8176",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/kit-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8177",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/circuito-spa-externo-bono-8-2-entradas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8178",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/masaje-deportivo-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8179",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/depilacion-de-entrecejo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8180",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-siargao/2x1-especial-en-deep-tissue-massage-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8181",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/spa-masaje-total-relajacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8182",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/tratamiento-facial-efecto-flash/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8183",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/pack-navidad-glow-up-circuito-spa-tratamiento-lifting-sicilium-marin-pack-regalo-pack-para-1-persona/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8184",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/circuito-vitality-grupos-6px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8185",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8186",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/masaje-energy-tea/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8187",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/circuito-dusch-alojados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8188",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/san-valentin-masaje-banera/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8189",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/la-cala-spa/facial-para-hombres-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8190",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/hidra-marine-higiene-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8191",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/wellness-center-natural-spa/masaje-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8192",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/momento-spa-brunch/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8193",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/nammu-spa-area-vincci-costa-golf/facial-mas-especifico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8194",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/tratamiento-antimanchas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8195",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/masaje-samay-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8196",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/contorno-de-ojos-cryo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8197",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/contorno-de-ojos-cryo-sothys/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8198",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/facial-hidratacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8199",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-naranja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8200",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/drenaje-linfatico-45-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8201",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8202",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/lomi-lomi-nui/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8203",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/entrenados-personal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8204",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/higueron-spa/pack-experience-day-spa-con-balinesa-en-infinity/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8205",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/circuito-spa-miercoles-2-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8206",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/depilacion-de-pecho/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8207",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/tratamiento-facial-rosa-de-bulgaria/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8208",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/ritual-masaje-balines-en-pareja-con-frutas-y-cava/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8209",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/plancha-rizada/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8210",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-tailandes-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8211",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-chocolate-15/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8212",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-belleza-dia-1-peeling-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8213",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/masaje-de-piedras-calientes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8214",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tarragona/spa-les-oliveres/exfoliacion-corporal-completa-envoltura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8215",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/recogido-alto-senza-vi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8216",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/recogido-fiesta/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8217",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/masaje-relax-60min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8218",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-deep-tissue-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8219",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/deep-tissue-45-min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8220",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/hipotels-mediterraneo-hotel/ayurveda-para-conocer/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8221",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/especifico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8222",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-daniya-denia/pass-gym-alojado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8223",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/personalizado-masaje-ayurvedico-40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8224",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/ritual-pasion-belga/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8225",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-descontracturante-de-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8226",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/masaje-local-nino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8227",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/tarjeta-regalo-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8228",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-empaquetado-europlayas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8229",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-15-min-pago-directo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8230",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-srns/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8231",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spaxion-estepona/masaje-luz-serena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8232",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-masia-vallromanes/promocion-black-friday-circuito-de-aguas-masaje-relax-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8233",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/energy-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8234",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/retirado-gel-color/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8235",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/senator-granada-spa-hotel/masaje-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8236",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/masaje-deportivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8237",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/tto-gold-therapy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8238",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-beer-spa-masaje-15-minutos-sala-comun-1-persona-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8239",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/masaje-holistico-con-aceite-de-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8240",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/masaje-craneo-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8241",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/circuito-spa-naturista/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8242",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cortar-y-peinar-senora-pelo-largo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8243",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-slimming-dia-1-masaje-circulatorio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8244",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/hifu-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8245",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacion-axilas-y-bikini-ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8246",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/orquidea-club-spa-resort-bahia-feliz/sensations-day-tu-dia-especial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8247",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/spa-buffet-infantil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8248",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/ultrasonido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8249",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8250",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-la-gavina/ritual-facial-valmont-purete-des-alpes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8251",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/circuito-spa-menores-4-a-14-anos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8252",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/depilacion-medias-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8253",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/aromaterapia-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8254",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/masaje-shi-zen-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8255",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/color-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8256",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/aromaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8257",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-de-la-felicidad-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8258",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/gr-spa-con-masaje-l-j/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8259",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/9issui-spa-fitness/circuito-spa-masaje-relax-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8260",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/masaje-corporal-nino-a-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8261",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-premium-pareja-residente-canario-justificante-de-residencia-obligatorio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8262",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-exclusivity-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8263",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/luz-del-mediterraneo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8264",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/beatriz-costa-spa/presoterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8265",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/relax-integral/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8266",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/corporales-vitamina-c-scrub/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8267",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-beatriz-toledo-auditorium/arctique/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8268",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/programa-serendipity/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8269",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/promocion-circuito-spa-masaje-15-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8270",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-iberostar-bahia-de-palma/sublime-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8271",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/depilacion-medias-piernas-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8272",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/ritual-pureza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8273",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/experiencia-relax-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8274",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/circuito-de-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8275",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/bicolor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8276",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/spa-peeling-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8277",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/especial-pareja-circuito-dusch-servicio-de-te-parejas-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8278",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/depilacion-espalda-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8279",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-quirogolf/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8280",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/masaje-relajante-i-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8281",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/santa-cruz-de-tenerife/beer-spa-tenerife/circuito-privado-pareja-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8282",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/circuito-privado-pareja-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8283",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/cara/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8284",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masajes-orientales-90-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8285",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-kobido-emperatriz/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8286",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-hidraluronic/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8287",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/momento-spa-masaje-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8288",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/frigi-legs-piernas-ligeras/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8289",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/sport-treat/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8290",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/piscina-puesta-en-forma-puente-de-la-asuncion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8291",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/pack-rosa-cerveza-hidromiel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8292",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/circuito-spa-adulto-club-senator-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8293",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-sensations-llaut-palma/lomi-lomi-nui-70-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8294",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/delicia-de-chocolate-be-aloe-wellness/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8295",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-leo-deluxe/pack-luxury-love/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8296",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/pedicura-basica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8297",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-marbella-spa-hotel/circuito-spa-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8298",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/toledo/spa-domus-aurea/masaje-localizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8299",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playacartaya-spa-hotel/ritual-for-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8300",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-ayurveda-abhyanga-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8301",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/castellon/balneario-de-montanejos/sesion-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8302",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/the-essential/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8303",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/balneario-de-chiclana/masaje-imserso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8304",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/exotico-ceilan/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8305",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabodegata-by-calagrande/circuito-termal-90-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8306",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/cura-intensiva-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8307",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/masaje-apacible-paraiso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8308",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/navaja-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8309",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8310",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/o-spa-pure-salt-port-adriano/pure-salt-ritual-global-experience/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8311",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-bodyna-palau-de-mar-valencia/masaje-drenante-de-pies-y-piernas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8312",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/vaho-spa/tratamiento-para-contorno-de-ojos-y-labios/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8313",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-signature-80min-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8314",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/masaje-signature-80min-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8315",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/fusion-asiatica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8316",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-exclusivity-completo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8317",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/spa-attica-21-villalba/bienestar-en-familia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8318",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/semi-recogido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8319",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/spa-privado-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8320",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/bono-4-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8321",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-leo-deluxe/pack-romantic-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8322",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-la-residencia-tarifa/exfoliacion-corporal-de-coco/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8323",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-armonia-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8324",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/desayuno-buffet-circuito-spa-masaje-25min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8325",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/shama-spa/personalizado-masaje-descontracturante-40/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8326",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/pedicura-esmalte-de-gel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8327",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-mirador/energy-c-masaje-circuito-de-agua-120-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8328",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/reflexologia-podal-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8329",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/girona/spa-mas-de-torrent/diamond-experience-facial-ritual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8330",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/pluschampoomascarillaplancha/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8331",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/guipuzcoa/spa-foresta-wellness/circuito-spa-y-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8332",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/spalopia-spa-brunch-no1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8333",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/cavitacion-presoterapia-1-sesion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8334",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-iberostar-las-gaviotas/deep-tissue/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8335",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/manicura-pedicura-spa-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8336",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/labio-superior-o-barbilla/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8337",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/relajese-y-disfrute-con-bebida-incluida/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8338",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/masaje-esencia-natural-chocolate-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8339",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/especial-pareja-circuito-spa-y-belleza-masaje-55min-servicio-de-te-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8340",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/hb-black-friday-serenity-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8341",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-aqua-no-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8342",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-palma/spa-la-palma-teneguia-princess/princess-aromaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8343",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-cadiz/pack-navidad-vikingo-circuito-spa-sala-comun-para-dos-personas-pack-regalo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8344",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/balneario-thermas-de-grinon/circuito-termal-navidad-expres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8345",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-roca-negra/circuito-spa-obsidiana-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8346",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/lavar-y-cortar-hombre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8347",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-hotel-alicante-golf/momento-spa-masaje-50-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8348",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masajes-orientales-60-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8349",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/smb-spa-2-pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8350",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/masaje-60-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8351",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/60-minutes-massage/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8352",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/masaje-anticelulitico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8353",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/masaje-con-aromaterapia-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8354",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/depilacion-piernas-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8355",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/the-spa-by-signature-sir-victor/serenity-vitality-ritual-55min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8356",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/relax-futuras-mamas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8357",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/black-friday-2x1-masaje-30-entrada-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8358",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/circuito-deloix-grupo6px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8359",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/reflexologia-podal-55min-home-service/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8360",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/ritual-de-juventud-kobido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8361",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/tratamiento-luminoso-ritual-flores-de-bali-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8362",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/hotel-neptuno-spa/spa-masaje-y-gastronomia-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8363",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/playa-marina-spa-hotel/ritual-so-delicate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8364",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/exfoliacion-con-peeling-cerveza-masaje-hidratante-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8365",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/masaje-especial-embarazadas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8366",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/mi-tiempo-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8367",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/depilacion-brazos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8368",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/tahara-spa/peeling-hidratacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8369",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-attica21-vigo/experiencia-spa-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8370",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/lavar-cortar-y-marcar/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8371",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-es-port/manicura-or-pedicure-gel/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8372",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/albacete/beatriz-albacete-spa/tratamiento-peeling-marine-by-thalgo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8373",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/masaje-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8374",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/timexpert-rides/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8375",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/masaje-cuatro-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8376",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/experiencia-uvas-de-la-suerte/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8377",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/masaje-holistico-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8378",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/special-black-friday-circuito-spa-60-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8379",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/especial-pareja-circuito-ohtels-masaje-de-25-min-servicio-de-te-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8380",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/pack-romantico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8381",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/ingles-integral/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8382",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/cena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8383",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/al-spa-75cc-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8384",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/al-spa-50cava-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8385",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/al-spa-50cc-v-d/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8386",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/masaje-especial-para-embarazadas-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8387",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/masaje-especial-para-embarazadas-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8388",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/escapada-romantica-privada-para-2-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8389",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/masaje-corporal-45/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8390",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/extra-relaxing-love-circuito-beer-spa-pareja-masaje-25-minutos-pack-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8391",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/masaje-thailandes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8392",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-sueco-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8393",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/energy-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8394",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-seda-club/especial-black-friday-masaje-holistico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8395",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/lomi-juma/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8396",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/especial-rebajas-piscina-puesta-en-forma/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8397",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/exfoliacion-con-peeling-cerveza-masaje-hidratante-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8398",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/bioterapia-antiaging-plus-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8399",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/piernas-ligeras-circulatorio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8400",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/despertar-by-serena-spa-desayuno-50-masaje-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8401",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/tratamiento-facial-hidratacion-intensiva/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8402",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/masaje-lomi-lomi-hawaii-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8403",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/lavar-y-peinar-pelo-medio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8404",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/gloria-palace-san-agustin-thalasso/peeling-cristal-con-flores/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8405",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/experiencia-mediterranea-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8406",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/reflexologia-podal-duo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8407",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-40-min-recepcion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8408",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/bodycare-the-volcan-lanzarote/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8409",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/senator-caleia-talayot-spa/ritual-hydraluronic/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8410",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-victoria/masaje-relajante-en-pareja-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8411",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/masaje-relajante-90-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8412",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/tinte-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8413",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/ocean-view-pool-30min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8414",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/ocean-view-pool-18e-suplemento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8415",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-sensations-lanzarote-park/higiene-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8416",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/foot-hand-dream/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8417",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/almunecar-playa-spa-hotel/ritual-bienestar-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8418",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/cerezaterapia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8419",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/isla-cristina-spa-ilunion-islantilla-huelva/jacuzzi-masaje-20-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8420",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/exfoliacion-con-savon-noir-beldi/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8421",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/agencias-wellness-area-50-privatizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8422",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-de-cerveza-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8423",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/masaje-ayurvedico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8424",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-nammu-corner-area-spa/ritual-facial-corporal-extra-vitamina-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8425",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cura-banera-cleopatra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8426",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/the-spa-by-signature-sir-victor/love-massage-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8427",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/fitspa-pullman-barcelona/masaje-deportivo-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8428",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/circuito-beer-spa-masaje-completo-55min-sala-comun-pareja-especial-black-friday/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8429",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/masaje-sueco-duo-80min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8430",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/experiencia-relax-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8431",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/mama-te-lo-mereces-circuito-agua-masaje-gratis-circuito-agua-acompanante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8432",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/papa-te-lo-mereces-masaje-antiestres-y-circuito-de-aguas-gratis-acompanante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8433",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/envoltura-con-algas-marinas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8434",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/a-coruna/spa-sercotel-odeon-hotel/masaje-infantil/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8435",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/ritual-gold-theraphy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8436",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-10-min-individual-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8437",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cantabria/spa-castilla-termal-solares/envolturas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8438",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/playaballena-spa-hotel/ritual-esplendor-radiante-circuito/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8439",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/tinte-de-pestanas-y-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8440",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/centro-bienestar-sunprime-atlantic/exfoliacion-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8441",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-oriental-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8442",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-exfoliacion-con-savon-noir-beldi-exfoliaciones-corporales/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8443",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/gentleman-facial-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8444",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/facial-especial-sierra-nevada/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8445",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-15min-directo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8446",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-ritual-de-kioto-japon-rituales-del-mundo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8447",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/relax-andalusi-en-pareja-circuito-spa-ritual-al-andalus/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8448",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sky/especial-navidad-beauty-spa-facial-flash-copa-cava-y-cofre-alqvimia-regalo-para-ti/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8449",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/preparacion-de-cabina/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8450",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/pack-spa-enamorados/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8451",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-sa-voga/ritual-perlas-y-oro-del-pacifico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8452",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-spa/masajes-de-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8453",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/gimnasio-7-dias/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8454",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/thalasso-gloria-amadores/masaje-90-promo-dia-del-padre-2024/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8455",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huelva/spa-islantilla/especial-parejas-circuito-ohtels-masaje-de-15-min-servicio-de-te-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8456",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/alqvimia-luxury/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8457",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/ritual-de-atlas-1pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8458",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/ritual-equilibrante-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8459",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/envolvimiento-de-algas-marinas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8460",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-25min-chocolate-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8461",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/ritual-radiance-c/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8462",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-25min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8463",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/facial-hydracure/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8464",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/sala-vip/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8465",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/balneario-san-nicolas/tratamiento-piel-de-cleopatra/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8466",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/reflexologia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8467",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/pack-tensor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8468",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/manicura-esmalte-normal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8469",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bodycare-gara-suites-golf-spa/especial-mexico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8470",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/sh-villa-gadea/exfoliacion-y-envoltura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8471",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-exclusivity-de-cerveza-55min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8472",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/pack-romantico-san-valentin/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8473",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/calendario-adviento-alqvimia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8474",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/manicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8475",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/depilacion-piernas-completas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8476",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacion-bikini-ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8477",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/black-beer-burbujeante-circuito-sala-comun-regalos-para-dos-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8478",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/bodyna-tratamiento-facial-fleurs-de-bali-tratamientos-faciales/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8479",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/moldeador-de-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8480",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/hyaluronic-filler-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8481",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/depilacion-espalda-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8482",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/pack-para-dos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8483",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/azores/musgo-spa/reflexologia-pes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8484",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/cena-2-px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8485",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/hesperides-thalasso-spa/experiencia-sensorial-citrica-acceso-thalasso/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8486",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/centro-bienestar-sunprime-atlantic/reflexologia-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8487",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-therapy-o2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8488",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-by-asetra-corte-ingles-castellana/pedicura-basica/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8489",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/masaje-deep-tisue/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8490",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/aladinia-masaje-de-40-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8491",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-royal-andalus/promocion-black-friday-mananas-deluxe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8492",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-spaxion-by-asetra-corte-ingles-alicante/piedras-de-yucamani/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8493",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-alto-aragon/cascada-de-luz/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8494",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/recogido-novia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8495",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/depilacion-piernas-enteras-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8496",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/spa-almuerzo-en-buffet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8497",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/depilacion-espalda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8498",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-20-min-2px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8499",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/pack-massada-men/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8500",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/spa-peeling/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8501",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/drenaje-linfatico-manual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8502",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/serena-spa-melia-madrid-princesa/masaje-experiencia-profes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8503",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/viaje-magico-de-la-ruta-de-la-seda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8504",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/man-glam-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8505",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/lifting-rejuvenecedor-tensor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8506",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/pack-energy/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8507",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/unico-spa/prueba-servicio-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8508",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cortar-y-lavar-caballero-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8509",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/san-valentin-masaje-30-y-spa-true-love/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8510",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/despacio-beauty-center/sesion-reiki-residentes-lanzarote/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8511",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/masaje-futura-mama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8512",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/facial-seashell-sense-hydrating/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8513",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/full-vitality-spa-sierra-nevada/experiencia-de-la-luna-llena-en-sierra-nevada/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8514",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-de-pindas-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8515",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/masaje-10-min-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8516",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/doce-love-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8517",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/doce-love-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8518",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hotel-spa-cadiz-plaza/masaje-facial-kobido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8519",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-anti-dolor-dia-1-evaluacion-dolor/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8520",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-azules-nivaria/spa-privado-by-night-para-1-a-6-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8521",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-las-salinas/facial-balance-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8522",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/c-privado-sala-comun/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8523",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/sueno-de-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8524",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-diamante-beach/circuito-spa-2-adultos-2-ninos-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8525",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/depilacion-laser-zona-muy-pequena/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8526",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-barcelona-golf/especial-pareja-navidad/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8527",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/my-day-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8528",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/masaje-55-min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8529",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/signature-spa-beach/love-massage-50min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8530",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/exfoliacion-corporal-completa-envoltura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8531",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/beer-spa/circuito-beer-spa-grupo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8532",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-balmoral-hotel/verano-de-relax-masaje-50-solarium-con-copa-de-cava-30-2x1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8533",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/tratamiento-facial-tensor-radiofrecuencia-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8534",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cordoba/spa-bodyna-cordoba/exfoliacion-iluminadora-con-pure-de-papaya/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8535",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/masaje-completo-pareja-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8536",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/exfoliacion-con-peeling-cerveza-masaje-hidratante-cerveza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8537",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-el-cortijo/pack-hydr-oxigen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8538",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/atardecer-laguna/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8539",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/masaje-secretos-de-karite-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8540",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-in-melia-tamarindos/retirado-de-esmalte-de-gel-permanente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8541",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/manicura-francesa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8542",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/tratamiento-facial-kobido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8543",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-bodyna-maricel/masaje-deportivo-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8544",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/cura-control-de-peso-y-o-volumen/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8545",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/playacalida-spa-hotel/circuito-spa-menores/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8546",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/the-ritz-carlton-spa/ritual-tradicional-abama/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8547",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/ritual-pindas-energeticas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8548",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/depilacion-de-ingles/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8549",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/circuito-spa-masaje-40-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8550",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/masaje-piernas-y-pies-25/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8551",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-aromaterapia-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8552",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/senzia-spa-wellness-barcelona/ritual-kobido-emperatriz/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8553",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/spa-senzia-caleia-mar-menor/experiencia-relax-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8554",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor/masaje-relajante-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8555",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/entrenamiento-funcional/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8556",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/entrenamiento-func-8-ses/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8557",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-gran-playa-palma/facial-aqua-rituals/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8558",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/spa-vegano-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8559",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/the-candle-love/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8560",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-detox-dia-2-masaje-circulatorio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8561",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-detox-dia-5-envolura-algas-masaje-circulatorio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8562",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-de-chocolate-252px/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8563",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/beer-spa-en-zahara/pack-bano/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8564",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/masaje-15min-paquete/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8565",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-iberostar-sabila/ayurveda-abhyanga-55-min-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8566",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/jarra-regalo-dia-del-padre/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8567",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-tenerife/masaje-aloe-vera-25-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8568",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-colon/depilacion-laser-dos-zonas-pequenas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8569",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-hotel-laguna-nivaria/sprunch-con-masaje-incluido/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8570",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/rock-spa-tenerife/acceso-circuito-spa-huespedes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8571",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/leyendas-del-oceano-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8572",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/despertar-by-serena-spa-desayuno-30-masaje-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8573",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/caceres/spa-bodyna-palacio-de-arenales-caceres/masaje-oriental-45/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8574",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/beer-spa-alicante/neceser-exfoliacion-xl/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8575",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-senzia-montanya/masaje-deep-tisue-50-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8576",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/beer-spa-tenerife/masaje-de-pindas-con-extractos-de-cerveza-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8577",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-beauty-gold-residente/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8578",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/programa-revitalizante-antiestres/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8579",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/espacio-acuatico-templarium-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8580",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/masaje-tailandes/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8581",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/lopesan-costa-meloneras/masaje-tailandes-80/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8582",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-10-min-individual-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8583",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-the-cookbook/summer-24-wellness-time/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8584",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/tinte-vegetal-caballero/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8585",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/15-minutos-adicionales/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8586",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/15-minutos-adicionales/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8587",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/15-minutos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8588",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/murcia/senator-mar-menor-golf-spa-resort/masaje-relajante-corporal-50/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8589",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/serena-spa-melia-sarria/black-friday-2x1-masaje-50-entrada-circuito-spa/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8590",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/faro/soul-spa-by-jupiter-marina/facial-hidratacao-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8591",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/faro/soul-spa-by-jupiter-marina/hidratacion-facial-profunda/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8592",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/senator-cadiz-spa-hotel/masaje-shi-zen-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8593",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/masaje-de-cerveza-de-55-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8594",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/benicaldea-thai-massage-spa/san-valentin-romantic-thai-experience-con-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8595",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/masaje-craneocervical-15/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8596",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/facial-royal-jelly/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8597",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/pack-navidad-relax-pareja-circuito-spa-ritual-gold-theraphy-pack-regalo-para-2-personas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8598",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/circuito-spa-para-ninos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8599",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/pedicura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8600",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-inagua/tinte-de-cejas-y-pestanas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8601",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/callao-spa-center/beauty-flash/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8602",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/ducha-jet/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8603",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-hd-parque-cristobal/promocion-black-friday-pack-relax-total-gastronomia/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8604",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/blancafort-spa-termal/exfoliacion-corporal-de-citricos-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8605",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/bono-4-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8606",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/bonos-masaje/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8607",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-gomera/spa-ahemon-jardin-tecina/momento-spa-masaje-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8608",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/pack-vital-prevention/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8609",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/higiene-facial-personalizada/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8610",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/radisson-blu-resort-gran-canaria/delicia-de-rosas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8611",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/masaje-a-cuatro-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8612",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/zimbali-playa-spa-hotel/masaje-corporal-con-mousse-de-chocolate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8613",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/masaje-de-cerveza-degustacion-de-1-cerveza-especial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8614",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/segovia/spa-spaxion-convento-capuchinos/momento-spa-y-masaje-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8615",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-bodyna-granada/especial-dia-de-la-madre-2-pax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8616",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/leon/leon-termal-sport/oferta-flash-masaje-local/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8617",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/senator-banus-spa-hotel/ritual-armonia-60/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8618",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/bono-de-5-masajes-50-all-days/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8619",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-fanabe/tratamiento-corporal-anticelulitico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8620",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/spa-nature-ilunion-les-corts/especial-black-friday-circuito-spa-ninos-4-15-anos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8621",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-calm-luxury-premium/privilege-mi-momento/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8622",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cantabria/spa-castilla-termal-solares/experiencia-balnea-romana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8623",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/pontevedra/spa-spaxion-by-asetra-pontevedra/dermoabrasion-spaxion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8624",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/blanqueamiento-dental/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8625",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alava/silken-villa-laguardia-hotel/hidratacion-wine-oil-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8626",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/sens-spa/depilacion-con-diodo-zona-media/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8627",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/masaje-hindu-de-cabeza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8628",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/cura-peeling-corporal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8629",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/lavar-y-peinar-cabello-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8630",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/playasol-spa-hotel/bono-solarium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8631",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/mechas-gorro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8632",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/la-palma/spa-la-palma-teneguia-princess/princess-momentos-compartidos-barro-y-aloe/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8633",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-sensations-marbella-coral-beach/axilas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8634",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/spa-bodyna-alicante/alicante-masaje-de-espalda-y-cabeza-20/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8635",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/senzia-gandia-spa-wellness/ritual-so-delicate/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8636",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/castellon/balneario-de-montanejos/masaje-craneo-facial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8637",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/thalasso-experience-menu-nature-corner/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8638",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/sevilla/spa-bodyna-las-casas-del-rey-de-baeza/ritual-brasileno-crema-de-cafe-adelgazante-y-reafirmante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8639",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/huesca/spa-spaxion-hg-alto-aragon/caprichos-de-la-naturaleza/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8640",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/hipotels-barrosa-palace-spa/lavar-y-secar-pelo-medio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8641",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/mallorca/spa-son-brull/mi-manana/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8642",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-tarifa/pack-vital-prevention/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8643",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-best-semiramis/programa-relax-total/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8644",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/dolce-vital-spa/oasis-relax-gourmet-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8645",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/elya-vital-spa/oasis-relax-gourmet-parejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8646",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-laguna-spa-golf/spa-bistro/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8647",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/pedicura-con-masaje-podal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8648",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/corte-nina-secado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8649",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/la-nucia-spa-masshotel/pack-romantico/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8650",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/spa-spaxion-madrid-by-asetra/lift/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8651",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/laura-leon-wellness/hidratacion/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8652",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-sensations-iberostar-selection-andalucia-playa/bioterapia-antiaging-plus-75min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8653",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/circuito-deloix-1px-atrapalo-15e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8654",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/manicura-spa-incluye-tratamiento-de-manos/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8655",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/balneari-broquetas/relax-day-viernes-a-domingo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8656",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-grand-hotel-mencey/ritual-passion-fruit-75-min-con-circuito-termal/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8657",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/oporto/neya-day-spa-porto/masaje-esencia-del-duero-90/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8658",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/masaje-deportivo/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8659",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-sensations-anthelia/ayurveda-abhyanga-55-min-doble/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8660",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/bio-spa-hotel-gf-isabel/spa-privado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8661",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/corallium-lopesan-villa-del-conde/secretos-marinos-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8662",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/banos-arabes-palacio-comares/bono-10-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8663",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lugo/spa-attica-21-villalba/tratamiento-manos-pies/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8664",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/granada-five-senses-spa/bono-10-sesiones-masaje-parcial/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8665",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/madrid/urso-hotel-spa/piedras-calientes-pareja/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8666",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/cadiz/spa-ges-melia-atlanterra/bono-10-sesiones-masaje-completo-1/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8667",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-la-quinta/tarjeta-regalo-50e/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8668",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/masaje-15min-individual/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8669",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/aqua-club-termal/pack-oriental-lomi-lomi-30-min/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8670",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/alicante/ges-spa-deloix/circuito-aqua-center/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8671",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-playa-granada/cura-relax/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8672",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/valencia/spa-spaxion-by-asetra-corte-ingles-avenida-francia/masaje-circulatorio/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8673",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/spa-wellness-center-natural-spa-gala-alexandre/lavar-y-peinar-pelo-corto/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8674",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-reina-victoria/programa-energetico-revitalizante/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8675",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/lanzarote/spa-body-care-la-cala-suites/pro-best-moment/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8676",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/fuerteventura/spa-sensations-fuerteventura-palace/pedicura-express/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8677",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/healthouse-medical-spa/ducha-kneipp/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8678",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/spa-vital-suites/spa-privado-masaje-para-2/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8679",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/granada/spa-catalonia-granada/tarjeta-regalo-premium/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8680",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/gran-canaria/be-aloe-wellness-salobre-golf-hotel/tinte-de-cejas/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8681",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/barcelona/grums-spa-mediterranean-wellness/personalized-facial-men-women-30/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8682",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/tenerife/oriental-spa-garden-hotel-botanico/programa-oriental-dia-1-masaje-thai/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8683",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/exfoliacion-corporal-completa-envoltura/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8684",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/almeria/spa-cabogata/bano-de-vino/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  },
  {
    name: Redirection_46pagerCHv4cd173Meta?.name ?? "Redirection landing: 8685",
    path: Redirection_46pagerCHv4cd173Meta?.path ?? "/spa-balneario/malaga/spa-catalonia-ronda/tratamiento-reductor-reafirmante-localizado/",
    props: Redirection_46pagerCHv4cd173Meta?.props ?? false,
    meta: Redirection_46pagerCHv4cd173Meta || {},
    alias: Redirection_46pagerCHv4cd173Meta?.alias || [],
    redirect: Redirection_46pagerCHv4cd173Meta?.redirect,
    component: () => import("/usr/src/app/content/n3-redirections-service-d09c6306-0e38-4ad1-a42d-8081742478d2/b2c-build/core/pages/redirection/Redirection.page.vue")
  }
]