<template>
  <div />
</template>

<script lang="ts">
import FBAdsEventBus from '~/core/events/fb-ads'

type FBEvent = {
  name: string,
  meta: Record<string, string>
}

export default defineNuxtComponent({
  setup() {
    const runtimeConf = useRuntimeConfig()

    const isActive = ref(false)
    const unSentEvents: Array<FBEvent> = []

    function sendEvent(name: string, meta: Record<string, string>) {
      const win = window as any

      if (!isActive.value) {
        unSentEvents.push({ name, meta })
        return
      }

      if (name.includes('custom.')) {
        const customEventName = name.split('.')[1]
        win.fbq('trackCustom', customEventName, meta)
        return
      }

      win.fbq('track', name, meta)
    }

    let attempts = 0
    function checkFBPixel() {
      const win = window as any

      attempts++

      if (attempts > 64) {
        console.warn('FBAds cannot be configured. FB pixel is not running!')
        return
      }

      if (!win.fbq) {
        setTimeout(checkFBPixel, 1000)
        return
      }

      isActive.value = true

      unSentEvents.forEach((event) => {
        sendEvent(event.name, event.meta)
      })

      console.info('FBAds is running')
    }

    onBeforeMount(() => {
      if (runtimeConf.public.isDev) {
        return
      }

      FBAdsEventBus.on(sendEvent)

      checkFBPixel()
    })

    return {}
  }
})
</script>
