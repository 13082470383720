import { isValidEmailString, isValidSimpleText } from '../util/string'

export type Buyer = {
  name: string;
  surname: string;
  phone: string;
  email: string;
  comment: string;
  location: {
    address: string,
    country: string,
    city: string,
    zipCode: string,
  };
  allowNotifications: boolean;
  termsAndConditions?: boolean;
};

const phoneValidator = (phoneStr: string) => {
  const cleanPhone = phoneStr.replaceAll(' ', '').replaceAll('+', '')

  const aCode = ('a').charCodeAt(0)
  const zCode = ('z').charCodeAt(0)

  for (const number of cleanPhone) {
    const code = number.charCodeAt(0)
    if (code >= aCode && code <= zCode) {
      return false
    }
  }

  return true
}

export const BuyerMethods = {
  validate: {
    name: isValidSimpleText,
    surname: isValidSimpleText,
    email: isValidEmailString,
    address: () => true,
    city: () => true,
    country: () => true,
    phone: (value: string) => {
      if (value) {
        return phoneValidator(value)
      }

      return true
    },
    requiredPhone: (value: string) => {
      if (!isValidSimpleText(value)) {
        return false
      }

      return phoneValidator(value)
    },
    zipCode: () => true, // zipCodeValidator,
  },
}
