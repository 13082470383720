import { translateForLang } from './util/translate'

const contentFolder = '.web-content/'

const registryFile = contentFolder + '__last-update'

function getPartialDeployConfig(): Array<string> {
  const rawDevFilter = process.env.PARTIAL_DEPLOY || ''

  if (!rawDevFilter.length) {
    return []
  }

  return rawDevFilter
    .split(',')
    .map((match) => match.trim())
    .filter((match) => !!match.length)
}

function isBlackFriday() {
  const today = new Date()
  const fullyear = today.getFullYear()
  const now = today.getTime()

  const startDate = (new Date(`${fullyear}-11-24`)).getTime()
  const finalDate = (new Date(`${fullyear}-12-03`)).getTime()

  return (finalDate > now && startDate < now)
}

// Activación automática de Navidad
function isChristmas() {
  const today = new Date()
  const fullyear = today.getFullYear()
  const now = today.getTime()

  // Diciembre del año actual
  const startDicDate = (new Date(`${fullyear}-12-03`)).getTime()
  const finalDicDate = (new Date(`${fullyear + 1}-01-01`)).getTime()

  // Enero del año siguiente
  const startJanDate = (new Date(`${fullyear}-01-01`)).getTime()
  const finalJanDate = (new Date(`${fullyear}-01-07`)).getTime()

  return (finalDicDate > now && startDicDate < now) || (finalJanDate > now && startJanDate < now)
}

// Activación automática de San Valentín
function isValentines() {
  const today = new Date()
  const fullyear = today.getFullYear()
  const now = today.getTime()

  const startDate = (new Date(`${fullyear}-01-29`)).getTime()
  const finalDate = (new Date(`${fullyear}-02-15`)).getTime()

  return (finalDate > now && startDate < now)
}

function isFathersDay() {
  const today = new Date()
  const fullyear = today.getFullYear()
  const now = today.getTime()

  const startDate = (new Date(`${fullyear}-03-01`)).getTime()
  const finalDate = (new Date(`${fullyear}-03-20`)).getTime()

  return (finalDate > now && startDate < now)
}

function isMothersDay() {
  const today = new Date()
  const fullyear = today.getFullYear()
  const now = today.getTime()

  const startDate = (new Date(`${fullyear}-04-15`)).getTime()
  const finalDate = (new Date(`${fullyear}-05-06`)).getTime()

  return (finalDate > now && startDate < now)
}

function isTeacherGift() {
  const today = new Date()
  const fullyear = today.getFullYear()
  const now = today.getTime()

  const startDate = (new Date(`${fullyear}-05-15`)).getTime()
  const finalDate = (new Date(`${fullyear}-07-1`)).getTime()

  return (finalDate > now && startDate < now)
}

function getCurrentPromotionYearStr() {
  const today = new Date()
  const fullyear = today.getFullYear()

  const month = today.getMonth() + 1
  const day = today.getDate()

  // Primera semana de enero, Navidades.
  if ((day >= 1 || day <= 10) && month === 1) {
    return fullyear - 1
  }

  return fullyear
}

const config = (function configConstructor() {
  const environment = process.env.ENV || 'dev'

  const isProd = environment === 'prod'
  const isDev = environment === 'dev'

  const domain = process.env.DOMAIN || 'http://localhost:3000'
  const apiBaseURL = process.env.API_BASE_URL || ''

  const language = process.env.LANGUAGE || 'es'
  const shouldPrependLanguajeToURLs = language !== 'es'

  // External keys
  const recaptchaSiteKey = process.env.RE_CAPTCHA || ''
  const ga4Key = process.env.GA4_KEY || ''
  const ga4ConversionKey = process.env.GA4_CONVERSION_KEY || ''
  const gtmKey = process.env.GTM_KEY || ''

  const redsysScriptURL = process.env.REDSYS_INSITE_URL || ''
  const sentryDSN = process.env.SENTRY_DSN || ''

  const partialDeployConfig = getPartialDeployConfig()

  const apiToken = 'f8d7ceb1a201753bd211fa073103dbca'

  // Enabled features
  const spasEnabled = process.env.NUXT3_FEATURE_SPAS === 'true' || false
  const noSpasEnabled = process.env.NUXT3_FEATURE_NO_SPAS === 'true' || false

  const promosEnabled = process.env.NUXT3_FEATURE_PROMOS === 'true' || false
  const treatmentsEnabled = process.env.NUXT3_FEATURE_TREATMENTS === 'true' || false

  const getAwaysEnabled = process.env.NUXT3_FEATURE_PACKAGES === 'true' || false

  const spasRedirectionEnabled = process.env.NUXT3_FEATURE_REDIRECTIONS_SPA === 'true' || false
  const servicesRedirectionEnabled = process.env.NUXT3_FEATURE_REDIRECTIONS_SERVICE === 'true' || false
  const getAwaysRedirectionEnabled = process.env.NUXT3_FEATURE_REDIRECTIONS_PACKAGE === 'true' || false
  const legacyRedirectionEnabled = process.env.NUXT3_FEATURE_REDIRECTIONS_LEGACY === 'true' || false

  const redirectionsEnabled = spasRedirectionEnabled
    || servicesRedirectionEnabled
    || getAwaysRedirectionEnabled
    || legacyRedirectionEnabled

  const sitemapEnabled = spasRedirectionEnabled

  const versionTag = process.env.VERSION_TAG || '4.x.x'

  const getAwayBaseUrlByLang = {
    es: 'escapadas-hotel-spa',
    pt: 'escapadinhas-hotel-spa',
  }
  const spasBaseUrlByLang = {
    es: 'spa-balneario',
    pt: 'spas',
  }
  const promotionBaseUrlByLang = {
    es: 'promociones',
    pt: 'promocoes',
  }

  return {
    runtime: {
      domain,
      language,
      shouldPrependLanguajeToURLs,

      generator: {
        spasEnabled,
        noSpasEnabled,

        promosEnabled,
        treatmentsEnabled,
        getAwaysEnabled,

        // Redirecciones
        redirectionsEnabled,
        spasRedirectionEnabled,
        servicesRedirectionEnabled,
        getAwaysRedirectionEnabled,
        legacyRedirectionEnabled,

        sitemapEnabled,
      },

      urls: {
        getAwayBaseUrlByLang,
        spasBaseUrlByLang,
        promotionBaseUrlByLang,
        getAwayBaseUrl: translateForLang(getAwayBaseUrlByLang, language),
        spasBaseUrl: translateForLang(spasBaseUrlByLang, language),
        promotionBaseUrl: translateForLang(promotionBaseUrlByLang, language),
      },

      isProd,
      isDev,

      apiBaseURL,

      versionTag,

      // Legacy
      bookingIframePath: 'https://api.spalopia.app/utbapis/utbinsert/load.php',

      // Claves de servicios externos.
      recaptchaSiteKey,
      ga4Key,
      ga4ConversionKey,
      gtmKey,

      sentryDSN,
    },

    migrator: {
      contentFolder,
      registryFile,
      location: {
        entities: contentFolder + 'locations.json',
      },
      spas: {
        landingsFile: contentFolder + 'spas-landings.json',
        landingsByLocationFile: contentFolder + 'spas-landings-by-location.json',
      },
      treatment: {
        landingsFile: contentFolder + 'treatment-landings.json',
        entities: contentFolder + 'treatments.json',
        entityParents: contentFolder + 'treatments-parent.json',
      },
      promotion: {
        landingsFile: contentFolder + 'promotion-landings.json',
        entities: contentFolder + 'promotion.json',
      },
      getaway: {
        landingsFile: contentFolder + 'getaway-landings.json',
        entities: contentFolder + 'getaway.json',
      },
      redirection: {
        spasFile: contentFolder + 'redirections-spas.json',
        servicesFile: contentFolder + 'redirections-services.json',
        getAwaysFile: contentFolder + 'redirections-get-aways.json',
        legacyFile: contentFolder + 'redirections-legacy.json',
      }
    },

    apiToken,

    urls: {
      voucherPurchaseBaseUrl: 'voucher',
      getAwayPurchaseBaseUrl: 'purchase', // LEGACY
      treatmentBaseUrl: 'tratamientos',
      serviceBookingUrl: 'service-booking',
      apiBaseURL,
    },
    promos: {
      isBlackFriday: isBlackFriday(),
      isChristmas: isChristmas(),
      isFathersDay: isFathersDay(),
      isMothersDay: isMothersDay(),
      isValentines: isValentines(),

      // Extra promotions
      isTeacherGift: isTeacherGift(),

      // Automatic labels
      promotionYearStr: getCurrentPromotionYearStr(),
    },
    payment: {
      redsysScriptURL,
    },
    partialDeployConfig,
  }
})()

export default config
