import { supportedLangs } from '../lang-config'
import config from '../server-config'
import type { Location } from './Location'
import { Money } from './Money'
import { Picture, type PictureDTO } from './Picture'
import { Promotion } from './Promotion'
import type { SpaLocation, SpaSaleConfig } from './Spa'
import { Tag, type TagDTO } from './Tag'

export type ServiceStatus = 'enable' | 'disable' | 'disable_by_closed_temp_spa' | 'disable_by_closed_spa'

export interface ServiceDTO {
  uuid: string,
  uuid_revision: string,
  spa_uuid: string,
  status: ServiceStatus,
  order: number,
  client_time_duration: number,
  online_booking_available: 'yes' | 'no' | 'request',
  is_online_voucher_available: true,
  is_package: boolean,
  has_availability_on: number | null,
  occupancy_type: string,
  price_per_person: {
    amount: number,
    currency: string,
  },
  translation: {
    langcode: string,
    name: string,
    description: string,
    legal_conditions: {
      legal_notice: string,
      political_condition: string,
      general_condition: string,
    },
    slug: string,
    uri: string,
  } | undefined,
  translations: Array<{
    langcode: string,
    name: string,
    description: string,
    legal_conditions: {
      legal_notice: string,
      political_condition: string,
      general_condition: string,
    },
    slug: string,
    uri: string,
  }> | undefined,
  slug: string,
  uri: string,
  capacity: {
    incrementing: number,
    maximum: number,
    minimum: number
  },
  price: {
    amount: number,
    currency: string,
  },
  offers: Array<{
    start_date: string;
    end_date: string;
    monday: boolean;
    tuesday: boolean;
    wednesday: boolean;
    thursday: boolean;
    friday: boolean;
    saturday: boolean;
    sunday: boolean;
    spa_discount_value: number;
    bono_discount_value: number;
    discount_mode: 'fixed' | 'percent';
    start_time: string;
    end_time: string;
  }>,
  images: PictureDTO[],
  service_tags: Array<TagDTO> | undefined,
  promotion_tags: Array<TagDTO> | undefined,
  category_tags: Array<TagDTO> | undefined,

  tags: Array<string> | undefined,
}

export interface Offer {
  startDate: number;
  endDate: number;
  daysByIndex: boolean[];
  spaDiscountValue: number;
  bonoDiscountValue: number;
  discountMode: 'fixed' | 'percent';
  startTime: string;
  endTime: string;
}

export type ServiceForEachTagFunc = (tag: Tag) => void

export type ServiceTexts = {
  langcode: string,
  name: string,
  description: string,
  legalNotice: string,
  politicalCondition: string,
  generalCondition: string,
  slug: string,
  uri: string,
}

export class Service {
  constructor(
    public uuid: string,
    public uuidRevision: string,
    public spaUUID: string,
    // Copiada del Spa
    public countryCode: string,

    public order: number,
    public status: ServiceStatus,
    public durationMin: number,
    public bookingAvailable: 'yes' | 'no' | 'request',
    public voucherAvailable: boolean,
    public isPackage: boolean,
    public texts: Record<string, ServiceTexts>,
    public isAvailableOn: number | null,
    public capacity: {
      inc: number,
      max: number,
      min: number
    },
    public pricePerPerson: Money,
    public price: Money,
    public uri: string,
    public serviceTags: Tag[],
    public promotionTags: Tag[],
    public categoryTags: Tag[],

    public offers: Array<Offer>,
    public images: Picture[],

    // Copiada del Spa
    public salesConfig: SpaSaleConfig,
  ) {
  }

  static from(dto: ServiceDTO, spaLocation: SpaLocation, spaDaysBeforeVoucherExpirate: number): Service {
    const texts: Service['texts']  = {}

    if (dto.translation) {
      texts[dto.translation.langcode] = {
        langcode: dto.translation.langcode,
        name: dto.translation.name,
        description: dto.translation.description,
        legalNotice: dto.translation.legal_conditions.legal_notice,
        politicalCondition: dto.translation.legal_conditions.political_condition,
        generalCondition: dto.translation.legal_conditions.general_condition,
        slug: dto.translation.slug,
        uri: dto.translation.uri,
      }
    }

    let uri = dto.uri

    if (dto.translations && dto.translations.length) {
      dto.translations.forEach((trans) => {
        if (!supportedLangs.includes(trans.langcode)) {
          return
        }

        texts[trans.langcode] = {
          langcode: trans.langcode,
          name: trans.name,
          description: trans.description,
          legalNotice: trans.legal_conditions.legal_notice,
          politicalCondition: trans.legal_conditions.political_condition,
          generalCondition: trans.legal_conditions.general_condition,
          slug: trans.slug,
          uri: trans.uri,
        }

        if (trans.langcode === config.runtime.language && trans.uri && trans.slug) {
          uri = trans.uri
        }
      })
    }

    const capacity: Service['capacity'] = {
      min: dto.capacity.minimum,
      max: dto.capacity.maximum,
      inc: dto.capacity.incrementing,
    }

    const serviceTags = dto.service_tags ? dto.service_tags.map((tag) => Tag.from(tag, config.runtime.language)) : []
    const promotionTags = dto.promotion_tags ? dto.promotion_tags.map((tag) => Tag.from(tag, config.runtime.language)) : []
    const categoryTags = dto.category_tags ? dto.category_tags.map((tag) => Tag.from(tag, config.runtime.language)) : []

    const offers = this.getValidOffers(
      dto.offers
        .map((offer) => ({
          startDate: (new Date(offer.start_date)).getTime(),
          endDate: (new Date(offer.end_date)).getTime(),
          daysByIndex: [
            offer.sunday,
            offer.monday,
            offer.tuesday,
            offer.wednesday,
            offer.thursday,
            offer.friday,
            offer.saturday,
          ],
          spaDiscountValue: offer.spa_discount_value,
          bonoDiscountValue: offer.bono_discount_value,
          discountMode: offer.discount_mode,
          startTime: offer.start_time,
          endTime: offer.start_time,
        })),
      new Date()
    )

    // HACK: Activar la petición de reserva para el URSO Spa
    //       UUID: 7df69c15-38a4-5ec1-8291-3b15f30e735f
    let bookingAvailable = dto.online_booking_available
    if (dto.spa_uuid === '7df69c15-38a4-5ec1-8291-3b15f30e735f') {
      bookingAvailable = 'request'
    }

    // // HACK: BlackFriday. Caducidad a 3meses para Callao Spa & Wellness y Salinas Spa & Wellness
    // 432e9289-d9d9-5a29-b20f-60d70f94b592
    // 6ed71d63-44ee-5c86-a743-18e34dd63955
    if (config.promos.isBlackFriday) {
      if (
        dto.spa_uuid === '432e9289-d9d9-5a29-b20f-60d70f94b592' ||
        dto.spa_uuid === '6ed71d63-44ee-5c86-a743-18e34dd63955'
      ) {
        const bf = Promotion.getBlackFriday()

        let isBF = false

        promotionTags.forEach((promo) => {
          if (bf.uuid === promo.uuid) {
            isBF = true
          }
        })

        if (isBF) {
          spaDaysBeforeVoucherExpirate = 90
        }
      }
    }

    return new Service(
      dto.uuid,
      dto.uuid_revision,
      dto.spa_uuid,
      spaLocation.country,
      dto.order,
      dto.status,
      dto.client_time_duration,
      bookingAvailable,
      dto.is_online_voucher_available,
      dto.is_package,
      texts,
      dto.has_availability_on,
      capacity,
      Money.from(dto.price_per_person),
      Money.from(dto.price),
      uri,
      serviceTags,
      promotionTags,
      categoryTags,
      offers,
      dto.images.map(Picture.from),
      {
        daysBeforeVoucherExpirate: spaDaysBeforeVoucherExpirate,
      }
    )
  }

  static isIndividual(self: Service | ServiceMiniList) {
    return self.capacity.min === 1
  }

  static addExtraImages(self: Service, images: Picture[], targetMax = 5) {
    const currentImages: Record<string, boolean> = {}
    self.images.forEach((img) => currentImages[img.uuid] = true)

    let i = 0
    while (self.images.length < targetMax && i < images.length) {
      const img = images[i]
      if (!currentImages[img.uuid]) {
        self.images.push(img)
      }
      i++
    }
  }


  static forEachTag(self: Service, func: ServiceForEachTagFunc) {
    self.serviceTags.forEach(func)
    self.promotionTags.forEach(func)
    self.categoryTags.forEach(func)
  }

  static getValidOffers(offers: Array<Offer>, date: Date): Offer[] {
    return offers.filter((offer: Offer) => {
      const day = date.getDay()

      if (!offer.daysByIndex[day]) {
        return false
      }

      const stamp = date.getTime()

      if (stamp < offer.startDate) {
        return false
      }

      if (stamp > offer.endDate) {
        return false
      }

      return true
    })
  }

  static hasAValidOffer(self: Service, date: Date): boolean {
    const offers = Service.getValidOffers(self.offers, date)

    return !!offers.length
  }

  static getVoucherCount(self: Service | ServiceMiniList, paxNumber?: number): number {
    let paxValue = paxNumber
    if (!paxValue) {
      paxValue = self.capacity.min
    }

    if (paxValue < self.capacity.min) {
      paxValue = self.capacity.min
    }

    const min = self.capacity.min
    const inc = self.capacity.inc
    return Math.ceil((min - inc - paxValue) / -inc)
  }

  /**
   * Normaliza el número de personas para que entre en el rango posible de valores.
   * Eg:
   *  Dado el caso: { min: 2, inc: 2 }
   *  'pax=3' se convertirá en 'pax=4' por ser el siguiente valor válido.
   *
   * @param self
   * @param paxNumber
   *
   * @returns
   */
  static normalizePaxNumber(self: Service, paxNumber: number): number {
    const voucherCount = this.getVoucherCount(self, paxNumber)

    const min = self.capacity.min
    const inc = self.capacity.inc

    return min + ( inc * (voucherCount - 1))
  }

  /**
   * Calcula el precio en céntimos para el pax dado
   *
   * @param self {Service}
   * @param paxNumber {number | null}
   *
   * @returns El precio del servicio en céntimos
   */
  static getBasePriceAmount(self: Service, paxNumber?: number): number {
    const voucherCount = this.getVoucherCount(self, paxNumber) // paxNumber || self.capacity.min
    const basePriceAmount = self.price.amount

    return basePriceAmount * voucherCount
  }

  static getBasePrice(self: Service, paxNumber?: number): Money {
    const priceAmount = this.getBasePriceAmount(self, paxNumber)
    return new Money(priceAmount, self.price.currency)
  }

  static getDiscountPercent(self: Service, date: Date): number {
    const basePrice = this.getBasePriceAmount(self)
    const discountedPrice = this.getDiscountedPriceAmount(self, date)

    if (!basePrice) {
      return 0
    }

    const invRatio = 1 - (discountedPrice / basePrice)
    return invRatio * 100
  }

  static getDiscountedPriceAmount(self: Service | ServiceMiniList, date: Date, paxNumber?: number): number {
    const offers = Service.getValidOffers(self.offers, date)

    const voucherCount = this.getVoucherCount(self, paxNumber)
    const basePriceAmount = self.price.amount
    let discount = 0

    offers.forEach((offer) => {
      if (offer.discountMode === 'percent') {
        discount += basePriceAmount * (offer.bonoDiscountValue / 100)
      } else if (offer.discountMode === 'fixed') {
        discount += (offer.bonoDiscountValue * 100)
      }
    })

    const finalPrice = basePriceAmount + discount
    return finalPrice * voucherCount
  }

  static getDiscountedPrice(self: Service, date: Date, paxNumber?: number): Money {
    const amount = this.getDiscountedPriceAmount(self, date, paxNumber)
    return new Money(amount, self.price.currency)
  }

  static hasAnyPromotionTag(self: Service | ServiceMiniList): boolean {
    return !!(self.categoryTags.length + self.promotionTags.length)
  }

  static hasPromotionTag(self: Service, promotionTag: Promotion) {
    return this.hasPromotionTagUUID(self, promotionTag.uuid)
  }

  static hasPromotionTagUUID(self: Service | ServiceMiniList, promoUuid: string) {
    for (const promo of self.promotionTags) {
      if (promo.uuid === promoUuid) {
        return true
      }
    }

    for (const categ of self.categoryTags) {
      if (categ.uuid === promoUuid) {
        return true
      }
    }

    return false
  }

  static compareWith(self: Service | ServiceMiniList, other: Service | ServiceMiniList, currentDate: Date): number {
    if (!currentDate) {
      return 0
    }

    const [amount1, amount2] = [
      Service.getDiscountedPriceAmount(self, currentDate),
      Service.getDiscountedPriceAmount(other, currentDate),
    ]

    if (amount1 < amount2) return -1
    if (amount1 > amount2) return 1
    return 0
  }
}

export class ServiceCollection {
  constructor(public services: Array<Service | ServiceMiniList>) {
  }

  public filterByCountry(country: Location) {
    this.services = this.services
      .filter((service) => service.countryCode === country.countryCode)
  }

  public filterByPromotionIDs(uuids: Array<string>) {
    this.services = this.services.filter((service) => {
      for (const uuid of uuids) {
        if (Service.hasPromotionTagUUID(service, uuid)) {
          return true
        }
      }

      return false
    })
  }

  public filterByPromotionCategoryUUID(promotionUUID: string) {
    const otherPromotionTagUUID = Promotion.getOther().uuid
    if (promotionUUID === otherPromotionTagUUID) {
      this.services = this.services
        .filter(
          (service) => !Service.hasAnyPromotionTag(service) || Service.hasPromotionTagUUID(service, promotionUUID)
        )
      return
    }

    this.services = this.services
      .filter((service) => Service.hasPromotionTagUUID(service, promotionUUID))
  }

  /**
   * Filtra los servicios por pareja si el paxNumber es par, por 'no pareja' si es impar
   *
   * @param paxNumber
   */
  public filterByPaxValue(paxNumber: number) {
    const isPaxValidForCouple = paxNumber % 2 === 0

    this.services = this.services.filter((service) => {
      const { min } = service.capacity

      if (!isPaxValidForCouple) {
        if (min == 2)   {
          return false
        }
      }

      return true
    })
  }

  /**
   * Filtra los servicios en función de si tiene alguna de las promociones
   *
   * @param promoUuids
   * @returns {void}
   */
  public filterByPromoCategoryUuids(promoUuids: string[]) {
    if (!promoUuids.length) {
      return
    }

    this.services = this.services.filter((service) => {
      for (const tag of service.promotionTags) {
        if (promoUuids.includes(tag.uuid)) {
          return true
        }
      }
      return false
    })
  }

  /**
   * Filtra los servicios que tienen el tratamiento dado
   *
   * @param treatmentUuid
   * @returns {void}
   */
  public filterByTreatmentUuid(treatmentUuid: string) {
    if (!treatmentUuid) {
      return
    }

    this.services = this.services.filter((service) => {
      for (const tag of service.categoryTags) {
        if (tag.uuid === treatmentUuid) {
          return true
        }
      }
      return false
    })
  }

  /**
   * Filtra los servicios en función de si tiene alguna de las promociones
   *
   * @param treatmentUuids
   * @returns {void}
   */
  public filterByTreatmentUuids(treatmentUuids: string[]) {
    if (!treatmentUuids.length) {
      return
    }

    this.services = this.services.filter((service) => {
      for (const tag of service.categoryTags) {
        if (treatmentUuids.includes(tag.uuid)) {
          return true
        }
      }
      return false
    })
  }

  public sortByStatus() {
    const serviceCollectionEnabled = this.services
      .filter((service) => service.status === 'enable')

    const serviceCollectionDisabledClosedByTemp = this.services
      .filter((service) => service.status !== 'enable')

    this.services = serviceCollectionEnabled.concat(
      serviceCollectionDisabledClosedByTemp
    )
  }

  public sortByCheaper(currentDate: Date) {
    this.services = this.services
      .sort((service1VO, service2VO) => Service.compareWith(service1VO, service2VO, currentDate))
    this.sortByStatus()
  }

  public sortByMostExpensive(currentDate: Date) {
    this.services = this.services
      .sort((service1VO, service2VO) => Service.compareWith(service1VO, service2VO, currentDate))
      .reverse()
    this.sortByStatus()
  }

  public sortByRecommended() {
    this.services = this.services.sort((a, b) => a.order - b.order)
    this.sortByStatus()
  }

  public sortByPromotion(promotion: Promotion) {
    const withPromo = this.services.filter((service) => {
      for (const tag of service.promotionTags) {
        if (tag.uuid === promotion.uuid) {
          return true
        }
      }

      return false
    })

    const withoutPromo = this.services.filter((service) => {
      for (const tag of service.promotionTags) {
        if (tag.uuid === promotion.uuid) {
          return false
        }
      }

      return true
    })

    this.services = withPromo.concat(withoutPromo)
    this.sortByStatus()
  }

  public getTheCheapest(): Service | ServiceMiniList | null {
    if (!this.services.length) {
      return null
    }

    let cheapest = this.services[0]

    for (const service of this.services) {
      if (service.price.amount < cheapest.price.amount) {
        cheapest = service
      }
    }

    return cheapest
  }

  public getTheMostExpensive(): Service | ServiceMiniList | null {
    if (!this.services.length) {
      return null
    }

    let expensive = this.services[0]

    for (const service of this.services) {
      if (service.price.amount > expensive.price.amount) {
        expensive = service
      }
    }

    return expensive
  }
}

export type ServiceMiniListTexts = {
  langcode: string,
  name: string,
  description: string,
  slug: string,
}

export class ServiceMiniList {
  constructor(
    public uuid: string,
    public spaUUID: string,
    public status: ServiceStatus,
    public order: number,

    public countryCode: string,

    public texts: Record<string, ServiceMiniListTexts>,

    public capacity: {
      inc: number,
      max: number,
      min: number
    },
    public pricePerPerson: Money,
    public price: Money,

    public offers: Array<Offer>,

    public serviceTags: Tag[],
    public promotionTags: Tag[],
    public categoryTags: Tag[],
  ) {
  }

  public static from(service: Service): ServiceMiniList {
    const texts: Record<string, ServiceMiniListTexts> = {}

    Object.entries(service.texts).forEach(([langcode, text]) => {
      texts[langcode] = {
        langcode,
        name: text.name,
        description: text.description,
        slug: text.slug,
      }
    })

    return new ServiceMiniList(
      service.uuid,
      service.spaUUID,
      service.status,
      service.order,

      service.countryCode,
      texts,
      service.capacity,
      service.pricePerPerson,
      service.price,

      service.offers,

      service.serviceTags,
      service.promotionTags,
      service.categoryTags,
    )
  }
}

export class ServiceMiniListCollection {
  constructor(public services: Array<ServiceMiniList>) {}

  public filterByPromotionIDs(uuids: Array<string>) {
    this.services = this.services.filter((service) => {
      for (const uuid of uuids) {
        if (Service.hasPromotionTagUUID(service, uuid)) {
          return true
        }
      }

      return false
    })
  }
}
