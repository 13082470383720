import { GetAwayPurchase } from '../ts/entity/GetAwayPurchase'
import type { VoucherPurchase } from '../ts/entity/VoucherPurchase'
import EventBus from './event-bus'

export default class FBAdsEventBus {
  static on(func: any): number {
    return EventBus.on('fb-ads-event', func)
  }

  static off(id: number) {
    return EventBus.off('fb-ads-event', id)
  }

  static send(name: string, meta: object | undefined) {
    EventBus.emit('fb-ads-event', name, meta)
  }

  static sendPurchaseVoucher(purchase: VoucherPurchase) {
    const value = purchase.pricing.final.amount / 100
    const currency = purchase.pricing.final.currency

    const meta = {
      currency,
      value,
    }
    this.send('Purchase', meta)
  }

  static sendPurchaseGetAway(purchase: GetAwayPurchase) {
    const rate = GetAwayPurchase.getSelectedRate(purchase)
    if (!rate) {
      return
    }
    const value = rate.basePriceWithDiscount.amount / 100
    const currency = rate.basePriceWithDiscount.currency

    const meta = {
      currency,
      value,
    }
    this.send('Purchase', meta)
  }
}
