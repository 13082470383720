import { supportedLangs } from '../lang-config'
import config from '../server-config'

import { capitalizeAllWords } from '../util/string'
import { translate } from '../util/translate'

import { Picture, type PictureDTO } from './Picture'
import { Service, type ServiceDTO, type ServiceForEachTagFunc } from './Service'
import type { SpaDTO, SpaLocation } from './Spa'
import { Tag, TagMini, type TagDTO } from './Tag'

export type GetAwayDTO = {
  uuid: string,
  uuid_revision: string,
  status: string,

  occupancy_resort: {
    hotel: {
      uuid: string,
      uuid_revision: string,
      name: string,
      slug: string,
      descriptions: Array<{
        langcode: string,
        description: string,
      }>,
      category: {
        type: string, // Eg. 4S
        name: string,
        value: number
      } | null,
      city: string,
      political_location_uuid: string,
      address: string,
      postal_code: string,
      country: string,
      latitude: number,
      longitude: number,
      contact: {
        email: string,
        web: string,
        phone: string
      },
      // Las escapadas tienen un array de habitaciones pero no se usa.
      // Las habitaciones disponibles se sacan de la info de disponibilidad.
      // rooms: [],
      images: Array<PictureDTO>,
    },
  },
  spa_resort: {
    spa: SpaDTO,
    services: Array<ServiceDTO>,
  }
  slug: string,
  uri: string,

  translations: Array<{
    langcode: string,
    name: string,
    short_name: string | undefined,
    description: string,
    slug: string,
    uri: string,
  }>,
  images: Array<PictureDTO>,

  amenities: Array<TagDTO>,
  facilities: Array<TagDTO>,
}

function concatenateHotelLiteralIfNeeded(originalName: string): string {
  if (!originalName) {
    return originalName
  }

  const lowercase = originalName.toLowerCase()

  if (lowercase.includes('hotel') || lowercase.includes('resort') || lowercase.includes('apartamento')) {
    return originalName
  } else {
    return `Hotel ${originalName}`
  }
}

function getLocation(dto: GetAwayDTO): SpaLocation {
  if (dto.spa_resort && dto.spa_resort.spa) {
    return {
      address: dto.spa_resort.spa.address,
      state: dto.spa_resort.spa.city,
      country: 'ES',
    }
  }

  return {
    address: dto.occupancy_resort.hotel.address,
    state: dto.occupancy_resort.hotel.city,
    country: 'ES',
  }
}

export type GetAwayHotel = {
  uuid: string,
  name: string,
  category: number,
  locationUUID: string,
  countryCode: string,
  images: Array<Picture>,
  texts: Record<string, { description: string }>,
}

export type GetAwaySpa = {
  uuid: string,
  name: string,
  score: number,
  location: SpaLocation,
  texts: Record<string, { description: string }>,
  images: Array<Picture>,
  services: Array<GetAwaySpaService>,
}

export class GetAwaySpaService {
  constructor(
    public uuid: string,

    public texts: Record<string, {
      // langcode: string,
      name: string,
    }>,

    public images: Array<Picture>,

    public serviceTags: Tag[],
    public promotionTags: Tag[],
    public categoryTags: Tag[],
  ) {}

  static forEachTag(self: GetAwaySpaService, func: ServiceForEachTagFunc) {
    self.serviceTags.forEach(func)
    self.promotionTags.forEach(func)
    self.categoryTags.forEach(func)
  }

  static from(service: Service): GetAwaySpaService {
    const texts: GetAwaySpaService['texts'] = {}

    Object.entries(service.texts).forEach(([lang, trans]) => {
      texts[lang] = {
        name: trans.name,
      }
    })
    return new GetAwaySpaService(
      service.uuid,
      texts,
      service.images,
      service.serviceTags,
      service.promotionTags,
      service.categoryTags,
    )
  }
}

export type GetAwayTexts = {
  name: string,
  shortName: string,
  description: string,
  uri: string,
}

export class GetAway {
  constructor(
    public uuid: string,
    public uuidRevision: string,
    public uri: string,
    public includesSpa: boolean,
    public hotel: GetAwayHotel,
    public images: Array<Picture>,
    public spa: GetAwaySpa,
    public texts: Record<string, GetAwayTexts>,
    public amenities: Array<TagMini>,
    public facilities: Array<TagMini>,
  ) {
  }

  static from(dto: GetAwayDTO): GetAway {
    const hotelName = capitalizeAllWords(
      concatenateHotelLiteralIfNeeded(
        dto.occupancy_resort.hotel.name
      )
    )

    const category = (() => {
      const categoryObj = dto.occupancy_resort.hotel.category
      if (!categoryObj) {
        return 0
      }

      return categoryObj.value || 0
    })()

    const spatexts: GetAwaySpa['texts'] = {}
    dto.spa_resort.spa.translations.forEach((trDto) => {
      if (!supportedLangs.includes(trDto.langcode)) {
        return
      }

      spatexts[trDto.langcode] = {
        description: trDto.long_description,
      }
    })

    const location = getLocation(dto)

    const spaHasImages = !!dto.spa_resort.spa.images.length

    let daysBeforeVoucherExpirate = 0
    if (dto.spa_resort.spa.sales_configuration && dto.spa_resort.spa.sales_configuration.days_before_voucher_expirate) {
      daysBeforeVoucherExpirate = dto.spa_resort.spa.sales_configuration.days_before_voucher_expirate
    }

    const spa: GetAwaySpa = {
      uuid: dto.spa_resort.spa.uuid,
      name: dto.spa_resort.spa.name,
      location,
      score: dto.spa_resort.spa.rating !== null ? Number(dto.spa_resort.spa.rating.value) : 0,
      texts: spatexts,
      images: dto.spa_resort.spa.images.map(Picture.from).slice(0, 1),
      services: dto.spa_resort.services.map((servDTO) => GetAwaySpaService.from(Service.from(
        servDTO,
        location,
        daysBeforeVoucherExpirate,
      ))),
    }

    const hotelTexts: Record<string, { description: string }> = {}

    dto.occupancy_resort.hotel.descriptions.forEach((trDto) => {
      if (!supportedLangs.includes(trDto.langcode)) {
        return
      }
      hotelTexts[trDto.langcode] = { description: trDto.description }
    })

    const hotel: GetAwayHotel = {
      uuid: dto.occupancy_resort.hotel.uuid,
      name: hotelName,
      category,
      locationUUID: dto.occupancy_resort.hotel.political_location_uuid,
      countryCode: dto.occupancy_resort.hotel.country.toLocaleUpperCase(),
      images: spaHasImages ? [] : dto.occupancy_resort.hotel.images.map(Picture.from).slice(0, 1),
      texts: hotelTexts,
    }

    const texts: Record<string, GetAwayTexts> = {}
    dto.translations.forEach((trans) => {
      if (!supportedLangs.includes(trans.langcode)) {
        return
      }

      texts[trans.langcode] = {
        name: trans.name,
        shortName: trans.short_name || '',
        description: trans.description,
        uri: trans.uri,
      }
    })

    //
    const IMAGES_MIN_COUNT = 5
    const images = dto.images.map(Picture.from).slice(0, IMAGES_MIN_COUNT)

    if (dto.occupancy_resort && dto.occupancy_resort.hotel) {
      const countToGetFromHotel = Math.max(IMAGES_MIN_COUNT - images.length, 0)
      dto.occupancy_resort.hotel.images
        .slice(0, countToGetFromHotel)
        .forEach((imageDto) => images.push(Picture.from(imageDto)))
    }

    if (dto.spa_resort && dto.spa_resort.spa) {
      const countToGetFromSpa = Math.max(IMAGES_MIN_COUNT - images.length, 0)
      dto.spa_resort.spa.images
        .slice(0, countToGetFromSpa)
        .forEach((imageDto) => images.push(Picture.from(imageDto)))
    }

    if (!images.length) {
      const picture = Picture.getDefaultImage()
      images.push(picture)
    }

    // Se coge la URL que tenga el idioma en el que se está generando la web o la que viene por defecto: 'ES'
    let uri = dto.uri
    dto.translations.forEach((trans) => {
      if (trans.langcode === config.runtime.language && trans.uri) {
        uri = trans.uri
      }
    })

    let includesSpa = false
    if (dto.spa_resort && dto.spa_resort.services) {
      includesSpa = !!dto.spa_resort.services.length
    }

    const amenities = dto.amenities.map((tag) => TagMini.from(tag, config.runtime.language))
    const facilities = dto.facilities.map((tag) => TagMini.from(tag, config.runtime.language))

    return new GetAway(
      dto.uuid,
      dto.uuid_revision,
      // dto.status === 'active' ? 'active' : 'inactive',
      // dto.slug,
      uri,
      includesSpa,
      hotel,
      images,
      spa,
      texts,
      amenities,
      facilities,
    )
  }

  static getTagUUIDs(self: GetAway): Array<string> {
    const uuids: Array<string> = []
    self.spa.services.forEach((service) => {
      GetAwaySpaService.forEachTag(service, (tag: Tag) => {
        uuids.push(tag.uuid)
      })
    })
    return uuids
  }

  static hasTagUUID(self: GetAway, tagUUID: string): boolean {
    for (const service of self.spa.services) {
      GetAwaySpaService.forEachTag(service, (tag: Tag) => {
        if (tagUUID === tag.uuid) {
          return true
        }
      })
    }

    return false
  }

  static getMiniDataImages(self: GetAway): Array<{image: Picture, name: string}> {
    const miniData: Array<{image: Picture, name: string}> = []

    // Habitación
    const roomImages = self.hotel.images.filter((img) => img.rel === 'room')
    if (roomImages.length) {
      miniData.push({
        image: roomImages[0],
        name: 'Habitación para dos',
      })
    }

    if (self.spa.services.length) {
      const name = self.spa.services.map((service) => {
        return translate(service.texts).name
      }).join(' + ')
      const imageCollection: Array<Picture> = []
      self.spa.services
        .forEach(
          (service) => service.images.forEach((img) => imageCollection.push(img))
        )

      if (imageCollection.length) {
        miniData.push({
          image: imageCollection[0],
          name,
        })
      }
    }

    // Hotel
    const hotelImages = self.hotel.images.filter((img) => img.rel === 'hotel')
    if (hotelImages.length) {
      miniData.push({
        image: hotelImages[0],
        name: 'Instalaciones',
      })
    }

    return miniData
  }

  public static normalizePaxNumber(paxNumber: number): number {
    if (paxNumber < 2) {
      return 2
    }

    return paxNumber + (paxNumber % 2)
  }
}
